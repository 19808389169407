//@flowDefaultSaleService

import type { Sale } from './../../types';
import { BaseSaleService } from './base-sale-service';

import { SkyworkRestClient } from './../../util/skywork-rest-client';
import { LocalDatabase } from './../../util/local-database';
import * as Events from './../../events';

export class DefaultSaleService extends BaseSaleService {
    
    _eventEmitter: Events.AppEventEmitter;
    _restClient: SkyworkRestClient;
    _localDatabase: LocalDatabase;
    _pendingInvoiceRequests: Array<string>;

    constructor(
        eventEmitter: Events.AppEventEmitter,
        restClient: SkyworkRestClient,
        localDatabase: LocalDatabase
    ){
        super();
        this._eventEmitter = eventEmitter
        this._restClient = restClient;
        this._localDatabase = localDatabase;
        this._pendingInvoiceRequests = [];
    }

    async insertSale(sale: Sale): Promise<void>{
        return Promise.resolve(undefined);
    }

    getPendingInvoiceRequests(): Array<string>{
        return this._pendingInvoiceRequests;
    }

    async requestInvoice(sale: Sale): Promise<void>{
        let error = null;

        let isFirstRequest = sale.invoiceData == null;

        this._eventEmitter.emit({
            name:Events.SaleServiceEvents.OnSaleInvoiceRequested,
            data: sale,
            error
        });

        try{
            this.registerInvoiceRequest(sale.saleId);

            let response = await this._restClient.emiteNotaPedido({
                id_sistema_externo: sale.saleId||'',
                cad_unidade_negocio_id: sale.businessUnitId
            });
            if(response.erro || response.nfce_status_id!='6' || !response.arquivo_xml){                    
                throw new Error(`Não foi possível emitir a nota. Status: ${response.status_nome||'?'} ${response.erro?`(${response.erro})`:''}`);
                this._eventEmitter.emit({name:Events.SaleServiceEvents.OnSaleInvoiceCreated, data: {sale}, error});
            }
            
            sale.invoiceData = response.arquivo_xml
            this._localDatabase.insertOrReplaceSale(sale);
        }
        catch(e){
            error = e;
        }
        this.unregisterInvoiceRequest(sale.saleId);
        this._eventEmitter.emit({
            name:Events.SaleServiceEvents.OnSaleInvoiceCreated,
            data: { sale, isFirstRequest },
            error
        });
    }

    registerInvoiceRequest(id: string){
        if(this._pendingInvoiceRequests.indexOf(id)!==-1)
            return;
        this._pendingInvoiceRequests.push(id);
    }

    unregisterInvoiceRequest(id: string){
        let index = this._pendingInvoiceRequests.indexOf(id);
        if(index!==-1){
            this._pendingInvoiceRequests.splice(index,1);
        }
    }
}
