//@flow

import { EventEmitter } from './../../util/event-emitter';
import type { IBlockerService, BlockReason } from './types';

export class MockBlockerService implements IBlockerService {

    constructor(){

    }
    
    isBlocked(): bool
    {
        return this.getBlockedReasons().length > 0;
    }

    getBlockedReasons(): Array<BlockReason>
    {
        return [];
    }
}