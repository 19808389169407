//@flow
import * as React from 'react';
import { DiscountTextField } from './../../../../components/DiscountTextField';
import { DiscountTextField2 } from './../../../../components/DiscountTextField2';

type DiscountTextFieldDemoProps = {

}

type DiscountTextFieldDemoState = {
    value: number
}

const refValue = 1000;

class DiscountTextFieldDemo extends React.Component<DiscountTextFieldDemoProps,DiscountTextFieldDemoState>{

    constructor(props: DiscountTextFieldDemoProps){
        super(props);
        this.state = {
            value: 5.05
        };
    }

    render(){
        return (<React.Fragment>
            <div><DiscountTextField
                refValue={refValue}
                value={this.state.value}
                onMoneyChange={(e: any, money: number)=>{
                    this.setState({value: money})
                }}
            /></div>
            <div><DiscountTextField2
                refValue={refValue}
                value={this.state.value}
                onMoneyChange={(e: any, money: number)=>{
                    this.setState({value: money})
                }}
            /></div>
        </React.Fragment>);
    }
}

export default { key: 'discount-text-field', demoComponent: DiscountTextFieldDemo };