//@flow

import { BaseCollectionDownSynchronizer } from './../base-collection-down-synchronizer'
import type { GetPaisesResponse } from './../../../../util/skywork-rest-client/types';
import type { Country } from './../../../../types';
import type { SynchronizationEntitiesKeys } from './../../types';

export class CountriesDownSynchronizer extends BaseCollectionDownSynchronizer<GetPaisesResponse,Country> {

    getEntityKey(): SynchronizationEntitiesKeys{
        return 'country';
    }

    getDataFromRemoteSource(): Promise<GetPaisesResponse>{
        return this._restClient.getPaises({fields: [
            'pais_id',
            'nome'
        ]})
    }

    convertRemoteData(reponse: GetPaisesResponse): Array<Country>{
        return reponse.map(item =>{
            let city: Country = {countryId: item.pais_id.toString(), name: item.nome};
            return city;
        })
    }

    async storeConvertedData(items: Array<Country>): Promise<Array<any>>{
        await this._localDatabase.batchInsertOrReplaceCountries(items);
        return items.map(x => x.countryId);
    }
}