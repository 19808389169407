import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

type MoreMenuProps = {
    iconProps?: any,
    menuProps?: any,
    menuItemProps?: any,
    onFullSyncClick: (e:any)=>void
}

export class MoreMenu extends React.Component<MoreMenuProps,any>{
    constructor(props:MoreMenuProps){
        super(props);
        this.state ={anchorEl:null};
    }

    handleClick=(e)=>{
        this.setState({anchorEl:e.currentTarget});
    }

    handleClose=(e)=>{
        this.setState({anchorEl:null});
    }

    handleFullSyncClick=(e)=>{
        this.handleClose();
        this.props.onFullSyncClick(e);
    }

    render(){
        let { anchorEl } = this.state;
        return (<React.Fragment>
            <IconButton {...this.props.iconProps} onClick={this.handleClick}><MoreVert /></IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                {...this.props.menuProps}
                onClose={this.handleClose}
            >
                <MenuItem {...this.props.menuItemProps} onClick={this.handleFullSyncClick}>Sincronização Total Agora</MenuItem>
            </Menu>
        </React.Fragment>);
    }
}