//@flow

export const appStyle = {
    componentApp: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxHeight: '100%',
        maxWidth: '100%'
    }
}

export const buttonStyle = {
    componentButtonWide: {
        width: '50%'
    },
    componentButton: {
        fontSize: '1.5rem',
        fontWidth:'300',
        flex: '1 0 auto',
        padding: '0',
        width: '25%',
        textAlign: 'center',
        lineHeight: '2em',
        position: 'relative',
        fontFamily: 'Roboto',
        fontWeight: '200',
        color:'white',
    },
    componentButtonTip: {
        position:'absolute',
        marginTop:'1.1rem',
        fontSize: '50%',
        opacity: '.5'
    },
    componentButtonOperation: {
        backgroundColor: 'RGBA(255,255,255,.1)'
    },
    componentButtonLabel:{
        top:'50%',
        marginTop:'-1em',
        position: 'absolute'
    }
}

export const buttonPanelStyle = (theme: any) => {
    return {
        componentButtonPanel: {
            backgroundColor: theme.palette.primary.dark,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: '1 0 auto',
            userSelect: 'none'
        },
        componentButtonPanelChild: {
            width: '100%',
            flex: '1 0 auto',
            display: 'flex'
        }
    }
};

export const displayStyle = {
    componentDisplay: {
        textAlign: 'right',
        flex: '0 0 auto',
        width: '100%',
        background: 'white',
        padding: '1em',
        fontSize: '200%'
    }
}