//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { withContainerFineGrained } from './../../container';
import type { IContainer, IDataStoreService, ISaleService } from './../../container';
import * as Events from './../../events';
import type { Customer, Sale, SyncState } from './../../types';
import { SaleRow } from './components/SaleRow';
import { MaterialLink } from './../../components/MaterialLink';

const styles = theme => ({
    root:{
        height:'100%',
        position:'relative',
        display:'flex',
        flexDirection: 'column'
    },
    actionsCol: {
        width: '72px'
    },
    filterWrapper:{
        padding: 16,
        borderBottom: 'solid 1px #ddd'
    },
    tableWrapper:{
        overflowY:'scroll'
    },
});

type SalesProps = {
}

export type SalesExtendedProps = {
    classes: any,
    theme: any,
    dataStoreService: IDataStoreService,
    saleService: ISaleService,
    eventEmitter: Events.AppEventEmitter,
    invoiceEnabled: bool
} & SalesProps;

type SalesState = {
    lastSales: Array<{sale:Sale, customer: ?Customer}>,
    salesSyncState: Array<SyncState>,
    expandedItemAnchor: any,
    expandedItemOpen: bool
}

class SalesExtended extends React.Component<SalesExtendedProps,SalesState>{

    closeTimeout: any;
    mounted: bool = false;

    constructor(props: SalesExtendedProps){
        super(props);
        this.state = {
            lastSales: [],
            salesSyncState: [],
            expandedItemAnchor: null,
            expandedItemOpen: false,
        };
    }

    handleAppEvent = (event: Events.AppEvent) => {
        if(event.name === Events.SaleServiceEvents.OnSaleInvoiceCreated){
            this.reloadSales();
        }
        if(event.name === Events.SaleServiceEvents.OnSaleInvoiceRequested){
            this.forceUpdate();
        }
    }

    componentDidMount = ()=>{
        this.props.eventEmitter.subscribe(this.handleAppEvent);
        this.mounted = true;
        this.reloadSales();
    }

    componentWillUnmount = () => {
        this.mounted = false;
        this.props.eventEmitter.unsubscribe(this.handleAppEvent);
    }

    async reloadSales(){
        let stateUpdate = {};
        await this.props.dataStoreService.listSales()
            .then(sales => stateUpdate.lastSales = sales);
        await this.props.dataStoreService.listSyncState('sales').then(salesSyncState => stateUpdate.salesSyncState = salesSyncState);
        this.setState(stateUpdate);
        
    }

    closeExpandedItem(){
        this.setState({expandedItemOpen: false});
    }

    getExpandedSale() : { sale: Sale, customer: ?Customer } {
        let index = parseInt(this.state.expandedItemAnchor.dataset.index)
        return this.state.lastSales[index]
    };

    handleCloseMoreClick = (e: any) => {
        this.closeExpandedItem();
    }

    handleUnlockSyncClick = (e: any) =>{
        const sale = this.getExpandedSale();
        this.props.dataStoreService.insertOrReplaceSyncState({table:'sales', id: sale.sale.saleId, state: 'pending'}).then(()=>{
            this.closeExpandedItem();
            return this.reloadSales();
        });
    }

    handleItemPrintClick= (e: any) =>{
        (async () => {
            let sale = this.getExpandedSale();
            if(sale.sale.invoiceData!=null){
                try{
                    await this.props.saleService.requestInvoice(sale.sale);
                }
                catch{
                    console.log('Failed to request invoice.');
                }
            }
            this.props.eventEmitter.emit({
                name: Events.UIEvents.OnSalePrintRequested,
                data: { sale: sale.sale, asap: true },
                error: null,
            });
            if(this.mounted){
                this.closeExpandedItem();
            }
        })();
    }

    handleItemDetailClick= (e: any) => {
        let sale = this.getExpandedSale();
        alert('detail');
        this.closeExpandedItem();
    }

    handleItemRequestInvoiceClick = (e: any) => {
        let sale = this.getExpandedSale();
        this.props.saleService.requestInvoice(sale.sale);
        this.closeExpandedItem();
    }

    handleMoreClick = (e: any) => {
        this.setState({expandedItemAnchor: e.currentTarget, expandedItemOpen: true });
    }

    handleRemoveClick = (e: any) => {
        let sale = this.getExpandedSale();
        alert('removed');
        this.closeExpandedItem();
    }

    render(){
        let { classes } = this.props;
        let pendingInvoiceRequest = this.props.saleService.getPendingInvoiceRequests();

        return (
        <React.Fragment>
            <div className={ classes.root }>
                <div className={ classes.filterWrapper }>
                    <p>Apenas as vendas efetuadas neste PDV são listadas abaixo.</p>
                    <p>Caso a venda desejada não esteja na lista, acesse <MaterialLink target="_blank" href={'https://sistema.skywork.com.br/?on=comercial_pedidos&menu=1'}>Skywork > Vendas</MaterialLink> para visualizá-la.</p>
                </div>
                <div className={ classes.tableWrapper }>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3}>Venda</TableCell>
                                <TableCell className={ classes.actionsCol }>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.lastSales.length===0?(<TableRow><TableCell colSpan={5}>Nenhuma venda encontrada.</TableCell></TableRow>):(null)}
                            {this.state.lastSales.map((sale,i) =>{
                                const isSyncBlocked: bool = this.state.salesSyncState.findIndex(x => x.id===sale.sale.saleId && x.state==='blocked')!==-1;
                                return (
                                    <SaleRow
                                        classes={ classes }
                                        key={i}
                                        index={i}
                                        sale={sale.sale}
                                        customer={sale.customer}
                                        onDetailClick={this.handleItemDetailClick}
                                        onPrintClick={this.handleItemPrintClick}
                                        onRequestInvoiceClick={this.handleItemRequestInvoiceClick}
                                        isRequestingInvoice={pendingInvoiceRequest.indexOf(sale.sale.saleId)!==-1}
                                        onMoreClick={this.handleMoreClick}
                                        onRemoveClick={this.handleRemoveClick}
                                        expandedItemAnchor={this.state.expandedItemAnchor}
                                        expandedItemOpen={this.state.expandedItemOpen}
                                        onCloseMoreClick={this.handleCloseMoreClick}
                                        total={this.props.saleService.calculateTotals(sale.sale).total}
                                        invoiceEnabled={this.props.invoiceEnabled}
                                        saleSyncBlocked={isSyncBlocked}
                                        onUnlockSyncClick={this.handleUnlockSyncClick}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </React.Fragment>
        );
    }
}


let Sales: React.ComponentType<SalesProps> = withContainerFineGrained(
    withStyles(styles, { withTheme: true })(SalesExtended),
    (container,onDestroySlot) => {        
        let bu = container.getPreferencesService().getBusinessUnit();
        if(bu==null) throw new Error('É necessário uma unidade de negócio para ver as vendas.');
        return {
            saleService: container.getSaleService(),
            dataStoreService: container.getDataStoreService(),
            eventEmitter: container.getEventEmitter(),
            invoiceEnabled: bu.invoiceEnabled
        };
    }
);


export { Sales };
