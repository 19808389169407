//@flow
import * as React from 'react';
import { DefaultPadding } from './../../components/DefaultPadding'
import { Link } from 'react-router-dom';
import { List, ListItem, ListSubheader, Paper,Typography } from '@material-ui/core';
import { containerFactory } from './../../container';
import { withStyles } from '@material-ui/core/styles';

let routeBUilder = containerFactory().getRouteBuilderService();

const styles = (theme: any) => {
    return {
        highlightedItem: {
            fontWeight: 500,
            color: theme.palette.primary.main
        }
    }
}

type HomeDumbProps = {
    classes: any,
    theme: any
}

export class HomeDumb extends React.Component<HomeDumbProps, void>
{
    render(){
        const { classes } = this.props;
        const linkTarget = '_blank';

        return (
            
        <DefaultPadding style={{overflowY:'auto', height:'100%'}}>
            <h2>Bem vindo!</h2>
            <div style={{display:'flex'}}>
                
                <Paper style={{flex:1}}>
                    <List component={'nav'} subheader={<ListSubheader component="div">Frente de Caixa</ListSubheader>}>
                        <ListItem className={classes.highlightedItem} button component={Link} to={routeBUilder.saleRoute()} >Começar a Vender</ListItem>
                        <ListItem button component={Link} to={routeBUilder.salesRoute()} >Últimas Vendas</ListItem>
                    </List>
                </Paper>
                
                <div style={{flex:'16px 0 0'}}></div>
                
                <Paper style={{flex:1}}>
                    <List component={'nav'} subheader={<ListSubheader component="div">Skywork (Online)</ListSubheader>}>
                        <ListItem button component={'a'} target={linkTarget} href={'https://sistema.skywork.com.br/?on=cadastro_clientes&menu=1'}>Clientes</ListItem>
                        <ListItem button component={'a'} target={linkTarget} href={'https://sistema.skywork.com.br/?on=estoque_produtos&menu=1'}>Produtos</ListItem>
                        <ListItem button component={'a'} target={linkTarget} href={'https://sistema.skywork.com.br/?on=cadastro_financeiro&menu=1'}>Dados Financeiros</ListItem>
                        <ListItem button component={'a'} target={linkTarget} href={'https://sistema.skywork.com.br/?on=comercial_pedidos&menu=1'}>Vendas</ListItem>
                        <ListItem button component={'a'} target={linkTarget} href={'https://sistema.skywork.com.br/?on=nfce&menu=1'}>Acessar NFC-e</ListItem>
                        <ListItem button component={'a'} target={linkTarget} href={'https://sistema.skywork.com.br/?on=contabil_fluxo_caixa&menu=1'}>Fluxo de Caixa</ListItem>

                    </List>
                </Paper>
                
            </div>
        </DefaultPadding>
        
        );
    }
}

export const Home: React.ComponentType<{}> = withStyles(styles, { withTheme: true })(HomeDumb);