//@flow
import type { BusinessUnit } from './../../types';
import type { GetUnidadeNegocioItemResponse } from './types';
export function unidadeDeNegocioToBusinessUnit(un: GetUnidadeNegocioItemResponse): BusinessUnit {
    let businessUnit: BusinessUnit = {
        businessUnitId: un.cad_unidade_negocio_id,
        name: un.nome,
        address:{
            cityName: un.cidade,
            provinceName: un.estado,
            provinceCode: un.uf,
            postalCode: un.cep, // un.cep
            neighborhood: un.bairro,
            streetName: un.endereco,
            number: un.numero,
            extraInfo: un.complemento,
        },
        phone: un.telefone,
        municipalRegistrationNumber: un.inscricao_municipal,
        provinceRegistrationNumber: un.inscricao_estadual,
        document: un.cnpj,
        invoiceEnabled: un.nota_liberada==='1'
    }

    return businessUnit;
}