//@flow
import * as React from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { GridOn as IconGrid } from '@material-ui/icons';

const styles = theme => ({
    root: {
        position:'relative',
        minWidth: '0',
        marginRight: 8
    },
    icon:{
        color: theme.palette.primary.contrastText,
    }
});

type CalculatorButtonProps = { onClick: ()=>void }
type CalculatorButtonState = {}
type CalculatorButtonThemedProps = CalculatorButtonProps & { classes: any, theme: any }

class CalculatorButtonThemed extends React.Component<CalculatorButtonThemedProps,CalculatorButtonState>{

    handleClick(){
        this.props.onClick();
    }

    render(){
        let { classes, theme } = this.props;
        return (
            <IconButton onClick={this.handleClick.bind(this)} className={classes.root}>
                <IconGrid className={classes.icon} />
            </IconButton>
        );
    }
}

let CalculatorButton: React.ComponentType<CalculatorButtonProps> = (withStyles(styles, { withTheme: true })(CalculatorButtonThemed): any);
export { CalculatorButton };