//@flow
import * as React from 'react';
import { Paper, TextField } from '@material-ui/core';
import { Validation } from './../../../../util/validation';
import { ValidationBox } from './../../../../components';

type ValidationDemoProps = {

}

type ValidationDemoState = {
    email: string
}

const Wrapper = ({children}: {children: any})=>{
    return <Paper style={{ padding:'24px' }}>{children}</Paper>;
}

class ValidationDemo extends React.Component<ValidationDemoProps,ValidationDemoState>{

    sampleRef: any;

    constructor(props: ValidationDemoProps){
        super(props);
        this.state = {
            email: ''
        };

        this.sampleRef = React.createRef();
    }

    handleEmailChange(e: any){
        this.setState({email: e.target.value});
    }



    render(){

        let validation1 = new Validation();
        validation1.validateRequiredString('email', this.state.email);

        return <div>
            <Wrapper>
                <h2>Class Validation</h2>
                <p>Cria objetos para fazer validações instantâneas do estado de um componente, retornando se ele é válido ou não e armazenando os erros de validações agrupados por campo (<i>field</i>).</p>
                <p>A validação pode ser feita no método <i>render</i>. Os objetos criados podem ser imediatamente descartados ou reaproveitados, fazendo-se uso do método <i>reset</i>. Istância de Validations responsabilizam exclusivamente por validar, e não por apresentar os erros visualmente.</p>
                <pre dangerouslySetInnerHTML={{ __html: `let validation = new Validation();
validation.validateRequiredString(
    'email',
    this.state.email
);

...
// more validations
...

if(!validation.isValid()){
    // show global form error with some component
}

if(!validation.hasError('email')){
    // show error for specific field using some component
    // component and validation.getErrors('email')
}
` }}></pre>
            </Wrapper>

            <p></p>

            <Wrapper>
                <h2>Component ValidationBox</h2>
                <p>Uma caixa de exibição de erros de validação. Apenas espera um array de strings como entrada.</p>
                <p>Esse erro será sempre exibido indiferente do valor do input.</p>
                <div>
                    <input type="text" />
                    <ValidationBox errors={['Erro 1', 'Erro 2']} />
                </div>
            </Wrapper>
        </div>;
    }
}

export default { key: 'validation', demoComponent: ValidationDemo };