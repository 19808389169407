//@flow
import * as Events from './../../events';
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { SynchronizationWidget } from './components/SynchronizationWidget';
import { MoreMenu } from './components/MoreMenu';
import type { SynchronizationEntityData } from './../../services/synchronization-service/types';
import { containerFactory } from './../../container';
import { resources } from './../../util/resources';

const container = containerFactory();
const synchronizationService = container.getSynchronizationService();


const styles = theme => ({
    moreMenuWrapper: {
        position:'absolute',
        right: 8,
        top: 8,
    },
    dialogContent: {
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    }
});

type SynchronizationDialogProps = {
    open: bool,
    onCloseClick: ()=>void
}

type SynchronizationDialogState = {
    primaryText: string,
    secondaryText: string,
    title: string,
    spin: bool
}

type SynchronizationDialogThemedProps = SynchronizationDialogProps & {
    classes: any,
    theme: any
}



class SynchronizationDialogThemed extends React.Component<SynchronizationDialogThemedProps,SynchronizationDialogState>{

    constructor(props: SynchronizationDialogThemedProps){
        super(props);
        this.state = this.resolveStateFromService();
    }

    resolveStateFromService(): SynchronizationDialogState{

        let title = 'Aguardando';
        let primaryText = 'próxima sincronização';
        let secondaryText = '';
        let spin = false;

        if(synchronizationService.isInSync()){
            title = 'Sincronizando'; spin=true;
            let lastEntityEvent = synchronizationService.getLastEntitySyncEvent();
            primaryText = '-';
            secondaryText = '';
            if(lastEntityEvent!=null){
                let data: SynchronizationEntityData = lastEntityEvent.data;
                primaryText = resources.getSynchronizationLabel(data.entityKey);
                secondaryText = data.download? 'Recebendo registros...': 'Enviando registros...'; //TODO, verificar o que fazer
            }
        }
        
        return { title, primaryText, secondaryText, spin };
    }

    componentDidMount(){
        Events.emitter.subscribe(this.handleAppEvent);
    }

    componentWillUnmount(){
        Events.emitter.unsubscribe(this.handleAppEvent);
    }

    handleAppEvent = (event: Events.AppEvent) => {
        if(Events.SynchronizationServiceEventsArray.indexOf(event.name)!=-1){
            this.setState(this.resolveStateFromService());
        }
    }

    handleForceSyncClick = () => {
        synchronizationService.startSync(false);
    }

    handleHideClick = () => {
        this.props.onCloseClick();
    }

    handleFullSyncClick = () => {
        synchronizationService.startSync(true);
    }

    render(){

        let { open, classes } = this.props;
        let { primaryText, secondaryText, spin, title } = this.state;

        return (<Dialog open={open} onBackdropClick={this.handleHideClick} >
            <DialogContent className={classes.dialogContent}>
                <div>
                    <div className={classes.moreMenuWrapper}>
                        <MoreMenu
                            onFullSyncClick={this.handleFullSyncClick}
                        />
                    </div>
                    <SynchronizationWidget 
                        spin={spin}
                        title={title}
                        primaryText={primaryText}
                        secondaryText={secondaryText} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleHideClick}>Ocultar</Button>
                <Button variant="contained" color="primary" disabled={synchronizationService.isInSync()} onClick={this.handleForceSyncClick}>Sincronizar Agora</Button>
            </DialogActions>
        </Dialog>);
    }
}

let SynchronizationDialog: React.ComponentType<SynchronizationDialogProps> = (withStyles(styles, { withTheme: true })(SynchronizationDialogThemed): any);

export { SynchronizationDialog };