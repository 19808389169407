//@flow

export interface IEvent<TName>{
    name: TName,
    data: any,
    error: any,
    correlationKey?: string
}

export type BusinessUnit = {
    businessUnitId: string,
    name: string,
    address: {
        cityName: string,
        extraInfo:string,
        number: string,
        provinceName: string,
        provinceCode: string,
        postalCode: string,
        neighborhood: string,
        streetName: string,        
    },
    phone:string,
    provinceRegistrationNumber: string,
    municipalRegistrationNumber: string,
    document:string,
    invoiceEnabled: bool
}

export type Product = {
    productId: string,
    barCode: string,
    name: string,
    sku: string,
    internalId: string,
    sellValue: number,
    unit: string
}


export type PaymentMethodType = 'money'|'other';

export type PaymentMethod = {
    paymentMethodId: string,
    type: PaymentMethodType,
    description: string
}

export type City = {
    cityId: string,
    name: string,
    provinceId: string
}

export type Country = {
    countryId: string,
    name: string
}

export type Province = {
    provinceId: string,
    name: string,
    shortName: string
}

export type PaymentCondition = {
    paymentConditionId: string,
    description: string
}

export type SaleItem = {
    product: Product,
    amount: number,
    adjustedSellValue: number,
    discount: number
}

export type Sale = {
    saleId?: string, //apenas um id local autogerado. É nulável para registros novos
    items: Array<SaleItem>,
    paymentMethodId: string,
    paymentConditionId: string,
    customerId: ?string, // O cliente, por referência. Será que não seria legal guardar o estado na conclusão da venda?
    autoFetchInvoice: bool, // Se é necessário emitir uma nota assim que possível - sincronização - talvez devesse sair daqui.
    invoiceData?: string, // O xml retornado
    date: string,
    businessUnitId: string,
    paidValue: number
}

export const CUSTOMER_TYPES = {
    PERSON: 'PERSON',
    COMPANY: 'COMPANY'
}

export type CustomerType = $Keys<typeof CUSTOMER_TYPES>;

export const TAX_PAYER_TYPES = {
    TAX_PAYER: 'TAX_PAYER',
    TAX_PAYER_FREE: 'TAX_PAYER_FREE',
    NOT_TAX_PAYER: 'NOT_TAX_PAYER'
}

export type TaxPayerType = $Keys<typeof TAX_PAYER_TYPES>;

export type Customer = {
    customerId: string,
    tempCustomerId?: string,
    name: string,
    type: CustomerType|'',
    cpf_cnpj: string,
    ie: string,
    ICMSTaxpayerType: ?TaxPayerType,
    email: string,
    phoneNumber: string,
    address: {
        streetName: string,
        number: string,
        postalCode: string,
        neighborhood: string,
        extraInfo: string,
        cityId: ?string,
        provinceId: ?string,
        countryId: ?string,
    },
    observation: string
}

export type PrintRequest = {
    printRequestId?: string,
    description: string,
    type: string,
    data: {[key:string]:any},
    ready: bool
}

export type SyncStateCode = 'blocked'|'pending';

export type SyncState = {
    table: string,
    id: any,
    state: SyncStateCode
}