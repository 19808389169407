//@flow

import * as React from 'react';
import { AdvancedSelect } from'./../../../components/AdvancedSelect';
import type { Country } from'./../../../types';

export const CountryAdvancedSelect = (props: {
        label: string,
        onDeselect: ()=>bool,
        selectedCountry: ?Country,
        countries: Array<Country>,
        onFilterChange: (filter: string)=> void,
        onItemClick: (country: Country)=> void,
        filter: string
    }) => {
    return (<AdvancedSelect
        adapter={(country)=>({ label: country.name, value: country.countryId })}
        filter={props.filter}
        items={ props.countries||[] }
        selectedItem={ props.selectedCountry }
        textFieldWrapper="none"
        label={props.label}
        showSearchIcon={false}
        onSelectItemDeselect={props.onDeselect}
        onFilterChange={props.onFilterChange}
        onSelectItemClick={(filter, index)=>{ props.onItemClick(props.countries[index]); return true; }}
        shrinkLabel={true}
    />);
}