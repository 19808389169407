//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { CustomerForm } from './components/CustomerForm'
import type { Customer, Country, City, Province } from './../../types'
import { containerFactory } from './../../container';

const container = containerFactory();
const dataStoreService = container.getDataStoreService();


const styles = theme => ({
    root:{

    },
    dialogPaper: {
        minHeight: '95vh',
        maxHeight: '95vh',
    },
    dialogContent:{
        width: 600,
        position: 'relative',
        display: 'flex',
        padding: 0,
    },
    customerForm:{
    }
});

type EditCustomerDialogProps = {
    customer: ?Customer,
    newCustomerName?: string,
    onClose: ()=>void,
    onSubmit: (customer: Customer)=>void,
    open: bool,
}

type EditCustomerDialogState = {
    renderForm: bool
}

type EditCustomerDialogThemedProps = EditCustomerDialogProps & {
    classes: any,
    theme: any,
}

const Transition = (props) => {
    return <Slide direction="up" {...props} />;
}

class EditCustomerDialogThemed extends React.Component<EditCustomerDialogThemedProps,EditCustomerDialogState>{

    constructor(props: EditCustomerDialogThemedProps){
        super(props);
        this.state = {
            renderForm: false
        };
    }

    handleClose = () => {
        this.props.onClose();
    }

    onEscapeKeyDown = (e: any) => {
        if(!e.defaultPrevented){
            this.props.onClose();
        }
    }

    handleExited = (e) => {
         this.setState({renderForm: false });
    }

    handleEntering = () => {
        this.setState({renderForm: true });
    }

    handleFormSubmit = (customer: Customer) => {
        dataStoreService.insertOrReplaceCustomer(customer);
        this.props.onSubmit(customer);
    }

    render(){
        let { classes } = this.props;

        return (<Dialog
            classes={{ root: classes.root, paper:classes.dialogPaper }}
            scroll="paper"
            open={this.props.open}
            onEscapeKeyDown={this.onEscapeKeyDown}
            onBackdropClick={this.handleClose}
            TransitionComponent={Transition}
            onExited={this.handleExited}
            onEntering={this.handleEntering}
        >
            <DialogTitle id="form-dialog-title" style={{position:'relative'}}>
                <IconButton style={{position:'absolute', right:24}} onClick={this.handleClose}>
                    <CloseIcon />
                </IconButton>
                {this.props.customer&&this.props.customer.customerId?"Editar Cliente":"Novo Cliente"}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                { this.state.renderForm ? (
                <CustomerForm
                    customerName={this.props.newCustomerName}
                    className={classes.customerForm}
                    customer={this.props.customer}
                    onSubmit={this.handleFormSubmit}
                />) : ( null ) }
            </DialogContent>
        </Dialog>);
    }
}

let EditCustomerDialog: React.ComponentType<EditCustomerDialogProps> = (
    withStyles(styles, { withTheme: true })(EditCustomerDialogThemed): any);

export { EditCustomerDialog };