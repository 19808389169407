//@flow
import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import { EditableButtonGroup, EditableButtonGroupItem } from './../../../../components/EditableButtonGroup';

type EditableButtonGroupDemoProps = {

}

type EditableButtonGroupDemoState = {
    buttons: Array<{name: string, color: string}>,
    nextIndex: number,
    editEnabled: bool
}

class EditableButtonGroupDemo extends React.Component<EditableButtonGroupDemoProps,EditableButtonGroupDemoState>{

    constructor(props: EditableButtonGroupDemoProps){
        super(props);
        this.state = {
            buttons: [0,1,2,3,4,5].map(x=>({name: x.toString(), color:'default'})),
            nextIndex: 6,
            editEnabled: true
        };
    }

    onRemoveItemClick = (index: number) => {
        let buttons = this.state.buttons.slice(0);
        buttons.splice(index, 1);
        this.setState({buttons});
    }

    handleSetItemColorClick = (index: number, color: string) => {
        let buttons = this.state.buttons.slice(0);
        buttons[index].color = color;
        this.setState({buttons});
    }

    onItemClick = (index: number) => {
        alert('Click '+index);        
    }

    render(){
        let { buttons, editEnabled } = this.state;

        return (<div>
            <Checkbox checked={editEnabled} onChange={(e)=>{this.setState({editEnabled: e.target.checked})}} />
            <EditableButtonGroup
                onSwapItemsClick={()=>alert('swap!')}
                items={buttons}
                onSetItemColorClick={this.handleSetItemColorClick}
                editEnabled={editEnabled}
                onItemClick={ this.onItemClick }
                onRemoveItemClick={ this.onRemoveItemClick }
                onEditEnabledToggle={ ()=>this.setState({editEnabled:false}) }
            />
            
        </div>);
    }
}

export default { key: 'editable-button-group', demoComponent: EditableButtonGroupDemo };