//@flow

import * as React from 'react';
import { List, ListItem, ListItemText, Radio } from '@material-ui/core';
import type { PaymentMethod } from './../../../types';

export class DefaultPaymentMethodSelector extends React.PureComponent<{
    paymentMethods: Array<PaymentMethod>,
    onSettingSet: (value: string)=>void,
    value: ?string
}>{
    
    onListItemClick= (e: any) => {
        this.props.onSettingSet(e.currentTarget.dataset.value);
    }

    render(){
        return (
            <List>
                { this.props.paymentMethods.map((item)=>(
                    <ListItem key={item.paymentMethodId} data-value={item.paymentMethodId} button onClick={this.onListItemClick}>
                        <Radio checked={item.paymentMethodId===this.props.value} disableRipple />
                        <ListItemText primary={item.description} />
                    </ListItem>
                ))}
            </List>
        );
    }
}