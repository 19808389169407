//@flow

import * as React from 'react';
import { OptimizeUIForTouchSelector, PreferenceListItem } from './../components';
import type { RenderDisplayItemContext, RenderSelectorContext, PreferenceHandler } from './../Preferences';
import type { IPreferencesService } from './../../../services/preferences-service'
import { resources } from './../../../util/resources';

export class OptimizeUIForTouchPreferenceHandler implements PreferenceHandler{
    preferencesService: IPreferencesService;
    
    constructor(preferencesService: IPreferencesService){
        this.preferencesService = preferencesService;
    }
    
    getKey(){
        return 'optimizeUIForTouch';
    }

    renderSelector(context: RenderSelectorContext){
        return (<OptimizeUIForTouchSelector
            onSettingSet={(value)=>{
                this.preferencesService.setOptimizeUIForTouch(value==='true');
                let stateUpdate = {}
                this.loadDataIntoState(stateUpdate)
                    .then(()=> context.updateState(stateUpdate));
            }}
            value={this.preferencesService.getOptimizeUIForTouch()}
        />);
    }

    async loadDataIntoState(state: any){
        let optimizeUIForTouch: ?bool = this.preferencesService.getOptimizeUIForTouch();
        state.optimizeUIForTouch = optimizeUIForTouch;
    }

    resetSetting(state: any): void{
        state.optimizeUIForTouch = null;
        this.preferencesService.clearOptimizeUIForTouch();
    }

    renderDisplayItem(context: RenderDisplayItemContext){
        let { loaded, state, forceUpdate, openSelector } = context;
        let valueLabel = !loaded? '...' : 'Não Configurado';
        let { optimizeUIForTouch } = state;
        if(optimizeUIForTouch!=null){
            valueLabel = optimizeUIForTouch?'Habilitado':'Não Habilitado';
        }
        return (<PreferenceListItem settingKey={this.getKey()}
            label="Otimizar Interface Para Touch"
            valueLabel={ valueLabel }
            clear={()=>{
                this.preferencesService.clearOptimizeUIForTouch();
                state.optimizeUIForTouch = null;
                forceUpdate();
            }}
            onClick={()=> openSelector(this.getKey()) }
        />);
    }
}