//@flow

import { BaseCollectionDownSynchronizer } from './../base-collection-down-synchronizer'
import type { GetCondicoesPagamentosResponse } from './../../../../util/skywork-rest-client/types';
import type { PaymentCondition } from './../../../../types';
import type { SynchronizationEntitiesKeys } from './../../types';

export class PaymentConditionsDownSynchronizer extends BaseCollectionDownSynchronizer<GetCondicoesPagamentosResponse,PaymentCondition> {

    getEntityKey(): SynchronizationEntitiesKeys{
        return 'paymentMethod';
    }

    getDataFromRemoteSource(): Promise<GetCondicoesPagamentosResponse>{
        return this._restClient.getCondicoesPagamentos({fields: [
            'com_condicao_pgto_id',
            'nome'
        ]})
    }

    convertRemoteData(reponse: GetCondicoesPagamentosResponse): Array<PaymentCondition>{
        return reponse.map(item =>{
            let paymentCondition: PaymentCondition = {
                paymentConditionId:item.com_condicao_pgto_id.toString(),
                description:item.nome
            };
            return paymentCondition;
        })
    }

    async storeConvertedData(items: Array<PaymentCondition>): Promise<Array<any>>{
        await this._localDatabase.clearTable('paymentConditions');
        await this._localDatabase.batchInsertOrReplacePaymentConditions(items);
        return items.map(x => x.paymentConditionId);
    }
}