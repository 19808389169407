//@flow

import * as React from 'react';
import * as Events from './../events';
//import logo from './logo.svg';
import { Block, ComponentsShowcase, DatabaseInspectorDialog,
Home, Login, LogsDialog, LoginToken, Preferences, Sale, Sales, SynchronizationDialog } from './../scenes';

import { AppLayout } from './components/AppLayout';
import { SyncButton } from './components/SyncButton';
import { CalculatorButton } from './components/CalculatorButton';
import { CalculatorDialog } from './components/CalculatorDialog';
import { PrintButtonWithQueue } from './components/PrintButtonWithQueue';
import { SnackStackBinded } from './components/SnackStackBinded';

import { Switch, Route, Redirect } from 'react-router';
import { CssBaseline, List, ListItem } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { containerFactory } from './../container';

import { appTheme } from './../themes';

const container = containerFactory();
const routeBuilder = container.getRouteBuilderService();
const authenticationService = container.getAuthenticationService();
const synchronizationService = container.getSynchronizationService();
const blockerService = container.getBlockerService();
// const notificationsService = container.getNotificationsService();
const preferecesService = container.getPreferencesService();

type AppProps = {}

type AppState = {
  showSyncDialog: bool,
  showDBInspectorDialog: bool,
  showLogsDialog: bool
}

class App extends React.Component<AppProps,AppState> {

  constructor(props: AppProps){
    super(props);
    this.state = {
      showSyncDialog: false,
      showDBInspectorDialog: false,
      showLogsDialog: false
    }
  }

  handleAppEvent = (event: Events.AppEvent) => {

    //TODO: Não seria mais interessante fazer essa ligação entre os services? Acredito que sim.
    if(Events.AuthenticationServiceEventsArray.indexOf(event.name)!==-1){
      if(event.name===Events.AuthenticationServiceEvents.OnSignedIn && event.error==null){
        if(blockerService.getBlockedReasons().find(x => x==='NoBusinessUnitSelected')==null){
          synchronizationService.startSync(false);
        }
        else{
          this.forceUpdate();
        }
      }
      else{ 
        this.forceUpdate();
      }
    }
    else if(Events.SynchronizationServiceEventsArray.indexOf(event.name)!==-1){
      //se começar a afetar a performance durante a sincronização, devemos considerar mudar isso
      //até porque a sincronização somente é afetada por filhos desse objeto, e não ele diretamente
      this.forceUpdate();
    }
    else if(Events.BlockerServiceEventsArray.indexOf(event.name)!==-1)
      this.forceUpdate();
    else if(event.name===Events.PreferencesServiceEvents.OnPreferenceValueChanged)
      this.forceUpdate();
  }

  handleSyncClick(){
    this.setState({showSyncDialog: !this.state.showSyncDialog});
  }
  
  handleSynchronizationDialogClose(){
    this.setState({showSyncDialog: false});
  }

  componentDidMount(){
      Events.emitter.subscribe(this.handleAppEvent);
  }

  componentWillUnmount(){
    Events.emitter.unsubscribe(this.handleAppEvent);
  }

  toggleCalculator = ()=>{
    container.getCalculatorService().openCalculator({});
  }

  

  renderInLayout(scene: React.Node, title: string, title2: string=""){

    let { showSyncDialog, showDBInspectorDialog, showLogsDialog } = this.state;
    let authenticatedUserEmail: ?string = null;
    let authenticatedUser = authenticationService.getAuthenticatedUser();
    let lastSyncEndedEvent = synchronizationService.getLastSyncEndedEvent();
    if(authenticatedUser!=null){
      authenticatedUserEmail = authenticatedUser.email;
    }

    return (
      <React.Fragment>
        <AppLayout
          authenticatedUser={authenticatedUserEmail}
          appBarButtons={[
            (<CalculatorButton key="btn-calc" onClick={this.toggleCalculator} />),
            (<PrintButtonWithQueue key="btn-print" />),
            (<SyncButton key="btn-sync" variant={lastSyncEndedEvent!=null&&lastSyncEndedEvent.error!=null?'error':undefined} onSyncClick={this.handleSyncClick.bind(this)} inSync={synchronizationService.isInSync()} />)
          ]}
          onSignOutClick={()=>authenticationService.signOut()}
          headerTitle={title}
          headerTitle2={title2}
          menuSections={[
            (open)=>(
              <Route render={({history})=>{
                let renderLink = (label: string, enabled: bool, path: string, onClick?: ()=>void)=>{
                  return (
                    <ListItem button tabIndex={enabled?undefined:-1} onClick={()=>{history.push(path);}}>{label}</ListItem>
                  ) };
                return (<List>
                  { renderLink('Home', open, routeBuilder.homeRoute()) }
                  { renderLink('Vender', open, routeBuilder.saleRoute()) }
                  { renderLink('Últimas Vendas', open, routeBuilder.salesRoute() ) }
                  { renderLink('Preferências', open, routeBuilder.preferencesRoute()) }
                  { preferecesService.getEnableDeveloperUI()?(<React.Fragment>
                    { renderLink('Galeria de Componentes', open, routeBuilder.componentsShowcaseRoute()) }
                    <ListItem button tabIndex={open?undefined:-1} onClick={()=>this.setState({showDBInspectorDialog:!this.state.showDBInspectorDialog})}>DB Inspector</ListItem>
                    <ListItem button tabIndex={open?undefined:-1} onClick={()=>this.setState({showLogsDialog:!this.state.showLogsDialog})}>Logs</ListItem>
                  </React.Fragment>): (null) }
                </List>);
              }} />
            )
          ]}>
          { scene }            
        </AppLayout>

        <SynchronizationDialog open={showSyncDialog} onCloseClick={this.handleSynchronizationDialogClose.bind(this)} />
        <DatabaseInspectorDialog open={showDBInspectorDialog} onCloseClick={()=>this.setState({showDBInspectorDialog:false})} />
        <LogsDialog open={showLogsDialog} onCloseClick={()=>this.setState({showLogsDialog:false})} />
        <CalculatorDialog />
        <SnackStackBinded />
        
      </React.Fragment>
    );
  }

  render() {

    const loginTokenRoute = (<Route path={routeBuilder.loginTokenRoute()} render={({match})=> {
      return (<LoginToken token={match.params.token||''} redirectPath={routeBuilder.homeRoute()} />);
    }}/>);

    if(!authenticationService.isAuthenticated()){
      return (<CssBaseline>
        <Switch>
            { loginTokenRoute }
            <Route path="*" render={()=> {
              return (<Login />);
            }}/>
        </Switch>
      </CssBaseline>
      );
    }

    if(blockerService.isBlocked()){
      return (<MuiThemeProvider theme={appTheme}>
        <CssBaseline>
          <Switch>
            { loginTokenRoute }
            <Route path="*" render={()=> {
              return this.renderInLayout(<Block />, 'Sessão Bloqueada');
            }}/>
          </Switch>
        </CssBaseline>
      </MuiThemeProvider>);
    }

    return (
      <MuiThemeProvider theme={appTheme}>
        <CssBaseline>
          <Switch>
            { loginTokenRoute }
            <Route exact path={routeBuilder.homeRoute()} render={ ()=> {
              return this.renderInLayout(<Home />, 'Home');
            }} />
            <Route exact path={routeBuilder.salesRoute()} render={ ()=> {
              return this.renderInLayout(<Sales />, 'Últimas Vendas');
            }} />
            <Route exact path={routeBuilder.saleRoute()} render={ ()=> {
              let businessUnit = preferecesService.getBusinessUnit();
              let businessUnitName = businessUnit==null?'':businessUnit.name;
              return this.renderInLayout(<Sale />, 'Vender', businessUnitName);
            }} />
            <Route path={routeBuilder.loginRoute()} render={()=> {
              return (<Redirect to={routeBuilder.homeRoute()} />);
            }}/>
            <Route path={routeBuilder.preferencesRoute()} render={()=> {
              return this.renderInLayout(<Preferences />, 'Preferências do Usuário');
            }} />
            <Route path={routeBuilder.componentsShowcaseRoute()} render={()=> {
              return this.renderInLayout(<ComponentsShowcase />, 'Components Showcase');
            }} />
            <Route path="*" render={()=> {
              return this.renderInLayout(<p style={{padding:24}}>Não encontrado.</p>, 'Página Não Encontrada');
            }}/>
          </Switch>
        </CssBaseline>
      </MuiThemeProvider>
    );
  }
}

export default App;