//@flow

import * as React from 'react';
import { List, ListItem, ListItemText, Radio } from '@material-ui/core';
import { resources } from './../../../util/resources';

export class DefaultPrintOptionSelector extends React.PureComponent<{
    onSettingSet: (value: string)=>void,
    value: ?string
}>{
    
    onListItemClick= (e: any) => {
        this.props.onSettingSet(e.currentTarget.dataset.value);
    }

    render(){
        return (
            <List>
                { ['skip', 'receipt', 'invoice'].map(x =>(
                    <ListItem key={x} data-value={x} button onClick={this.onListItemClick}>
                        <Radio checked={x===this.props.value} disableRipple />
                        <ListItemText primary={resources.getPrintOptionLabel(x)} />
                    </ListItem>
                )) }
            </List>
        );
    }
}