//@flow

import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
    root:{
        textDecoration: 'none',
        '&:hover':{
            textDecoration: 'underline',
        }
    }
});

export class MaterialLinkUnstyled extends React.PureComponent<any>{

    render(){
        return (
            <Typography
                { ...this.props }
                variant={this.props.variant||'body1'}
                className={this.props.classes.root}
                component={this.props.component||"a"}
                style={Object.assign({}, {display: 'inline'}, this.props.style)}
                color={this.props.color||'primary'}
                href={'https://sistema.skywork.com.br/?on=comercial_pedidos&menu=1'}>
                {this.props.children}
            </Typography>
        );
    }
}

let MaterialLink: React.ComponentType<any> = (withStyles(styles, { withTheme: true })(MaterialLinkUnstyled): any);
export { MaterialLink };