//@flow

import * as React from 'react';
import { List, ListItem, Radio, ListItemText } from '@material-ui/core';
import type { PaymentCondition } from './../../../types';
import { Done } from '@material-ui/icons';

export class DefaultPaymentConditionSelector extends React.PureComponent<{
    paymentConditions: Array<PaymentCondition>,
    onSettingSet: (value: string)=>void,
    value: ?string
}>{
    
    onListItemClick= (e: any) => {
        this.props.onSettingSet(e.currentTarget.dataset.value);
    }

    render(){
        return (
            <List>
                { this.props.paymentConditions.map((item)=>(
                    <ListItem color="primary" variant="contained" key={item.paymentConditionId} data-value={item.paymentConditionId} button onClick={this.onListItemClick}>
                        <Radio
                            checked={item.paymentConditionId===this.props.value}
                            disableRipple
                        />
                        <ListItemText primary={item.description} />
                    </ListItem>
                ))}
            </List>
        );
    }
}