//@flow
import * as React from 'react';
import { BlockCard } from './BlockCard';
import { IContainer, withContainer } from './../../../container';
import { Button, CircularProgress } from '@material-ui/core';
import * as Events from './../../../events';

type NotFullySyncedBlockDetailProps = {}
type _NotFullySyncedBlockDetailProps = {} & { container: IContainer };
type NotFullySyncedBlockDetailState = {
    inSync: bool
}

class _NotFullySyncedBlockDetail extends React.Component<_NotFullySyncedBlockDetailProps,NotFullySyncedBlockDetailState>{
    
    constructor(props: _NotFullySyncedBlockDetailProps){
        super(props);
        this.state = {
            inSync: props.container.getSynchronizationService().isInSync()
        }
    }

    componentDidMount(){
        this.props.container.getEventEmitter().subscribe(this.handleAppEvent);
    }

    componentWillUnmount(){
        this.props.container.getEventEmitter().unsubscribe(this.handleAppEvent);
    }

    handleAppEvent = (event: Events.AppEvent) => {
        if(event.name===Events.SynchronizationServiceEvents.OnSynchronizationStarted){
            this.setState({inSync:true});
        }
        else if(event.name===Events.SynchronizationServiceEvents.OnSynchronizationEnded){
            this.setState({inSync:false});
        }
    }

    render(){
        return (<BlockCard title="Sincronização Completa Requerida"
            actions={[
                <Button color="primary" variant="contained" key="1" disabled={this.state.inSync} onClick={()=>this.props.container.getSynchronizationService().startSync(true)}>Sincronizar Agora</Button>
            ]}>
            <p>É necessário realizar uma sincronização completa.</p>
            { this.state.inSync ? (<p>A sincronização está em progresso.</p>) : (null)}
        </BlockCard>);
    }

}

export const NotFullySyncedBlockDetail: React.ComponentType<NotFullySyncedBlockDetailProps> = withContainer(
    _NotFullySyncedBlockDetail
);