//@flow

import { BaseCollectionDownSynchronizer } from './../base-collection-down-synchronizer'
import type { GetProdutosResponse } from './../../../../util/skywork-rest-client/types';
import type { Product } from './../../../../types';
import type { SynchronizationEntitiesKeys } from './../../types';
import type { SynchronizeContext } from './../default-synchronization-service';

const DATA_ATUALIZACAO_KEY='defaultSync_getProdutosDtAtualizacao';

export class ProductsDownSynchronizer extends BaseCollectionDownSynchronizer<GetProdutosResponse,Product> {

    getEntityKey(): SynchronizationEntitiesKeys{
        return 'product';
    }

    getDataFromRemoteSource(context: SynchronizeContext): Promise<GetProdutosResponse>{
        return this._restClient.getProdutos({
            fields: [
                'est_produto_id',
                'codigo_interno',
                'nome',
                'part_number',
                'cd_barras',
                'valor_venda',
                'unidade'
            ],
            disponivel_pdv: 1,
            dt_atualizacao: context.forceFull? null : localStorage.getItem(DATA_ATUALIZACAO_KEY)
        });
    }

    _commaDecimalNumberReg = /^[0-9]{1,3}([.][0-9]{3})*[,][0-9]{2,}$/;
    _dotDecimalNumberReg = /^[0-9]{1,3}([,][0-9]{3})*[.][0-9]{2,}$/;
    _trimReg = /(^ | $)/g;
    _moneyReg = /R?[$]/g;

    _getFloat(value: string): number{
        value = value.replace(this._moneyReg,'').replace(this._trimReg,'');
        if(this._commaDecimalNumberReg.test(value)){
            let values = value.split(',');
            return parseFloat(values[0].replace(/[.]/g,'')) + parseFloat(values[1].substr(0,2))/100.0;
        }
        if(this._dotDecimalNumberReg.test(value)){
            let values = value.split('.');
            return parseFloat(values[0].replace(/[,]/g,'')) + parseFloat(values[1].substr(0,2))/100.0;
        }
        return parseFloat(value);
    }

    convertRemoteData(response: GetProdutosResponse): Array<Product>{

        return response.map(item =>{

            let product: Product = {
                productId: item.est_produto_id.toString(),
                name: item.nome,
                sku: item.part_number,
                internalId: item.codigo_interno||'',
                barCode: (item.cd_barras||'').toString(),
                sellValue: this._getFloat(item.valor_venda),
                unit: item.unidade
            };

            return product;
        });
    }

    async storeConvertedData(items: Array<Product>): Promise<Array<any>>{
        let date = this._restClient.getLastServerTouchDate();
            if(date!=null) localStorage.setItem(DATA_ATUALIZACAO_KEY, date);
        await this._localDatabase.batchInsertOrReplaceProducts(items);
        return items.map(x => x.productId);
    }
}