//@flow

/**
    Gerador de rotas para manter consistência.
 */
export class RouteBuilderService{

    _basePath: string = '';
    setBasePath(path: string){
        if(path){
            this._basePath = path.replace(/\/$/,'');
            if(!this._basePath.startsWith('/'))
            this._basePath = '/' + this._basePath;
        }
        
    }

    homeRoute(): string {
        return `${this._basePath}/`;
    }

    saleRoute(): string {
        return `${this._basePath}/venda`;
    }

    salesRoute(): string {
        return `${this._basePath}/vendas`;
    }

    componentsShowcaseRoute(): string {
        return `${this._basePath}/components-showcase`;
    }

    loginRoute(): string {
        return `${this._basePath}/login`;
    }

    preferencesRoute(): string {
        return `${this._basePath}/preferences`;
    }

    loginTokenRoute(): string {
        return `${this._basePath}/login-token/:token`;
    }
}