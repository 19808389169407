//@flow
import * as Events from './../../events';

export const SynchronizationEntities = {
    city: 'city',
    country: 'country',
    customer: 'customer',
    paymentCondition: 'paymentCondition',
    paymentMethod: 'paymentMethod',
    product: 'product',
    province: 'province'
}

export type SynchronizationEntitiesKeys = $Keys<typeof(SynchronizationEntities)>;

export type SynchronizationEntityData = {
    entityKey: SynchronizationEntitiesKeys,
    download: bool,
    length: ?number,
    ids: ?Array<any>
}

export interface ISynchronizationService{
    isInSync(): bool;
    startSync(forceFull: bool): void;
    getLastEntitySyncEvent(): ?Events.AppEvent;
    getLastSyncEndedEvent(): ?Events.AppEvent;
    getDidFullSync(): bool;
}