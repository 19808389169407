//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { PersonOutlined as PersonIcon, Person as PersonIconChecked, Business as BusinessIcon, BusinessTwoTone as BusinessIconChecked } from '@material-ui/icons';

const styles = theme => ({
});

type PersonTypeOptionsProps = {
    onPersonTypeOptionChange: (value: string)=>void,
    personTypeOption: string,
}

type PersonTypeOptionsState = {    
}

type PersonTypeOptionsDumbProps = PersonTypeOptionsProps & {
    classes: any,
    theme: any
}

class PersonTypeOptionsThemed extends React.Component<PersonTypeOptionsDumbProps,PersonTypeOptionsState>{

    handlePersonTypeOptionChange = (e: any) => {
        this.props.onPersonTypeOptionChange(e);    
    }

    render(){
        const { personTypeOption, onPersonTypeOptionChange, ...rest } = this.props;
        return (
            <RadioGroup style={{margin:'0 14px'}} row onChange={this.handlePersonTypeOptionChange} value={personTypeOption} {...rest}>
                <FormControlLabel
                    style={{flex:1}}
                    value={"PERSON"}
                    control={(<Radio
                        icon={<PersonIcon fontSize="large" />}
                        checkedIcon={<PersonIconChecked fontSize="large" />}
                    />)}
                    label={'Pessoa'}
                />
                <FormControlLabel
                    style={{flex:1}}
                    value={"COMPANY"}
                    control={(<Radio
                        icon={<BusinessIcon fontSize="large" />}
                        checkedIcon={<BusinessIconChecked fontSize="large" />}
                    />)}
                    label={'Empresa'}
                />
            </RadioGroup>
        );
    }
}

let PersonTypeOptions: React.ComponentType<PersonTypeOptionsProps> = (withStyles(styles, { withTheme: true })(PersonTypeOptionsThemed): any);

export { PersonTypeOptions };