//@flow

import * as React from 'react';
import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow, Typography } from '@material-ui/core';
import { MoreVert as MoreIcon, Print as PrintIcon, GetApp as DownloadIcon, Delete as DeleteIcon, LockOpen as UnlockIcon } from '@material-ui/icons';
import type { Customer, Sale } from './../../../types';
import { toBrazilianDate } from './../../../util/formatters';
import numeral from "numeral";
import 'numeral/locales/pt-br';

export class SaleRow extends React.PureComponent<{
    sale: Sale,
    customer: ?Customer,
    onPrintClick: (e: any) => void,
    onMoreClick: (e: any) => void,
    onDetailClick: (e: any) => void,
    onRemoveClick: (e: any) => void,
    onCloseMoreClick: (e: any)=> void,
    onRequestInvoiceClick: (e: any) => void,
    onUnlockSyncClick: (e: any) => void,
    expandedItemAnchor: any,
    expandedItemOpen: bool,
    index: number,
    classes: any,
    total: number,
    isRequestingInvoice: bool,
    saleSyncBlocked: bool,
    invoiceEnabled: bool,
}>{
    render(){
        let { index, customer, expandedItemAnchor, sale, onDetailClick, onPrintClick, onRequestInvoiceClick, onMoreClick, onRemoveClick, onCloseMoreClick, onUnlockSyncClick, expandedItemOpen, classes, total, isRequestingInvoice, saleSyncBlocked } = this.props;
        let itemOpen = expandedItemAnchor!=null && expandedItemAnchor.dataset.index==index && expandedItemOpen;
        
        let invoiceStatus = isRequestingInvoice?
            (<span>Solicitando<CircularProgress style={{position:'absolute', bottom:6, marginLeft:6 }} size={24}/></span>): sale.invoiceData?
            (<span>Emitida</span>):
            (<span>Não Emitida*</span>);
        return (
            <TableRow>

                <TableCell>
                    <Typography variant={'caption'} color={'primary'}>Descrição</Typography>
                    <Typography variant={'body2'}>
                    {customer?customer.name: 'Cliente Não Identificado'} - { numeral(total).format('$0,0.00') } { saleSyncBlocked ? <Typography variant={'body2'} color="error">[Bloqueado]</Typography>:'' }
                </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant={'caption'} color={'primary'}>Data</Typography>
                    <Typography variant={'body2'}>{toBrazilianDate(sale.date, true)}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant={'caption'} color={'primary'}>NFC-e </Typography>
                    <Typography style={{position:'relative'}} variant={'body2'}>{invoiceStatus}</Typography>
                </TableCell>

                <TableCell className={ classes.actionsCol }>
                    
                    <IconButton data-index={index} onClick={onMoreClick}>
                        <MoreIcon />
                    </IconButton>

                    <Menu
                        id="simple-menu"
                        anchorEl={expandedItemAnchor}
                        open={itemOpen}
                        onClose={onCloseMoreClick}
                    >
                        <MenuItem onClick={onPrintClick}>
                            <ListItemIcon><PrintIcon /></ListItemIcon>
                            <ListItemText>Imprimir {sale.invoiceData?'Nota Fiscal':'Comprovante'}</ListItemText>
                        </MenuItem>
                        {/* <MenuItem onClick={onRemoveClick}>
                            <ListItemIcon><DeleteIcon /></ListItemIcon>
                            <ListItemText>Remover Venda</ListItemText>
                        </MenuItem> */}
                        { saleSyncBlocked && (<MenuItem onClick={onUnlockSyncClick}>
                            <ListItemIcon><UnlockIcon /></ListItemIcon>
                            <ListItemText>Desbloquear Syncronização</ListItemText>    
                        </MenuItem>) }
                        { !sale.invoiceData && (<MenuItem disabled={!this.props.invoiceEnabled} onClick={onRequestInvoiceClick}>
                            <ListItemIcon><DownloadIcon /></ListItemIcon>
                            <ListItemText>Emitir Nota</ListItemText>    
                        </MenuItem>)}
                    </Menu>
                    
                </TableCell>
            </TableRow>
        );
    }
}