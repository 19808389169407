//@flow

//interfaces
import type { IContainer } from './types';
import type { AppEventEmitter } from './../events';

//concrete types
import { RouteBuilderService } from './../services/route-builder-service';
// import { NotificationsService } from './../services/notifications-service';
import * as Events from './../events';
import { CalculatorService } from './../services/calculator-service';
import { ErrorLoggerService } from './../services/error-logger-service';

const FACTORY_ERROR = 'Service factory not implemented';

export class BaseContainer implements IContainer{
    
    _appEventEmitter: AppEventEmitter;
    _routeBuilderService: RouteBuilderService;
    // _notificationsService: NotificationsService;
    _calculatorService: CalculatorService;
    _errorLoggerService: ErrorLoggerService;
    

    constructor(){
        this._appEventEmitter = Events.emitter;
        this._errorLoggerService = new ErrorLoggerService(this._appEventEmitter);
        this._routeBuilderService = new RouteBuilderService();
        let routerBasePath = process.env.REACT_APP_ROUTER_BASE_PATH;
        if(routerBasePath!=null){
            this._routeBuilderService.setBasePath(routerBasePath);
        }
        // this._notificationsService = new NotificationsService();
        this._calculatorService = new CalculatorService();
    }

    /* Factories que PODEM ser sobreescritas */

    getEventEmitter(): AppEventEmitter{
        return this._appEventEmitter;
    }

    getRouteBuilderService(){
        return this._routeBuilderService;
    }

    getCalculatorService(){
        return this._calculatorService;
    }

    getErrorLoggerService(){
        return this._errorLoggerService;
    }

    // getNotificationsService(){
    //     return this._notificationsService;
    // }

    /* Factories que DEVEM ser sobreescritas */

    getDataStoreService(){
        throw new Error(FACTORY_ERROR);
    }

    getPreferencesService(){
        throw new Error(FACTORY_ERROR);
    }

    getPrintQueueService(){
        throw new Error(FACTORY_ERROR);
    }

    getSynchronizationService(){
        throw new Error(FACTORY_ERROR);
    }

    getAuthenticationService(){
        throw new Error(FACTORY_ERROR);
    }

    getBlockerService(){
        throw new Error(FACTORY_ERROR);
    }

    getSaleService(){
        throw new Error(FACTORY_ERROR);
    }
}