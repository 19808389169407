//@flow

import * as React from 'react';
import { DefaultPrintOptionSelector, PreferenceListItem } from './../components';
import type { RenderDisplayItemContext, RenderSelectorContext, PreferenceHandler } from './../Preferences';
import type { IPreferencesService } from './../../../services/preferences-service'
import { resources } from './../../../util/resources';

export class DefaultPrintOptionPreferenceHandler implements PreferenceHandler{
    
    preferencesService: IPreferencesService;
    
    constructor(preferencesService: IPreferencesService){
        this.preferencesService = preferencesService;
    }
    
    getKey(){
        return 'defaultPrintOption';
    }

    renderSelector(context: RenderSelectorContext){
        return (<DefaultPrintOptionSelector
            onSettingSet={(value)=>{
                this.preferencesService.setDefaultPrintOption(value);
                let stateUpdate = {}
                this.loadDataIntoState(stateUpdate)
                    .then(()=> context.updateState(stateUpdate));
            }}
            value={this.preferencesService.getDefaultPrintOption()}
        />);
    }

    async loadDataIntoState(state: any){
        let defaultPrintOption: ?string = this.preferencesService.getDefaultPrintOption();
        state.defaultPrintOption = defaultPrintOption;
    }

    resetSetting(state: any): void{
        state.defaultPrintOption = null;
        this.preferencesService.clearDefaultPrintOption();
    }

    renderDisplayItem(context: RenderDisplayItemContext){
        let { loaded, state, forceUpdate, openSelector } = context;
        let valueLabel = !loaded? '...' : 'Não Configurado';
        let { defaultPrintOption } = state;
        if(defaultPrintOption!=null){
            valueLabel = resources.getPrintOptionLabel(defaultPrintOption);
        }
        return (<PreferenceListItem settingKey={this.getKey()}
            label="Impressão de Vendas Padrão"
            valueLabel={ valueLabel }
            clear={()=>{
                this.preferencesService.clearDefaultPrintOption();
                state.defaultPrintOption = null;
                forceUpdate();
            }}
            onClick={()=> openSelector(this.getKey()) }
        />);
    }
}