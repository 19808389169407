//@flow

//interfaces
import { BaseContainer } from './base-container';
import type { IAuthenticationService } from './../services/authentication-service/types';
import type { IBlockerService } from './../services/blocker-service/types';
import type { IDataStoreService } from './../services/data-store-service/types';
import type { IPreferencesService } from './../services/preferences-service/types';
import type { ISynchronizationService } from './../services/synchronization-service/types';
import type { ISaleService } from './../services/sale-service/types';

//concrete types
import { MockAuthenticationService } from './../services/authentication-service/mock-authentication-service';
import { MockBlockerService } from './../services/blocker-service/mock-blocker-service';
import { MockDataStoreService } from './../services/data-store-service';
import { MockSaleService } from './../services/sale-service/mock-sale-service';
import { MockSynchronizationService } from './../services/synchronization-service';
import { PrintQueueService, MockPrintRequestDataSource } from './../services/print-queue-service';
import { RawHTMLPrintHandler } from './../services/print-queue-service/print-handlers/raw-html-print-handler';
import { DefaultPreferencesService, PreferencesLocalStorage } from './../services/preferences-service/default-preferences-service';


export class MockContainer extends BaseContainer{
    
    _authenticationService: IAuthenticationService;
    _blockerService: IBlockerService;
    _dataStoreService: IDataStoreService;
    _printQueueService: PrintQueueService;
    _syncronizationService: ISynchronizationService;
    _preferencesService: IPreferencesService;
    _saleService: ISaleService;

    constructor(){
        super();
        this._authenticationService = new MockAuthenticationService();
        this._dataStoreService = new MockDataStoreService();
        this._syncronizationService = new MockSynchronizationService();
        this._blockerService = new MockBlockerService();
        this._preferencesService = new DefaultPreferencesService(new PreferencesLocalStorage(()=>'MOCK'), this._appEventEmitter);
        this._printQueueService = new PrintQueueService(this._appEventEmitter, new MockPrintRequestDataSource(), {
            'raw-html': new RawHTMLPrintHandler()
        });
        this._saleService = new MockSaleService();
    }

    getAuthenticationService(){
        return this._authenticationService;
    }

    getBlockerService(){
        return this._blockerService;
    }

    getDataStoreService(){
        return this._dataStoreService;
    }

    getPreferencesService(){
        return this._preferencesService;
    }

    getPrintQueueService(){
        return this._printQueueService;
    }

    getSynchronizationService(){
        return this._syncronizationService;
    }

    getSaleService(){
        return this._saleService;
    }
}