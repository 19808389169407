//@flow
import * as React from 'react';
import { AdvancedSelect } from './../../../components/AdvancedSelect';
import type { Product } from './../../../types';
import { Star as StarIcon, StarBorder as StarBorderIcon } from '@material-ui/icons';
import { TableCell } from '@material-ui/core';
import numeral from "numeral";
import 'numeral/locales/pt-br';


type ProductSearchProps = {
    products: Array<Product>,
    onProductFilterChange: (filter: string)=>void,
    onProductClick: (Product)=>void,
    onProductShortcutToggle: (Product)=>void,
    productsShortcuts: Array<{productId:string, color: string}>,
    advancedSelectRef?: any
}

type ProductSearchState = {
}

class ProductSearch extends React.Component<ProductSearchProps,ProductSearchState>{

    render(){
        return (<AdvancedSelect
            label={'Procurar Produtos'}
            adapter={(product)=>({label: product.name, value: product.productId})}
            items={ this.props.products }
            actionRenderers={[
                (item: any, index: number) => {
                    let isShortcut = (this.props.productsShortcuts||[]).findIndex(x => x.productId === item.productId)>-1;
                    return {
                        icon: isShortcut?<StarIcon />:<StarBorderIcon />,
                        onClick:(e)=>{ this.props.onProductShortcutToggle(this.props.products[index]); }}
                }
            ]}
            selectedItem={ null }
            onFilterChange={(filter)=>{
                this.props.onProductFilterChange(filter)
            }}
            onSelectItemClick={(filter, index)=>{
                this.props.onProductClick(this.props.products[index]); return false; //não vamos permitir a conclusão de uma seleção
            }}
            innerRef={this.props.advancedSelectRef}
            cellRenderers={[(item,index)=>(<TableCell style={{opacity:.5}}>
                { numeral(item.sellValue).format('$0,0.00') }
            </TableCell>)]}
        />);
    }
}

export { ProductSearch };