//@flow
import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';

const styles = (theme) => ({});

type ConfirmationDialogProps = {
    classes: any,
    theme: any,
    children: any,
    title: any,
    open: bool,
    onOkClick: ()=>void,
    onCancelClick: ()=>void,
    dialogProps?: any
}

export class ConfirmationDialog extends React.PureComponent<ConfirmationDialogProps> {

    handleOkClick = () => {
        this.props.onOkClick();
    }

    handleCancelClick = () => {
        this.props.onCancelClick();
    }

    render(){
        return(
            <Dialog
                { ...this.props.dialogProps }
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="confirmation-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelClick} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={this.handleOkClick} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


type ConfirmationDialogStyledProps = $Diff<ConfirmationDialogProps, {classes: any, theme: any}>;

export const ConfirmationDialogStyled: React.ComponentType<ConfirmationDialogStyledProps> = (withStyles(styles, { withTheme: true })(ConfirmationDialog): any);