//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { EditableButtonGroupItem } from './components/EditableButtonGroupItem';

const styles = theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: 'repeat( 3, minmax(0, 1fr) )',
        gridGap: '10px'
    }
});

type EditableButtonGroupProps = {
    columns?: number,
    autoFocusIndex?: number,
    items: Array<{name: string, color: string}>,
    onRemoveItemClick?:(index: number)=>void,
    onSetItemColorClick:(index: number, color: string)=>void,
    onItemClick: (index: number)=>void,
    onSwapItemsClick: (indexFrom: number, indexTo: number)=>void,
    editEnabled: bool,
    onEditEnabledToggle: ()=>void,
}

type EditableButtonGroupState = {
    editEnabled: bool,
    swapFrom: number
}

type EditableButtonGroupThemedProps = EditableButtonGroupProps & {
    classes: any,
    theme: any
}

class EditableButtonGroupThemed extends React.Component<EditableButtonGroupThemedProps,EditableButtonGroupState>{

    buttonsRef: Array<any> = [];
    constructor(props: EditableButtonGroupThemedProps){
        super(props);
        this.state = {
            editEnabled: props.editEnabled,
            swapFrom: -1
        };
    }

    static getDerivedStateFromProps(props: EditableButtonGroupProps, state: EditableButtonGroupState){
        if(props.editEnabled!=state.editEnabled){
            let update = {};
            update.swapFrom = -1;
            return update;
        }
        return null;
    }

    componentDidUpdate(){
        setTimeout(()=>{
            let editEnabled = this.props.editEnabled;
            if(this.state.editEnabled!==editEnabled){
                this.setState({editEnabled: editEnabled});
            }
        },100);
    }

    handleOutsideClick = (e: any)=>{
        if(this.state.editEnabled){
            this.setState({swapFrom:-1});
            this.props.onEditEnabledToggle();
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.handleOutsideClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.handleOutsideClick);
    }

    handleColorClick(e, color, buttonIndex){
        this.props.onSetItemColorClick(buttonIndex, color);
    }

    handleItemClick = (e, index)=>{
        let { editEnabled, swapFrom } = this.state;
        if(editEnabled){
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            if(swapFrom!=-1){
                //swap!!
                this.props.onSwapItemsClick(swapFrom, index);
                this.setState({swapFrom:-1});
            }
        }
        else{
            //click!
            this.props.onItemClick(index);
        }
    }

    handleSwapStartClick = (index)=>{
        this.setState({swapFrom: index});
    }

    handleButtonRef = (ref) =>{
        if(ref) this.buttonsRef[ref.dataset.index] = ref;
    }

    handleButtonKeyDown = (e) =>{
        let currentIndex = parseInt(e.target.dataset.index);
        let ref;
        if(e.keyCode==37||e.keyCode==38){
            ref = this.buttonsRef[--currentIndex];
        }
        else if(e.keyCode==39||e.keyCode==40){
            ref = this.buttonsRef[++currentIndex];
        }
        if(ref!=null)
            ref.focus();
        
    }

    render(){

        let { autoFocusIndex, classes, columns, items, onRemoveItemClick, onSetItemColorClick, onItemClick,
            onSwapItemsClick, editEnabled, onEditEnabledToggle, ...rest } = this.props;
        let { swapFrom } = this.state;
        editEnabled = this.state.editEnabled;
        
        let gridTemplateColumns = null;
        if(columns){
            gridTemplateColumns = `repeat( ${columns}, minmax(0, 1fr) )`;
        }

        let mode = 'ready';
        if(editEnabled){
            mode = 'editing';
            if(swapFrom!=-1){
                mode = 'swappable';
            }
        }

        return <div className={classes.root} style={{ gridTemplateColumns:gridTemplateColumns }} { ...rest } >
            { items.map((item,index)=>{
                let m = mode;
                if(mode==='swappable' && swapFrom===index){
                    m = 'swapping';
                }
                return (<EditableButtonGroupItem
                    key={index}
                    onClick={(e)=>{ this.handleItemClick(e, index) }}
                    onRemoveClick={ onRemoveItemClick!=null?()=>{ onRemoveItemClick(index)}:undefined}
                    buttonProps={{
                        tabIndex:index!==0?-1:undefined,
                        onKeyDown: this.handleButtonKeyDown,
                        "data-index": index,
                        buttonRef: this.handleButtonRef,
                        autoFocus: autoFocusIndex===index,
                    }}
                    onSwapStartClick={()=>{ this.handleSwapStartClick(index) }}
                    label={item.name}
                    color={item.color}
                    onColorClick={ (e,{color})=>{ this.handleColorClick(e, color, index) }}
                    mode={m}
                />);
            }) }
        </div>;
    }
}

let EditableButtonGroup: React.ComponentType<EditableButtonGroupProps> = (withStyles(styles, { withTheme: true })(EditableButtonGroupThemed): any);

export { EditableButtonGroup };