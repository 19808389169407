//@flow 
import * as React from 'react';

type TouchControllerProps = {
    onTouchedsChange: (target: HTMLElement)=>void,
    toucheds: {[key: string]: bool},
    children: any
}

export class TouchController extends React.Component<TouchControllerProps, void>{

    handleTouch = (e: any)=>{
        let input = e.target;
        if(input.id){
            if(this.props.toucheds&&this.props.toucheds[input.id]===true)
                return;
            let toucheds = Object.assign({}, this.props.toucheds, {[input.id]: true});
            this.props.onTouchedsChange(toucheds);
        }
    }

    render(){
        return (
        <div onFocus={this.handleTouch} onBlur={this.handleTouch} onKeyDown={this.handleTouch}>
            { this.props.children }
        </div>
        );
    }
}