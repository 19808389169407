//@flow

import * as React from 'react';
import { AdvancedSelect } from'./../../../components/AdvancedSelect';
import type { City, Province } from'./../../../types';
import { TableCell } from '@material-ui/core';

export const CityAdvancedSelect = (props: {
        label: string,
        onDeselect: ()=>bool,
        selectedCity: ?[City, ?Province],
        cities: Array<[City, ?Province]>,
        onFilterChange: (filter: string)=> void,
        onItemClick: (city: [City, ?Province])=> void,
        filter: string,
        disabled?: bool
    }) => {
    return (<AdvancedSelect
        filter={props.filter}
        adapter={(city)=>({ label: city[0].name, value: city[0].cityId })}
        cellRenderers={[(city)=> (<TableCell>{city[1]?city[1].name:'-'}</TableCell>)]}
        items={ props.cities||[] }
        selectedItem={ props.selectedCity }
        textFieldWrapper="none"
        label={props.label}
        showSearchIcon={false}
        onSelectItemDeselect={props.onDeselect}
        onFilterChange={props.onFilterChange}
        onSelectItemClick={(filter, index)=>{ props.onItemClick(props.cities[index]); return true; }}
        disabled={props.disabled}
        showSelectionIcon
        shrinkLabel={true}
    />);
}