//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root:{
        lineHeight: 0,
        boxShadow: 'inset 0 0 3px RGBA(0,0,0,.3)'
    },
    colorSquare: {
        width:'3em',
        height:'3em',
        display:'inline-block',
        cursor: 'pointer',
    },
});

type ColorPickerProps = {
    onColorClick: (e:any, { color:string, index:number})=>void,
    colors: Array<string>
}

type ColorPickerState = {
}

type ColorPickerThemedProps = ColorPickerProps & {
    classes: any,
    theme: any
}

class ColorPickerThemed extends React.PureComponent<ColorPickerThemedProps,ColorPickerState>{

    handleClick = (e)=>{
        let set = e.currentTarget.dataset;
        this.props.onColorClick(e, {color: set.color, index: parseInt(set.index)});
    }

    render(){
        let { colors, classes } = this.props;
        return <div className={classes.root}>
        { colors.map((color,index)=>(<React.Fragment>
            {index && index%4===0?(<br />):(null)}
            <div
                key={`color-${index}`}
                className={classes.colorSquare}
                style={{backgroundColor: color }}
                data-index={index}
                data-color={color}
                onClick={this.handleClick}>
            </div>
        </React.Fragment>)) }
        </div>;
    }
}

let ColorPicker: React.ComponentType<ColorPickerProps> = (withStyles(styles, { withTheme: true })(ColorPickerThemed): any);

export { ColorPicker };