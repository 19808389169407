//@flow
import * as React from 'react';
import { SaleItems } from './../../../../scenes/Sale/components/SaleItems';
import type { SaleItem } from './../../../../types';

type SaleItemsDemoProps = {

}

type SaleItemsDemoState = {
    items: Array<SaleItem>
}

class SaleItemsDemo extends React.Component<SaleItemsDemoProps,SaleItemsDemoState>{

    constructor(props: SaleItemsDemoProps){
        super(props);
        this.state = {
            items: [
                {
                    amount:1,
                    discount:0,
                    product:{ productId: '1', name: 'Cerveja HEINEKEN Lata 350ml', sku: '01203090128', internalId:'i1', barCode:'1', sellValue: 3.00, unit:'unidade' },
                    adjustedSellValue: 3.00
                },{
                    amount:1,
                    discount:0,
                    product: { productId: '2', name: 'Cerveja SKOL Lata 350ml', sku: '01203090128', internalId:'i2', barCode:'2', sellValue: 2.00, unit:'unidade' },
                    adjustedSellValue: 3.00
                }
            ]
        }
    }

    handleHighlightProdutoId(productId: string){
        alert(productId);
    }

    render(){
        return <SaleItems
            items={ this.state.items }
            highlightedItem={null}
            onItemHighlight={this.handleHighlightProdutoId.bind(this)}
            onItemDelete={ (item: SaleItem)=>{
                alert(item);
            }}
            onItemDiscountChange={(item: SaleItem)=>{
                alert(item);
            }}
            onItemAmountChange={(item: SaleItem)=>{
                alert(item);
            }}
            onItemChange={ (item: any)=>{
                //alert(JSON.stringify(item));
                this.forceUpdate();
            }}
        />
    }
}

export default { key: 'sale-items', demoComponent: SaleItemsDemo };