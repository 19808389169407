//@flow

import * as React from 'react';
import { List, ListItem, ListItemText, Radio } from '@material-ui/core';
import { resources } from './../../../util/resources';

export class OptimizeUIForTouchSelector extends React.PureComponent<{
    onSettingSet: (value: string)=>void,
    value: ?bool
}>{
    
    onListItemClick= (e: any) => {
        this.props.onSettingSet(e.currentTarget.dataset.value);
    }

    render(){
        return (
            <List>
                <ListItem data-value="true" button onClick={this.onListItemClick}>
                    <Radio checked={this.props.value===true} disableRipple />
                    <ListItemText primary={'Habilitar'} />
                </ListItem>
                <ListItem data-value="false" button onClick={this.onListItemClick}>
                    <Radio checked={this.props.value===false} disableRipple />
                    <ListItemText primary={'Desabilitar'} />
                </ListItem>
            </List>
        );
    }
}