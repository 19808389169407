//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Paper } from '@material-ui/core';
import { containerFactory } from './../container';

const errorLoggerService = containerFactory().getErrorLoggerService();

const styles = theme => ({
});

type LogsDialogProps = {
    open: bool,
    onCloseClick: ()=>void
}

type LogsDialogState = {
    result: string
}

type LogsDialogThemedProps = LogsDialogProps & {
    classes: any,
    theme: any
}

const FormItem = (props: any)=>{
    return (<div style={{marginBottom:'24px'}}>{props.children}</div>);
}

class LogsDialogThemed extends React.Component<LogsDialogThemedProps,LogsDialogState>{

    constructor(props: LogsDialogThemedProps){
        super(props);
        this.state = {
            result: ''
        };
    }

    refresh = async ()=>{
        const errors = await errorLoggerService.getLastErrors();
        this.setState({result: JSON.stringify(errors, null, '  ')});
    }    

    render(){
        let { onCloseClick, ...rest } = this.props;
        return <Dialog
                fullScreen={true}
                onBackdropClick={()=>onCloseClick()}
                onEscapeKeyDown={()=>onCloseClick()}
                {...rest}
            >
            <DialogContent style={{display:'flex', height:'100%'}}>
                <div style={{flex:'260px 0 0', padding:'16px'}}>
                    <Button style={{marginRight:'8px'}} onClick={this.refresh} color="primary" variant="contained">Pesquisar</Button>
                    <Button onClick={onCloseClick}>Ocultar</Button>
                </div>
                <div style={{flex:'24px 0 0'}}></div>
                <Paper style={{ flex:'1', overflow: 'auto', padding:'16px' }}>
                    <pre style={{widh:'100%'}}>{this.state.result}</pre>
                </Paper>
            </DialogContent>
        </Dialog>;
    }
}

let LogsDialog: React.ComponentType<LogsDialogProps> = (withStyles(styles, { withTheme: true })(LogsDialogThemed): any);

export { LogsDialog };