//@flow

export class Validation {
    _errors: {[key: string]: Array<string>};
    _hasErrors: bool;
    _requiredFields: Array<string>;
    _toucheds: ?{[key: string]: bool} = null;

    constructor(){
        this._errors = {};
        this._hasErrors = false;
        this._requiredFields = [];
    }

    setToucheds(toucheds: {[key: string]: bool}){
        this._toucheds = toucheds;
    }

    reset(){
        this._errors = {};
        this._hasErrors = false;
        this._requiredFields = [];
    }

    validateRequiredString(key: string, value: string): bool{
        this._requiredFields.push(key);
        if(value==null||value.length===0){
            this.putError(key, 'O campo é obrigatório.');
            this._hasErrors = true;
            return false;
        }
        return true;
    }

    validate(key: string, fn: (value: string)=>bool, value: string){
        if(value!=null && !fn(value)){
            this.putError(key, 'O campo é inválido.');
            this._hasErrors = true;
            return false;
        }
        return true;
    }

    validateEquals(key: string, equalTo: string, value: string){
        if(value!=null && equalTo!==value){
            this.putError(key, 'O campo é inválido.');
            this._hasErrors = true;
            return false;
        }
        return true;
    }

    validatePattern(key: string, pattern: RegExp, value: string){
        if(value!=null && !pattern.test(value)){
            this.putError(key, 'O campo é inválido.');
            this._hasErrors = true;
            return false;
        }
        return true;
    }

    putError(key: string, message: string){
        let errors = this.getErrors(key);
        errors.push(message);
        this._errors[key] = errors;
    }

    getErrors(key: string): Array<string>{
        return this._errors[key]||[];
    }

    getErrorsIfTouched(key: string): Array<string>{
        return (this._toucheds == null || this._toucheds[key]===true)  ? (
            this._errors[key]||[]
        ) : [];
    }

    fieldHasErrors(key: string): bool{
        return this._errors[key]!=null && this._errors[key].length>0;
    }

    touchedFieldHasErrors(key: string): bool{
        return (this._toucheds == null || this._toucheds[key]===true) && ( this._errors[key]!=null && this._errors[key].length>0 );
    }

    isRequiredField(key: string): bool{
        return this._requiredFields.indexOf(key) > -1;
    }

    hasErrors(){
        return this._hasErrors;
    }

    valid(){
        return !this._hasErrors;
    }
}