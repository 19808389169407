//@flow
import type { IPrintHandler } from './../../print-queue-service';
import { ReceiptPrintHandler } from './../receipt-print-handler';
import { RawHTMLPrintHandler } from './../raw-html-print-handler';
import { IPreferencesService } from './../../../../services/preferences-service/'
import { ISaleService } from './../../../../services/sale-service/types'
import * as HTML from './../../../../util/html-string-gen';
import { LocalDatabase } from './../../../../util/local-database';
import type { BusinessUnit, Customer, PaymentMethod, Sale } from './../../../../types';
import * as QRCode from 'qrcode-svg';
import * as POS from './../pos-print-util';
import { parseInvoice } from './invoice-xml-parser';
import { toBrazilianNumber, formatCEP, formatCPF, formatCNPJ } from './../../../../util/formatters';
import type { NFe } from './invoice-xml-parser';import numeral from "numeral";
import 'numeral/locales/pt-br';
numeral.locale('pt-br');

const formatMoney = (input:string|number) => {
    return numeral(parseFloat(input)).format('0.00');
}

let timeReg = /^([0-9]+)[-]([0-9]+)[-]([0-9]+)T([0-9]+)[:]([0-9]+)[:]([0-9]+)([+-].+)$/;
const formatDateTime = (input: ?string) => {
    if(input==null||input.length===0){ return ""; }
    return input.replace(timeReg,'$3/$2/$1 $4:$5:$6($7)');
}

const fontSize = 0.27;
const lineHeight = 1.4;

export class InvoicePrintHTMLRenderer{  

    render(nfe: NFe, sale: Sale, paymentMethod: PaymentMethod){
        let customerName = nfe.destinatario != null ? nfe.destinatario.nome : 'Cliente Não Identificado';
        let sAddress = nfe.emitente.endereco;
        let sellerFullAddress = `${sAddress.logradouro}, N${sAddress.numero}, Bairro ${sAddress.bairro} - ${sAddress.municipio} / ${sAddress.uf}. CEP:&nbsp;${formatCEP(sAddress.cep).replace('-','&#8209;')}`;

        let html = HTML.doc({
            title: 'Nota Fiscal',
            cssFragments: [
                HTML.cssRobotoMono400FontFace(),
                HTML.cssPrintReset(),
                HTML.cssForSelector('body', {
                    'font-size':fontSize+'cm',
                    'font-family':`'Roboto Monospace', monospace`,
                    'line-height': lineHeight.toString()
                }),
                HTML.cssHideNotPrintMedia(),
                `svg {width: 100%; height: auto;}`
            ],
            body:`
            ${POS.rowCentered({content: nfe.emitente.nome, isHeader:true})}
            ${POS.rowCentered({content: `${sellerFullAddress}`})}
            ${POS.rowCentered({content: `CNPJ: ${formatCNPJ(nfe.emitente.cnpj)}`})}
            ${POS.rowCentered({content: ""})}
            ${POS.rowCentered({content: "Documento Fiscal"})}
            ${POS.rowCentered({content: ""})}
            ${POS.horizontalLine()}
            ${POS.rowItemDescription({isHeader:true, item:"Item", description:"Descrição"})}
            ${POS.rowItemValues({isHeader:true, amount:"Quant.", unity:"Unidade", unityValue:"Val.Un.", totalValue:"Valor T."})}
            ${POS.horizontalLine()}
            ${nfe.itens.map((item,i) =>(`
                ${ i!==0? POS.lightHorizontalLine() : '' }
                ${POS.rowItemDescription({item:`${i}`, description:item.descricao})}
                ${POS.rowItemValues({
                    amount: toBrazilianNumber(item.quantidade.toString()),
                    unity: item.unidade,
                    unityValue: toBrazilianNumber(item.valor),
                    totalValue: toBrazilianNumber(item.valorTotal)
                })}
            `)).join('')}
            ${POS.horizontalLine()}
            ${POS.rowLabelValue({label:'Forma de Pagamento', value:paymentMethod.description})}
            ${POS.rowLabelValue({label:'Valor Total', value:toBrazilianNumber(nfe.valorTotal)})}
            ${POS.rowLabelValue({label:'Desconto', value:toBrazilianNumber(nfe.descontoTotal)})}
            ${POS.rowLabelValue({'label':'Valor a Pagar', value:toBrazilianNumber(nfe.valorTotalPagar)})}
            ${POS.rowLabelValue({'label':'Valor Pago', value:formatMoney(sale.paidValue)})}
            ${POS.rowLabelValue({'label':'Troco', value:formatMoney(sale.paidValue - parseFloat(nfe.valorTotalPagar))})}
            ${POS.horizontalLine()}
            ${POS.rowCentered({content:`Consulte pela Chave de Acesso em https://www.sefaz.rs.gov.br/NFCE/NFCE-COM.aspx`})}
            ${POS.rowCentered({content:nfe.protocolo.chave.split('').map((x,i) => (i%4===0&&i?' ':'')+x ).join('')})}
            ${POS.horizontalLine()}
            ${POS.rowCentered({content: ""})}
            ${POS.rowQRAndContent({
                qr:new QRCode({content: nfe.qrCode, padding: 0, width: 256, height: 256, color: "#000000", background: "#ffffff"}).svg().replace('<svg ','<svg viewBox="0 0 256 256" '), content:`
                ${POS.row({content:`Consumidor:<br/> ${nfe.destinatario?nfe.destinatario.nome:'Não Identificado'}`})}
                ${nfe.destinatario!=null && nfe.destinatario.cnpj !=null ?
                    POS.row({content:`CNPJ:&nbsp;${formatCNPJ(nfe.destinatario.cnpj)}`}) : ''
                }
                ${nfe.destinatario!=null && nfe.destinatario.cpf !=null ?
                    POS.row({content:`CPF:&nbsp;${formatCPF(nfe.destinatario.cpf)}`}) : ''
                }
                <br />
                ${POS.row({content:`NFC-e Nº${nfe.identificador.numero} Série&nbsp;${nfe.identificador.serie} ${formatDateTime(nfe.identificador.dataEmissao)}`})}
                <br />
                ${POS.row({content:`Protocolo de autorização:`})}
                ${POS.row({content:`${nfe.protocolo.numero}`})}
                <br />`
            })}
            ${POS.rowCentered({content:`Data de Autorização: ${formatDateTime(nfe.protocolo.dataRecebimento)}`})}
            ${POS.rowCentered({content: nfe.informacaoComplementar})}
            ${POS.rowCentered({content: ""})}
            ${POS.horizontalLine()}
            ${POS.rowCentered({content:'Gerado por Skywork: www.skywork.com.br'})}`
        });
        return html;
    }
};

export class InvoicePrintHandler implements IPrintHandler{
    
    htmlPrintHandler: IPrintHandler = new RawHTMLPrintHandler();
    localDatabase: LocalDatabase;
    preferencesService: IPreferencesService;
    saleService: ISaleService;

    constructor(localDatabase: LocalDatabase, preferencesService: IPreferencesService, saleService: ISaleService){
        this.localDatabase = localDatabase;
        this.preferencesService = preferencesService;
        this.saleService = saleService;
    }

    async print(data: {saleId: string}){
        let sale = await this.localDatabase.getSale(data.saleId);
        if(sale==null)
            throw new Error('Venda não encontrada.');
        if(sale.invoiceData==null)
            throw new Error('Venda não possui dados da nota.');
        let invoice = parseInvoice(sale.invoiceData);
        
        let paymentMethod = (await this.localDatabase.listPaymentMethods()).find(x => x.paymentMethodId === sale.paymentMethodId);
            if(paymentMethod==null) throw new Error('Forma de Pagamento não encontrada.');

        return this.htmlPrintHandler.print(
            {
                title:'Nota Fiscal',
                html:new InvoicePrintHTMLRenderer().render(invoice, sale, paymentMethod)
            }
        );
    }

    async getLabel(data: {saleId: string}){
        let customerName = 'Cliente Não Identificado';
        let sale = await this.localDatabase.getSale(data.saleId);
        if(sale==null) throw new Error('Venda não localizada.');
        if(sale.customerId!=null){
            let customer = await this.localDatabase.getCustomer(sale.customerId);
            if(customer) customerName = customer.name;
        }
        let totals = this.saleService.calculateTotals({items:sale.items});
        return `Nota Fiscal: ${customerName}, R$${formatMoney(totals.total)}`;
    }
}