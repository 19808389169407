//@flow

export const isStrictCNPJFormat = (value: string)=>{
    return /\d\d\.\d\d\d\.\d\d\d\/\d\d\d\d-\d\d/.test(value);
}

export const isValidCNPJ = (value: string)=>{
    var c = value;
    var b = [6,5,4,3,2,9,8,7,6,5,4,3,2];

    if((c = c.replace(/[^\d]/g,"")).length !== 14)
        return false;

    if(/0{14}/.test(c))
        return false;

    for (var i = 0, n = 0; i < 12; n += parseInt(c[i],10) * b[++i]);
    if(parseInt(c[i],12) !== (((n %= 11) < 2) ? 0 : 11 - n))
        return false;

    for (var j = 0, m = 0; j <= 12; m += parseInt(c[j],10) * b[j++]);
    if(parseInt(c[j],13) !== (((m %= 11) < 2) ? 0 : 11 - m))
        return false;

    return true;
}

export const isStrictCPFFormat = (value: string)=>{
    return /\d\d\d\.\d\d\d\.\d\d\d-\d\d/.test(value);
}

export const isValidCPF = (value: string) => {
    var cpf = value.replace(/-|[.]/g,'');
    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11)
          return false;
    for (i = 0; i < cpf.length - 1; i++){
        if (cpf.charAt(i) !== cpf.charAt(i + 1)){
            digitos_iguais = 0;
            break;
        }
    }
    if (!digitos_iguais)
    {
        numeros = cpf.substring(0,9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--){
            soma += parseInt(numeros.charAt(10 - i),10) * i;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado.toString() !== digitos.charAt(0)){
            return false;
        }
        numeros = cpf.substring(0,10);
        soma = 0;
        for (i = 11; i > 1; i--){
            soma += parseInt(numeros.charAt(11 - i),10) * i;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado.toString() !== digitos.charAt(1)){
            return false;
        }
        return true;
    }
    else
        return false;
  }