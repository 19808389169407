//@flow

import { EventEmitter } from './../../util/event-emitter';
import type { IEvent } from './../../types';

export const BLOCK_KEYS={
    NotFullySynced:'NotFullySynced',
    NoBusinessUnitSelected:'NoBusinessUnitSelected'
}

export type BlockReason = $Keys<typeof(BLOCK_KEYS)>;

export interface IBlockerService{
    isBlocked(): bool;
    getBlockedReasons(): Array<BlockReason>;
}