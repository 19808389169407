//@flow
import * as React from 'react';
import { Grid, Paper, Button } from '@material-ui/core/';
import { Alert } from './../../../../components/Alert';
import { DefaultPadding } from './../../../../components/DefaultPadding';

const papers = [
    {message: 'Open a success alert', alertMessage: 'This is a success message!', variant: 'success',},
    {message: 'Open a warning alert', alertMessage: 'This is a warning message!', variant: 'warning'},
    {message: 'Open an error alert', alertMessage: 'This is an error message!', variant: 'error'},
    {message: 'Open a info alert', alertMessage: 'This is an info message!', variant: 'info'}
]

type AlertDemoProps = {

}

type Variants = 'success' | 'warning' | 'error' | 'info' | '';

type AlertDemoState = {
    message: string,
    variant: Variants,
    status: bool
}

type AlertDemoThemedProps = AlertDemoProps & {
    classes: any,
    theme: any
}

class AlertDemo extends React.Component<AlertDemoThemedProps,AlertDemoState>{

    constructor(props: AlertDemoThemedProps){
        super(props);
        this.state = {
            variant: '',
            message: '',
            status: false
        };
    }

    handleOpenClick(message: string, variant: Variants){
        this.setState({message: message});
        this.setState({variant: variant});
        this.setState({status: true});
    }

    render(){
        
        return(
            <Grid container spacing={24}>
                {
                    papers.map((info, i)=>{
                        return (
                            <Grid item md={6} xs={12} key={i}>
                                <Paper style={{marginButton:'24px'}}>
                                    <DefaultPadding>
                                        <h3>{ info.message }</h3>
                                        <Button variant="contained" onClick={this.handleOpenClick.bind(this, info.alertMessage, info.variant)}>{info.variant}</Button>
                                    </DefaultPadding>
                                </Paper>
                            </Grid>
                        )
                    })
                }

                <Alert
                    variant={this.state.variant}
                    message={this.state.message}
                    open={this.state.status}
                    onCloseAlert={()=>{this.setState({status: false})}}
                />
            </Grid>
        )
    }
}

export default { key: 'alert', demoComponent: AlertDemo };