//@flow
// Talvez seja útil mais tarde para podermos abstrair os eventos da aplicação dos componentes princias
// Isso possibilitaria separar melhor a apresentação da infraestrutura de eventos
// Tornaria os componentes de alto nível mais leves
// Mas pode ser preciosismo demais...

export class HandlerSlot<Payload>{
    
    _handler: ?(Payload)=>void = null;

    setHandler(handler: (Payload)=>void){
        this._handler = handler;
    }

    trigger(data: Payload){
        if(this._handler!=null)
            this._handler(data);
    }
}