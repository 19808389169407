//@flow
import * as React from 'react';
import { PrintButton } from './components/PrintButton';
import { withContainer, IContainer } from './../../../container/index';
import { PrintQueueService } from './../../../services/print-queue-service';
import * as Events from './../../../events';
import type { PrintRequest } from './../../../types';

type PrintButtonWithQueueDumbProps = {
    container: IContainer
}
type PrintButtonWithQueueDumbState = {
    items: Array<PrintRequest>
}
export class PrintButtonWithQueueDumb extends React.Component<PrintButtonWithQueueDumbProps,PrintButtonWithQueueDumbState>{
    
    printQueueService: PrintQueueService;

    constructor(props: PrintButtonWithQueueDumbProps){
        super(props);
        this.printQueueService = props.container.getPrintQueueService();
        this.state = {
            items: []
        }
    }

    handleApplicationEvent = (event: Events.AppEvent) => {
        if(event.name===Events.PrintQueueServiceEvents.OnPrintQueueChanged){
            this.refreshItems();
        }
    }

    refreshItems(){
        this.printQueueService.listPrintRequests(true)
            .then(items =>this.setState({items}));
    }

    componentDidMount(){
        this.refreshItems();
        this.props.container.getEventEmitter().subscribe(this.handleApplicationEvent, 'app-print-button');
    }

    componentWillUnmount(){
        this.props.container.getEventEmitter().unsubscribe('app-print-button');
    }

    handleItemClick  = (e:any, index: number) => {
        let printable = this.state.items[index];
        e.stopPropagation();
        this.printQueueService.print(printable);
    }

    handleRemoveItemClick = (e:any, index: number) => {
        let printable = this.state.items[index];
        this.printQueueService.removePrint(printable);
        e.stopPropagation();
    }

    render(){
        return (<PrintButton
            items={this.state.items.map(x=>({ label:x.description, ready:x.ready }))}
            onItemClick={this.handleItemClick}
            onRemoveItemClick={this.handleRemoveItemClick}
        />);
    }
}

export const PrintButtonWithQueue: React.ComponentType<{}> = (withContainer(PrintButtonWithQueueDumb): any);