//@flow
import AdvancedSelectDemo from './AdvancedSelectDemo';
import AlertDemo from './AlertDemo';
import AppLayoutDemo from './AppLayoutDemo';
import DiscountTextFieldDemo from './DiscountTextFieldDemo';
import EditableButtonGroupDemo from './EditableButtonGroupDemo';
import SoftNumberDemo from './SoftNumberDemo';
import TextFieldMaskedDemo from './TextFieldMaskedDemo';
import ValidationDemo from './ValidationDemo';
import WizardDemo from './WizardDemo';

type DemoItem = { key: string, demoComponent: any };

let items: Array<DemoItem> = [
    AdvancedSelectDemo,
    AlertDemo,
    AppLayoutDemo,
    DiscountTextFieldDemo,
    EditableButtonGroupDemo,
    SoftNumberDemo,
    TextFieldMaskedDemo,
    ValidationDemo,
    WizardDemo
].map((item: DemoItem)=>{
    return { key: 'shared/'+item.key, demoComponent:item.demoComponent };
});

export default items;
