//@flow


import type { Sale } from './../../types';
import * as Events from './../../events';
import type { PrintRequest } from './../../types';

export interface IPrintRequestDataSource{
    listPrintRequests(highlightedOnly: bool): Promise<Array<PrintRequest>>;
    putPrintRequest(printRequest: PrintRequest): Promise<void>;
    getPrintRequestByLocator(locator: string): Promise<?PrintRequest>;
    updatePrintRequest(printRequest: PrintRequest): Promise<void>;
    removePrintRequest(printRequest: PrintRequest): Promise<void>;
}

export interface IPrintHandler{
    getLabel(data: {[key:string]: any}): Promise<string>;
    print(data: {[key:string]: any}): Promise<void>;
}

export class PrintQueueService {
    
    _appEventEmitter: Events.AppEventEmitter;
    _dataSource: IPrintRequestDataSource;
    _printHandlers: {[key:string]: IPrintHandler };

    constructor(appEventEmitter: Events.AppEventEmitter, dataSource: IPrintRequestDataSource, printHandlers: {[key:string]: IPrintHandler }){
        this._dataSource = dataSource;
        this._appEventEmitter = appEventEmitter;
        this._printHandlers = printHandlers;
        this._appEventEmitter.subscribe(this.handleAppEvents,'print-queue-service');
    }

    emitChanges(){
        this._appEventEmitter.emit({name: Events.PrintQueueServiceEvents.OnPrintQueueChanged, data:{}, error:null});
    }

    handleAppEvents = async (event: Events.AppEvent) => {
        if(event.name===Events.UIEvents.OnSalePrintRequested){
            let sale: Sale = event.data.sale;
            let type = (sale.autoFetchInvoice===true || sale.invoiceData)?'invoice':'receipt';
            let ready: bool = sale.autoFetchInvoice!==true || sale.invoiceData!=null;
            let data = { saleId: sale.saleId };
            let description = await this._printHandlers[type].getLabel(data);

            let printRequest = {
                locator: `sale:${sale.saleId||''}`,
                description, data, ready, type 
            };

            if(printRequest.ready){
                //sent directly to printer
                this.print(printRequest);
            }
            else{
                //enqueud
                this._dataSource.putPrintRequest(printRequest).then(() =>{
                    this.emitChanges();
                });
            }

        }
        else if(event.name === Events.SaleServiceEvents.OnSaleInvoiceCreated){
            let sale = event.data.sale;
            this._dataSource.getPrintRequestByLocator(`sale:${sale.saleId||''}`)
            .then(printRequest=>{
                if(printRequest!=null){
                    if(event.error){
                        this._dataSource.removePrintRequest(printRequest);
                        this.emitChanges();
                    }
                    else{
                        printRequest.ready=true;
                        this._dataSource.updatePrintRequest(printRequest);
                        this.emitChanges();
                    }
                }
            });
            
        }
    }

    async print(printRequest: PrintRequest): Promise<void> {
        if(this._printHandlers.hasOwnProperty(printRequest.type)){
            await this._printHandlers[printRequest.type].print(printRequest.data);
            await this.removePrint(printRequest);
            return;
        }
        throw new Error(`Could not find a print handler for type ${printRequest.type}.`);        
    }

    async removePrint(printRequest: PrintRequest): Promise<void>{
        await this._dataSource.removePrintRequest(printRequest);
        this.emitChanges();
    }

    async listPrintRequests(highlightedOnly: bool): Promise<Array<PrintRequest>>{
        return this._dataSource.listPrintRequests(highlightedOnly);
    }
}