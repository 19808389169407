//93900000 -> 93900-000
const isCEP = /[0-9]{8}/
export function formatCEP(input: string){
    if(isCEP.test(input)) {
        return `${input.substr(0,5)}-${input.substr(5)}`;
    }
    return input;
}

const isCNPJ = /[0-9]{14}/;
export function formatCNPJ(input: string){
    if(isCNPJ.test(input)){
        return `${input.substr(0,2)}.${input.substr(2,3)}.${input.substr(5,3)}/${input.substr(8,4)}-${input.substr(12,2)}`;
    }
    return input;
}

const isCPF = /[0-9]{11}/;
export function formatCPF(input: string){
    if(isCPF.test(input)){
        return `${input.substr(0,3)}.${input.substr(3,3)}.${input.substr(6,3)}-${input.substr(9,2)}`;
    }
    return input;
}

export function toBrazilianNumber(input: string){
    return input.replace(',',"_,_").replace('.',',').replace('_,_','.');
}

export function toBrazilianTime(input: (Date|string|number)){
    let date: Date;
    if(typeof(input)==='string'){
        date = new Date(input);
    }
    else if(typeof(input)==='number'){
        date = new Date(input);
    }
    if(typeof(input)==='string'){
        date = new Date(input)
    }
    else{
        throw new Error('Invalid input to parse date.');
    }
    return `${date.getHours()}:${date.getMinutes()}`;
    
}

export function toBrazilianDate(input: (Date|string|number), includeTime?: bool) {
    let date: Date;
    if(typeof(input)==='string'){
        date = new Date(input);
    }
    else if(typeof(input)==='number'){
        date = new Date(input);
    }
    if(typeof(input)==='string'){
        date = new Date(input)
    }
    else{
        throw new Error('Invalid input to parse date.');
    }
    let datePart = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
    if(includeTime===true){
        return `${datePart} ${date.getHours()}:${date.getMinutes()}`;
    }
    return datePart;
}