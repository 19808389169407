//@flow

import { BaseCollectionDownSynchronizer } from './../base-collection-down-synchronizer'
import type { GetClientesResponse } from './../../../../util/skywork-rest-client/types';
import type { Customer } from './../../../../types';
import type { SynchronizationEntitiesKeys } from './../../types';
import type { SynchronizeContext } from './../default-synchronization-service';


const DATA_ATUALIZACAO_KEY='defaultSync_getClientesDtAtualizacao';

export class CustomersDownSynchronizer extends BaseCollectionDownSynchronizer<GetClientesResponse,Customer> {
    
    _isMergeEnabled: bool = false;

    enableMerge(){
        this._isMergeEnabled = true;
    }

    getEntityKey(): SynchronizationEntitiesKeys{
        return 'customer';
    }

    getDataFromRemoteSource(context: SynchronizeContext): Promise<GetClientesResponse>{
        return this._restClient.getClientes({
            fields: [
                'cad_cliente_id',
                'nome',
                'tipo_pessoa',
                'cpf_cnpj',
                'inscricao_estadual',
                'email',
                'telefone_comercial',
                'telefone_comercial2',
                'endereco',
                'numero',
                'cep',
                'bairro',
                'complemento',
                'cidade_id',
                'estado_id',
                'pais_id',
                'obs'
            ],
            dt_atualizacao: context.forceFull? null : localStorage.getItem(DATA_ATUALIZACAO_KEY)
        });
    }

    toNullableString(value: any): ?string {
        if(value!=null)
            return value.toString();
        return null;
    }

    convertRemoteData(response: GetClientesResponse): Array<Customer>{
        return response.map(item =>{

            let customer: Customer = {
                customerId: item.cad_cliente_id.toString(),
                name: item.nome,
                type: (item.tipo_pessoa==='PF'||item.tipo_pessoa==='pf'?'PERSON':'COMPANY'),
                cpf_cnpj: item.cpf_cnpj,
                ie: item.inscricao_estadual,
                ICMSTaxpayerType: null,
                email: item.email,
                phoneNumber: item.telefone_comercial||item.telefone_comercial2,
                address: {
                    streetName: item.endereco,
                    number: item.numero,
                    postalCode: item.cep,
                    neighborhood: item.bairro,
                    extraInfo: item.complemento,
                    cityId: this.toNullableString(item.cidade_id),
                    provinceId: this.toNullableString(item.estado_id),
                    countryId: this.toNullableString(item.pais_id)
                },
                observation: item.obs
            };

            return customer;
        });
    }

    async storeConvertedData(items: Array<Customer>): Promise<Array<any>>{
        let ids = [];
        if(this._isMergeEnabled){
            //to merge data and not replace it
            for(let i = 0; i < items.length; i++){
                let customer = items[i];
                await this._localDatabase.insertOrMergeCustomer(customer);
            }
        }
        else{
            await this._localDatabase.batchInsertOrReplaceCustomers(items);
        }
        items.forEach(x =>{
            ids.push(x.customerId);
            if(x.tempCustomerId){
                ids.push(x.tempCustomerId);
            }
        });
        let date = this._restClient.getLastServerTouchDate();
            if(date!=null) localStorage.setItem(DATA_ATUALIZACAO_KEY, date);
        return ids;
    }
}