//@flow
import CustomerFormDemo from './CustomerFormDemo';
import CustomerSearchDemo from './CustomerAdvancedSelectDemo';

type DemoItem = { key: string, demoComponent: any };

let items: Array<DemoItem> = [
    CustomerFormDemo,
    CustomerSearchDemo,
].map((item: DemoItem)=>{
    return { key: 'customer/'+item.key, demoComponent:item.demoComponent };
});

export default items;