//@flow

import axios from 'axios';
import { ExtendedError } from './../error';

import type {
    EmiteNotaPedidoItemResponse,
    EmiteNotaPedidoResponse,
    EmiteNotaPedidoRequest,
    GetCidadesRequest,
    GetCidadesResponse,
    GetClientesRequest,
    GetClientesResponse,
    GetCondicoesPagamentosItemRequest,
    GetCondicoesPagamentosResponse,
    GetCondicoesPagamentosItemResponse,
    GetEstadosRequest,
    GetEstadosResponse,
    GetFormasPagamentosRequest,
    GetFormasPagamentosResponse,
    GetLoginPDVRequest,
    GetLoginPDVResponse,
    GetPaisesRequest,
    GetPaisesResponse,
    GetProdutosRequest,
    GetProdutosResponse,
    GetUnidadeNegocioRequest,
    GetUnidadeNegocioResponse,
    SetClientesRequest,
    SetClientesResponse,
    SetPedidosVendaRequest,
    SetPedidosVendaResponse
} from './types';

export interface ITokenProvider{
    +getToken: ()=>?string;
}

/*
    Documentação fornecida pelo Nissios:
    https://documenter.getpostman.com/view/2951376/metodos-frente-de-caixa/RW1VqMuR
*/

export class SkyworkRestClient {
    
    _axios: axios.Axios;
    _tokenProvider: ITokenProvider;
    _lastServerTouchDate: ?string;

    constructor(baseUrl: string, tokenProvider: ITokenProvider){
        this._tokenProvider = tokenProvider;
        this._axios = axios.create({
            baseURL: baseUrl
        });
    }

    // TODO: add logging logic here
    // axios.interceptors.request.use(request => {
    // console.log('Starting Request', request)
    // return request
    // })

    // axios.interceptors.response.use(response => {
    // console.log('Response:', response)
    // return response
    // })
    
    _performRequest(methodName: string, requestData: Object, filter?:(any)=>any) : Promise<any>{
        return this._axios.post(methodName, requestData).then(response=> {
            if(filter == null){ //default filter
                if(!response.data)
                    throw new Error('Resposta vazia.');
                let data = response.data;
                this._lastServerTouchDate = data.date;
                if(data.status!==0){
                    let error = new ExtendedError(`Ocorreu um problema ao invocar o método "${methodName}". Mensagem:"${data.msg}"`, null, "serverValidation");
                    error.data = data;
                    throw error;
                }
                return Promise.resolve(data.data);
            }
            else{
                return Promise.resolve(filter(requestData));
            }
        },
        (error)=>{
            let errorExtended = new ExtendedError(`Ocorreu um problema ao invocar o método "${methodName}".`, error, 'serverCommunication');
            return Promise.reject(errorExtended);
        });
    }

    _injectFieldsFilter(requestData: Object){
        if(requestData.fields){
            requestData.fields = requestData.fields.join(',');
        }
    }

    _injectTokenOrThrow(requestData: Object){
        let token = this._tokenProvider.getToken();
        if(token==null)
            throw new Error('The requested method requires a token.');
        requestData.token = token;
    }

    async emiteNotaPedido(requestData: EmiteNotaPedidoRequest) : Promise<EmiteNotaPedidoItemResponse>{
        this._injectTokenOrThrow(requestData);
        let response: EmiteNotaPedidoResponse = await this._performRequest('emiteNotaPedido', requestData);
        return response.pedidos[requestData.id_sistema_externo];
    }

    getLastServerTouchDate() : ?string{
        return this._lastServerTouchDate;
    }

    getCidades(requestData: GetCidadesRequest) : Promise<GetCidadesResponse> {
        this._injectFieldsFilter(requestData);
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getCidades', requestData);
    }

    getClientes(requestData: GetClientesRequest) : Promise<GetClientesResponse> {
        this._injectFieldsFilter(requestData);
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getClientes', requestData);
    }

    getCondicoesPagamentos(requestData: GetCondicoesPagamentosItemRequest) : Promise<GetCondicoesPagamentosResponse> {
        this._injectFieldsFilter(requestData);
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getCondicoesPagamentos', requestData);
    }

    getEstados(requestData: GetEstadosRequest) : Promise<GetEstadosResponse> {
        this._injectFieldsFilter(requestData);
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getEstados', requestData);
    }

    getFormasPagamentos(requestData: GetFormasPagamentosRequest) : Promise<GetFormasPagamentosResponse> {
        this._injectFieldsFilter(requestData);
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getFormasPagamentos', requestData);
    }

    getLoginPDV(requestData:  GetLoginPDVRequest) : Promise<GetLoginPDVResponse> {
        this._injectFieldsFilter(requestData);
        return this._performRequest('getLoginPDV', requestData);
    }

    getPaises(requestData: GetPaisesRequest) : Promise<GetPaisesResponse> {
        this._injectFieldsFilter(requestData);
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getPaises', requestData);
    }

    getProdutos(requestData: GetProdutosRequest) : Promise<GetProdutosResponse> {
        this._injectFieldsFilter(requestData);
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getProdutos', requestData);
    }

    getUnidadeNegocio(): Promise<GetUnidadeNegocioResponse>{
        let requestData: GetUnidadeNegocioRequest = {};
        this._injectTokenOrThrow(requestData);
        return this._performRequest('getUnidadeNegocio', requestData);
    }

    setClientes(requestData: SetClientesRequest) : Promise<SetClientesResponse>{
        this._injectTokenOrThrow(requestData);
        return this._performRequest('setClientes', requestData);
    }

    setPedidosVenda(requestData: SetPedidosVendaRequest) : Promise<SetPedidosVendaResponse>{
        this._injectTokenOrThrow(requestData);
        return this._performRequest('setPedidosVenda', requestData);
    }
}

//Cliente para invocar diretamente pelo console do browser
// if(process.env.NODE_ENV==='development'){
    //Endereço do serviço em desenvolvimento:
    // const DEVELOPMENT_URL='http://192.168.1.106/skyworkNFC/php/api/v1';
    // window.restClient = new SkyworkRestClient(DEVELOPMENT_URL, { getToken: ()=>'e1b52599e68bd243d55eb7a185ac037e' });
    //para gerar types:
    //window.restClient._genResponseTypes('getPaises',{token: 'e1b52599e68bd243d55eb7a185ac037e'});
// }