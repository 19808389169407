//@flow

import * as React from 'react';
import { ConfirmableTrigger } from './../../../components/ConfirmableTrigger';
import { IconButton, TextField, Typography } from '@material-ui/core';
import { AttachMoney as AttachMoneyIcon } from '@material-ui/icons';
import { DiscountTextField2 } from './../../../components/DiscountTextField2';

export type SetGlobalDiscountButtonProps = {
    onConfirmClick: (money: number)=>void,
    maxValue: number
} & { [key: string]: any };

export type SetGlobalDiscountButtonState = {
    value: number
}

export class SetGlobalDiscountButton extends React.Component<SetGlobalDiscountButtonProps, SetGlobalDiscountButtonState>{

    dialogProps: any;

    constructor(props: SetGlobalDiscountButtonProps){
        super(props);
        this.state = {value: 0};
        this.dialogProps = {
            onEntering: ()=>{
                this.setState({value:0});
            }
        }
    }

    handleConfirmClick = (e: any) => {
        this.props.onConfirmClick(this.state.value);
    }

    handleMoneyChange = (e: any, money: number) =>{
        this.setState({value: money});
    }
    
    render(){
        const { onConfirmClick, maxValue, ...rest } = this.props;

        return(
            <ConfirmableTrigger
                { ...rest }
                dialogProps={this.dialogProps}
                component={IconButton}
                confirmationMessage={<React.Fragment>
                    <DiscountTextField2
                        disabled={this.props.maxValue===0}
                        value={this.state.value}
                        onMoneyChange={this.handleMoneyChange}
                        label={"Desconto Global"}
                        refValue={maxValue}
                    />
                    <Typography>Atenção: Ao definir um desconto global, todos os descontos aplicados diretamente nos itens serão ignorados.</Typography>
                </React.Fragment>}
                title="Aplicar Desconto Global"
                onConfirmClick={this.handleConfirmClick}
            >
                <AttachMoneyIcon style={{position:'relative', left:'-0.1em'}} />
                <Typography color="textSecondary" style={{position:'absolute', fontSize:12, marginTop:'0.7em', marginLeft:'0.7em'}}>%</Typography>
            </ConfirmableTrigger>
        );
    }
}