//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Input, InputAdornment, TextField } from '@material-ui/core';
import { decimalAdapterFactory } from './InputAdapters';
import numeral from "numeral";
import 'numeral/locales/pt-br';
import { CompareArrows } from '@material-ui/icons';
numeral.locale('pt-br');

const styles = theme => ({
});

type DiscountTextField2Props = {
    value: number,
    onMoneyChange: (e: any, money: number)=>void,
    refValue: number,
    percentInputProps?: Object,
    percentTextFieldProps?: Object,
    moneyInputProps?: Object,
    moneyTextFieldProps?: Object,
    separatorSpanProps?: Object,
    disabled?: bool
}

type DiscountTextField2State = {
    percentValue: number
}

type DiscountTextField2ThemedProps = DiscountTextField2Props & {
    classes: any,
    theme: any
}

class DiscountTextField2Themed extends React.Component<DiscountTextField2ThemedProps,DiscountTextField2State>{

    percentNumberFormatAdapter = decimalAdapterFactory(2);
    moneyNumberFormatAdapter = decimalAdapterFactory(2);
    previousRefValue: ?number;
    previousValue: ?number;
    moneyInputRef: any;

    constructor(props: DiscountTextField2ThemedProps){
        super(props);
        let percentValue = props.value/props.refValue*100.0;
        if(isNaN(percentValue)){
            percentValue = 0;
        }
        this.state = {
            percentValue
        };
    }

    handlePercentChange = (e: any) =>{
        let rawValue = parseFloat(e.target.rawValue);
        if(rawValue>100.0){
            e.target.rawValue='100.0';
        }
        this.setState({percentValue: parseFloat(e.target.rawValue)});
        let refValue = parseFloat(this.props.refValue);
        let money = Math.min(refValue, refValue * parseFloat(e.target.rawValue||'0')/100.0);
        this.props.onMoneyChange(e, money);
    }

    componentDidUpdate(){
        let { previousRefValue, previousValue } = this;
        let refValue = this.props.refValue;
        let value = this.props.value;
        if(previousRefValue!=null && previousValue!=null){
            if(previousRefValue!=refValue||previousValue!=value){
                //recalculate max and percent
                let money: number = value;
                let refValue = parseFloat(this.props.refValue);
                let rawValue = parseFloat(value);
                if(rawValue>refValue){
                    money = refValue;
                }
                let percentValue = money/refValue*100.0;
                this.setState({percentValue: isNaN(percentValue)?0:percentValue});
                this.props.onMoneyChange({target: this.moneyInputRef}, parseFloat(money));
            }
        }
        this.previousRefValue = refValue;
        this.previousValue = value;
    }

    handleMoneyChange = (e: any) =>{
        let money: number = parseFloat(e.target.rawValue);
        let refValue = parseFloat(this.props.refValue);
        let rawValue = parseFloat(e.target.rawValue);
        if(rawValue>refValue){
            money = refValue;
        }
        let percentValue = money/refValue*100.0;
        this.setState({percentValue: isNaN(percentValue)?0:percentValue});
        this.props.onMoneyChange(e, parseFloat(money));
    }

    handleInputRef = (ref)=>{
        this.moneyInputRef = ref;
    }

    render(){
        let { percentInputProps, percentTextFieldProps, moneyInputProps, moneyTextFieldProps, separatorSpanProps } = this.props;

        let { style:spanStyle, ...separatorSpanPropsRest } = separatorSpanProps||{};
        return (<div style={{display: 'flex'}}>
        
            <TextField
                style={{flex:3, alignSelf: 'flex-end'}}
                fullWidth
                value={ this.props.value }
                inputRef={ this.handleInputRef }
                InputProps={{
                    inputComponent: this.moneyNumberFormatAdapter.component,
                    startAdornment: (<InputAdornment position="start">R$</InputAdornment>),
                    ...moneyInputProps
                }}
                disabled={this.props.disabled}
                InputLabelProps={{shrink:true}}
                onChange={ this.handleMoneyChange }
                { ...moneyTextFieldProps }
            />
            <span {...separatorSpanPropsRest} style={{margin:'0px 10px 6px 10px', alignSelf: 'flex-end', ...spanStyle}}>=</span>
            <TextField
                style={{flex:2, alignSelf: 'flex-end'}}
                fullWidth
                value={ this.state.percentValue }
                disabled={this.props.disabled}
                InputProps={{
                    inputComponent: this.percentNumberFormatAdapter.component,
                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                    ...percentInputProps
                }}
                InputLabelProps={{shrink:true}}
                onChange={ this.handlePercentChange }
                { ...percentTextFieldProps }
            /> 
            
        </div>);
    }
}

let DiscountTextField2: React.ComponentType<DiscountTextField2Props> = (withStyles(styles, { withTheme: true })(DiscountTextField2Themed): any);

export { DiscountTextField2 };