//@flow
import { ITokenProvider } from './../../util/skywork-rest-client';
import { IAuthenticationService } from './../../services/authentication-service';

export class AuthenticationTokenProviderAdapter implements ITokenProvider {
    
    _authServiceFactory: ()=>?IAuthenticationService;

    constructor(authServiceFactory: ()=>?IAuthenticationService){
        this._authServiceFactory = authServiceFactory;
    }

    getToken(): ?string{
        let authService = this._authServiceFactory();
        if(authService==null)
            return null;
        let user = authService.getAuthenticatedUser();
        if(user!=null)
            return user.token;
        return null;
    }
}