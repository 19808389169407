//@flow

type CalculatorOpenSetup = {
    callbackLabel?: string,
    callback: (result: string) => void,
    cancelCallback?: () => void
}

type CalculatorInitialState = {
    selectedOperation?: string,
    storedValue?: number
}

type CalculatorOpenHandlerContext = {
    initialState: CalculatorInitialState,
    callbackLabel: ?string,
    callback: ?(result: string) => void,
    cancelCallback: ?() => void
}

type CalculatorCallback = (result: string)=>void;

/**
    Responsável por permitir que a calculadora seja invocada de qualquer lugar,
    sem se comunicar diretamente com o componente
 */
export class CalculatorService{

    _setup: ?CalculatorOpenSetup;
    _openHandler: (context: CalculatorOpenHandlerContext) => void;

    openCalculator(initialState: CalculatorInitialState, setup?: CalculatorOpenSetup ){
        if(this._openHandler){
            this._openHandler({
                initialState:initialState,
                callback: setup? setup.callback : null,
                callbackLabel: setup? setup.callbackLabel : null,
                cancelCallback: setup? setup.cancelCallback : null
            });
            this._setup = setup;
        }
    }

    setOpenHandler(handler: (context: CalculatorOpenHandlerContext) => void){
        this._openHandler = handler;
    }
}