//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Tab, Tabs } from '@material-ui/core/';
import SwipeableViews from 'react-swipeable-views';

const DEBUG_LOG = false;

const styles = theme => ({
    wrapper:{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
        justifyContent: 'space-around',
        height: '100%',
        minHeight: '200px'
    },
    contentItemHighlighted: {
        
    },
    tabs: {
        flex: '0 0 0',
        minHeight: '57px'
    },
    tabWrapper: {
        minHeight:'57px'
    },
    tabHighlighted:{
        background: 'RGBA(0,0,0,.1)'
    },
    tabKeyNavAnchor:{
        opacity:0,
        position:'absolute',
        cursor: 'none',
        pointerEvents:'none'
    }
});

type SwipeableTabsProps = {
    tabs: Array<{key?: string, label: string, content: React.Node }>,
    contentItemClass?: any,
    onTabIndexChange?: (index: number, ref: any)=> void,
    tabContentRef?: (ref: any, index: number)=>void,
    onTransitionEnd?: (number)=>void,
    wrapperProps?: {}
}

type SwipeableTabsState = {
    tabIndex: number,
    highlightCurrentTab: bool,
}

type SwipeableTabsThemedProps = SwipeableTabsProps & {
    classes: any,
    theme: any
}

class SwipeableTabsThemed extends React.Component<SwipeableTabsThemedProps,SwipeableTabsState>{

    focusStartsRefs: Array<any> = [];
    tabsRefs: Array<any> = [];
    landingAnchorThrowsBack: bool = false;
    ignoreSwipeChanges: bool = false;

    constructor(props: SwipeableTabsThemedProps){
        super(props);
        this.state = {
            tabIndex: 0,
            highlightCurrentTab: false
        };
    }

    handleWindowBlur = ()=>{
        this.landingAnchorThrowsBack=false;
    }

    componentDidMount(){
        window.addEventListener('blur', this.handleWindowBlur);
    }

    componentWillUnmount(){
        window.removeEventListener('blur', this.handleWindowBlur);
    }

    tryChangeTabIndex(tabIndex: number){
        if(DEBUG_LOG) console.log(`${this.state.tabIndex} to ${tabIndex}`);
        let maxIndex = this.props.tabs.length-1;
        if(tabIndex>maxIndex){
            tabIndex = maxIndex
        }
        if(tabIndex<0){
            tabIndex = 0;
        }
        if(tabIndex!==this.state.tabIndex){
            this.ignoreSwipeChanges = true;
            this.landingAnchorThrowsBack = false;
            this.setState({tabIndex});
            
            if(this.props.onTabIndexChange){
                this.props.onTabIndexChange(tabIndex, this.tabsRefs[tabIndex]);
            }

            let focusRef = this.focusStartsRefs[tabIndex];
            if(focusRef&&focusRef.current){
                focusRef.current.focus();
            }

            return true;
        }
        return false;
    }

    handleSwipeableChangeIndex = (tabIndex: number) => {
        if(!this.ignoreSwipeChanges){
            if(DEBUG_LOG) console.log('Swipeable_onChangeIndex');
            this.tryChangeTabIndex(tabIndex)
        }
    }

    handleChange = (event, tabIndex) => {
        if(DEBUG_LOG) console.log('Tabs_onChangeIndex');
        this.tryChangeTabIndex(tabIndex)
    };

    getTabContentRef(index: number){
        if(this.props.tabContentRef!=null){
            let tabContentRef = this.props.tabContentRef;
            return (ref)=>{
                tabContentRef(ref, index);
            }
        }
        return undefined;
    }

    handleTransitionEnd = () => {
        let { tabIndex } = this.state;
        if(this.props.onTransitionEnd){
            this.props.onTransitionEnd(tabIndex);
        }
        this.ignoreSwipeChanges = false;
    }

    preventDefault(e){
        e.preventDefault();
    }

    goPrev = ()=>{
        this.tryChangeTabIndex(this.state.tabIndex-1);
    }

    goToStart = ()=>{
        this.tryChangeTabIndex(0);
    }

    goNext = () => {
        this.tryChangeTabIndex(this.state.tabIndex+1);
    }

    handleAnchorFocus = () => {
        if(this.landingAnchorThrowsBack){
            this.tryChangeTabIndex(this.state.tabIndex-1);
        }
        else{
            this.landingAnchorThrowsBack=true;   
        }
        if(!this.state.highlightCurrentTab)
            this.setState({highlightCurrentTab: true});
    }

    handleAnchorBlur = () => {
        if(this.state.highlightCurrentTab)
            this.setState({highlightCurrentTab: false});
    }

    getTabRefHandler = (index:number) => {
        return (ref)=>{
            this.tabsRefs[index] = ref;
        }
    }

    render(){

        let { theme, classes, tabs, contentItemClass, wrapperProps } = this.props;
        let { highlightCurrentTab } = this.state;
        let tabIndex = this.state.tabIndex;
        return (
        <div className={classNames(classes.wrapper)} {...wrapperProps} >
            <a 
                className={classes.tabKeyNavAnchor}
                href="#tabs-init"
                onClick={ this.preventDefault }
                onFocus={ this.goToStart }>
                INIT
            </a>
            <Tabs
                className={classNames(classes.tabs)}
                value={ tabIndex }
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                fullWidth
            >
                { tabs.map((tab, index)=>{
                    let highlight = highlightCurrentTab && index===tabIndex;
                    return (
                    <Tab
                        classes={{ wrapper: classes.tabWrapper }}
                        className={ (highlight?classes.tabHighlighted:null)}
                        ref={this.getTabRefHandler(index)}
                        key={`tab-${tab.key||tab.label}`}
                        tabIndex={-1}
                        label={tab.label} />                    
                )}) }
            </Tabs>
            <SwipeableViews
                onTransitionEnd={this.handleTransitionEnd}
                disableLazyLoading={true}
                style={{flex: '1 0 0'}}
                containerStyle={{ height: '100%' }}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tabIndex}
                onChangeIndex={this.handleSwipeableChangeIndex}
            >
                { tabs.map((tab, index)=>{
                    if(this.focusStartsRefs[index]==null){
                        this.focusStartsRefs[index] = React.createRef();
                    }

                    let highlight = highlightCurrentTab && index===tabIndex;

                    let contentItemClassName = classNames(
                        contentItemClass,
                        {[classes.contentItemHighlighted]: highlight }
                    );
                    
                    return (
                    <div
                        ref={this.getTabContentRef(index)}
                        key={`tab-content-${tab.key||tab.label}`}
                        className={contentItemClassName}>

                        { index!==0? (
                            <a
                                className={classes.tabKeyNavAnchor}
                                href="#prev"
                                onClick={ this.preventDefault }
                                onFocus={ this.goPrev }>
                                PREV
                            </a>
                        ) :( null ) }
                        <a
                            className={classes.tabKeyNavAnchor}
                            href="#tab-init"
                            onClick={ this.preventDefault }
                            ref={ this.focusStartsRefs[index] }
                            onFocus={ this.handleAnchorFocus }
                            onBlur={ this.handleAnchorBlur }
                            >
                            ANCHOR
                        </a>
                        
                        {tab.content}

                        { index < tabs.length - 1 ? (
                            <a
                                href="#next"
                                className={classes.tabKeyNavAnchor}
                                onClick={ this.preventDefault }
                                onFocus={ this.goNext }>
                                NEXT
                            </a>
                        ) : ( null ) }
                    </div>
                    );
                }) }
            </SwipeableViews>
        </div>
        );
    }
}

let SwipeableTabs: React.ComponentType<SwipeableTabsProps> = (withStyles(styles, { withTheme: true })(SwipeableTabsThemed): any);

export { SwipeableTabs };