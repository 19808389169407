//@flow
import * as Events from './../../../../events';
import type { ISynchronizer } from './../default-synchronization-service';
import type { SynchronizationEntityData, SynchronizationEntitiesKeys } from './../../types';
import { SynchronizationEntities } from './../../types';
import { SkyworkRestClient } from './../../../../util/skywork-rest-client';
import type { SetClientesRequest, SetClientesItemRequest } from './../../../../util/skywork-rest-client/types';
import { EventEmitter } from './../../../../util/event-emitter';
import { ExtendedError } from './../../../../util/error';
import { LocalDatabase } from './../../../../util/local-database';
import type { Customer } from './../../../../types';


export class CustomersUpSynchronizer implements ISynchronizer {
    
    _localDatabase: LocalDatabase;
    _restClient: SkyworkRestClient;
    _eventEmitter: EventEmitter<Events.AppEvent>;

    constructor(
        restClient: SkyworkRestClient,
        localDatabase: LocalDatabase,
        eventEmitter: EventEmitter<Events.AppEvent>
    ){
        this._eventEmitter = eventEmitter;
        this._restClient = restClient;
        this._localDatabase = localDatabase;
    }

    nullableStringToNullableNumber(value: ?string): ?number{
        if(value==null)
            return null;
        return parseInt(value);
    }

    async synchronize(): Promise<void>{
        
        let entityKey = 'customer';
        let syncedIds = [];
        let download = false;
        this._eventEmitter.emit({
            name: Events.SynchronizationServiceEvents.OnEntitySynchronizationStarted,
            data:{ entityKey, download},
            error:null
        });

        let error: ?Error = null;
        let touchedIds = [];

        try{

            let ids = await this._localDatabase.getSyncStates('customers','pending');
                
            for(let i = 0; i < ids.length; i++){

                let id = ids[i];

                let customer: ?Customer = await this._localDatabase.getCustomer(id);
                if(customer!=null){

                    let isLocalId = customer.customerId.startsWith('_local_');
                    let currentCad_cliente_id: ?number = isLocalId?null:parseInt(customer.customerId,10);

                    let requestItem: SetClientesItemRequest = {
                        cad_cliente_id: currentCad_cliente_id,
                        tipo_pessoa: customer.type==='PERSON'?'PF':'PJ',
                        cpf_cnpj: customer.cpf_cnpj,
                        nome: customer.name,
                        codigo: undefined,
                        razao_social: undefined,
                        rg: undefined,
                        email: customer.email,
                        email2: undefined,
                        telefone_comercial: customer.phoneNumber,
                        telefone_comercial2: undefined,
                        telefone_celular: undefined,
                        obs: customer.observation,
                        dt_nascimento: undefined,
                        sexo: undefined,//0=Homem,1=Mulher não tratamos isso no momento...
                        profissao: undefined,
                        cep: customer.address.postalCode,
                        endereco: customer.address.streetName,
                        numero: customer.address.number!=null?parseInt(customer.address.number,10):undefined,
                        complemento: customer.address.extraInfo,
                        bairro: customer.address.neighborhood,
                        pais_id: this.nullableStringToNullableNumber(customer.address.countryId),
                        estado_id: this.nullableStringToNullableNumber(customer.address.provinceId),
                        cidade_id: this.nullableStringToNullableNumber(customer.address.cityId),
                        obs_financeiro: undefined
                    };

                    for(var key in requestItem){ //removendo propriedades não enviadas
                        let val = requestItem[key];
                        if(val==null||val===''||/^\s+$/.test(val)||val===NaN){
                            delete requestItem[key];
                        }
                    }

                    let request: SetClientesRequest = {
                        clientes:[requestItem]
                    };

                    let setClientResponse = await this._restClient.setClientes(request);

                    let item = setClientResponse[0];

                    if(isLocalId){
                        touchedIds.push(customer.customerId);
                        touchedIds.push(item.cad_cliente_id);
                        await this._localDatabase.assignIdForCustomer(customer.customerId, item.cad_cliente_id.toString());
                    }
                    else{
                        touchedIds.push(customer.customerId);
                    }
                    
                }
                await this._localDatabase.deleteSyncState('customers', id);
            }

        }
        catch(e){
            error = new ExtendedError('Erro ao enviar os clientes.', e, 'summary');
        }

        this._eventEmitter.emit({
            name: Events.SynchronizationServiceEvents.OnEntitySynchronizationEnded,
            data:{ entityKey, download, ids:touchedIds },
            error
        });

        if(error) throw error;
    }
    
}