//@flow
import * as React from 'react';
import { CustomerFormDumbStyled } from './../../../EditCustomerDialog/components/CustomerFormDumb';
import { MockDataStoreService } from './../../../../services/data-store-service';

type CustomerFormDemoProps = {
}

type CustomerFormDemoState = {
}

class CustomerFormDemo extends React.Component<CustomerFormDemoProps,CustomerFormDemoState>{

    mockService = new MockDataStoreService();

    render(){

        let validCustomer = new MockDataStoreService()._customers[0];

        return (
            <React.Fragment>
                <CustomerFormDumbStyled
                    onSubmit={()=>alert('Submit')}
                    cityGetter={this.mockService.getCity.bind(this.mockService)}
                    citiesDataSource={this.mockService.listCities.bind(this.mockService)}
                    countryGetter={this.mockService.getCountry.bind(this.mockService)}
                    countriesDataSource={this.mockService.listCountries.bind(this.mockService)}
                    customer={null}
                    localCountryId={this.mockService.getLocalCountry().countryId}
                    foreignCity={this.mockService.getForeignCity()}
                />
                <CustomerFormDumbStyled
                    onSubmit={()=>alert('Submit')}
                    cityGetter={this.mockService.getCity.bind(this.mockService)}
                    citiesDataSource={this.mockService.listCities.bind(this.mockService)}
                    countryGetter={this.mockService.getCountry.bind(this.mockService)}
                    countriesDataSource={this.mockService.listCountries.bind(this.mockService)}
                    customer={validCustomer}
                    localCountryId={this.mockService.getLocalCountry().countryId}
                    foreignCity={this.mockService.getForeignCity()}
                />
            </React.Fragment>
        )
    }
}

export default { key: 'customer-form', demoComponent: CustomerFormDemo };