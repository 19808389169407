//@flow

import * as React from 'react';
import { Button } from '@material-ui/core';
import { ConfirmationDialogStyled } from './ConfirmationDialog';

type ConfirmableTriggerProps = {
    component?: any,
    onConfirmClick: ()=>void,
    confirmationMessage: any,
    title: string,
    dialogProps?: any
} & {[key: string]: any};

export class ConfirmableTrigger extends React.Component<ConfirmableTriggerProps,{open: bool}>{

    constructor(props: ConfirmableTriggerProps){
        super(props);
        this.state = { open: false }
    }

    handleOkClick = (e: any) => {
        this.setState({open: false});
        this.props.onConfirmClick();
    }

    handleCancelClick = (e: any) => {
        this.setState({open: false});
    }

    handleTriggerClick = (e: any) => {
        this.setState({open: true});
    }

    render(){

        const { component, title, confirmationMessage, dialogProps, onConfirmClick, ...triggerProps } = this.props;

        const Component = component || Button;
        return (
        <React.Fragment>
            <Component {...triggerProps} onClick={this.handleTriggerClick} />
            <ConfirmationDialogStyled
                open={this.state.open}
                onOkClick={this.handleOkClick}
                onCancelClick={this.handleCancelClick}
                title={title}
                dialogProps={dialogProps}
            >
                { confirmationMessage }
            </ConfirmationDialogStyled>
        </React.Fragment>
        );
    }

}