//@flow

import * as Events from './../../events';
import { SkyworkRestClient } from './../../util/skywork-rest-client/';
import { SynchronizationEntities  } from './types';
import type { ISynchronizationService, SynchronizationEntitiesKeys, SynchronizationEntityData } from './types';

export class MockSynchronizationService implements ISynchronizationService{
     
    _isInSync: bool = false;
    _hasFullySyncedOnce: bool = false;
    _didFullSync: bool = false;
    _lastEntitySyncEvent: ?Events.AppEvent = null;
    _lastSyncEndedEvent: ?Events.AppEvent = null;

    timeoutPromise(time: number): Promise<void>{
        return new Promise(resolve=>{
            setTimeout(resolve, time);
        });
    }

    async mockEntitySynchronization(entity: SynchronizationEntitiesKeys){
        
        let data: SynchronizationEntityData = {
            entityKey: entity,
            length: 0,
            download: true,
            ids: null
        }

        this._lastEntitySyncEvent = {name: Events.SynchronizationServiceEvents.OnEntitySynchronizationStarted, data, error: null };
        Events.emitter.emit(this._lastEntitySyncEvent);

        await this.timeoutPromise(1000 + Math.random()*2000);

        data.length = 10;
        data.ids = [];

        this._lastEntitySyncEvent = {name: Events.SynchronizationServiceEvents.OnEntitySynchronizationEnded, data, error: null };
        Events.emitter.emit(this._lastEntitySyncEvent);

        return true;
    }

    isInSync(){
        return this._isInSync;
    }

    startSync(forceFull: bool): void {
        if(this._isInSync)
            return;

        (async ()=>{
            this._isInSync = true;
            Events.emitter.emit({name: Events.SynchronizationServiceEvents.OnSynchronizationStarted, data: {}, error: null });
            if(
                await this.mockEntitySynchronization(SynchronizationEntities.paymentCondition)
                && await this.mockEntitySynchronization(SynchronizationEntities.paymentMethod)
                && await this.mockEntitySynchronization(SynchronizationEntities.product)
                && await this.mockEntitySynchronization(SynchronizationEntities.customer)
            ){
                this._isInSync = false;
                this._didFullSync = true;
                this._lastSyncEndedEvent = {name: Events.SynchronizationServiceEvents.OnSynchronizationEnded, data: { aborted: false }, error: null };
                Events.emitter.emit(this._lastSyncEndedEvent);            
            }
            else{
                this._isInSync = false;
                this._lastSyncEndedEvent = {name: Events.SynchronizationServiceEvents.OnSynchronizationEnded, data: { aborted: true }, error: null };
                Events.emitter.emit(this._lastSyncEndedEvent);       
            }
        })();
    }

    getLastEntitySyncEvent(){
        return this._lastEntitySyncEvent;
    }

    getLastSyncEndedEvent(){
        return this._lastSyncEndedEvent;
    }

    getDidFullSync(){
        return this._didFullSync;
    }
}