//@flow
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import primaryColor from '@material-ui/core/colors/indigo';
import secondaryColor from '@material-ui/core/colors/pink';
import errorColor from '@material-ui/core/colors/red';
import alternatePrimaryColor from '@material-ui/core/colors/deepPurple';

const useAlternateTheme = process.env.REACT_APP_THEME==='ALTERNATE';
const _primaryColor = useAlternateTheme ? alternatePrimaryColor : primaryColor;

export const appTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: _primaryColor,
    secondary: secondaryColor,
    error: errorColor
  },
  overrides: {
    MuiSnackbarContent: { 
        root: { 
            maxWidth: '100000px !important'
        },
    },
    MuiInputAdornment: {
      
    }
  }
});

export const darkTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
    primary: _primaryColor,
    secondary: secondaryColor,
    error: errorColor
  },
  overrides: {
    MuiListItem: { 
        root: { 
            color: _primaryColor.contrastText
        },
    },
    MuiInputAdornment: {
      
    }
  }
});
