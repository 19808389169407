//@flow
import * as React from 'react';
import { containerFactory } from './container-factory';
import type { IContainer } from './types';
import { HandlerSlot } from './../util/handler-slot';

let context : React$Context<IContainer> = (React.createContext(containerFactory()): any);

export const {
    Provider: ContainerProvider,
    Consumer: ContainerConsumer
} = context;

export function withContainer(WrappedComponent: any): any{
    class WithContainer extends React.Component<any,any> {
        render(){
            return (
                <ContainerConsumer>
                    {container => (
                        <WrappedComponent container={container} {...this.props} />
                    )}
                </ContainerConsumer>
            );
        }
    }

    let displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithContainer.displayName = `WithContainer(${displayName})`;
    return WithContainer;
}

export function withContainerFineGrained(
    WrappedComponent: any,
    inject: (container: IContainer, onDestroySlot: HandlerSlot<void>, props: any)=> {[key:string]: any}
) : any {

    class WithContainer extends React.Component<any,any> {
        
        onDestroyHandler = new HandlerSlot<void>();

        componentDidMount(){
            this.onDestroyHandler.trigger();
        }

        render(){


            return (
                <ContainerConsumer>
                    {container => (
                        <WrappedComponent {...this.props} {...(inject(container, this.onDestroyHandler, this.props))} />
                    )}
                </ContainerConsumer>
            );
        }
    }

    let displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithContainer.displayName = `WithContainer(${displayName})`;
    return WithContainer;
}