//@flow
import { ButtonBase } from '@material-ui/core';
import React from "react";
import PropTypes from "prop-types";
import { buttonStyle } from './styles';

class Button extends React.Component<any,any> {
  
  handleClick = () => {
    this.props.clickHandler(this.props.name);
  }

  onMouseOut = (e: any)=>{
    this.setState({hover:true});
  }

  onMouseOver = (e: any)=>{
    this.setState({hover:false});
  }

  render() {
    const style = Object.assign(
      {},
      buttonStyle.componentButton, 
      this.props.operationStyle? buttonStyle.componentButtonOperation : undefined,
      this.props.wide? buttonStyle.componentButtonWide : undefined
    );

    return (
      <ButtonBase style={style} onClick={this.handleClick}>
        {this.props.tip?(<span style={buttonStyle.componentButtonTip}>{this.props.tip}</span>):(null)}
        {this.props.name}
      </ButtonBase>
    );
  }
}

Button.propTypes = {
  name: PropTypes.string,
  orange: PropTypes.bool,
  wide: PropTypes.bool,
  clickHandler: PropTypes.func,
};

export default Button;