//@flow

import type { ISynchronizer, SynchronizeContext } from './../default-synchronization-service';
import type { IPreferencesService } from './../../../../services/preferences-service';
import type { SkyworkRestClient } from './../../../../util/skywork-rest-client';
import { unidadeDeNegocioToBusinessUnit } from './../../../../util/skywork-rest-client';


export class BusinessUnitDownSynchronizer implements ISynchronizer{
    
    restClient: SkyworkRestClient;
    preferencesService: IPreferencesService;

    constructor(restClient: SkyworkRestClient, preferencesService: IPreferencesService){
        this.restClient = restClient;
        this.preferencesService = preferencesService;
    }
    async synchronize(context: SynchronizeContext){
        let currentBusinessUnit = this.preferencesService.getBusinessUnit();
        if(currentBusinessUnit!=null){
            let businessUnits = (await this.restClient.getUnidadeNegocio()).map(unidadeDeNegocioToBusinessUnit);
            let newBusinessUnit = businessUnits.find(x => x.businessUnitId == currentBusinessUnit.businessUnitId);
            if(newBusinessUnit==null){
                this.preferencesService.clearBusinessUnit();
            }
            else{
                this.preferencesService.setBusinessUnit(newBusinessUnit);
            }

        }
    }
}