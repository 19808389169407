//@flow
import * as React from 'react';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';

export const BlockCard = (props: {children: any, title: string, actions: Array<any>}) => {
    return (<Card style={{flex: '1 0 300px', margin:'0 8px 16px 8px'}}>
        <CardContent style={{minHeight:144}}>
            <Typography color="secondary">{props.title}</Typography>
            {props.children}
        </CardContent>
        { props.actions.length? (<CardActions>{props.actions}</CardActions>) : (null) }
    </Card>);
}