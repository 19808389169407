//@flow

import { BaseCollectionDownSynchronizer } from './../base-collection-down-synchronizer'
import type { GetCidadesResponse } from './../../../../util/skywork-rest-client/types';
import type { City } from './../../../../types';
import type { SynchronizationEntitiesKeys } from './../../types';

export class CitiesDownSynchronizer extends BaseCollectionDownSynchronizer<GetCidadesResponse,City> {

    getEntityKey(): SynchronizationEntitiesKeys{
        return 'city';
    }

    getDataFromRemoteSource(): Promise<GetCidadesResponse>{
        return this._restClient.getCidades({fields: [
            'cidade_id',
            'nome',
            'codigo_ibge',
            'estado_id'
        ]})
    }

    convertRemoteData(reponse: GetCidadesResponse): Array<City>{
        return reponse.map(item =>{
            let city: City = {cityId: item.cidade_id.toString(), name: item.nome, provinceId:item.estado_id.toString()};
            return city;
        })
    }

    async storeConvertedData(items: Array<City>): Promise<Array<any>>{
        await this._localDatabase.batchInsertOrReplaceCities(items);
        return items.map(x => x.cityId);
    }
}