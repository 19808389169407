//@flow

import type { IPrintHandler } from './../print-queue-service';
import { ExtendedError } from './../../../util/error';

export class RawHTMLPrintHandler implements IPrintHandler{
    
    async print(data: {html: string, title: string}){
        let win = window.open("","_blank");
        if(win==null){
            throw new ExtendedError('Não foi possível abrir uma nova janela para impressão.', null, 'summary');
        }
        win.document.body.innerHTML = data.html;
        
        var script = document.createElement("script");
        script.innerHTML = "print(); close();";
        win.document.body.appendChild(script);        
    }

    async getLabel(data: {html: string, title: string}){
        return data.title;
    }
}