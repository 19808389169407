//@flow
import type { IPreferencesService, ProductShortcutsPreferenceItem, PaymentMethodsShortcutsPreferenceItem, PaymentConditionsShortcutsPreferenceItem } from './../types';
import type { BusinessUnit, City, Province } from './../../../types';
import * as Events from './../../../events';

const KEYS = {
    businessUnit: 'businessUnit',
    defaultCity: 'defaultCity',
    defaultPaymentMethodId: 'defaultPaymentMethodId',
    defaultPaymentConditionId: 'defaultPaymentConditionId',
    defaultPrintOption: 'defaultPrintOption',
    productsShortcuts: 'productsShortcuts',
    paymentMethodsShortcuts: 'paymentMethodsShortcuts',
    paymentConditionsShortcuts: 'paymentConditionsShortcuts',
    enableDeveloperUI: 'enableDeveloperUI',
    optimizeUIForTouch: 'optimizeUIForTouch'
}

export interface IPreferencesStorage {
    get(key: string): ?string;
    set(key: string, value: string): void;
    clear(key: string): void;
}

export class DefaultPreferencesService implements IPreferencesService{
    
    _storage: IPreferencesStorage;
    _appEventEmitter: Events.AppEventEmitter;

    constructor(storage: IPreferencesStorage, appEventEmitter: Events.AppEventEmitter){
        this._storage = storage;
        this._appEventEmitter = appEventEmitter;
    }

    _emitChange(key: string){
        this._appEventEmitter.emit({
            name:Events.PreferencesServiceEvents.OnPreferenceValueChanged,
            error:null, data:{key}
        });
    }

    getDefaultCity(): ?[City, ?Province]{
        let data = this._storage.get(KEYS.defaultCity);
        if(data){ return JSON.parse(data); }
        return null;
    }
    setDefaultCity(city: ?[City, ?Province]): void{
        this._storage.set(KEYS.defaultCity, city!=null?JSON.stringify(city):'');
        this._emitChange(KEYS.defaultCity);
    }
    clearDefaultCity(): void{
        this._storage.clear(KEYS.defaultCity);
        this._emitChange(KEYS.defaultCity);
    }

    getEnableDeveloperUI(): ?bool{
        let val = this._storage.get(KEYS.enableDeveloperUI);
        if(val==null) return null;
        return val==true.toString();
    }
    setEnableDeveloperUI(enable: bool): void{
        this._storage.set(KEYS.enableDeveloperUI, enable.toString());
        this._emitChange(KEYS.enableDeveloperUI);
    }
    clearEnableDeveloperUI(): void{
        this._storage.clear(KEYS.enableDeveloperUI);
        this._emitChange(KEYS.enableDeveloperUI);
    }

    getDefaultPaymentMethodId(): ?string{
        return this._storage.get(KEYS.defaultPaymentMethodId);
    }
    setDefaultPaymentMethodId(id: string): void{
        this._storage.set(KEYS.defaultPaymentMethodId, id);
        this._emitChange(KEYS.defaultPaymentMethodId);
    }
    clearDefaultPaymentMethodId(): void{
        this._storage.clear(KEYS.defaultPaymentMethodId);
        this._emitChange(KEYS.defaultPaymentMethodId);
    }

    getDefaultPaymentConditionId(): ?string{
        return this._storage.get(KEYS.defaultPaymentConditionId);
    }
    setDefaultPaymentConditionId(id: string): void{
        this._storage.set(KEYS.defaultPaymentConditionId, id);
        this._emitChange(KEYS.defaultPaymentConditionId);
    }
    clearDefaultPaymentConditionId(): void{
        this._storage.clear(KEYS.defaultPaymentConditionId);
        this._emitChange(KEYS.defaultPaymentConditionId);
    }

    getDefaultPrintOption(): ?string{
        return this._storage.get(KEYS.defaultPrintOption);
    }
    setDefaultPrintOption(option: string): void{
        this._storage.set(KEYS.defaultPrintOption, option);
        this._emitChange(KEYS.defaultPrintOption);
    }
    clearDefaultPrintOption(): void{
        this._storage.clear(KEYS.defaultPrintOption);
        this._emitChange(KEYS.defaultPrintOption);
    }

    getProductsShortcuts(): ?Array<ProductShortcutsPreferenceItem>{
        let value = this._storage.get(KEYS.productsShortcuts);
        if(value==null)
            return null
        return JSON.parse(value);
    }
    setProductsShortcuts(items: Array<ProductShortcutsPreferenceItem>): void{
        this._storage.set(KEYS.productsShortcuts, JSON.stringify(items));
        this._emitChange(KEYS.productsShortcuts);
    }

    getPaymentMethodsShortcuts(): ?Array<PaymentMethodsShortcutsPreferenceItem>{
        let value = this._storage.get(KEYS.paymentMethodsShortcuts);
        if(value==null)
            return null
        return JSON.parse(value);
    }
    setPaymentMethodsShortcuts(items: Array<PaymentMethodsShortcutsPreferenceItem>): void{
        this._storage.set(KEYS.paymentMethodsShortcuts, JSON.stringify(items));
        this._emitChange(KEYS.paymentMethodsShortcuts);
    }

    getPaymentConditionsShortcuts(): ?Array<PaymentConditionsShortcutsPreferenceItem>{
        let value = this._storage.get(KEYS.paymentConditionsShortcuts);
        if(value==null)
            return null
        return JSON.parse(value);
    }
    setPaymentConditionsShortcuts(items: Array<PaymentConditionsShortcutsPreferenceItem>): void{
        this._storage.set(KEYS.paymentConditionsShortcuts, JSON.stringify(items));
        this._emitChange(KEYS.paymentConditionsShortcuts);
    }

    getBusinessUnit(): ?BusinessUnit {
        let value = this._storage.get(KEYS.businessUnit);
        if(value==null)
            return null;
        return JSON.parse(value);
    }
    setBusinessUnit(businessUnit: BusinessUnit): void {
        this._storage.set(KEYS.businessUnit, JSON.stringify(businessUnit));
        this._emitChange(KEYS.businessUnit);
    }
    clearBusinessUnit(): void{
        this._storage.clear(KEYS.businessUnit);
        this._emitChange(KEYS.businessUnit);
    }

    getOptimizeUIForTouch(): ?bool{
        let val = this._storage.get(KEYS.optimizeUIForTouch);
        if(val==null) return null;
        return val==true.toString();
    }
    setOptimizeUIForTouch(optimize: bool): void{
        this._storage.set(KEYS.optimizeUIForTouch, optimize.toString());
        this._emitChange(KEYS.enableDeveloperUI);
    }
    clearOptimizeUIForTouch(): void{
        this._storage.clear(KEYS.optimizeUIForTouch);
        this._emitChange(KEYS.optimizeUIForTouch);
    }
}