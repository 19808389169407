//@flow
export const leftOuterJoin = function<L,R,O>(
    leftCollection: Array<L>,
    leftPropExtractor: ((L)=>any)|string,
    rightCollection: Array<R>,
    rightPropExtractor: ((R)=>any)|string,
    makeOutputItem: (left: L, right: ?R)=> O
): Array<O> {
    let rightMap = {};

    if(typeof(rightPropExtractor)==='string'){
        let rightKey: string = rightPropExtractor;
        rightPropExtractor = (r)=> {
            //$FlowFixMe
            return (r)[rightKey]
        };
    }
    if(typeof(leftPropExtractor)==='string'){
        let leftKey: string = leftPropExtractor;
        leftPropExtractor = (l)=> {
            //$FlowFixMe
            return l[leftKey];
        }
    }
    
    //$FlowFixMe
    rightCollection.forEach(r=> rightMap[rightPropExtractor(r)] = r);

    let output: Array<O> = [];

    //$FlowFixMe
    leftCollection.forEach(l=>{ let r: ?R = rightMap[leftPropExtractor(l)]; output.push(makeOutputItem(l,r)); });

    return output;
}

