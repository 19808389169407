//@flow
import * as React from 'react';
import { Fade, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import type { PaymentMethod, PaymentCondition } from './../../../../types';
import { resources } from './../../../../util/resources';
import type { PaymentModel, PaymentFormProps } from './../../types';
import { PaymentMethodsShortcuts } from './../PaymentMethodsShortcuts';
import { PaymentConditionsShortcuts } from './../PaymentConditionsShortcuts';
import { PrintOptions } from './../PrintOptions';
import { decimalAdapterFactory } from './../../../../components/InputAdapters';
import { DiscountTextField2 } from './../../../../components/DiscountTextField2';
import numeral from "numeral";
import 'numeral/locales/pt-br';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, GridOn as GridIcon } from '@material-ui/icons';
import { containerFactory } from './../../../../container';
numeral.locale('pt-br');

const calculatorService = containerFactory().getCalculatorService();
const preferencesService = containerFactory().getPreferencesService();

const styles = theme => ({
    headerLeft: {
        flexBasis: '50.00%',
    },
    headerLeftLabel: {
        fontWeight: 400,
        fontSize:'80%',
    },
    headerRight: {
        flexBasis: '50.00%',
        textAlign: 'right',
    },
    headerRightLabel: {
        fontWeight: 500,
        color: theme.palette.secondary.main
    },
});

type PaymentAccordionFormState = {
    expandedIndex: number
}

type PaymentAccordionFormThemedProps = PaymentFormProps & {
    classes: any,
    theme: any
}

class PanelTransition extends React.PureComponent<any,any>{
    render(){
        return (
            <div style={{width:'100%'}}>{this.props.children}</div>
        );
    }
}

class PaymentAccordionFormThemed extends React.Component<PaymentAccordionFormThemedProps,PaymentAccordionFormState>{

    paymentValueMoneyMask = decimalAdapterFactory(2);
    lastPaymentValueSetTime: any;

    constructor(props: PaymentAccordionFormThemedProps){
        super(props);
        this.state = {
            expandedIndex: -1,
        };
    }

    onVisible=()=>{
        let { paymentMethodId, paymentConditionId } = this.props.model;
        if(!paymentMethodId)
            this.setState({expandedIndex:0});
        else if(!paymentConditionId)
            this.setState({expandedIndex:1});
        else
            this.setState({expandedIndex:2});
    }

    handleChange = index => (event, expanded) => {
        let { expandedIndex } = this.state;
        this.setState({
            expandedIndex: expandedIndex!==index?index:-1,
        });
    };

    handlePaymentMethodShortcutClick = (paymentMethod: PaymentMethod) =>{
        this.props.model.paymentMethodId = paymentMethod.paymentMethodId;
        this.setState({expandedIndex: 1 + this.state.expandedIndex});
        this.props.onModelChange('paymentMethodId');
    }

    handlePaymentConditionShortcutClick = (paymentCondition: PaymentCondition) =>{
        this.props.model.paymentConditionId = paymentCondition.paymentConditionId;
        this.setState({expandedIndex: 1 + this.state.expandedIndex});
        this.props.onModelChange('paymentConditionId');
    }

    handlePrintOptionChange = (e: any) => {
        this.props.model.printOption = e.target.value;
        this.props.onModelChange('printOption');
    }

    handleChangeNumber=(e: any)=>{
        let val = e.target.rawValue !== null ? e.target.rawValue : e.target.value;
        this.props.model[e.target.name] = val;
        this.props.onModelChange(e.target.name);
    }

    setPaymentValueCallback = (value: string) =>{
        this.props.model.paymentValue = parseFloat(value);
        this.props.onModelChange('paymentValue');
        this.lastPaymentValueSetTime = new Date().getTime();
    }

    callCalculatorForPayment = (e: any) => {
        calculatorService.openCalculator({total: this.props.model.paymentValue}, {callbackLabel: 'Confirmar Valor Pago', callback: this.setPaymentValueCallback, cancelCallback: ()=>{
            this.lastPaymentValueSetTime = new Date().getTime();
        }})
    }

    handlePaymentValueFocus = (e: any) => {
        const model = this.props.model;
        let selectedPaymentMethod = model.paymentMethodId? this.props.paymentMethods.find(x => x.paymentMethodId === model.paymentMethodId): null;
        if(selectedPaymentMethod!=null && selectedPaymentMethod.type==='money' && preferencesService.getOptimizeUIForTouch()){
            if(this.lastPaymentValueSetTime==null || (new Date().getTime() - this.lastPaymentValueSetTime > 1000)){
                this.callCalculatorForPayment();
            }
        }
    }

    render(){
        let { classes, invoiceEnabled, model, paymentMethods, paymentConditions, total } = this.props;
        let { expandedIndex } = this.state;
        let selectedPaymentMethod = model.paymentMethodId? paymentMethods.find(x => x.paymentMethodId === model.paymentMethodId): null;
        let selectedPaymentCondition = model.paymentConditionId? paymentConditions.find(x => x.paymentConditionId == model.paymentConditionId): null;
        let printLabel = resources.getPrintOptionLabel(model.printOption);
        let collapseConfig = {unmountOnExit:true, timeout:200};
        let exchange = model.paymentValue - total;

        console.log(JSON.stringify(model));
        
        return (
        <div>
            <ExpansionPanel CollapseProps={collapseConfig} expanded={ expandedIndex === 0 } onChange={this.handleChange(0)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.headerLeft}>
                    <Typography className={classes.headerLeftLabel}>Forma de Pagamento</Typography>
                </div>
                <div className={classes.headerRight}>
                    <Typography color="textPrimary" className={classes.headerRightLabel}>{selectedPaymentMethod?selectedPaymentMethod.description:'Selecione'}</Typography>
                </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <PanelTransition>
                        <PaymentMethodsShortcuts
                            paymentMethods={this.props.paymentMethods}
                            onPaymentMethodShortcutClick={this.handlePaymentMethodShortcutClick} />
                    </PanelTransition>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel CollapseProps={collapseConfig} expanded={ expandedIndex === 1 } onChange={ this.handleChange(1) }>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.headerLeft}>
                    <Typography className={classes.headerLeftLabel}>Cond. de Pagamento</Typography>
                </div>
                <div className={classes.headerRight}>
                    <Typography color="textPrimary" className={classes.headerRightLabel}>{selectedPaymentCondition?selectedPaymentCondition.description:'Selecione'}</Typography>
                </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>  
                    <PanelTransition>
                        <PaymentConditionsShortcuts
                            paymentConditions={this.props.paymentConditions}
                            onPaymentConditionShortcutClicked={ this.handlePaymentConditionShortcutClick } />
                    </PanelTransition>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel CollapseProps={collapseConfig} expanded={expandedIndex===2} onChange={this.handleChange(2)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.headerLeft}>
                    <Typography className={classes.headerLeftLabel}>Troco</Typography>
                </div>
                <div className={classes.headerRight}>
                    <Typography color="textPrimary" className={classes.headerRightLabel}>
                    <span style={{fontSize:'85%'}}>{numeral(model.paymentValue).format('0.00')} - {numeral(total).format('0,0.00')}</span>&nbsp; = &nbsp;{ exchange < 0 ? 'INSUFICIENTE' : numeral(exchange).format('$0,0.00') }</Typography>
                </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display:'block'}}>
                    <PanelTransition>
                        {/* <FormControl fullWidth style={{flexBasis:'100%'}}>
                            <DiscountTextField2
                                value={this.props.model.paymentValue} refValue={total} onMoneyChange={()=>{}}
                                moneyTextFieldProps={{label:'Desconto Global'}}
                            />
                        </FormControl>

                        <div style={{height:16}}></div> */}
                    
                        <FormControl fullWidth style={{flexBasis:'100%'}}>
                            <InputLabel shrink htmlFor="paymentValue">Valor Pago</InputLabel>
                            <Input
                                className={ classes.regularFontSize } onChange={this.handleChangeNumber}
                                inputComponent={ this.paymentValueMoneyMask.component } name="paymentValue" id="paymentValue"
                                autoFocus
                                onFocus={this.handlePaymentValueFocus}
                                value={this.props.model.paymentValue}
                                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                endAdornment={<InputAdornment position="end"><IconButton tabIndex={-1} onClick={this.callCalculatorForPayment}><GridIcon /></IconButton></InputAdornment>}
                            />
                        </FormControl>
                    </PanelTransition>             
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel CollapseProps={collapseConfig} expanded={expandedIndex===2} onChange={this.handleChange(2)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.headerLeft}>
                    <Typography className={classes.headerLeftLabel}>Impressão</Typography>
                </div>
                <div className={classes.headerRight}>
                    <Typography color="textPrimary" className={classes.headerRightLabel}>
                        { this.props.model.printOption? resources.getPrintOptionLabel(this.props.model.printOption): 'Selecione' }
                    </Typography>
                </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display:'block'}}>
                    <PanelTransition>
                        <PrintOptions
                            invoiceEnabled={invoiceEnabled}
                            printOption={this.props.model.printOption}
                            onPrintOptionChange={this.handlePrintOptionChange}
                        />
                    </PanelTransition>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
    }
}

let PaymentAccordionForm: React.ComponentType<PaymentFormProps> = (withStyles(styles, { withTheme: true })(PaymentAccordionFormThemed): any);

export { PaymentAccordionForm };