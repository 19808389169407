//@flow
import * as React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const styles = theme => ({
    container: {
        width: '99px',
        background: 'inherit',
        display:'inline-block',
        textAlign: 'center'
    },
    button: {
        transition: 'opacity linear 0.3s',
        minWidth: '33px',
        opacity: '.2',
        display: 'inline-block',
        lineHeight: '38px',
        cursor: 'pointer'
    },
    buttonHighlighted: {
        opacity: '1'
    },
    icon: {
        position:'relative',
        top: '7px',
        transition: 'color 0.3s ease-out'
    },
    input: {
        padding: '0',
        width: '33px',
        lineHeight: '38px',
        textAlign: 'center',
        backgroundColor: 'RGBA(230,230,230,0)',
        transition: 'background-color linear 0.3s',
        borderRadius: '3px',
        border: 'none',
        outline: 'none',
    },
    inputHighlighted: {
        backgroundColor: 'RGBA(0,0,0,.1)'
    }
});

type SoftNumberProps = {
    valor: number,
    onChange: (event: Event, valor:number)=>void,
    onDestroy?: (event: Event)=>void,
    onClick?: (event: Event)=>void,
    inputClassName?: any,
    inputHighlightedClassName?: any
}

type SoftNumberState = {
    highlighted: bool,
}

type SoftNumberThemedProps = SoftNumberProps & {
    classes: any,
    theme: any
}

class SoftNumberThemed extends React.Component<SoftNumberThemedProps,SoftNumberState>{
    state = {
        highlighted: false,
    }

    subtractValue(event: any){
        if(this.props.valor > 0){
            this.props.onChange(event, this.props.valor-1);
        }
        else if(this.props.onDestroy){
            this.props.onDestroy(event);
        }
    };

    subtractValueOrDestroy(event: any){
        this.props.valor > 0 ? this.props.onChange(event, this.props.valor-1) : this.props.onChange(event, this.props.valor);
    };

    addValue(event: any){
        this.props.onChange(event, this.props.valor+1);
    };

    handleMouseEnter(){
        this.setState(prevState => ({
            highlighted: true,
        }));
    };

    handleMouseLeave(){
        this.setState(prevState => ({
            highlighted: false,
        }));
    };

    handleChange(event: any){
        let inputValue = event.target.validity.valid && event.target.value !== '' ? parseInt(event.target.value, 10) : 0;
        this.props.onChange(event, inputValue);
    };

    handleKeyDown(e: any){
        switch (e.keyCode) {
            case 38://up
                e.preventDefault();
                this.addValue(e);
                break;
            case 40://down
                e.preventDefault();
                this.subtractValue(e);
                break;
            default:
                break;
        }
    }

    render(){

        let { classes, inputClassName, inputHighlightedClassName } = this.props;
        let buttonClass = classNames(classes.button, {[classes.buttonHighlighted]: this.state.highlighted});
        let inputClass = classNames(
            classes.input,
            inputClassName,
            {[classes.inputHighlighted]: this.state.highlighted},
            {[inputHighlightedClassName||'']: this.state.highlighted}
        );

        let buttonSubtract = (
            <div className={ buttonClass }
                onClick={this.subtractValue.bind(this)}>
                <ChevronLeft className={classes.icon} />
            </div>
        );
       

        let buttonAdd = (
            <div className={ buttonClass }
                onClick={ this.addValue.bind(this) }>
                <ChevronRight className={classes.icon} />
            </div>
        );

        return <div
            onClick={this.props.onClick}
            className={classes.container}
            onMouseEnter={this.handleMouseEnter.bind(this)}
            onMouseLeave={this.handleMouseLeave.bind(this)}>
            {buttonSubtract}
            <input 
                type="text"
                pattern="[0-9]*"
                onKeyDown={this.handleKeyDown.bind(this)}
                onChange={this.handleChange.bind(this)}
                value={this.props.valor}
                className={inputClass} />
            {buttonAdd}
        </div>;
    };
}

let SoftNumber: React.ComponentType<SoftNumberProps> = (withStyles(styles, { withTheme: true })(SoftNumberThemed): any);

export { SoftNumber };