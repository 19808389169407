//@flow

import * as React from 'react';
import { Typography } from '@material-ui/core';
import { DefaultPaymentConditionSelector, PreferenceListItem } from './../components';
import type { RenderDisplayItemContext, RenderSelectorContext, PreferenceHandler } from './../Preferences';
import type { IPreferencesService } from './../../../services/preferences-service'
import type { IDataStoreService } from './../../../services/data-store-service'

export class CurrentBusinessUnitPreferenceHandler implements PreferenceHandler{
    
    preferencesService: IPreferencesService;
    
    constructor(preferencesService: IPreferencesService){
        this.preferencesService = preferencesService;
    }
    
    getKey(){
        return 'defaultBusinessUnit';
    }

    renderSelector(context: RenderSelectorContext){
        let label = "Não definida";
        let unit = this.preferencesService.getBusinessUnit();
        if(unit!=null){ label = unit.name; }
        return (<div style={{padding: '16px'}}>
            <Typography color="primary" variant="caption">Unidade Atual</Typography>
            <Typography variant="body1">{label}</Typography>
            <br />
            <Typography variant="body2" color="text-secondary">Para selecionar uma nova unidade de negócio, simplesmente limpe essa configuração (botão inferior).</Typography>
            <Typography variant="body2" color="text-secondary">Em seguida, você poderá escolher a nova unidade.</Typography>
        </div>);
    }

    async loadDataIntoState(state: any){
             
    }

    resetSetting(state: any): void{
        this.preferencesService.clearBusinessUnit();
    }

    renderDisplayItem(context: RenderDisplayItemContext){
        let { loaded, state, forceUpdate, openSelector } = context;
        let valueLabel = !loaded? '...' : 'Não Configurado';
        let businessUnit = this.preferencesService.getBusinessUnit();
        if(businessUnit!=null){
            valueLabel = businessUnit.name;
        }
        return (<PreferenceListItem settingKey={this.getKey()}
            label="Unidade de Negócio"
            valueLabel={ valueLabel }
            onClick={()=> openSelector(this.getKey()) }
            clear={()=>{
                this.preferencesService.clearBusinessUnit();
                forceUpdate();
            }}
        />);
    }
}