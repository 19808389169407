import * as React from 'react';
import { FormatPaint as EditIcon, FormatPaintOutlined as EditIconOutlined } from '@material-ui/icons';
import { Checkbox, FormControlLabel, Switch  } from '@material-ui/core';

export class ButtonPrefEditCheckbox extends React.PureComponent<any>{
    render(){
        return (
            <Checkbox
                tabIndex={-1}
                icon={<EditIconOutlined />}
                checkedIcon={<EditIcon />}
                checked={this.props.checked}
                onChange={this.props.onChange}
            />
        )
    }
}

export class OldButtonPrefEditCheckbox extends React.PureComponent<any>{
    render(){
        return (
            <FormControlLabel
                tabIndex={-1}
                control={(
                    <Switch
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                    />
                )}
                label="Editar Favoritos"
            />
        )
    }
}
    