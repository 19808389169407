//@flow

import type { Sale } from './../../types';
import { BaseSaleService } from './base-sale-service';

export class MockSaleService extends BaseSaleService {
    insertSale(sale: Sale): Promise<void>{
        return Promise.resolve(undefined);
    }

    requestInvoice(sale: Sale): Promise<void>{
        return Promise.resolve(undefined);
    }
}