//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    divPadding:{
        padding: theme.spacing.unit * 3
    }
});

type DefaultPaddingProps = {
    children: any,
    style?: any
}

type DefaultPaddingState = {
}

type DefaultPaddingThemedProps = DefaultPaddingProps & {
    classes: any,
    theme: any
}

class DefaultPaddingThemed extends React.Component<DefaultPaddingThemedProps,DefaultPaddingState>{

    constructor(props: DefaultPaddingThemedProps){
        super(props);
        this.state = {
            
        };
    }

    render(){
        return <div className={ this.props.classes.divPadding } style={this.props.style} >{this.props.children}</div>;
    }
}

let DefaultPadding: React.ComponentType<DefaultPaddingProps> = (withStyles(styles, { withTheme: true })(DefaultPaddingThemed): any);

export { DefaultPadding };