//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton, Zoom, Popover } from '@material-ui/core';
import { OpenWith, MoreVert, Delete } from '@material-ui/icons';
import { ColorPicker } from './ColorPicker';
import classNames from 'classnames';
import COLORS from './../colors';



const styles = theme => ({
    root:{
        position: 'relative',
        minWidth: '100%',
        height: '80px',
    },
    rootUnconstrained:{
        minHeight: '80px',
        height: '100%',
    },
    button:{
        width:'100%',
        height:'100%',
        zIndex: '1',
        transition: 'all .5s',
        overflow: 'hidden',
    },
    buttonLabelSoft:{
        opacity:.6,
        fontSize:'80%',
    },
    rightButton:{
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '2',
        margin:1,
    },
    leftButton:{
        position: 'absolute',
        top: '0',
        right: 'left',
        zIndex: '2',
        margin:1
    },
    buttonDrag: {
        
    },
    icon:{
    }
});

type EditableButtonGroupItemProps = {
    label: string,
    color: string,
    mode: 'editing'|'ready'|'swapping'|'swappable',
    onRemoveClick?:(e: any)=>void,
    onSwapStartClick:(e:any)=>void,
    onColorClick:(e: any, { color: string, index: number })=>void,
    onClick: (e: any)=>void,
    buttonProps?: any
}

type EditableButtonGroupItemState = {
    popoverAnchor: any
}

type EditableButtonGroupItemThemedProps = EditableButtonGroupItemProps & {
    classes: any,
    theme: any
}

class EditableButtonGroupItemThemed extends React.Component<EditableButtonGroupItemThemedProps,EditableButtonGroupItemState>{

    constructor(props: EditableButtonGroupItemThemedProps){
        super(props);
        this.state = {
            popoverAnchor: null
        };
    }

    handleRemoveClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if(this.props.onRemoveClick!=null){
            this.props.onRemoveClick();
        }
        this.setState({ popoverAnchor: null });
    }

    handleMoreClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ popoverAnchor: e.currentTarget });
    }

    handleStartSwapClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.onSwapStartClick(e);
    }

    handleClose = (e)=>{
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation(e);
        this.setState({ popoverAnchor: null });
    }

    handleClick = (e) => {
        this.props.onClick(e);
    }

    handleColorClick = (e, {color, index}) =>{
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ popoverAnchor: null });
        this.props.onColorClick(e, {color: COLORS.LIST[index].name, index });
    }

    render(){
        let { buttonProps, classes, color, mode, label, onSwapStartClick, onColorClick, onRemoveClick, onClick, ...rest } = this.props;       
        let colorSchema = COLORS.MAP[color] || { };
        
        let editing = mode==='editing';
        let swapping = mode==='swapping';
        let ready = mode==='ready';
        let swappable = mode ==='swappable';

        return (<div className={ classNames(classes.root, !ready?classes.rootUnconstrained:null)} { ...rest }>
            <Zoom in={editing||swapping} unmountOnExit>
                <IconButton className={ classes.leftButton }
                    disabled={ready||swapping}
                    onClick={this.handleStartSwapClick}
                >
                    <OpenWith style={{color:colorSchema.text||'#000000'}} fontSize="small" className={classes.icon} />
                </IconButton>
            </Zoom>
            <Zoom in={editing} unmountOnExit>
                <IconButton className={ classes.rightButton }
                    disabled={ready||swapping}
                    onClick={this.handleMoreClick}
                >
                    <MoreVert style={{color:colorSchema.text||'#000000'}} fontSize="small" className={classes.icon} />
                </IconButton>
            </Zoom>
            <Button
                { ...buttonProps }
                variant="contained"
                size="small"
                classes={{
                    root: classNames(classes.button),
                    label: (!ready) ? classes.buttonLabelSoft : undefined
                }}
                onClick={this.handleClick}
                disabled={editing||swapping}
                style={{ backgroundColor: colorSchema.main, color: colorSchema.text||'#000000' }}
                >
                {label}
            </Button>
            { this.state.popoverAnchor!=null ? (
                <Popover
                    open={true}
                    anchorOrigin={{vertical: 'top',horizontal: 'left'}}
                    transformOrigin={{vertical: 'top',horizontal: 'right',}}
                    anchorEl={this.state.popoverAnchor}
                    onClose={this.handleClose}
                >
                    <div style={{padding:16}}>
                        <ColorPicker
                            colors={COLORS.VALUES}
                            onColorClick={this.handleColorClick}
                        />
                        { onRemoveClick!=null ? (<React.Fragment>
                            <br />
                            <Button onClick={this.handleRemoveClick}><Delete /></Button>
                        </React.Fragment>) : (null) }
                    </div>
                </Popover>
            ) : (null)}
            
        </div>)
    }
}

let EditableButtonGroupItem: React.ComponentType<EditableButtonGroupItemProps> = (withStyles(styles, { withTheme: true })(EditableButtonGroupItemThemed): any);

export { EditableButtonGroupItem };