//@flow
import { IAuthenticationService } from './../../services/authentication-service';
import { hash } from './../../util/hash';

export function preferencesLoggedUserKeyProviderFactory(authServiceProvider: ()=>?IAuthenticationService): ()=>string{
    return ()=>{
        let authService = authServiceProvider();
        if(authService!=null){
            let user = authService.getAuthenticatedUser();
            if(user!=null) return hash(user.email).toString().replace('-','');
        }
        return 'UNAUTHENTICATED';
    }
}