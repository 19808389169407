//@flow

class Resources {   

    dict = {
        sync_syncingCityEntityLabel:'Cidades',
        sync_syncingCountryEntityLabel:'Paises',
        sync_syncingCustomerEntityLabel:'Clientes',
        sync_syncingPaymentConditionEntityLabel:'Condições de Pagamento',
        sync_syncingPaymentMethodEntityLabel:'Métodos de Pagamento',
        sync_syncingProductEntityLabel:'Produtos',
        sync_syncingProvinceEntityLabel:'Unidades Federativas',
        sync_syncingSaleEntityLabel:'Vendas',
        printOpt_skip: 'Não Imprimir',
        printOpt_receipt: 'Comprovante',
        printOpt_invoice: 'Nota Fiscal',
    }

    getSynchronizationLabel(key: string): string
    {
        key = key.substr(0,1).toUpperCase() + key.substr(1);
        return this.getStringOrDefault(`sync_syncing${key}EntityLabel`);
    }

    getStringOrDefault(key: string): string{
        let label = this.dict[key];
        if(label!=null) return label;
        return '???';
    }

    getPrintOptionLabel(key:string): string{
        return this.getStringOrDefault(`printOpt_${key}`);
    }
}

export const resources = new Resources();