//@flow

import * as Events from './../../../events';
import type { ISynchronizer, SynchronizeContext } from './default-synchronization-service';
import type { SynchronizationEntityData, SynchronizationEntitiesKeys } from './../types';
import { SynchronizationEntities } from './../types';
import { SkyworkRestClient } from './../../../util/skywork-rest-client';
import { resources } from './../../../util/resources';
import { ExtendedError } from './../../../util/error';
import { EventEmitter } from './../../../util/event-emitter';
import { LocalDatabase } from './../../../util/local-database';

export class BaseCollectionDownSynchronizer<RemoteResponse,LocalType> implements ISynchronizer{
    
    _localDatabase: LocalDatabase;
    _restClient: SkyworkRestClient;
    _eventEmitter: EventEmitter<Events.AppEvent>;

    constructor(
        restClient: SkyworkRestClient,
        localDatabase: LocalDatabase,
        eventEmitter: EventEmitter<Events.AppEvent>
    ){
        this._eventEmitter = eventEmitter;
        this._restClient = restClient;
        this._localDatabase = localDatabase;
    }

    //METHODS TO OVERRIDE

    getEntityKey(): SynchronizationEntitiesKeys{
        throw new Error('The method must be overridden.');
    }

    getDataFromRemoteSource(context: SynchronizeContext): Promise<RemoteResponse>{
        throw new Error('The method must be overridden.');
    }

    convertRemoteData(reponse: RemoteResponse): Array<LocalType>{
        throw new Error('The method must be overridden.');
    }

    storeConvertedData(items: Array<LocalType>): Promise<Array<any>>{
        throw new Error('The method must be overridden.');
    }

    _clone<T>(data: T): T{
        return JSON.parse(JSON.stringify(data));
    }

    async synchronize(context: SynchronizeContext): Promise<void>{
        
        let entityKey = this.getEntityKey();

        let data: SynchronizationEntityData = {
            entityKey, download: true, length: null, ids: null
        };

        this._eventEmitter.emit({
            name: Events.SynchronizationServiceEvents.OnEntitySynchronizationStarted,
            data: this._clone(data),
            error: null
        });
        
        let response;
        let items;
        let error;
        try{
            response = await this.getDataFromRemoteSource(context);

            items = this.convertRemoteData(response);

            data.length = items.length;
            
            data.ids = await this.storeConvertedData(items);
        }
        catch(e){
            error = new ExtendedError(`Ocorreu um problema ao baixar registros (${resources.getSynchronizationLabel(entityKey)}).`, e, "summary");
            if(error.hasTag('serverCommunication')){
                context.isAborted = true;
            }
        }
        
        this._eventEmitter.emit({
            name: Events.SynchronizationServiceEvents.OnEntitySynchronizationEnded,
            data: this._clone(data),
            error
        });

        if(error) throw error;
    }
}