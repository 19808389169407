//@flow

import { BLOCK_KEYS } from './types';
import type { IBlockerService, BlockReason } from './types';
import * as Events from './../../events';

import type { ISynchronizationService } from './../synchronization-service';
import { IPreferencesService } from './../preferences-service';
import { Debounce } from './../../util/debounce';

export class DefaultBlockerService implements IBlockerService {

    _blockedReasons: Array<BlockReason> = [];
    _synchronizationService: ISynchronizationService;
    _preferencesService: IPreferencesService;
    _updateDebounce: Debounce = new Debounce(200);

    constructor(synchronizationService: ISynchronizationService, preferencesService: IPreferencesService){
        
        this._synchronizationService = synchronizationService;
        this._preferencesService = preferencesService;
        Events.emitter.subscribe(this.appEventHandler, 'default-blocker-service');
        this.checkSynchronizationServiceBlock(true);
        this.checkBusinessUnitBlock(true);
    }

    appEventHandler = (event: Events.AppEvent) => {
        if(event.name===Events.SynchronizationServiceEvents.OnSynchronizationEnded){
            this.checkSynchronizationServiceBlock();
        }
        if(event.name===Events.AuthenticationServiceEvents.OnSignedIn){
            this.checkAll();
        }
        else if(event.name===Events.PreferencesServiceEvents.OnPreferenceValueChanged){
            this.checkBusinessUnitBlock();
        }
    }

    checkAll(){
        this.checkBusinessUnitBlock();
        this.checkSynchronizationServiceBlock();
    }

    checkSynchronizationServiceBlock(omitEvents?:bool = false){
        let blockIndex = this._blockedReasons.indexOf(BLOCK_KEYS.NotFullySynced);
        let changed = false;
        if(this._synchronizationService.getDidFullSync()){
            //remove block if exist
            if(blockIndex!==-1){ 
                this._blockedReasons.splice(blockIndex, 1);
                changed = true;
            }
        }
        else{
            //create block if not exist
            if(blockIndex===-1){
                changed = true;
                this._blockedReasons.push(BLOCK_KEYS.NotFullySynced);
            }
        }
        if(changed && !omitEvents){
            this._updateDebounce.run(()=>{
                Events.emitter.emit({name: Events.BlockerServiceEvents.OnBlockChanged, data: true, error: null});
            });
        }
    }

    checkBusinessUnitBlock(omitEvents?:bool = false){
        let blockIndex = this._blockedReasons.indexOf(BLOCK_KEYS.NoBusinessUnitSelected);
        let changed = false;
        if(this._preferencesService.getBusinessUnit()!=null){
            //remove block if exist
            if(blockIndex!==-1){ 
                this._blockedReasons.splice(blockIndex, 1);
                changed = true;
            }
        }
        else{
            //create block if not exist
            if(blockIndex===-1){
                changed = true;
                this._blockedReasons.push(BLOCK_KEYS.NoBusinessUnitSelected);
            }
        }
        if(changed && !omitEvents){
            this._updateDebounce.run(()=>{
                Events.emitter.emit({name: Events.BlockerServiceEvents.OnBlockChanged, data: true, error: null});
            });
        }
    }

    isBlocked(): bool
    {
        return this.getBlockedReasons().length > 0;
    }

    getBlockedReasons(): Array<BlockReason>
    {
        return this._blockedReasons;
    }
}