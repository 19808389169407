//@flow

import type { IPreferencesStorage } from './default-preferences-service';

const BASE_KEY = 'defaultPreferences';

export class PreferencesLocalStorage implements IPreferencesStorage{

    _userKeyProvider: ()=>string;

    constructor(userKeyProvider: ()=>string){
        this._userKeyProvider = userKeyProvider;
    }

    key(key: string): string{
        return `${BASE_KEY}_${this._userKeyProvider()}_${key}`;
    }

    get(key: string): ?string{
        return localStorage.getItem(this.key(key));
    }

    set(key: string, value: string): void{
        localStorage.setItem(this.key(key), value);
    }

    clear(key: string){
        localStorage.removeItem(this.key(key));
    }
}