//@flow

import { BaseCollectionDownSynchronizer } from './../base-collection-down-synchronizer'
import type { GetFormasPagamentosResponse } from './../../../../util/skywork-rest-client/types';
import type { PaymentMethod, PaymentMethodType } from './../../../../types';
import type { SynchronizationEntitiesKeys } from './../../types';

export class PaymentMethodsDownSynchronizer extends BaseCollectionDownSynchronizer<GetFormasPagamentosResponse,PaymentMethod> {

    getEntityKey(): SynchronizationEntitiesKeys{
        return 'paymentMethod';
    }

    getDataFromRemoteSource(): Promise<GetFormasPagamentosResponse>{
        return this._restClient.getFormasPagamentos({fields: ['con_forma_pgto_id','nome','tipo_pagamento_id']})
    }

    convertRemoteData(reponse: GetFormasPagamentosResponse): Array<PaymentMethod>{
        return reponse.map(item =>{
            let type: PaymentMethodType;
            
            switch(item.tipo_pagamento_id.toString()){
                case '1': type='money'; break;
                default: type='other'; break;
            }

            let paymentMethod: PaymentMethod = {
                paymentMethodId: item.con_forma_pgto_id.toString(),
                description: item.nome, type
            };

            return paymentMethod;
        })
    }

    async storeConvertedData(items: Array<PaymentMethod>): Promise<Array<any>>{
        await this._localDatabase.clearTable('paymentMethods');
        await this._localDatabase.batchInsertOrReplacePaymentMethods(items);
        return items.map(x => x.paymentMethodId);
    }
}