//@flow

import * as React from 'react';
import { ConfirmableTrigger } from './../../../components/ConfirmableTrigger';
import { IconButton } from '@material-ui/core';
import { DeleteSweep as DeleteSweepIcon } from '@material-ui/icons';

export type ClearSaleButtonProps = {
    onConfirmClick: ()=>void
} & { [key: string]: any };

export class ClearSaleButton extends React.PureComponent<ClearSaleButtonProps>{

    handleConfirmClick = (e: any) => {
        this.props.onConfirmClick();
    }
    
    render(){
        const { onConfirmClick, ...rest } = this.props;

        return(
            <ConfirmableTrigger
                { ...rest }
                component={IconButton}
                confirmationMessage={<p>Tem certeza que deseja limpar a venda?</p>}
                title="Remover Todos os Itens"
                onConfirmClick={this.handleConfirmClick}>
                <DeleteSweepIcon />
            </ConfirmableTrigger>
        );
    }

}