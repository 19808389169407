//@flow
// import ButtonDemo from './ButtonDemo';

type DemoItem = { key: string, demoComponent: any };

let items: Array<DemoItem> = [
    
].map((item: DemoItem)=>{
    return { key: 'sandbox/'+item.key, demoComponent:item.demoComponent };
});

export default items;