//@flow
import * as React from 'react';
import classNames from 'classnames';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { ListItemIcon, Button, Drawer, AppBar, Fade, Toolbar, Typography, Divider, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import { Logo } from './Logo';
import { darkTheme } from './../../themes';
import logoHeader from './logo-header.svg';
import * as Events from './../../events';

const drawerWidth = 240;

const styles = (theme) => {

    let transition = theme.transitions.create(['margin','width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    });

    return {
        root: {
            height:'100%',
            width:'100%',
            position:'absolute'
        },
        logoHeader:{
            height:'64px',
            width: 'auto',
            marginLeft:'-7px'
        },
        authListItem:{
            
        },
        iconButton: {
            color: theme.palette.primary.contrastText,
        },
        appFrame: {
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            width: '100%',
            height: '100%'
        },
        appBar: {
            position: 'absolute',
            transition: transition,
            paddingRight:'16px'
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        menuButton: {
            marginLeft: 12,
            marginRight: 20,
            color: theme.palette.primary.contrastText,
        },
        drawerPaper: {
            position: 'relative',
            width: drawerWidth,
            transition: transition,
            zIndex:1,
            backgroundColor: theme.palette.primary.dark
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        content: {
            zIndex: 2,
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: 0/*theme.spacing.unit * 3*/,
            transition: transition,
            position: 'relative',
            height: 'calc(100%)',
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: -drawerWidth,
        },
        contentShift: {
            marginLeft: 0
        },
        childrenWrapper: {
            height: 'calc(100%  - 64px)'
        },
        title:{
            fontWeight:'300',
            flex: 1,
            marginLeft:'12px'
        }
    };
};


type MenuSectionFactory = (isMenuOpen: bool) => React.Node;

type AppLayoutProps = {
    appBarButtons?: Array<React.Node>,
    headerTitle: string,
    headerTitle2: string,
    children: any,
    menuSections: Array<MenuSectionFactory>,
    authenticatedUser: ?string,
    onSignOutClick: ()=> void
}

type AppLayoutState = {    
    open: bool
}

type AppLayoutThemedProps = AppLayoutProps & {
    classes: any,
    theme: any
}

class AppLayoutThemed extends React.Component<AppLayoutThemedProps,AppLayoutState>{

    constructor(props: AppLayoutThemedProps){
        super(props);
        this.state = {
            open:true
        };
    }    

    handleDrawerToggle(){
        this.setState({ open: !this.state.open });
        setTimeout(()=>window.dispatchEvent(new Event('resize')), this.props.theme.transitions.duration.leavingScreen ); //hack to tell tabs about the change!!!
    };

    handleAppEvent = (event: Events.AppEvent) => {
        if(event.name===Events.UIEvents.OnSceneOpened && event.data==='Sale'){
            this.setState({open: false});
        }
    }

    componentDidMount(){
        Events.emitter.subscribe(this.handleAppEvent);
    }
    componentWillUnmount(){
        Events.emitter.unsubscribe(this.handleAppEvent);
    }

    close(){
        this.setState({ open: false });
    }

    handleSignOutClick(){
        this.props.onSignOutClick();
    }

    render(){
        let { appBarButtons, authenticatedUser, classes, theme, children } = this.props;
        let { open } = this.state;
        const drawer = (
            <Drawer
                variant="permanent"
                anchor={'left'}
                open={open}
                classes={{ paper: classes.drawerPaper }}
            >
                {/* <Logo style={{width:100, position:'absolute', margin:'10px 0 0 24px'}} /> */}
                <div className={classes.drawerHeader}>
                    {/* <IconButton className={classes.iconButton} disabled={!open} onClick={this.handleDrawerToggle.bind(this)}>
                        <ChevronLeftIcon />
                    </IconButton> */}
                </div>
                <Divider />
                {this.props.menuSections.map((section, index)=>{
                    return (
                    <React.Fragment key={`section-${index}`}>
                        { index> 0? <Divider /> : null }
                        { section(open) }
                    </React.Fragment>
                    )
                })}
                <Divider />
                { authenticatedUser? (<React.Fragment>
                        <List>
                            <ListItem button tabIndex={open?undefined:-1} className={classes.authListItem}
                                onClick={this.handleSignOutClick.bind(this)}
                            >
                                {/* <ListItemIcon> */}
                                    <AccountCircleIcon />
                                {/* </ListItemIcon> */}
                                <ListItemText primary="Sair" secondary={authenticatedUser.split(/@/)[0]} />
                            </ListItem>
                        </List>
                        <Divider />
                </React.Fragment>
                ): (null) }
            </Drawer>
        );
        const titleBlock = this.props.headerTitle2!==""? (
        <div style={{flex:1}}>
            <Typography variant="h6" color="inherit" className={classNames(classes.title)} noWrap>
                { this.props.headerTitle }
            </Typography>
            <Typography variant="caption" color="inherit" className={classNames(classes.title)} noWrap>
                { this.props.headerTitle2 }
            </Typography>
        </div>) : (
        <Typography variant="h6" color="inherit" className={classNames(classes.title)} noWrap>
            { this.props.headerTitle }
        </Typography>
        );

        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <MuiThemeProvider theme={darkTheme}>
                        <AppBar className={classNames(classes.appBar, { [classes.appBarShift]: open })} >
                            <Toolbar disableGutters={true}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerToggle.bind(this)}
                                className={classNames(classes.menuButton)}
                            >
                                <Fade in={!open}>
                                    <MenuIcon />
                                </Fade>
                                <Fade in={open}>
                                    <ChevronLeftIcon style={{position: 'absolute'}} />
                                </Fade>
                            </IconButton>

                            <img src={logoHeader} className={classes.logoHeader} />
                            
                            { titleBlock }
                            { appBarButtons }                            
                            </Toolbar>
                        </AppBar>
                        {drawer}
                    </MuiThemeProvider>
                    
                    <main className={ classNames( classes.content, { [classes.contentShift]: open } ) } >
                        <div className={classes.drawerHeader} />
                        <div className={classes.childrenWrapper}>
                        { children }
                        </div>
                    </main>
                </div>
            </div>            
        );
    }
}


let AppLayout: React.ComponentType<AppLayoutProps> = (withStyles(styles, { withTheme: true })(AppLayoutThemed): any);

export { AppLayout };