//@flow

import type { IPrintRequestDataSource } from './print-queue-service';
import type { PrintRequest } from './../../types';


export class MockPrintRequestDataSource implements IPrintRequestDataSource {
    listPrintRequests(highlightedOnly: bool): Promise<Array<PrintRequest>>{
        return Promise.resolve([{
                id: '1',
                description: 'Impressão 1',
                data: { html:`<html><head><style>body { font-family: "Lucida Console", Monaco, monospace }</style></head><body>Impressão 1</body></html>` },
                type: 'raw-html',
                ready: true,
                highlight: true
            },
            {
                id: '2',
                description: 'Impressão 2',
                data: { html:`<html><head><style>body { font-family: "Lucida Console", Monaco, monospace }</style></head><body>Impressão 2</body></html>` },
                type: 'raw-html',
                ready: false,
                highlight: true
            }
        ]);
    }

    putPrintRequest(printRequest: PrintRequest): Promise<void>{
        return Promise.resolve();
    }

    getPrintRequestByLocator(locator: string): Promise<?PrintRequest>{
        return Promise.resolve(null);
    }

    updatePrintRequest(printRequest: PrintRequest): Promise<void>{
        return Promise.resolve();
    }

    removePrintRequest(printRequest: PrintRequest): Promise<void>{
        return Promise.resolve();
    }
}