//@flow
import * as React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core/';
import { CheckCircle, Warning, Error, Info, Close } from '@material-ui/icons/';
import { green, amber } from '@material-ui/core/colors/';

const variantIcon = {
    success: CheckCircle,
    warning: Warning,
    error: Error,
    info: Info,
};

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: '20',
    },
    iconVariant: {
        opacity: '0.9',
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    }
});

type AlertProps = {
    variant: 'success' | 'warning' | 'error' | 'info' | '',
    open: bool,
    onCloseAlert: ()=>void,
    message: string
}

type AlertState = {

}

type AlertThemedProps = AlertProps & {
    classes: any,
    theme: any
}

class AlertThemed extends React.Component<AlertThemedProps,AlertState>{

    handleCloseAlert(){
        this.props.onCloseAlert();
    }
    
    configSnackbarContent(message: string, variant: string){
        let { classes } = this.props;

        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={classes[variant]}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            classNames={classNames(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleCloseAlert.bind(this)}
                    >
                        <Close className={classes.icon} />
                    </IconButton>,
                ]}
            />
        );
    }

    render(){
        
        return(
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.props.open}
                autoHideDuration={6000}
                onClose={this.handleCloseAlert.bind(this)}
            >
            
                { this.configSnackbarContent(this.props.message, this.props.variant) }

            </Snackbar>
        )
    }
}

let Alert: React.ComponentType<AlertProps> = (withStyles(styles, { withTheme: true })(AlertThemed): any);

export { Alert };