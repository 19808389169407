//@flow
import * as React from 'react';
import { List, ListItem, Paper, TextField } from '@material-ui/core';
import { Switch, Route } from 'react-router';
import customer from './components/customer';
import shared from './components/shared';
import sandbox from './components/sandbox';
import sales from './components/sales';
import synchronization from './components/synchronization';
import { DefaultPadding } from './../../components/DefaultPadding';
import { withContainer, ContainerProvider, IContainer, MockContainer } from './../../container';

let demoComponents: Array<{key:string, demoComponent:React.ComponentType<any>}> = [
    ...customer,
    ...sandbox,
    ...shared,
    ...sales,
    ...synchronization
].sort((x,y) => x.key.localeCompare(y.key));

type ComponentsShowcaseProps = {
    
}

type ComponentsShowcaseExtendedProps = {
    container: IContainer
}

type ComponentsShowcaseState = {
    search: string
}

export class ComponentsShowcaseExtended extends React.Component<ComponentsShowcaseExtendedProps, ComponentsShowcaseState>
{
    _baseRoute: string;
    constructor(props: ComponentsShowcaseExtendedProps){
        super(props);
        this.state = {
            search:''
        }
        this._baseRoute = this.props.container.getRouteBuilderService().componentsShowcaseRoute();
    }

    mockContainer = new MockContainer();

    keyToNiceFormat(key: string){
        return key.split('-')
            .map((fragment)=>{ return fragment[0].toUpperCase() + fragment.substring(1) })
            .join('')
            .split('/')
            .map((fragment)=>{ return fragment[0].toUpperCase() + fragment.substring(1) })
            .join('/');
    }

    render(){
        let filteredDemoComponents = demoComponents.filter(x=> { return x.key.toLowerCase().search(this.state.search.toLowerCase()) > -1; });
        return (
            <ContainerProvider value={this.mockContainer}>
                <div style={{display:'flex', height:'100%', position: 'relative'}}>
                    <div style={{height:'100%', flex:'260px 0 0'}}>
                        <Paper style={{height:'100%', overflow:'auto'}}>
                            <Route render={({history})=>{ return (
                                <List>
                                    <ListItem>
                                        <TextField
                                            label="Search"
                                            fullWidth={true}
                                            value={this.state.search}
                                            onChange={(e)=> { this.setState({search: e.target.value})}} />
                                    </ListItem>
                                    { filteredDemoComponents.map((demoComponent)=>{
                                        let route = `${this._baseRoute}/${demoComponent.key}`;
                                        return (
                                            <ListItem button key={demoComponent.key} onClick={()=>{ history.push(route); }} >
                                                <span dangerouslySetInnerHTML={{ __html: this.keyToNiceFormat(demoComponent.key).replace(/\//g,'/<wbr>') }}></span>
                                            </ListItem>
                                        )
                                    }) }
                                </List>
                            ) }} />
                        </Paper>
                    </div>
                    <div style={{flex:1}}>
                        <DefaultPadding style={{ height:'100%', overflow:'auto', overflowY:'scroll' }}>
                            <Switch>
                                { demoComponents.map((demoComponent)=>{
                                    return <Route
                                        path={`${this._baseRoute}/${demoComponent.key}`}
                                        component={demoComponent.demoComponent}
                                        key={demoComponent.key}
                                    />
                                }) }
                                <Route path="*" render={()=>(<div>Selecione um componente da lista.</div>)} />
                            </Switch>
                        </DefaultPadding>
                    </div>
                </div>
            </ContainerProvider>
        );
    }
}

export const ComponentsShowcase: React.ComponentType<ComponentsShowcaseProps> = withContainer(
    ComponentsShowcaseExtended
);