//@flow
import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, Slide, withStyles } from '@material-ui/core'

const styles = theme => ({
});

type ErrorDetailDialogProps = {
    error: ?{ message: string, obj: any },
    onClose: ()=>void
}

type ErrorDetailDialogState = {
    error: any
}

type ErrorDetailDialogThemedProps = ErrorDetailDialogProps & {
    classes: any,
    theme: any
}

const Transition = (props) => {
    return <Slide direction="up" {...props} unmountOnExit={true} />;
}

class ErrorDetailDialogThemed extends React.Component<ErrorDetailDialogThemedProps,ErrorDetailDialogState>{

    constructor(props: ErrorDetailDialogThemedProps){
        super(props);
        this.state = {
            error: props.error
        };
    }

    static getDerivedStateFromProps(props: any, state: any){
        if(props.error!=null)
            return { error: props.error };
        return null;
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleEscapeKeyDown = (e: any) => {
        if(!e.defaultPrevented)
            this.props.onClose();
    }

    render(){

        let { error } = this.state;
        let currentError: any = error!=null?error.obj:null;

        let errors: Array<any> = [];
        
        if(error!=null){
            errors.push(currentError);
            while(currentError.innerError) {
                currentError = currentError.innerError;
                errors.push(currentError);
            } 
        }

        return (<Dialog
            open={this.props.error!=null}
            onEscapeKeyDown={this.handleEscapeKeyDown}
            onBackdropClick={this.handleClose}
            TransitionComponent={Transition}
        >
            <DialogTitle>Detalhes do Erro</DialogTitle>
            <DialogContent>
                { errors.map((error,i) =>(
                    <React.Fragment key={`error-${i}`}>
                        <p>{error.message}</p>
                        <p style={{fontSize:'.8em'}}>{error.stack}</p>
                    </React.Fragment>
                )) }
            </DialogContent>
        </Dialog>);
    }
}

let ErrorDetailDialog: React.ComponentType<ErrorDetailDialogProps> = (withStyles(styles, { withTheme: true })(ErrorDetailDialogThemed): any);

export { ErrorDetailDialog };