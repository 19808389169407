//@flow

import * as React from 'react';
import { DefaultCitySelector, PreferenceListItem } from './../components';
import type { RenderDisplayItemContext, RenderSelectorContext, PreferenceHandler } from './../Preferences';
import type { IPreferencesService } from './../../../services/preferences-service'
import type { IDataStoreService } from './../../../services/data-store-service'

export class DefaultCityPreferenceHandler implements PreferenceHandler{
    preferencesService: IPreferencesService;
    dataStoreService: IDataStoreService;
    
    constructor(preferencesService: IPreferencesService, dataStoreService: IDataStoreService){
        this.preferencesService = preferencesService;
        this.dataStoreService = dataStoreService;
    }
    
    getKey(){
        return 'defaultCityId';
    }

    renderSelector(context: RenderSelectorContext){
        return (<DefaultCitySelector
            value={this.preferencesService.getDefaultCity()}
            cityProvider={(filter: string)=> this.dataStoreService.listCities(filter) }
            onSettingSet={(value, close)=>{
                this.preferencesService.setDefaultCity(value);
                let stateUpdate = { open: !close };
                this.loadDataIntoState(stateUpdate).then(()=> context.updateState(stateUpdate));
            }}
        />);
    }

    async loadDataIntoState(state: any){
        state.defaultCity = this.preferencesService.getDefaultCity();
    }

    resetSetting(state: any): void{
        this.preferencesService.clearDefaultCity();
        state.defaultCity = null;
    }

    renderDisplayItem(context: RenderDisplayItemContext){
        let { loaded, state, forceUpdate, openSelector } = context;
        let valueLabel = !loaded? '...' : 'Não Configurado';
        let { defaultCity } = state;
        if(defaultCity!=null){
            valueLabel = defaultCity[0].name + (defaultCity[1]!=null?' - '+defaultCity[1].name : '');
        }
        return (<PreferenceListItem settingKey={this.getKey()}
            label="Cidade Padrão"
            valueLabel={ valueLabel }
            clear={()=>{
                this.preferencesService.clearDefaultCity();
                state.defaultCity = null;
                forceUpdate();
            }}
            onClick={()=> openSelector(this.getKey()) }
        />);
    }
}