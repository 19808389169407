//@flow

export const styles = (theme: any) => ({
    root:{
        position: 'relative'
    },
    filter:{
        position: 'relative'
    },
    listWrapper:{
        position: 'absolute',
        width:'calc(100% - 2px)',
        left: '1px',
        marginTop: 10,
        zIndex: 10,
        maxHeight:'300px',
        overflowY:'auto',
        overflowX:'hidden',
        boxSizing: 'border-box',
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        transition: 'opacity linear 0.15s',
        opacity: 0
    },
    listTable:{
        width:'100%',
        boxSizing: 'border-box'
    },
    listItem:{
        cursor: 'pointer'
    },
    listItemFocused:{
        backgroundColor: '#eee' // encontrar cor mais adequada?
    },
    listItemNoResult:{
        fontStyle:'italic'
    },
    listItemSelected:{
        
    },
    buttonNew: {
        margin: '0px',
        width: '100%'
    },
    buttonNewWrapper: {
        padding: theme.spacing.unit
    }
});