//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Debounce } from '../../util/debounce';
import { AdvancedSelectItem } from './AdvancedSelectItem';
import { Button, Icon, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import { Search as IconSearch, Done as IconDone, Add as IconAdd, Clear as IconClear } from '@material-ui/icons';
import { styles } from './styles';

const DEFAULT_ITEM_HEIGHT: number = 60;

const preventDefault = (event: any) =>{
    event.preventDefault();
}

export type AdvancedSelectProps<T> = {
    items: Array<T>,
    filter?: string,
    adapter: (item: T)=> {label: string, value: string},
    selectedItem: ?T,
    onFilterChange: (filter: string)=> void,
    onSelectItemClick: (item: T, index: number)=>bool,
    itemHeight?: number,
    onCreateClick?: (filter: string)=> void,
    onCreateLabel?: string,
    onSelectItemDeselect?: (item: T)=>bool,
    cellRenderers?: Array<(item: T, index: number)=> React.Element<typeof(TableCell)>>,
    actionRenderers?: Array<(item: T, index: number)=> {
        icon: React.Element<typeof(Icon)>,
        onClick: (e: any)=>void
    }>,
    showSearchIcon?: bool,
    textFieldWrapper?: React.Node|'none',
    fullWidth?: bool,
    label?: string,
    autoFocus?: bool,
    shrinkLabel?: bool,
    disabled?: bool,
    showClearButton?: bool,
    showSelectionIcon?: bool,
}

export type AdvancedSelectState<T> = {
    filter: string,
    anchorEl: any,
    focusedIndex: number,
    lastItems: Array<T>
}

export type AdvancedSelectThemedProps<T> = AdvancedSelectProps<T> & {
    classes: any,
    theme: any
}

class AdvancedSelectThemed<T> extends React.Component<AdvancedSelectThemedProps<T>,AdvancedSelectState<T>>{
    _debounceChange: Debounce;
    _debounceHide: Debounce;
    _listWrapperRef: any = React.createRef();
    _updateItemsKey = 0;
    _searchInputRef: any = React.createRef();

    focus = ()=>{
        setTimeout(() => {
            var element = this._searchInputRef.current;
            if(element!=null){
                element.focus();
                this.setState({anchorEl: element, focusedIndex: 0 });
            }    
        }, 1);
    }

    constructor(props: AdvancedSelectThemedProps<T>){
        super(props);
        this.state = {
            filter: props.selectedItem?props.adapter(props.selectedItem).label:(props.filter||''),
            anchorEl: null,
            focusedIndex:0,
            lastItems: props.items
        };
        if(props.hasOwnProperty('filter')){ //controlled
            this._debounceChange = new Debounce(0);
            this._debounceHide = new Debounce(0);
        }
        else{
            this._debounceChange = new Debounce(100);
            this._debounceHide = new Debounce(10);
        }
    }

    static getDerivedStateFromProps(props: AdvancedSelectThemedProps<T>, state: AdvancedSelectState<T>){
        let derivedState = null;

        if(props.items!==state.lastItems){            
            derivedState = derivedState||{};
            derivedState.focusedIndex = 0;
            derivedState.lastItems = props.items;
        }
        return derivedState;
    }

    componentDidUpdate(){
        this.updateListOpacity(this.state.anchorEl==null?0:1);
    }

    updateListOpacity(value: number){
        setTimeout(()=>{
            if(this._listWrapperRef && this._listWrapperRef.current)
                this._listWrapperRef.current.style.opacity = value.toString();
        },0);
    }

    handleCreateClick = (event: any) => {
        if(this.props.onCreateClick)
            this.props.onCreateClick(this.state.filter);
    }

    handleFilterChange = (e: any) => {
        if(this.props.selectedItem!=null && this.props.onSelectItemDeselect){
            let selectedItem = this.props.selectedItem;
            if(!this.props.onSelectItemDeselect(selectedItem)){
                this.setState({filter: this.props.adapter(selectedItem).label});
                return;
            }
        }
        this.setState({filter: e.target.value, anchorEl: e.currentTarget});
        this._debounceChange.run(()=>{
            this.props.onFilterChange(this.state.filter);
        });
    }

    handleFilterClick = (event: any) => {
        this.setState({anchorEl: event.currentTarget});
    }

    handleFilterFocus = (event: any) => {
        console.log('Focused');
        this.props.onFilterChange(this.state.filter);
        this.setState({anchorEl: event.currentTarget});
        this._debounceHide.cancelNext();        
    }

    handleFilterBlur = (event: any, focusIn: bool) => {
        this._debounceHide.run(()=>{
            this.setState({anchorEl: null });
            this.updateListOpacity(0);
        })
    }

    handleSelectItemClick = (event: any) => {
        let index = event.currentTarget.dataset.index;
        this.selectItem(event, parseInt(index));
    }

    selectItem(event: any, index: number){
        let item = this.props.items[index];
        if(this.props.onSelectItemClick(item, index)){
            this._updateItemsKey++;
            let resolvedFilter = this.props.adapter(item).label;
            if(resolvedFilter!==this.state.filter){
                this.setState({ filter: resolvedFilter });
                this.props.onFilterChange(resolvedFilter);
            }
            this._debounceChange.cancelNext();
            this.setState({ anchorEl: null });
            this.updateListOpacity(0);
        }
        else{
            event.preventDefault();
        }
    }

    tryAdjustFocusedElement(mod){
        let { focusedIndex, lastItems } = this.state;
        let { onCreateClick } = this.props;
        let hasCreateBtn = onCreateClick!=null;
        let maxIndex = lastItems.length + ( !hasCreateBtn ? -1 : 0 );
        if(lastItems){
            let newIndex = focusedIndex + mod;
            if(newIndex < 0) return;
            if(newIndex > maxIndex) return;
            this.adjustScrollToFocused(newIndex);
            this.setState({focusedIndex: newIndex});
        }
    }

    adjustScrollToFocused(index: number){
        if(this._listWrapperRef && this._listWrapperRef.current){
            let { itemHeight } = this.props;
            itemHeight = itemHeight||DEFAULT_ITEM_HEIGHT;
            let listWrapper = this._listWrapperRef.current;
            let height = listWrapper.clientHeight;
            let scrollTop = listWrapper.scrollTop;
            let focusedItemBottom = index * itemHeight;
            let focusedItemTop = focusedItemBottom + itemHeight;
            let visibleBottom = scrollTop;
            let visibleTop = scrollTop + height;
            if(focusedItemBottom<visibleBottom){
                listWrapper.scrollTop = focusedItemBottom;
            }
            else if(focusedItemTop>visibleTop){                
                listWrapper.scrollTop = focusedItemBottom - height + itemHeight;
            }
        }
        
    }

    handleEscKeyDown(e){
        if(this.state.anchorEl!=null){
            e.preventDefault();
            this.setState({anchorEl:null});
        }
    }

    handleEnterKeyDown(e){
        e.preventDefault();
        if(this.state.anchorEl!=null){
            let { focusedIndex, filter } = this.state;
            let { onCreateClick, items } = this.props;

            if(focusedIndex!=null){
                //select item r create new
                if(onCreateClick!=null && items.length===focusedIndex){
                    onCreateClick(filter);
                }
                else{
                    this.selectItem(e, this.state.focusedIndex);
                }
            }
        }
        else{
            this.setState({anchorEl:null});
        }

    }

    handleKeyDown = (e: any) => {
        switch (e.keyCode) {
            case 13://enter
                this.handleEnterKeyDown(e);
                break;
            case 27://esc
                this.handleEscKeyDown(e);
                break;
            case 38://up
                e.preventDefault();
                this.tryAdjustFocusedElement(-1);
                break;
            case 40://down
                e.preventDefault();
                this.tryAdjustFocusedElement(+1);
                break;
            default:
                break;
        }
    }

    renderExtraCells(item: T, index: number){
        let extraCells = [];
        if(this.props.cellRenderers){
            for(let i = 0; i < this.props.cellRenderers.length; i++){
                extraCells.push(<React.Fragment key={`cell-${i}`}>{this.props.cellRenderers[i](item, index)}</React.Fragment>);
            }
        }
        if(this.props.actionRenderers){
            let icons = [];
            for(let i = 0; i < this.props.actionRenderers.length; i++){
                let action = this.props.actionRenderers[i](item, index);
                icons.push(
                    <IconButton key={`action-${index}`} tabIndex={-1} onClick={(e: any)=>{
                        this._updateItemsKey++;
                        e.stopPropagation(); action.onClick(e);
                    }}>
                        {action.icon}
                    </IconButton>
                );
            }
            extraCells.push(<TableCell style={{width: '1%'}} key='cell-actions'>
                {icons}
            </TableCell>);
            
        }
        return extraCells;
    }

    clear = () => {
        let { selectedItem, onSelectItemDeselect, onFilterChange } = this.props;
        let { filter } = this.state;
        
        if(selectedItem && onSelectItemDeselect){
            onSelectItemDeselect(selectedItem);
        }
        if(filter && onFilterChange){
            onFilterChange('');
        }
        this.setState({filter:''});
    }

    render(){
        let { classes, items, selectedItem, autoFocus, cellRenderers,
        actionRenderers, onCreateLabel, onCreateClick, adapter, fullWidth,
        showSearchIcon, label, textFieldWrapper, shrinkLabel, showClearButton, showSelectionIcon } = this.props;
        let propFilter = selectedItem? adapter(selectedItem).label : this.props.filter;

        let { focusedIndex, filter } = this.state;
        
        let selectedItemAdapted = selectedItem ? adapter(selectedItem) : null;

        let isSelected = false;
        let selectedItemValue = '';

        if(selectedItemAdapted!=null){
            isSelected = true;
            selectedItemValue = selectedItemAdapted.value;
        }

        let textField = (<div style={{display:'flex', alignItems: 'baseline'}}>
            <TextField
                key="search"
                className={classes.filter}
                value={propFilter!=null?propFilter:filter||''}
                label={label||'Pesquisa'}
                onKeyDown={this.handleKeyDown}
                fullWidth={fullWidth!=null?fullWidth:true}
                disabled={this.props.disabled}
                InputLabelProps={{ shrink: shrinkLabel }}
                autoFocus={true}
                InputProps={{
                    inputRef: this._searchInputRef,
                    autoFocus: autoFocus||false,
                    startAdornment: (showSearchIcon!=null?showSearchIcon:true) ? (
                        <div><InputAdornment position="start"><IconSearch /></InputAdornment></div>
                    ) : ( null ), 
                    onChange:this.handleFilterChange,
                    onFocus:this.handleFilterFocus,
                    onBlur:this.handleFilterBlur,
                    onClick: this.handleFilterClick,
                }}
            />
            <div style={{height:30, position:'relative', top:-5}}><div style={{display:'flex'}}>
            { showSelectionIcon ? (
                <IconButton disabled>
                    <IconDone fontSize="small" style={{color: isSelected ? '#00ae7c' : 'eee', display: 'inline-block' }}/>
                </IconButton>) : null
            }
            { showClearButton==null || showClearButton ? (
                <IconButton tabIndex={-1}>
                    <IconClear fontSize="small" onClick={this.clear} style={{color: '#aaa', display: 'inline-block' }} />
                </IconButton>) : null
            }
            </div></div>
        </div>);
        
        let list = null;
        let tableCellsLength = 1;
        tableCellsLength += (cellRenderers? cellRenderers.length: 0);
        tableCellsLength += (actionRenderers? 1: 0);
        let itemHeight = this.props.itemHeight||DEFAULT_ITEM_HEIGHT;
            
        if(this.state.anchorEl!=null && !this.props.disabled){
            let createButtonActive = onCreateClick && items.length === focusedIndex;
            list = (/*<Popper open={this.state.anchorEl!=null} anchorEl={this.state.anchorEl}>*/
            <div className={ classes.listWrapper } ref={this._listWrapperRef}>
                <Table className={ classes.listTable }>
                    <TableBody>
                        
                        { items.length===0? (
                        <TableRow className={classes.listItemNoResult} key={'no-item'} style={{height:itemHeight}}>
                                <TableCell>Nenhum resultado</TableCell>
                        </TableRow>
                        ) : (null) }

                        { items.map((item, index)=>{
                            let adaptedItem = adapter(item);
                            let extraCells = this.renderExtraCells(item,index);
                            let tableRowClass = classNames(
                                classes.listItem,
                                {[classes.listItemSelected]:selectedItemValue===adaptedItem.value},
                                {[classes.listItemFocused]:focusedIndex===index}
                            )
                            
                            return (
                            <AdvancedSelectItem
                                key={adaptedItem.value}
                                invalidateIndex = {this._updateItemsKey}
                                label={adaptedItem.label}
                                onItemClick={this.handleSelectItemClick}
                                index={index}
                                tableRowClass={tableRowClass}
                                itemHeight={itemHeight}
                                extraCells={extraCells}
                            />
                            )
                        }) }
                        { onCreateClick ? (
                            <TableRow onMouseDown={preventDefault} style={{height: itemHeight}}>
                                <TableCell className={ classNames(classes.buttonNewWrapper, {[classes.listItemFocused]:createButtonActive}) } style={{paddingRight:8}} colSpan={tableCellsLength}>
                                    <Button
                                        tabIndex={-1}
                                        variant="contained"
                                        className={classes.buttonNew}
                                        onClick={this.handleCreateClick}
                                    >
                                        <IconAdd /> 
                                        { onCreateLabel || 'Novo' }
                                    </Button>
                                </TableCell>
                            </TableRow>
                            ) : (null) }
                    </TableBody>
                </Table>
            </div>
            /*</Popper>*/);
        }

        let wrappedTextField =
            textFieldWrapper==null? (<Paper elevation={1} style={{padding:16}}>{textField}</Paper>)
            : textFieldWrapper==='none'? textField
            : React.cloneElement((textFieldWrapper: any), {children: textField});

        return (<div className={ classes.root }>
            {wrappedTextField}
            {list}
        </div>);
    }
}

let AdvancedSelect: React.ComponentType<AdvancedSelectProps<any>> = (withStyles(styles, { withTheme: true })(AdvancedSelectThemed): any);
export { AdvancedSelect };