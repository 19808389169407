//@flow

import { BaseCollectionDownSynchronizer } from './../base-collection-down-synchronizer'
import type { GetEstadosResponse } from './../../../../util/skywork-rest-client/types';
import type { Province } from './../../../../types';
import type { SynchronizationEntitiesKeys } from './../../types';

export class ProvincesDownSynchronizer extends BaseCollectionDownSynchronizer<GetEstadosResponse,Province> {

    getEntityKey(): SynchronizationEntitiesKeys{
        return 'province';
    }

    getDataFromRemoteSource(): Promise<GetEstadosResponse>{
        return this._restClient.getEstados({fields: null})
    }

    convertRemoteData(reponse: GetEstadosResponse): Array<Province>{
        return reponse.map(item =>{
            let province: Province = {
                provinceId: item.estado_id.toString(),
                name: item.nome,
                shortName: item.sigla
            };
            return province;
        })
    }

    async storeConvertedData(items: Array<Province>): Promise<Array<any>>{
        await this._localDatabase.batchInsertOrReplaceProvinces(items);
        return items.map(x => x.provinceId);
    }
}