//@flow
import * as LINQ from './../../util/linq';
import * as Events from './../../events';
import type {
    BusinessUnit,
    PaymentCondition,
    City,
    Country,
    Customer,
    PaymentMethod,
    Province,
    Product,
    Sale,
    IEvent,
    SyncState
} from './../../types';

import type { 
    IDataStoreService
} from './types';

export class MockDataStoreService implements IDataStoreService
{
    _cities: Array<City>;
    _countries: Array<Country>;
    _customerIdNextIndex: number;
    _customers: Array<Customer>;
    _paymentConditions: Array<PaymentCondition>;
    _paymentMethods: Array<PaymentMethod>;
    _provinces: Array<Province>;
    _products: Array<Product>;
    _sales: Array<Sale>;
    
    constructor(){
        this._customers = [
            { customerId: '1', name: 'Bruce Willis', type: 'PERSON', cpf_cnpj: '24451957567', ie: '', ICMSTaxpayerType: null, email: 'brucewillis@exemplo.com', phoneNumber: '998765432', address: { streetName: 'Corredor do João', number: '525', postalCode: '96840-540', neighborhood: 'Faxinal Menino Deus', extraInfo: '', cityId: null, provinceId: null, countryId: null }, observation: 'Observação sobre o cliente.'},
            { customerId: '2', name: 'Brad Pitt', type: 'PERSON', cpf_cnpj: '74982159696', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null }, observation: ''},
            { customerId: '3', name: 'Tom Cruise', type: 'COMPANY', cpf_cnpj: '56455539000190', ie: '745/9242536', ICMSTaxpayerType: null, email: 'tomcruise@exemplo.com', phoneNumber: '967451345', address: { streetName: 'Avenida Rio Grande', number: '641', postalCode: '96205-001', neighborhood: 'Cassino', extraInfo: '', cityId: null, provinceId: null, countryId: null }, observation: 'Nenhuma observação.'},
            { customerId: '4', name: 'Johnny Depp', type: 'PERSON', cpf_cnpj: '69864569724', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '5', name: 'Angelina Jolie', type: 'COMPANY', cpf_cnpj: '53016472000107', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '6', name: 'Will Smith', type: 'PERSON', cpf_cnpj: '18372766843', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '7', name: 'Nicolas Cage', type: 'PERSON', cpf_cnpj: '86658184640', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '8', name: 'Leonardo DiCaprio', type: 'COMPANY', cpf_cnpj: '45277560000126', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '9', name: 'Denzel Washington', type: 'PERSON', cpf_cnpj: '07126503504', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '10', name: 'Nicole Kidman', type: 'COMPANY', cpf_cnpj: '65057352000129', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '11', name: 'Morgan Freeman', type: 'PERSON', cpf_cnpj: '36891762804', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '12', name: 'Tom Hanks', type: 'PERSON', cpf_cnpj: '57694421827', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '13', name: 'Julia Roberts', type: 'PERSON', cpf_cnpj: '34224216612', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '14', name: 'Mel Gibson', type: 'PERSON', cpf_cnpj: '46331849203', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '15', name: 'Sandra Bullock', type: 'COMPANY', cpf_cnpj: '57117145000194', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '16', name: 'Clint Eastwood', type: 'PERSON', cpf_cnpj: '41747220647', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '17', name: 'Meryl Streep', type: 'PERSON', cpf_cnpj: '06944762000122', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '18', name: 'Adam Sandler', type: 'COMPANY', cpf_cnpj: '64196171000110', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '19', name: 'Robert De Niro', type: 'PERSON', cpf_cnpj: '53447357312', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '20', name: 'Sylvester Stallone', type: 'PERSON', cpf_cnpj: '89439337540', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '21', name: 'Jennifer Aniston', type: 'COMPANY', cpf_cnpj: '66123560000141', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '22', name: 'Jim Carrey', type: 'PERSON', cpf_cnpj: '56583844605', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''},
            { customerId: '23', name: 'Keanu Reeves', type: 'COMPANY', cpf_cnpj: '43434839000140', ie: '', ICMSTaxpayerType: null, email: '', phoneNumber: '', address: { streetName: '', number: '', postalCode: '', neighborhood: '', extraInfo: '', cityId: null, provinceId: null, countryId: null}, observation: ''}
        ];
        this._customerIdNextIndex=24;
        
        this._paymentConditions = [
            { paymentConditionId:'1', description:'A Prazo' }
        ];

        this._paymentMethods = [
            { paymentMethodId:'1', description:'Dinheiro', type:'money' },
            { paymentMethodId:'2', description:'Cartão', type:'other' },
            { paymentMethodId:'3', description:'Vale', type:'other' },
        ];

        this._products = [
            'Cerveja BRAHMA Lata 350ml',
            'Cerveja DEVASSA Lata 350ml',
            'Cerveja SCHIN Lata 350ml',
            'Cerveja HEINEKEN Lata 350ml',
            'Cerveja BAVARIA Lata 350ml',
            'Cerveja BOHEMIA Lata 350ml',
            'Cerveja STELLA ARTOIS Lata 350ml',
            'Cerveja PRETA Lata 350ml',
            'Cerveja BRANCA Lata 350ml',
            'Cerveja MARROM Lata 350ml',
            'Cerveja NORTEÑA Lata 350ml',
            'Cerveja CORONA Lata 350ml',
            'Cerveja ORIGINAL Lata 350ml',
            'Cerveja SKOL Lata 350ml', 
            'Pepsi Lata 350ml', 
            'Pepsi Litro 2L', 
            'Pepsi Litro 3L', 
            'Coca-Cola Lata 350ml', 
            'Coca-Cola Litro 2L', 
            'Coca-Cola Litro 3L', 
            'Suco Petry 195ml', 
            'Suco Laranja Natural Pequeno', 
            'Suco Laranja Natural Grande' 
        ].map((x,i)=>({
            productId: `p${i}`,
            name: x,
            sku: `sku${i}`,
            sellValue: i%5+1,
            internalId: `i${i}`,
            unit: 'unidade',
            barCode: `${i}`
        }));

        this._countries= [
            {countryId:'1', name:'Brasil'},
            {countryId:'2', name:'Outro 1'},
            {countryId:'3', name:'Outro 2'}
        ]

        this._cities = [
            { name:'Porto Velho', cityId:'1100205', provinceId:'11'},
            { name:'Manaus', cityId:'1302603', provinceId:'13'},
            { name:'Rio Branco', cityId:'1200401', provinceId:'12'},
            { name:'Campo Grande', cityId:'5002704', provinceId:'50'},
            { name:'Macapá', cityId:'1600303', provinceId:'16'},
            { name:'Brasília', cityId:'5300108', provinceId:'53'},
            { name:'Boa Vista', cityId:'1400100', provinceId:'14'},
            { name:'Cuiabá', cityId:'5103403', provinceId:'50'},
            { name:'Palmas', cityId:'1721000', provinceId:'17'},
            { name:'Teresina', cityId:'2211001', provinceId:'22'},
            { name:'São Paulo', cityId:'3550308', provinceId:'35'},
            { name:'Rio de Janeiro', cityId:'3304557', provinceId:'33'},
            { name:'Belém', cityId:'1501402', provinceId:'15'},
            { name:'São Luís', cityId:'2111300', provinceId:'21'},
            { name:'Goiânia', cityId:'5208707', provinceId:'52'},
            { name:'Salvador', cityId:'2927408', provinceId:'29'},
            { name:'Maceió', cityId:'2704302', provinceId:'27'},
            { name:'Porto Alegre', cityId:'4314902', provinceId:'43'},
            { name:'Curitiba', cityId:'4106902', provinceId:'41'},
            { name:'Florianópolis', cityId:'4205407', provinceId:'42'},
            { name:'Belo Horizonte', cityId:'3106200', provinceId:'31'},
            { name:'Fortaleza', cityId:'2304400', provinceId:'23'},
            { name:'Recife', cityId:'2611606', provinceId:'26'},
            { name:'João Pessoa', cityId:'2507507', provinceId:'25'},
            { name:'Aracaju', cityId:'2800308', provinceId:'28'},
            { name:'Natal', cityId:'2408102', provinceId:'24'},
            { name:'Vitória', cityId:'3205309', provinceId:'32'},
            { name:'Exterior', cityId:'100000', provinceId:'100'}
        ];

        this._provinces=[
            { provinceId:'11', name:'Rondônia', shortName:'RO' },
            { provinceId:'13', name:'Amazonas', shortName:'AM' },
            { provinceId:'12', name:'Acre', shortName:'AC' },
            { provinceId:'50', name:'Mato Grosso do Sul', shortName:'MS' },
            { provinceId:'16', name:'Amapá', shortName:'AP' },
            { provinceId:'53', name:'Distrito Federal', shortName:'DF' },
            { provinceId:'14', name:'Roraima', shortName:'RR' },
            { provinceId:'50', name:'Mato Grosso', shortName:'MT' },
            { provinceId:'17', name:'Tocantins', shortName:'TO' },
            { provinceId:'22', name:'Piauí', shortName:'PI' },
            { provinceId:'35', name:'São Paulo', shortName:'SP' },
            { provinceId:'33', name:'Rio de Janeiro', shortName:'RJ' },
            { provinceId:'15', name:'Pará', shortName:'PA' },
            { provinceId:'21', name:'Maranhão', shortName:'MA' },
            { provinceId:'52', name:'Goiás', shortName:'GO' },
            { provinceId:'29', name:'Bahia', shortName:'BA' },
            { provinceId:'27', name:'Alagoas', shortName:'AL' },
            { provinceId:'43', name:'Rio Grande do Sul', shortName:'RS' },
            { provinceId:'41', name:'Paraná', shortName:'PR' },
            { provinceId:'42', name:'Santa Catarina', shortName:'SC' },
            { provinceId:'31', name:'Minas Gerais', shortName:'MG' },
            { provinceId:'23', name:'Ceará', shortName:'CE' },
            { provinceId:'26', name:'Pernambuco', shortName:'PE' },
            { provinceId:'25', name:'Paraíba', shortName:'PB' },
            { provinceId:'28', name:'Sergipe', shortName:'SE' },
            { provinceId:'24', name:'Rio Grande do Norte', shortName:'RN' },
            { provinceId:'32', name:'Espirito Santo', shortName:'ES' },
            { provinceId:'100', name:'Exterior', shortName:'EXT'}
        ];

        this._sales = [];
    }

    insertOrReplaceSyncState(syncState: SyncState): Promise<void>{
        return Promise.resolve(undefined);
    }

    listBusinessUnits(): Promise<Array<BusinessUnit>>{
        return Promise.resolve([1,2].map(x =>({
            businessUnitId: x.toString(),
            name: 'Unidade de Negócio '+x.toString(),
            address:{
                cityName: 'Cidade',
                provinceName: 'Estado',
                provinceCode: 'RS',
                postalCode: '93900-000',
                neighborhood: 'Bairro',
                streetName: 'Nome da Rua',
                number: '535',
                extraInfo: 'casa',
            },
            phone: '51 999 99 99 99',
            municipalRegistrationNumber: '444444-4',
            provinceRegistrationNumber: '555555-5',
            document: '324-56.34-34',
            postalCode:'93900-000',
            invoiceEnabled: true
        })));
    }

    _deepClone<T>(data: T): T {
        return JSON.parse(JSON.stringify(data));
    }

    async listSyncState(table: string): Promise<Array<SyncState>>{
        return Promise.resolve([]);
    }    

    listProducts(filter:string, limit: ?number) : Promise<Array<Product>> {
        filter = filter.toLowerCase();
        let limitOrDefault = limit||20;
        return Promise.resolve(this._deepClone(
            this._products
            .filter(x=>x.name.toLocaleLowerCase().indexOf(filter)!==-1)
            .slice(0,limitOrDefault-1)
        ));
    }

    listProductsIn(ids: Array<string>): Promise<Array<Product>>{
        return Promise.resolve(this._deepClone(
            this._products
            .filter(x=> ids.indexOf(x.productId)!==-1)
        ));
    }

    listCountries(filter:string, limit: ?number) : Promise<Array<Country>> {
        return Promise.resolve(this._countries);
    }

    listCustomers(filter:string, limit: ?number) : Promise<Array<Customer>>{
        filter = filter.toLowerCase();
        let limitOrDefault = limit||20;

        if(isNaN(filter)){
            return Promise.resolve(this._deepClone(
                this._customers
                .filter(x=>x.name.toLocaleLowerCase().indexOf(filter)!==-1)
                .slice(0,limitOrDefault-1)
            ));
        }else{
            return Promise.resolve(this._deepClone(
                this._customers
                .filter(x=>x.cpf_cnpj.indexOf(filter)!==-1)
                .slice(0,limitOrDefault-1)
            ));
        }
    }

    insertOrReplaceCustomer(customer: Customer){
        if(!customer.customerId){
            customer.customerId = (this._customerIdNextIndex++).toString();
            this._customers.push(customer);
        }
        else{
            let index = this._customers.findIndex(x => x.customerId === customer.customerId);
            if(index!==-1){
                this._customers[index] = customer;
            }
            else{
                throw new Error('Could not find provided Customer to update.');
            }
        }
        let customerClone = Object.assign({}, customer);
        Events.emitter.emit({name: Events.DataStoreServiceEvents.OnCustomerDataUpdated, data:{ customer:customerClone }, error: null});
        return Promise.resolve();
    }

    listPaymentMethods() : Promise<Array<PaymentMethod>>{
        return Promise.resolve(this._deepClone(this._paymentMethods));
    }

    listPaymentConditions() : Promise<Array<PaymentCondition>>{
        return Promise.resolve(this._deepClone(this._paymentConditions));
    }

    listCities(filter: string, limit: ?number) : Promise<Array<[City, ?Province]>>{
        filter = filter.toLowerCase();
        let limitOrDefault = limit||20;
        let cities = this._cities.filter(x=>x.name.toLocaleLowerCase().indexOf(filter)!==-1)
            .slice(0,limitOrDefault-1);
        let response: Array<[City, ?Province]> = LINQ.leftOuterJoin(
            cities, 'provinceId', 
            this._provinces, 'provinceId',
            (c,p)=>[c, p]
        );
        return Promise.resolve(this._deepClone(response));
    }

    listSales() : Promise<Array<{sale: Sale, customer: ?Customer}>>{
        return Promise.resolve([]);
    }

    getCountry(id: string) : Promise<?Country>{
        return Promise.resolve(this._countries.find(x => x.countryId===id));
    }

    getCity(id: string) : Promise<?[City, ?Province]>{
        let city = this._cities.find(x => x.cityId===id);
        if(city){
            return Promise.resolve([city, this._provinces.find(x => x.provinceId===city.provinceId)]);
        }
        else{
            return Promise.resolve(null);
        }
    }

    insertSale(sale: Sale){
        return Promise.resolve();
    }

    getCustomerFromOutdated(customer: Customer): Promise<?Customer>{
        return Promise.resolve(customer);
    }

    getCustomer(id: string): Promise<?Customer>{
        return Promise.resolve(this._customers.find(x => x.customerId===id));
    }

    getLocalCountry(){
        return { countryId:'1', name:'Brasil' };
    }

    getForeignCity(){
        return [{ cityId:'5', name:'Exterior', provinceId:'100' }, { provinceId: '100', name:'Exterior', shortName:'EXT' }];
    }
}