//@flow
import Dexie from 'dexie';
import type { DexieTransaction } from 'dexie';
import * as LINQ from './linq';
import type {
    PaymentCondition,
    Customer,
    City,
    Country,
    PaymentMethod,
    PrintRequest,
    Product,
    Province,
    Sale,
    SyncState,
    SyncStateCode
} from './../types';

const TABLES = {
    cities: 'cities',
    provinces: 'provinces',
    countries: 'countries',
    customers: 'customers',
    paymentMethods: 'paymentMethods',
    paymentConditions: 'paymentConditions',
    products: 'products',
    sales: 'sales',
    syncStates: 'syncStates',
    printRequests: 'printRequests'
}

type TableNames = $Keys<typeof(TABLES)>;

/*

Esse serviço fará uso do IndexedDB.

Para ABSTRAIR o IndexedDB vamos usar o Dexie: http://dexie.org/

PARA LIMPAR A BASE LOCAL PROCURAR LOCALHOST EM:
[USER_ROOT]\AppData\Local\Google\Chrome\User Data\Default\IndexedDB

*/

const DB_NAME: string = "skywork-database";

const BASE_SCHEMA = {
    [TABLES.cities]: 'cityId, name',
    [TABLES.provinces]: 'provinceId, name',
    [TABLES.countries]: 'countryId, name',
    [TABLES.customers]: 'customerId, tempCustomerId, cpf_cnpj, name',
    [TABLES.paymentMethods]: 'paymentMethodId, description',
    [TABLES.paymentConditions]: 'paymentConditionId, description',
    [TABLES.products]: 'productId, name, sku',
    [TABLES.sales]: 'saleId, customerId',
    [TABLES.syncStates]: '[table+id], table, state',
    [TABLES.printRequests]: '++printRequestId, locator',
};

export type Invalidator = (invalidationKey: string) => Promise<void>;

export class LocalDatabase
{
    _db: Dexie;
    _initializationPromise: ?Promise<void> = null;
    _initialized: bool = false;
    _invalidator: Invalidator;

    constructor(invalidator: Invalidator){
        this._db = new Dexie(DB_NAME);
        this._invalidator = invalidator;
    }

    _getSchemaCopy(){
        return JSON.parse(JSON.stringify(BASE_SCHEMA));
    }

    async initialize(): Promise<void> {
        if(this._initialized===true){
            return Promise.resolve();
        }

        if(this._initializationPromise!=null){
            //$FlowFixMe
            return this._initializationPromise;
        }



        let initializationPromise = new Promise((resolve: ()=>void, reject: (error: any)=>void)=>{
            
            // VERSION 1
            let schema = this._getSchemaCopy();
            this._db.version(1).stores(schema);

            // VERSION 2
            const v2Upgrade: any = async (transaction: DexieTransaction)=>{
                let sales = await transaction.table(TABLES.sales).toArray();
                sales.forEach(x => x.paidValue=0)
                await this._invalidator(TABLES.sales);
                await transaction.table(TABLES.sales).bulkPut(sales);
            };           
            this._db.version(2).upgrade(v2Upgrade);

            // VERSION 3
            let schemaV3 = this._getSchemaCopy();
            schemaV3.products = 'productId, name, sku, barCode';
            this._db.version(3).stores(schemaV3);

            // VERSION 4
            let schemaV4 = this._getSchemaCopy();
            schemaV4.products = 'productId, name, sku, barCode, internalId';
            let v4Upgrade: any = async (transaction: DexieTransaction)=>{
                let sales = await transaction.table(TABLES.sales).toArray();
                sales.forEach(x => x.internalId="");
                await transaction.table(TABLES.sales).bulkPut(sales);
                await this._invalidator(TABLES.sales);
            };
            this._db.version(4).stores(schemaV4).upgrade(v4Upgrade);

            this._db.open().then(()=>{
                console.log(`Dexie database "${DB_NAME}" initialized.`);
                //
                this.insertOrReplaceSyncState(TABLES.sales, '1548762861393-4717-2', 'blocked' );
                resolve();
            }, (e)=>{
                console.error(`Dexie database "${DB_NAME}" failed to initialize.`);
                reject(e);
            });
        });

        this._initializationPromise = initializationPromise;
        initializationPromise.then(()=>{
            this._initializationPromise = null;
            this._initialized = true;
        })
        return initializationPromise;         
    }

    async _getDB() : Promise<Dexie>
    {
        await this.initialize();
        return this._db;
    }

    _newUniqueID() {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return '_local_' + Math.random().toString(36).substr(2, 9);
    };

    async getCustomer(id: string) : Promise<?Customer>{
        let db = await this._getDB();
        return db.table(TABLES.customers)
            .where('customerId')
            .equals(id)
            .first();
    }

    async _putSyncState(state: SyncState) : Promise<void>{
        let db = await this._getDB();
        return db.table(TABLES.syncStates)
            .put(state);
    }

    async assignIdForCustomer(oldId: string, newId: string): Promise<void>{
        let db = await this._getDB();
        let customer: ?Customer = await db.table(TABLES.customers)
            .where('customerId')
            .equals(oldId)
            .first();
        if(customer!=null){
            customer.customerId = newId.toString();
            await db.table(TABLES.customers).put(customer);
            await db.table(TABLES.customers).delete(oldId);
            let sales: Array<Sale> = await db.table(TABLES.sales)
                .where('customerId')
                .equals(oldId)
                .toArray();
            
            if(sales){
                sales.forEach(sale=>{sale.customerId=newId});
                await db.table(TABLES.sales).bulkPut(sales);
            }
        }
    }

    async getSyncStates(table: TableNames, state: SyncStateCode): Promise<Array<any>>{
        let db = await this._getDB();
        
        let syncStates = await db.table(TABLES.syncStates)
            .where({table, state})
            .toArray();
        return syncStates.map(item=> item.id )
    }

    async deleteSyncState(table: TableNames, id: string): Promise<void>{
        let db = await this._getDB();
        return db.table(TABLES.syncStates).delete([table.toString(), id.toString()]);
    }

    async insertOrReplaceSyncState(table: TableNames, id: string, state: 'pending'|'blocked'): Promise<void>{
        await this._putSyncState({table, id, state});
    }
    
    async listCities(filter:string, limit: ?number): Promise<Array<[City, ?Province]>>{
        let db = await this._getDB();
        let cities;
        return db.table(TABLES.cities)
            .where('name')
            .startsWithIgnoreCase(filter)
            .limit(limit||20)
            .toArray()
            .then(items => {
                cities = items;
                let provincesIds = items.map(c=>c.provinceId);
                return db.table(TABLES.provinces)
                    .where('provinceId')
                    .anyOf(provincesIds)
                    .toArray();
            }).then(items =>{
                let provinceMap = {};
                items.forEach(item=> { provinceMap[item.provinceId] = item });
                return cities.map(item=> [item, provinceMap[item.provinceId]]);
            });        
    }

    async listCountries(filter:string, limit: ?number): Promise<Array<Country>>{
        let db = await this._getDB();
        return db.table(TABLES.countries)
            .where('name')
            .startsWithIgnoreCase(filter)
            .limit(limit||100)
            .toArray();
    }

    async listCustomers(filter:string, limit: ?number): Promise<Array<Customer>>{
        let db = await this._getDB();
        let isDoc = /^[0-9.-]+$/.test(filter);
        if(isDoc)
            filter = filter.replace(/[.-]/g,'');
        return db.table(TABLES.customers)
            .where(isDoc?'cpf_cnpj':'name')
            .startsWithIgnoreCase(filter)
            .limit(limit||100)
            .toArray();
    }

    async listPaymentMethods(): Promise<Array<PaymentMethod>>{
        let db = await this._getDB();
        return db.table(TABLES.paymentMethods).toArray();
    }

    async listPaymentConditions(): Promise<Array<PaymentCondition>>{
        let db = await this._getDB();
        return db.table(TABLES.paymentConditions).toArray();
    }

    async listProducts(filter:string, limit: ?number): Promise<Array<Product>>{
        let db = await this._getDB();

        let productByCode = filter ? await db.table(TABLES.products)
            .where('sku')
            .equalsIgnoreCase(filter)
            .or('barCode')
            .equalsIgnoreCase(filter)
            .or('internalId')
            .equalsIgnoreCase(filter)
            .first() : null;

        let productsByName = await db.table(TABLES.products)
            .where('name')
            .startsWithIgnoreCase(filter)
            .limit(limit||100)
            .toArray();
        
        if(productByCode!=null){
            productsByName.unshift(productByCode)
        }
        
        return productsByName;
    }

    async listPrintRequests(highlightedOnly: bool): Promise<Array<PrintRequest>>{
        let db = await this._getDB();
        return db.table(TABLES.printRequests).limit(20).toArray();
    }

    async listProductsIn(ids: Array<string>): Promise<Array<Product>>{
        let db = await this._getDB();
        return db.table(TABLES.products)
            .where('productId')
            .anyOf(ids)
            .toArray();
    }

    async listSales(): Promise<Array<{sale:Sale, customer: ?Customer}>>{
        let db = await this._getDB();
        let sales = await db.table(TABLES.sales).reverse().limit(50).toArray();
        let customersIds = sales.map(x => x.customerId).filter(x => x!=null);
        let customers = await db.table(TABLES.customers).where('customerId').anyOf(customersIds).toArray();
        return LINQ.leftOuterJoin(sales,'customerId',customers,'customerId', (sale,customer)=>{
            return {sale, customer};
        });
    }

    async listSyncState(table: string): Promise<Array<SyncState>>{
        let db = await this._getDB();
        return await db.table(TABLES.syncStates).where('table').equalsIgnoreCase(table).toArray();
    }

    async getCountry(id: string) : Promise<?Country>{
        let db = await this._getDB();
        return db.table(TABLES.countries)
            .where('countryId')
            .equals(id)
            .first();
    }

    async getCity(id: string) : Promise<?[City, ?Province]>{
        let db = await this._getDB();
        let city = await db.table(TABLES.cities).where('cityId').equals(id).first();           
        if(city==null) return null;
        let province = await  db.table(TABLES.provinces).where('provinceId').equals(city.provinceId).first();
        return [city, province];
    }

    async getCustomerFromOutdated(customer: Customer): Promise<?Customer>{
        let db = await this._getDB();
        let ids = [customer.customerId, customer.tempCustomerId]
            .filter(x => x!=null);
        return db.table(TABLES.customers)
            .where('customerId').anyOf(ids)
            .or('tempCustomerId').anyOf(ids)
            .first();
    }

    async clearTable(table: TableNames): Promise<void>{
        let db = await this._getDB();
        await db.table(table).clear();
    }

    async getPrintRequestByLocator(locator: string): Promise<?PrintRequest>{
        let db = await this._getDB();
        return db.table(TABLES.printRequests)
            .where('locator').equals(locator)
            .first();
    }

    async getSale(id: string) : Promise<?Sale>{
        let db = await this._getDB();
        return db.table(TABLES.sales)
            .where('saleId')
            .equals(id)
            .first();
    }

    //WRITE METHODS

    async batchInsertOrReplaceCities(cities: Array<City>): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.cities).bulkPut(cities)
    }

    async batchInsertOrReplaceCountries(countries: Array<Country>): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.countries).bulkPut(countries)
    }

    async batchInsertOrReplaceCustomers(customers: Array<Customer>): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.customers).bulkPut(customers)
    }

    async batchInsertOrReplacePaymentConditions(paymentConditions: Array<PaymentCondition>): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.paymentConditions).bulkPut(paymentConditions);
    }

    async batchInsertOrReplacePaymentMethods(paymentMethods: Array<PaymentMethod>): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.paymentMethods).bulkPut(paymentMethods);
    }

    async batchInsertOrReplaceProducts(products: Array<Product>): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.products).bulkPut(products);
    }

    async batchInsertOrReplaceProvinces(provinces: Array<Province>): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.provinces).bulkPut(provinces);
    }

    async insertOrReplaceCustomer(customer: Customer): Promise<void>{
        this._sanitizeCustomer(customer);
        if(customer.customerId==null||customer.customerId===''){
            let id = this._newUniqueID();
            customer.tempCustomerId = id;
            customer.customerId = id;
        }
        await this._putSyncState({table:TABLES.customers, id:customer.customerId, state: 'pending'}); //to synchronize later
        let db = await this._getDB();
        await db.table(TABLES.customers).put(customer)
    }

    _trim(value: string){
        if(value==null) return value;
        return value.replace(/(^ +| +$)/g,'');
    }

    _sanitizeCustomer(customer: Customer){
        if(customer.cpf_cnpj!=null)
            customer.cpf_cnpj = customer.cpf_cnpj.replace(/[^0-9]/g,'');
        if(customer.name!=null) customer.name = this._trim(customer.name);
    }

    async insertOrMergeCustomer(customer: Customer): Promise<void>{
        let db = await this._getDB();
        this._sanitizeCustomer(customer);
        if(customer.customerId==null||customer.customerId===''){
            let id = this._newUniqueID();
            customer.tempCustomerId = id;
            customer.customerId = id;
        }
        else{
            let existentCustomer = await db.table(TABLES.customers)
                .where('customerId').equals(customer.customerId).first();

            if(existentCustomer!=null){ //we only have one field to really preserve
                if(customer.tempCustomerId==null)
                    customer.tempCustomerId = existentCustomer.tempCustomerId;
            }
        }
        await db.table(TABLES.customers).put(customer)
    }

    async insertPrintRequest(printRequest: PrintRequest): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.printRequests).add(printRequest);
    }

    async insertOrReplacePrintRequest(printRequest: PrintRequest): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.printRequests).put(printRequest)
    }

    async insertSale(sale: Sale): Promise<void>{
        let db = await this._getDB();
        if(!sale.saleId){
            sale.saleId = `${new Date().getTime()}-${sale.businessUnitId}-${parseInt(Math.random() * 9)}`;
        }
        await db.table(TABLES.sales).add(sale)
        await this._putSyncState({table: TABLES.sales, id: sale.saleId, state: 'pending'});
    }

    async insertOrReplaceSale(sale: Sale): Promise<void>{
        let db = await this._getDB();
        await db.table(TABLES.sales).put(sale);
    }
    
    async removePrintRequest(printRequest: PrintRequest): Promise<void>{
        if(printRequest.printRequestId){
            let db = await this._getDB();
            await db.table(TABLES.printRequests)
                .delete(printRequest.printRequestId);
        }
    }
}