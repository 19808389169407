//@flow

import Dexie from 'dexie';

type SyncStateCode = 'inError'|'pending';

const DB_NAME: string = "skywork-logs";

const TABLES = {
    logs: 'logs',
}

type LoggerOpts = {name?: string, keep?: number, keepElastic?: number};

export class Logger {

    _db: Dexie;
    _name: string;
    _initializationPromise: ?Promise<void> = null;
    _initialized: bool = false;
    _keep: ?number;
    _keepElastic: number;


    constructor(options?: LoggerOpts){
        const opts = {
            keepElastic: 10,
            name: DB_NAME,
            ...options
        }
        this._name = opts.name;
        this._keep = opts.keep||null;
        this._keepElastic = opts.keepElastic;
        this._db = new Dexie(this._name);
    }

    initialize(): Promise<void> {
        if(this._initialized===true){
            return Promise.resolve();
        }

        if(this._initializationPromise!=null){
            //$FlowFixMe
            return this._initializationPromise;
        }

        let initializationPromise = new Promise((resolve: ()=>void, reject: (error: any)=>void)=>{
            this._db.version(1).stores({
                [TABLES.logs]: '++logId',
            });
            this._db.open().then(()=>{
                console.log(`Dexie database "${this._name}" initialized.`);
                resolve();
            }, (e)=>{
                console.error(`Dexie database "${this._name}" failed to initialize.`);
                reject(e);
            });
        });

        this._initializationPromise = initializationPromise;
        initializationPromise.then(()=>{
            this._initializationPromise = null;
            this._initialized = true;
        })
        return initializationPromise;         
    }

    async _getDB() : Promise<Dexie>
    {
        await this.initialize();
        return this._db;
    }

    async log(message: string): Promise<void>{
        const db = await this._getDB();
        await db.table(TABLES.logs).add({message})

        //limit
        const keep = this._keep;
        if(keep!=null){
            
            let count = await db.table(TABLES.logs).count();
            if(count > keep + this._keepElastic){
                const trimIndex = count - keep;
                const log = (await db.table(TABLES.logs).offset(trimIndex).limit(1).toArray())[0];
                await db.table(TABLES.logs).where('logId').below(log.logId).delete();
            }
        }
    }

    async list(): Promise<Array<string>>{
        const db = await this._getDB();
        return (await db.table(TABLES.logs).toArray()).map(x => x.message);
    }

}