//@flow
import * as React from 'react';
import classNames from 'classnames';
import type { SaleItem } from '../../../../types';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ItemDetail } from './components/ItemDetail';
import { SoftNumber } from '../../../../components/SoftNumber';
import numeral from "numeral";
import 'numeral/locales/pt-br';
import { ExpandMore } from '@material-ui/icons';

numeral.locale('pt-br');

const styles = (theme) => ({
    root: {
        position: 'relative',
        overflowY:"auto"
    },
    table: {
        background: 'white'
    },
    discountLabel:{
        color: theme.palette.secondary.main,
        fontSize:'85%'
    },
    rightAligned: {
        textAlign: 'right'
    },
    centerAligned: {
        textAlign: 'center'
    },
    containerDetailItem: {
        padding: '0!important'
    },
    tableHeader:{
        marginLeft: '24px'
    },
    iconExpanded:{
        transition: 'transform ease-in-out 0.2s'
    },
    iconExpandedToggled:{
        transform: 'rotate(180deg)'
    },
    row:{
        cursor: 'pointer',
        outline: '0px solid RGBA(0,157,255,0)',
        transition: 'all ease-in-out .150s'
    },
    rowHighlighted:{
        outline: '8px solid RGBA(0,157,255,.3)'
    },
    softNumberInputHighlighted:{
        backgroundColor: 'RGBA(255,255,255,.8)'
    },
    wideCell:{
        width:'99%',
        padding: '8px'
    },
    smallCell:{
        maxWidth:'140px',
        minWidth:'140px',
        with:'140px',
        padding: '8px'
    },
    strongCell:{
        fontWeight: '500'
    }
});

type SaleItemsProps={
    items: Array<SaleItem>,
    highlightedItem: ?SaleItem,
    onItemChange: (item: SaleItem) => void,
    onItemDelete: (item: SaleItem) => void,
    onItemDiscountChange: (item: SaleItem, discount: number) => void,
    onItemAmountChange: (item: SaleItem, amount: number) => void,
    className?: any,
    style?: any
}

type SaleItemsState={
    expandedItem: ?SaleItem,
    highlightingItem: ?SaleItem,
    highlightedItem: ?SaleItem
 }

type SaleItemsThemedProps= SaleItemsProps & {
    classes: any,
    theme: any
}

class SaleItemsThemed extends React.Component<SaleItemsThemedProps,SaleItemsState>{

    rootRef: any;
    lastScrollHeight: any;

    constructor(props: SaleItemsThemedProps){
        super(props);
        this.state = {
            expandedItem: null,
            highlightedItem: null,
            highlightingItem: null
        }
    }

    static getDerivedStateFromProps(props, state){
        if(state.highlightedItem !== props.highlightedItem){
           return Object.assign({}, state, { highlightingItem: props.highlightedItem, highlightedItem:null });
        }
        return null;
    }

    handleItemDelete = (saleItem: SaleItem) => {
        //let productId = saleItem.product.productId;
        // if(productId===this.state.expandedItem){
        this.setState({expandedItem: null});
        // }
        this.props.onItemDelete(saleItem);
    }

    handleItemDiscountChange = (item: SaleItem, discount: number) => {
        this.props.onItemDiscountChange(item, discount);
    }

    handleItemAmountChange = (item: SaleItem, amount: number) => {
        this.props.onItemAmountChange(item, amount);
    }

    toggleExpandedItem(saleItem: SaleItem){
        let expandedItem = this.state.expandedItem;
        if(expandedItem==null ||
            expandedItem.product.productId !== saleItem.product.productId ||
            expandedItem.adjustedSellValue !== saleItem.adjustedSellValue
        ){
            this.setState({expandedItem: saleItem});
        }
        else{
            this.setState({expandedItem: null});
        }
    }

    componentDidMount(){
        this.checkHighlight();
    }

    componentDidUpdate(prevProps: SaleItemsThemedProps, prevState: SaleItemsState, snapshot: any){
        this.checkHighlight();
        this.checkTableScroll();
    }

    checkTableScroll(){
        let scrollHeight = this.rootRef.scrollHeight;
        if(scrollHeight!==this.lastScrollHeight){
            this.rootRef.scrollTop = this.rootRef.scrollHeight;
            this.lastScrollHeight = scrollHeight;
        }
    }

    checkHighlight(){
        let { highlightedItem, highlightingItem } = this.state;
        if(highlightingItem!=null && highlightingItem !== highlightedItem){
            setTimeout(()=>{
                this.setState({highlightedItem: highlightingItem});
            });
        }
    }

    handleRootRef = (ref: any) =>{
        this.rootRef = ref;
    }

    render(){
        let { classes, className, style } = this.props;
        let { expandedItem, highlightedItem} = this.state;

        let quantityClass = classNames(classes.centerAligned, classes.smallCell);
        let priceClass = classNames(classes.rightAligned, classes.smallCell);
        let totalClass = classNames(classes.rightAligned, classes.smallCell, classes.strongCell);
        let nameClass = classNames(classes.wideCell, classes.strongCell);

        return (
        <div ref={this.handleRootRef} style={style} className={ classNames(classes.root, className) }>
                {/* <h3 className={classes.tableHeader} >Venda Atual</h3> */}
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding='checkbox'></TableCell>
                            <TableCell className={classes.wideCell}>PRODUTO</TableCell>
                            <TableCell className={quantityClass}>QUANTIDADE</TableCell>
                            <TableCell className={priceClass}>PREÇO&nbsp;UN.</TableCell>
                            <TableCell className={totalClass}>TOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.props.items.map((saleItem, index)=>{

                            let isHighlighted = false;
                            if(highlightedItem != null){
                                isHighlighted = saleItem.product.productId === highlightedItem.product.productId
                                    && saleItem.adjustedSellValue === highlightedItem.adjustedSellValue;
                            }
                            
                            let isExpanded = false;
                            if(expandedItem != null){
                                isExpanded = saleItem.product.productId === expandedItem.product.productId
                                    && saleItem.adjustedSellValue === expandedItem.adjustedSellValue;
                            }
                            let row = classNames(classes.row, { [classes.rowHighlighted]: isHighlighted });
                            let iconExpanded = classNames(classes.iconExpanded, {[classes.iconExpandedToggled]: isExpanded});

                            return [
                                <TableRow
                                    style={saleItem.amount===0?{opacity:.5}:undefined}
                                    className={row}
                                    hover={true}
                                    onClick={()=>{ this.toggleExpandedItem(saleItem) }}
                                    key={saleItem.product.productId}
                                >
                                    <TableCell padding='checkbox'>
                                        <ExpandMore className={iconExpanded} />
                                    </TableCell>
                                    <TableCell className={nameClass}>
                                        {saleItem.product.name}
                                    </TableCell>
                                    <TableCell className={quantityClass}>
                                        <SoftNumber
                                        onClick={ (e)=>{ e.stopPropagation() } }
                                        valor={parseFloat(saleItem.amount)}
                                        inputHighlightedClassName={classes.softNumberInputHighlighted}
                                        onChange={(event, value)=>{
                                            saleItem.amount = value;
                                            this.props.onItemChange(saleItem)
                                        }}
                                        onDestroy={(event)=>{
                                            this.handleItemDelete(saleItem);                                            
                                        }}
                                         />
                                    </TableCell>
                                    <TableCell className={ priceClass }>
                                        { numeral(saleItem.adjustedSellValue).format('$0,0.00') }
                                    </TableCell>
                                    <TableCell className={ totalClass }>
                                        { numeral(saleItem.amount * saleItem.adjustedSellValue).format('$0,0.00') }
                                        { saleItem.discount ? (
                                            <div className={classes.discountLabel}>
                                                -{numeral(saleItem.discount).format('$0,0.00')}
                                            </div>
                                        ) : (null) }
                                    </TableCell>
                                </TableRow>,
                                
                                isExpanded? (<TableRow key={saleItem.product.productId + '_details'}>
                                    <TableCell colSpan='5' className={classes.containerDetailItem}>
                                        <ItemDetail
                                            style={{paddingLeft:56}}
                                            saleItem={saleItem}
                                            onDiscountChange={this.handleItemDiscountChange}
                                            onAmountChange={this.handleItemAmountChange}
                                            onDelete={this.handleItemDelete}
                                        />
                                    </TableCell>                                    
                                </TableRow>) : (null)
                            ];
                        })}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

let SaleItems: React.ComponentType<SaleItemsProps> = (withStyles(styles, { withTheme: true })(SaleItemsThemed): any);

export { SaleItems };
