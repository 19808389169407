//@flow
import * as React from 'react';
import { BlockCard } from './BlockCard';
import { IContainer, withContainer } from './../../../container';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as Events from './../../../events';
import type { BusinessUnit } from './../../../types';

type NoBusinessUnitSelectedDetailProps = {}
type _NoBusinessUnitSelectedDetailProps = {} & { container: IContainer };
type NoBusinessUnitSelectedDetailState = {
    businessUnit: string,
    businessUnits: Array<BusinessUnit>
}

class _NoBusinessUnitSelectedDetail extends React.Component<_NoBusinessUnitSelectedDetailProps,NoBusinessUnitSelectedDetailState>{
    
    constructor(props: _NoBusinessUnitSelectedDetailProps){
        super(props);
        this.state = {
            businessUnit: "none",
            businessUnits: []
        }
    }

    componentDidMount(){
        this.props.container.getDataStoreService().listBusinessUnits().then(businessUnits=>this.setState({businessUnits}));
    }

    handleChange = (e: any)=>{
        this.setState({businessUnit: e.target.value});        
    }

    onConfirmClick = ()=>{
        let selectedBusinessUnit = this.state.businessUnits.find(x => x.businessUnitId===this.state.businessUnit);
        if(selectedBusinessUnit!=null)
            this.props.container.getPreferencesService().setBusinessUnit(selectedBusinessUnit);
    }

    render(){
        return (<BlockCard title="Seleção de Unidade de Negócio" actions={[
            <Button variant="contained" color="primary" key="1" onClick={this.onConfirmClick} disabled={this.state.businessUnit==='none'} >Confirmar</Button>
        ]}>
        <p>
            <span>É necessário escolher uma unidade de negócio:<br /></span>
        </p>
        <div>
            <FormControl>
            <InputLabel>Unidade de Negócio</InputLabel>
            <Select 
                value={this.state.businessUnit}
                onChange={this.handleChange}
                fullWidth={true}
                inputProps={{
                    name: 'business-unit',
                    id: 'business-unit',
                }}
                SelectDisplayProps={{ style:{ minWidth: '180px' } }}
            >
                {[<MenuItem value="none" key="none"><em>Selecione</em></MenuItem>]
                .concat(this.state.businessUnits.map((x,i)=>(
                    <MenuItem key={i} value={x.businessUnitId}>{x.name}</MenuItem>
                )))}
            </Select>
            </FormControl>
        </div>
        </BlockCard>);
    }

}

export const NoBusinessUnitSelectedDetail: React.ComponentType<NoBusinessUnitSelectedDetailProps> = withContainer(
    _NoBusinessUnitSelectedDetail
);