//@flow
import * as React from 'react';
import { Button, Paper, RadioGroup, FormControlLabel,  Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { resources } from './../../../util/resources';
import { Receipt as ReceiptIcon,
    ReceiptOutlined as ReceiptOutlinedIcon,
    InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
    InsertDriveFile as InsertDriveFileIcon,
    PrintDisabledOutlined as PrintDisabledOutlinedIcon,
    PrintDisabled as PrintDisabledIcon
} from '@material-ui/icons';
import * as PaperComp from './PaperComp';

const styles = theme => ({
});

type PrintOptionsProps = {
    printOption: string,
    onPrintOptionChange: (e: any)=>void,
    invoiceEnabled: bool
}

type PrintOptionsState = {    
}

type PrintOptionsThemedProps = PrintOptionsProps & {
    classes: any,
    theme: any
}

class PrintOptionsThemed extends React.Component<PrintOptionsThemedProps,PrintOptionsState>{

    constructor(props: PrintOptionsThemedProps){
        super(props);
        this.state = {};
    }

    handlePrintOptionChange = (e: any)=>{
        this.props.onPrintOptionChange(e);
    }

    render(){
        let { printOption, onPrintOptionChange, invoiceEnabled, ...rest } = this.props;
        return (
            <RadioGroup row onChange={this.handlePrintOptionChange} value={printOption} {...rest}>
                <FormControlLabel
                    style={{flex:1}}
                    value={"skip"}
                    control={(<Radio
                        icon={<PrintDisabledOutlinedIcon />}
                        checkedIcon={<PrintDisabledIcon />}
                    />)}
                    label={resources.getPrintOptionLabel('skip')} />
                <FormControlLabel
                    style={{flex:1}}
                    value={"receipt"}
                    control={(<Radio
                        icon={<ReceiptOutlinedIcon />}
                        checkedIcon={<ReceiptIcon />}
                    />)}
                    label={resources.getPrintOptionLabel('receipt')} />
                <FormControlLabel
                    style={{flex:1}}
                    value={"invoice"}
                    control={(<Radio
                        disabled={!invoiceEnabled}
                        icon={<ReceiptOutlinedIcon />}
                        checkedIcon={<ReceiptIcon />}
                    />)}
                    label={resources.getPrintOptionLabel('invoice')} />
            </RadioGroup>
        );
    }
}

let PrintOptions: React.ComponentType<PrintOptionsProps> = (withStyles(styles, { withTheme: true })(PrintOptionsThemed): any);

export { PrintOptions };