//@flow
import * as React from 'react';
import { TotalBar } from './../../../Sale/components/TotalBar';
import { MockDataStoreService } from './../../../../services/data-store-service/mock-data-store-service'
import type { SaleItem } from './../../../../types'


type TotalBarDemoProps = {

}

type TotalBarDemoState = {
    sales: Array<SaleItem>
}

class TotalBarDemo extends React.Component<TotalBarDemoProps,TotalBarDemoState>{

    

    constructor(props: TotalBarDemoProps){
        super(props);
        this.state = {
            sales: []
        };

    }

    componentDidMount(){
        new MockDataStoreService().listProducts('',5)
        .then((products) => {
            this.setState({sales: products.map((product, i)=>{
                return { product, amount: i+1, discount: i%2===0?0.1:0, adjustedSellValue: product.sellValue}
            })})
        })
    }

    render(){
        return <TotalBar items={this.state.sales} />;
    }
}

export default { key: 'total-bar', demoComponent: TotalBarDemo };