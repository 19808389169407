//@flow

export class Debounce{
  
    _timeout: TimeoutID;
    _duration: number;

    constructor(duration?: number){
        this._duration = duration!=null?duration:300;
    }

    run(action: ()=>void){
        this.cancelNext();
        
        this._timeout = setTimeout(action, this._duration);
    }

    cancelNext(){
        if(this._timeout!=null){
            clearTimeout(this._timeout);
        }
    }
}