//@flow
import * as React from 'react';
import { CustomerAdvancedSelect } from './../../../../components/CustomerAdvancedSelect';
import { MockDataStoreService } from './../../../../services/data-store-service/mock-data-store-service'
import type { Customer } from './../../../../types'

type CustomerAdvancedSelectDemoProps = {

}

type CustomerAdvancedSelectDemoState = {
    customers: ?Array<Customer>,
    wokingSampleFilter: string,
    workingSampleSelectedCustomer: ?Customer
}

class CustomerAdvancedSelectDemo extends React.Component<CustomerAdvancedSelectDemoProps,CustomerAdvancedSelectDemoState>{

    constructor(props: CustomerAdvancedSelectDemoProps){
        super(props);
        this.state = {
            customers: null,
            wokingSampleFilter: '',
            workingSampleSelectedCustomer: null
        };
    }


    componentDidMount(){
        (new MockDataStoreService()).listCustomers('',20).then((customers) =>{
            this.setState({customers})
        });
    }

    render(){
        if(this.state.customers!=null){
            let customers = this.state.customers;
            const handleCreateCustomerClick = (filter) => { alert(`Create customer with filter "${filter}".`) };
            const handleCustomerClick = (filter) => { alert(`Clicked customer.`); return false; };
            const handleCustomerFilterChange = (filter) => {};
            const handleCustomerDeselect = () => { alert(`Tried to deselect customer.`); return false; };
            return (
                <React.Fragment>
                    <p>All customers fixed in list</p>
                    <CustomerAdvancedSelect
                        selectedCustomer={ null }
                        customers={ this.state.customers }
                        onCustomerFilterChange={handleCustomerFilterChange}
                        onCustomerClick={handleCustomerClick}
                        onCreateCustomerClick={handleCreateCustomerClick}
                        onCustomerDeselect={handleCustomerDeselect}
                    />

                    <p>All customers fixed in list, first selected and locked</p>
                    <CustomerAdvancedSelect
                        selectedCustomer={ customers[0] }
                        customers={ customers }
                        onCustomerFilterChange={handleCustomerFilterChange}
                        onCustomerClick={handleCustomerClick}
                        onCreateCustomerClick={handleCreateCustomerClick}
                        onCustomerDeselect={handleCustomerDeselect}
                    />

                    <p>3 customers fixed in list</p>
                    <CustomerAdvancedSelect
                        selectedCustomer={ null }
                        customers={ customers.filter((x,i) => i < 3) }
                        onCustomerFilterChange={handleCustomerFilterChange}
                        onCustomerClick={handleCustomerClick}
                        onCreateCustomerClick={handleCreateCustomerClick}
                        onCustomerDeselect={handleCustomerDeselect}
                    />

                    <p>0 customers fixed in list</p>
                    <CustomerAdvancedSelect
                        selectedCustomer={ null }
                        customers={ [] }
                        onCustomerFilterChange={handleCustomerFilterChange}
                        onCustomerClick={handleCustomerClick}
                        onCreateCustomerClick={handleCreateCustomerClick}
                        onCustomerDeselect={handleCustomerDeselect}
                    />

                    <p>Fully working sample (except for customer creation)</p>
                    <CustomerAdvancedSelect
                        selectedCustomer={ this.state.workingSampleSelectedCustomer }
                        customers={ customers.filter(x => x.name.toLowerCase().indexOf(this.state.wokingSampleFilter.toLowerCase()) > -1) }
                        onCustomerFilterChange={(filter)=>{ this.setState({wokingSampleFilter: filter}) }}
                        onCustomerClick={(customer)=>{ this.setState({workingSampleSelectedCustomer: customer}); return true; }}
                        onCreateCustomerClick={handleCreateCustomerClick}
                        onCustomerDeselect={(customer)=>{this.setState({workingSampleSelectedCustomer: null}); return true; }}
                    />

                    <div style={{marginTop: 400}}></div>
                </React.Fragment>
            );
        }
        return (null);
    }
}

export default { key: 'customer-advanced-select', demoComponent: CustomerAdvancedSelectDemo };