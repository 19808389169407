//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';

const styles = theme => {
    return {
    }
};

type ValidationBoxProps = {
    errors: Array<string>
}

type ValidationBoxState = {    
}

type ValidationBoxThemedProps = ValidationBoxProps & {
    classes: any,
    theme: any
}

class ValidationBoxThemed extends React.Component<ValidationBoxThemedProps,ValidationBoxState>{

    constructor(props: ValidationBoxThemedProps){
        super(props);
        this.state = {
            
        };
    }

    render(){
        let { errors, ...rest } = this.props;

        if(errors.length===0)
            return ( null );

        return (
        <FormHelperText error={true} { ...rest } >
            { errors.map((error, i)=> (
                <span key={`error-${i}`}>{error}<br /></span>)
            ) }
        </FormHelperText>
        );
    }
}

let ValidationBox: React.ComponentType<ValidationBoxProps> = (withStyles(styles, { withTheme: true })(ValidationBoxThemed): any);

export { ValidationBox };