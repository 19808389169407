//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Customer } from '../types';
import { AdvancedSelect } from './AdvancedSelect';
import { TableCell } from '@material-ui/core';

type CustomerAdvancedSelectProps = {
    autoFocus?: bool,
    customers: Array<Customer>,
    selectedCustomer: ?Customer,
    onCustomerFilterChange: (filter: string)=> void,
    onCustomerClick: (customer: Customer)=>bool,
    onCreateCustomerClick: (filter: string)=> void,
    onCustomerDeselect: ()=>bool,
    advancedSelectRef?: any
}

export const CustomerAdvancedSelect = (props: CustomerAdvancedSelectProps) => {
    let {
        autoFocus,
        customers,
        selectedCustomer,
        onCreateCustomerClick,
        onCustomerFilterChange,
        onCustomerClick,
        onCustomerDeselect,
        advancedSelectRef
    } = props;

    return (<AdvancedSelect
        label={'Cliente'}
        adapter={(customer)=>({ label: customer.name, value: customer.customerId })}
        autoFocus={autoFocus}
        items={ customers }
        cellRenderers={[(item: any, index: number) => {return <TableCell>{ customers[index].cpf_cnpj }</TableCell>}]}
        selectedItem={ selectedCustomer }
        onCreateClick={(filter)=>{onCreateCustomerClick(filter)}}
        onFilterChange={(filter)=>{onCustomerFilterChange(filter)}}
        onSelectItemClick={(filter, index)=>{return onCustomerClick(customers[index]);}}
        onSelectItemDeselect={()=>{return onCustomerDeselect();}}
        showSelectionIcon
        innerRef={advancedSelectRef}
    />);
}