//@flow
import React from "react";
import Display from "./Display";
import ButtonPanel from "./ButtonPanel";
import { calculate } from "../logic/calculate";
import { appStyle } from './styles';

type CalculatorProps = {
  initialState?: {
    total?: string,
    next?: string,
    operation?: string
  },
  style?: any,
  buttonTips?: { [key: string]: string }
}

type CalculatorState = {
  total: ?number,
  next: ?number,
  operation: ?string
}

class Calculator extends React.Component<CalculatorProps,any> {
  constructor(props: any) {
    super(props);
    this.state = Object.assign({
      total: null,
      next: null,
      operation: null
    }, props.initialState);
  }


  handleClick = (buttonName: string) => {
    this.triggerButtonByName(buttonName);
  };

  triggerButtonByName = (buttonName: string) => {
    this.setState(calculate(this.state, buttonName));
  };

  isResolved(){
    return this.state.operation==null;
  }

  deleteLastChar(){
    if(this.state.next!=null)
      this.setState({next: this.state.next.substr(0, this.state.next.length-1)||null });
    else if(this.state.operation!=null)
      this.setState({operation: null, next: null });
  }

  calculate = () => {
    let result = Object.assign({}, this.state, calculate(this.state, '='));
    this.setState(result);
    return result;
  };

  render() {
    const { style, buttonTips, ...rest } = this.props;

    let { total, next, operation } = this.state;

    let active: any = '';
    let inactive: any = '';
    if(total==null && next==null){ //all null
      active = '0';
    }
    else if(total==null&&next!=null){
      active = next.toString();
    }
    else if(total!=null && next==null){
      if(operation==null)
        active = total.toString();
      else{
        inactive = total.toString() + ' ' + (operation||'') + ' ';
      }
    }
    else{ //all not null
      active = next.toString();
      inactive = total.toString() + ' ' + (operation||'') + ' ';
    }

    let displayValue = (<React.Fragment>
      <span style={{opacity:.7, fontSize:'70%'}}>{inactive}</span>
      <span>{active}</span>
    </React.Fragment>);
        
    return (
      <div { ...rest } style={Object.assign({}, appStyle.componentApp, this.props.style)}>
        <Display value={displayValue} />
        <ButtonPanel clickHandler={this.handleClick} tips={buttonTips} />
      </div>      
    );
  }
}
export default Calculator;