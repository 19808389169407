//@flow
import * as React from 'react';
import { Tabs } from './../../../Sale/components/Tabs';

type TabsDemoProps = {

}

type TabsDemoState = {
    
}

class TabsDemo extends React.Component<TabsDemoProps,TabsDemoState>{

    constructor(props: TabsDemoProps){
        super(props);
        this.state = {
            
        };
    }

    render(){
        return <Tabs
            addItemsComponent={<p>Add Items</p>}
            customerComponent={<p>Pick customer</p>}
            paymentComponent={<p>Payment options</p>}
        />;
    }
}

export default { key: 'tabs', demoComponent: TabsDemo };