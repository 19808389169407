//@flow
/* eslint-disable */
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Switch, Zoom, Fade } from '@material-ui/core';
import type { Product } from './../../../types';
import * as Events from './../../../events';
import { EditableButtonGroup, EditableButtonGroupItem } from './../../../components/EditableButtonGroup';
import { withContainer } from './../../../container';
import { ButtonPrefEditCheckbox as ButtonPrefEditCheckbox } from './../../../components/ButtonPrefEditCheckbox';

const styles = theme => ({
    
});

type ProductsShortcutsProps = {
    onProductShortcutClicked: (Product)=>void
}

type ProductsShortcutsState = { 
    isEditEnabled: bool,
    productsShortcuts: Array<{product:Product, color:string}>
}

type ProductsShortcutsDumbProps = ProductsShortcutsProps & {
    classes: any,
    theme: any,
    container: any
}

class ProductsShortcutsDumb extends React.Component<ProductsShortcutsDumbProps,ProductsShortcutsState>{
    constructor(props: ProductsShortcutsDumbProps){
        super(props);
        this.state = {
            isEditEnabled: false,
            productsShortcuts: []
        };
    }

    handleAppEvent = (event: Events.AppEvent) => {
        if(event.name===Events.PreferencesServiceEvents.OnPreferenceValueChanged && event.data.key === 'productsShortcuts'){
            this.refreshProducts();
        }
    }

    componentDidMount(){
        Events.emitter.subscribe(this.handleAppEvent);
        this.refreshProducts();
    }

    componentWillUnmount(){
        Events.emitter.unsubscribe(this.handleAppEvent);
    }

    refreshProducts = ()=>{
        let productsShortcutsPref = this.props.container.getPreferencesService().getProductsShortcuts();
        if(productsShortcutsPref!=null){
            let prodsIds = productsShortcutsPref.map(x => x.productId);
            this.props.container.getDataStoreService().listProductsIn(prodsIds).then(products=>{
                let productsShortcuts = productsShortcutsPref.map(x =>({
                    product: (products.find(product=> product.productId === x.productId): any),
                    color: x.color
                })).filter(x => x.product!=null);
                this.setState({productsShortcuts});
            });
        }
        else{
            this.setState({productsShortcuts:[]});
        }
    }

    handleItemClick = (index: number) => {
        this.props.onProductShortcutClicked(this.state.productsShortcuts[index].product);
    }

    handleRemoveItemClick = (index: number) => {
        let prefService = this.props.container.getPreferencesService();
        let data = prefService.getProductsShortcuts();
        data.splice(index, 1);
        prefService.setProductsShortcuts(data);
    }

    handleSetItemColorClick = (index: number, color: string) =>{
        let prefService = this.props.container.getPreferencesService();
        let data = prefService.getProductsShortcuts();
        let shortcut = this.state.productsShortcuts[index];
        data.filter(x => x.productId===shortcut.product.productId).forEach(x => x.color = color);
        prefService.setProductsShortcuts(data);
    }

    handleEditEnabledToggle = () => {
        this.setState({isEditEnabled:false});
    }

    handleSwitchChange = (event: any) => {
        this.setState({isEditEnabled: event.target.checked});
    };

    handleSwapItemsClick = (from: number, to: number) => {
        let prefService = this.props.container.getPreferencesService();
        let data = prefService.getProductsShortcuts();
        let a = data[from];
        data[from] = data[to];
        data[to] = a;
        prefService.setProductsShortcuts(data);
    }

    render(){
        let { classes, theme } = this.props;
        let { isEditEnabled, productsShortcuts } = this.state;
                
        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        }

        let show = productsShortcuts.length > 0;

        return (<div>
            <Fade in={show} unmountOnExit={true}>
                <EditableButtonGroup
                    items={productsShortcuts.map(x => ({ name: x.product.name, color: x.color }))}
                    editEnabled={isEditEnabled}
                    onItemClick={this.handleItemClick}
                    onRemoveItemClick={this.handleRemoveItemClick}
                    onEditEnabledToggle={this.handleEditEnabledToggle}
                    onSetItemColorClick={this.handleSetItemColorClick}
                    onSwapItemsClick={this.handleSwapItemsClick}
                />
            </Fade>
            <Fade in={show} unmountOnExit={true}>
                <ButtonPrefEditCheckbox
                    checked={this.state.isEditEnabled}
                    onChange={this.handleSwitchChange.bind(this)}
                />
            </Fade>
        </div>);
    }
}

let ProductsShortcuts: React.ComponentType<ProductsShortcutsProps> = withContainer(
    withStyles(styles, { withTheme: true })(ProductsShortcutsDumb)
);

export { ProductsShortcuts };
