//@flow
import * as React from 'react';
import { Fade, Paper, CircularProgress  } from '@material-ui/core';
import { ValidationBox } from '../components/ValidationBox';
import { withStyles } from '@material-ui/core/styles';
import { withContainer, IContainer } from './../container'
import { Redirect } from 'react-router';
import * as Events from './../events';

const styles = theme => ({
    root:{
        height:'100%',
        width:'100%',
        position:'absolute',
        background: theme.palette.primary.dark,
        display:'flex',
        alignItems: 'center', 
        justifyContent: 'center'
    },
    paper:{
        width:400,
        maxWith:'100%',
        padding: theme.spacing.unit*3,
        textAlign: 'center'
    }
});

type LoginTokenProps = {
    token: string,
    redirectPath: string
}

type LoginTokenState = {    
    busy: bool,
    error: string,
    redirect: ?bool
}

type LoginTokenExtendedProps = {
    classes: any,
    theme: any,
    container: IContainer,
    token: string,
    redirectPath: string
}

class LoginExtended extends React.Component<LoginTokenExtendedProps,LoginTokenState>{
    
    currentTimeout: any;
    formRef: any;

    constructor(props: LoginTokenExtendedProps){
        super(props);
        this.state = {
            busy: true,
            error: '',
            redirect: null
        };
    }

    appEventsHandler = (event: Events.AppEvent)=>{
        if(event.name===Events.AuthenticationServiceEvents.OnSignInStarted){
            this.setState({busy: true});
        }
        else if(event.name===Events.AuthenticationServiceEvents.OnSignedIn){
            if(event.error!=null){
                let error = 'Não foi possível acessar o servidor.';
                if(event.error.hasTag('serverValidation'))
                    error = 'A credencial fornecida não foi aceita.';
                
                this.setState({
                    busy: false,
                    error
                });
            }
            else{
                this.setState({
                    redirect: true
                });
            }
        }
    }

    componentDidMount(){
        Events.emitter.subscribe(this.appEventsHandler);
        this.tryAuthenticate();
    }

    tryAuthenticate(){
        this.currentTimeout = setTimeout(()=>{
            this.props.container.getAuthenticationService().authenticateWithToken({token:this.props.token});
        },500);
    }   

    componentWillUnmount(){
        clearTimeout(this.currentTimeout);
        Events.emitter.unsubscribe(this.appEventsHandler);
    }

    render(){
        let { classes, theme } = this.props;

        if(this.state.redirect)
            return (<Redirect to={this.props.redirectPath} />);
        
        return (<div className={classes.root}>            
            <Paper className={classes.paper}>
                <h2>Login Automático</h2>
                <Fade in={this.state.busy} appear >
                    <CircularProgress size={theme.spacing.unit*5} />
                </Fade>
                { this.state.error!=null? (
                    <ValidationBox style={{textAlign:'center'}} errors={[this.state.error]} />
                ):( null )}
            </Paper>
        </div>);
    }
}

let LoginToken: React.ComponentType<LoginTokenProps> = withContainer(
    withStyles(styles, { withTheme: true })(LoginExtended)
);

export { LoginToken };