//@flow

import * as Events from './../../../events';
import type { IDataStoreService } from './../../data-store-service';
import type { IPreferencesService } from './../../preferences-service';
import type { IAuthenticationService } from './../../authentication-service/types';

import type { ISynchronizationService, SynchronizationEntityData, SynchronizationEntitiesKeys } from './../types';
import { SynchronizationEntities } from './../types';
import { SkyworkRestClient } from './../../../util/skywork-rest-client';
import { EventEmitter } from './../../../util/event-emitter';
import { ExtendedError } from './../../../util/error';
import * as Synchronizers from './synchronizers';
import { LocalDatabase } from './../../../util/local-database';

export type SynchronizeContext = {
    forceFull: bool,
    isAborted: bool
}

export interface ISynchronizer{
    synchronize(context: SynchronizeContext): Promise<void>;
}

type CollectionDownSyncronizerConfig<RemoteResponse,LocalType> = {
    entityKey: SynchronizationEntitiesKeys,
    remoteSource: ()=> Promise<RemoteResponse>,
    converter: (reponse: RemoteResponse)=>Array<LocalType>,
    store: (items: Array<LocalType>)=>Promise<void>
}

const LOCAL_STORAGE_FULL_SYNC_KEY = 'defaultSync_fullySyncedFlag';
const LOCAL_STORAGE_FULL_SYNC_LAST_DATE_KEY = 'defaultSync_fullySyncedLastDateFlag';

export class DefaultSynchronizationService implements ISynchronizationService {
    
    _localDatabase: LocalDatabase;
    _restClient: SkyworkRestClient;
    _isInSync = false;
    _lastEntitySyncEvent: ?Events.AppEvent = null;
    _lastSyncEndedEvent: ?Events.AppEvent = null;
    _preferencesService: IPreferencesService;
    _syncInterval: any;
    _authenticationService: IAuthenticationService;
    
    constructor(localDatabase: LocalDatabase, restClient: SkyworkRestClient, preferencesService: IPreferencesService, authenticationService: IAuthenticationService){
        this._localDatabase = localDatabase;
        this._restClient = restClient;
        this._preferencesService = preferencesService;
        this._authenticationService = authenticationService;
        Events.emitter.subscribe(this._synchronizationServiceEventsHandler, 'synchronization-service');
        this.registerAutoSync();
    }


    _synchronizationServiceEventsHandler = (evt: Events.AppEvent)=>{
        if(
            evt.name===Events.SynchronizationServiceEvents.OnEntitySynchronizationStarted
            || evt.name===Events.SynchronizationServiceEvents.OnEntitySynchronizationEnded
        ){
            this._lastEntitySyncEvent = evt;
        }
        else if(
            evt.name===Events.DataStoreServiceEvents.OnSaleDataInserted
            || evt.name===Events.DataStoreServiceEvents.OnCustomerDataUpdated
        ){
            // lançar eventos sempre que ocorrer uma escrita que pode ser enviada imediatamente
            // só postergar um pouco para não sobrecarregar a interface com muitos handlers de atualização de interface simultâneos
            setTimeout(()=>{
                this.startSync(false);
            }, 300)
        }
    }

    registerAutoSync(){
        const syncMinutes = 2;
        const fullSyncMinutes = 15;
        this._syncInterval = setInterval(()=>{
            if(this.getDidFullSync() && this._authenticationService.isAuthenticated()){
                let lastSyncDate = this._getLastFullSyncDate();
                // if(lastSyncDate!=null){
                //     console.log('last sync', lastSyncDate.getTime());
                //     console.log('cut sync', new Date().getTime() - fullSyncMinutes * 60 * 1000);
                // }
                let fullSync = (
                    lastSyncDate==null
                    || lastSyncDate.getTime() < (new Date().getTime() - (fullSyncMinutes * 60 * 1000))
                );
                console.log('Autosync Full='+fullSync.toString());
                this.startSync(fullSync);
            }
        },1000*60*syncMinutes);
    }

    isInSync(): bool{
        return this._isInSync;
    }


    getDownSynchronizers(fullSync: bool): Array<ISynchronizer>{
        let synchronizers: Array<ISynchronizer> = [];
        if(fullSync){
            synchronizers.push(new Synchronizers.BusinessUnitDownSynchronizer(this._restClient, this._preferencesService));
            synchronizers.push(new Synchronizers.CountriesDownSynchronizer(this._restClient, this._localDatabase, Events.emitter));
            synchronizers.push(new Synchronizers.ProvincesDownSynchronizer(this._restClient, this._localDatabase, Events.emitter));
            synchronizers.push(new Synchronizers.CitiesDownSynchronizer(this._restClient, this._localDatabase, Events.emitter));
            synchronizers.push(new Synchronizers.PaymentConditionsDownSynchronizer(this._restClient, this._localDatabase, Events.emitter));
            synchronizers.push(new Synchronizers.PaymentMethodsDownSynchronizer(this._restClient, this._localDatabase, Events.emitter));
        }
        
        let customerSynchronizers = new Synchronizers.CustomersDownSynchronizer(this._restClient, this._localDatabase, Events.emitter);
        if(!fullSync) customerSynchronizers.enableMerge();
        synchronizers.push(customerSynchronizers);

        synchronizers.push(new Synchronizers.ProductsDownSynchronizer(this._restClient, this._localDatabase, Events.emitter));
        
        return synchronizers;
    }

    getUpSynchronizers(): Array<ISynchronizer>{
        let synchronizers: Array<ISynchronizer> = [];
        synchronizers.push(new Synchronizers.CustomersUpSynchronizer(this._restClient, this._localDatabase, Events.emitter));
        synchronizers.push(new Synchronizers.SalesUpSynchronizer(this._restClient, this._localDatabase, Events.emitter));
        return synchronizers;
    }
    
    startSync(forceFull: bool): void {

        if(this._isInSync)
            return;

        const config = { forceFull: forceFull, isAborted:false };

        const runStartSync = async ()=>{

            this._isInSync = true;
            
            let error: ?any;
            let didFullSync = this.getDidFullSync();
            let fullSync = forceFull || !didFullSync;

            Events.emitter.emit({ name: Events.SynchronizationServiceEvents.OnSynchronizationStarted, data:null, error:null});
            
            try{
                
                //UPLOAD
                if(didFullSync){
                    let upSynchronizers: Array<ISynchronizer> = this.getUpSynchronizers();
                    for(let i = 0; i < upSynchronizers.length; i++){
                        let upSynchronizer = upSynchronizers[i];
                        await upSynchronizer.synchronize(config);
                        if(config.isAborted){ break; }
                    }
                }

                //DOWNLOAD
                let downSynchronizers: Array<ISynchronizer> = this.getDownSynchronizers(fullSync);
                for(let i = 0; i < downSynchronizers.length; i++){
                    let downSynchronizer = downSynchronizers[i];
                    await downSynchronizer.synchronize(config);
                    if(config.isAborted){ break; }
                }
            }
            catch(e){
                error = new ExtendedError('Não foi possível concluir a sincronização.', e, 'summary');;
            }

            this._isInSync = false;

            if(error==null && fullSync){
                this.setDidFullSync();
            }

            this._lastSyncEndedEvent = { name: Events.SynchronizationServiceEvents.OnSynchronizationEnded, data:null, error};
            Events.emitter.emit(this._lastSyncEndedEvent);
        }
        runStartSync();
    }
    
    getLastEntitySyncEvent(): ?Events.AppEvent{
        return this._lastEntitySyncEvent;
    }

    getLastSyncEndedEvent(): ?Events.AppEvent{
        return this._lastSyncEndedEvent;
    }

    _getLastFullSyncDate(): ?Date{
        // debugger;
        let value = window.localStorage.getItem(LOCAL_STORAGE_FULL_SYNC_LAST_DATE_KEY);
        if(value==null){ return null; }
        return new Date(parseInt(value));
    }

    _setLastFullSyncDate(){
        window.localStorage.setItem(LOCAL_STORAGE_FULL_SYNC_LAST_DATE_KEY,new Date().getTime().toString());
    }

    setDidFullSync(){
        window.localStorage.setItem(LOCAL_STORAGE_FULL_SYNC_KEY,'1');
        this._setLastFullSyncDate();
    }

    resetDidFullSync(){
        window.localStorage.setItem(LOCAL_STORAGE_FULL_SYNC_KEY,'0');
        window.localStorage.removeItem(LOCAL_STORAGE_FULL_SYNC_LAST_DATE_KEY);
    }

    getDidFullSync(){
        return window.localStorage.getItem(LOCAL_STORAGE_FULL_SYNC_KEY)==='1';
    }
}