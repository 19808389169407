//@flow
import SynchronizationWidgetDemo from './SynchronizationWidgetDemo';

type DemoItem = { key: string, demoComponent: any };

let items: Array<DemoItem> = [
    SynchronizationWidgetDemo,
].map((item: DemoItem)=>{
    return { key: 'sync/'+item.key, demoComponent:item.demoComponent };
});

export default items;
