//@flow
import * as React from 'react';
import { AppLayout } from './../../../../App/components/AppLayout';
import { List, ListItem } from '@material-ui/core';
import { DefaultPadding } from './../../../../components/DefaultPadding';

type AppLayoutDemoProps = {

}

type AppLayoutDemoState = {
    
}

class AppLayoutDemo extends React.Component<AppLayoutDemoProps,AppLayoutDemoState>{

    constructor(props: AppLayoutDemoProps){
        super(props);
        this.state = {
            
        };
    }

    render(){

        let itemClicked = ()=>{ alert('item clicked'); }

        return <AppLayout
            menuSections={[
                (open)=>{
                return (<List>
                    <ListItem button onClick={itemClicked}>Item 1</ListItem>
                    <ListItem button onClick={itemClicked}>Item 2</ListItem>
                    <ListItem button onClick={itemClicked}>Item 3</ListItem>
                </List>)
                }
            ]}
            authenticatedUser="sample@sample.com"
            onSignOutClick={()=>{ alert('SignedOut!') }}
            headerTitle="App Title"
            headerTitle2="App Title 2">
            <DefaultPadding>Children</DefaultPadding>
        </AppLayout>;
    }
}

export default { key: 'app-layout', demoComponent: AppLayoutDemo };