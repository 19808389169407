//@flow
import * as React from 'react';

import type { Customer, City, Province, Country } from'./../../../types';
import { withStyles } from '@material-ui/core/styles';
import { containerFactory } from './../../../container';
import { CustomerFormDumbStyled } from './CustomerFormDumb';

type CustomerFormProps = {
    className? : string,
    customerName?: string,
    customer: ?Customer,
    onSubmit: (customer: Customer) => void,
}

const container = containerFactory();
const dataStoreService = container.getDataStoreService();
const foreignCity = dataStoreService.getForeignCity();
const localCountry = dataStoreService.getLocalCountry();

export class CustomerForm extends React.Component<any,any>{

    defaultCity: any;

    constructor(props: any){
        super(props)
        this.defaultCity = container.getPreferencesService().getDefaultCity();
    }

    render(){
        
        return (<CustomerFormDumbStyled
            { ...this.props }
            countryGetter={(id)=> dataStoreService.getCountry(id)}
            countriesDataSource={(filter)=>{ return dataStoreService.listCountries(filter,20).then(countries=>{
                return [localCountry].concat(countries.filter(x => x.countryId!==localCountry.countryId));
            }) }}
            cityGetter={(id)=>dataStoreService.getCity(id)}
            citiesDataSource={(filter)=>{
                return dataStoreService.listCities(filter,20).then(cities=> {
                    return (this.defaultCity?[this.defaultCity]:[]).concat(cities.filter(x => { return x[0].cityId!==foreignCity[0].cityId }))
                })
            }}
            foreignCity={foreignCity}
            localCountryId={localCountry.countryId}
        />);
    }
}