//@flow
import * as React from 'react';
import { SynchronizationWidget } from './../../../SynchronizationDialog/components/SynchronizationWidget';

type SynchronizationWidgetDemoProps = {

}

type SynchronizationWidgetDemoState = {
    spin: bool
}

class SynchronizationWidgetDemo extends React.Component<SynchronizationWidgetDemoProps,SynchronizationWidgetDemoState>{

    constructor(props: SynchronizationWidgetDemoProps){
        super(props);
        this.state = {
            spin: true
        };
    }

    onCheckboxChange(){
        this.setState({spin: !this.state.spin});
    }

    render(){

        let { spin } = this.state;

        return <div>
            <SynchronizationWidget title={'Title'} primaryText={'First Line'} secondaryText={'Second Line'} spin={spin} />
            <input type="checkbox" onChange={this.onCheckboxChange.bind(this)} checked={spin} />
        </div>;
    }
}

export default { key: 'synchronization-widget', demoComponent: SynchronizationWidgetDemo };