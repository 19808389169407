//@flow

import * as React from 'react';
import { IconButton, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

export const PreferenceListItem = (props: {
        label: string,
        valueLabel: string,
        settingKey: string,
        clear: ?()=>void,
        onClick?: ()=>void
    }) =>{
    return (
        <ListItem
            button
            data-value={props.settingKey}
            onClick={props.onClick}>
            <ListItemText 
                primary={props.label}
                secondary={props.valueLabel}
            />
            <ListItemSecondaryAction>
                <IconButton onClick={props.clear} style={{marginRight: 8}}>
                <ClearIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}