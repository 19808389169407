//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Switch, Zoom } from '@material-ui/core';
import { ButtonPrefEditCheckbox as ButtonPrefEditCheckbox } from './../../../components/ButtonPrefEditCheckbox';
import type { PaymentCondition } from './../../../types';
import { EditableButtonGroup, EditableButtonGroupItem } from './../../../components/EditableButtonGroup';
import { withContainerFineGrained } from './../../../container';

const styles = theme => ({
    
});

type PaymentConditionsShortcutsProps = {
    onPaymentConditionShortcutClicked: (PaymentCondition)=>void,
    paymentConditions: Array<PaymentCondition>,
}

type PaymentConditionsShortcutsState = { 
    isEditEnabled: bool,
    paymentConditions: Array<{paymentCondition: PaymentCondition, color: string, index: number}>,
}

type PaymentConditionsShortcutsDumbProps = {
    classes: any,
    theme: any,
    setPaymentConditionsShortcus: (Array<{paymentConditionId: string, color: string}>) => void,
    getPaymentConditionsShortcus: () => ?Array<{paymentConditionId: string, color: string}>,
    onPaymentConditionShortcutClicked: (PaymentCondition)=>void,
    paymentConditions: Array<PaymentCondition>,
};

class PaymentConditionsShortcutsDumb extends React.Component<PaymentConditionsShortcutsDumbProps,PaymentConditionsShortcutsState>{
    constructor(props: PaymentConditionsShortcutsDumbProps){
        super(props);
        this.state = {
            isEditEnabled: false,
            paymentConditions: []
        };
    }

    refreshShortcuts = async () =>{
        let shortcuts = this.props.getPaymentConditionsShortcus()||[];
        let paymentConditions = this.props.paymentConditions;

        let shortcutsExtended = (paymentConditions||[]).map(paymentCondition => {
            let index = shortcuts.findIndex(x => x.paymentConditionId == paymentCondition.paymentConditionId);
            let shortcut = shortcuts[index];
            if(shortcut==null){
                return { paymentCondition, color:'default', index:10000 };
            }
            else{
                return { paymentCondition, color:shortcut.color, index };
            }
        });
        shortcutsExtended.sort((a,b)=>{
            return a.index > b.index?1:-1;
        });
        this.setState({paymentConditions: shortcutsExtended});
    }

    componentDidMount = () => {
        this.refreshShortcuts();
    }

    handleEditableButtonItemClicked = (index: number) => {
        this.props.onPaymentConditionShortcutClicked(this.state.paymentConditions[index].paymentCondition);
    }

    handleSetItemColorClick = (index: number, color: string) =>{
        let paymentConditions = this.state.paymentConditions.slice(0);
        let shortcut = paymentConditions[index];
        shortcut.color = color;
        this.setState({paymentConditions}, ()=> this.persistShortcuts());
    }

    persistShortcuts(){
        this.props.setPaymentConditionsShortcus(this.state.paymentConditions.map(x =>({
            paymentConditionId: x.paymentCondition.paymentConditionId,
            color: x.color
        })));
    }

    handleEditEnabledToggle = ()=>{
        this.setState({isEditEnabled:false});
    }

    handleToggleChange(event: any){
        this.setState({isEditEnabled: event.target.checked});
    };

    handleSwapItemsClick = (from: number, to: number)=>{
        let paymentConditions = this.state.paymentConditions.slice(0);
        let temp = paymentConditions[from];
        paymentConditions[from] = paymentConditions[to];
        paymentConditions[to] = temp;
        this.setState({paymentConditions}, ()=> this.persistShortcuts());
    }

    render(){
        let { classes, theme } = this.props;
        let { isEditEnabled, paymentConditions } = this.state;
                
        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        }

        return <div style={{width: '100%'}}>
            <EditableButtonGroup
                autoFocusIndex={0}
                editEnabled={isEditEnabled}
                onItemClick={this.handleEditableButtonItemClicked}
                onEditEnabledToggle={this.handleEditEnabledToggle}
                onSetItemColorClick={this.handleSetItemColorClick}
                onSwapItemsClick={this.handleSwapItemsClick}
                items={paymentConditions.map(x => ({name:x.paymentCondition.description, color:x.color}))}
            />
            { paymentConditions.length > 0 ? (
            <Zoom 
                in={ paymentConditions.length > 0 } timeout={transitionDuration}
                style={{ transitionDelay: this.state.isEditEnabled === true ? transitionDuration.exit : 0 }}
                unmountOnExit={true}
            >
                <ButtonPrefEditCheckbox
                    checked={this.state.isEditEnabled}
                    onChange={this.handleToggleChange.bind(this)}
                />
            </Zoom>
        ) : ( null ) }
        </div>
    }
}

let PaymentConditionsShortcuts: React.ComponentType<PaymentConditionsShortcutsProps> = withContainerFineGrained(
    withStyles(styles, { withTheme: true })(PaymentConditionsShortcutsDumb),
    (container, onDestroy)=>{

        return {
            getPaymentConditionsShortcus: ()=> container.getPreferencesService().getPaymentConditionsShortcuts(),
            setPaymentConditionsShortcus: (items)=> container.getPreferencesService().setPaymentConditionsShortcuts(items),
        };
    }
);

export { PaymentConditionsShortcuts };
