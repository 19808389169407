import React from "react";
import PropTypes from "prop-types";
import { displayStyle } from './styles';

class Display extends React.Component {
  render() {
    return (
      <div class="calculator-display" style={displayStyle.componentDisplay}>
        <div>{this.props.value}</div>
      </div>
    );
  }
}
export default Display;
