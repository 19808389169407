//@flow
import * as React from 'react';
import type { SaleItem } from '../../../../../types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, InputAdornment, Grid, TextField, Typography, Slide } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import numeral from "numeral";
import 'numeral/locales/pt-br';
import { DiscountTextField2, decimalAdapterFactory } from './../../../../../components'
numeral.locale('pt-br');

const styles = theme => ({
    root: {
        padding:'24px',
        overflow: 'hidden'
    },
    buttonDelete: {
        width:'2em',
        height:'2em',
    },
    containerDetailItem: {
        padding: '16px'
    },
    detailItem:{
        padding: '24px 24px 16px 24px'
    },
    bold: {
        fontWeight: '500'
    },
    typography:{
        marginBottom: '5px'
    },
    info:{
        marginBottom: '12px'
    }
});

type ItemDetailProps = {
    saleItem: SaleItem,
    onDelete: (item: SaleItem)=>void,
    onDiscountChange: (item: SaleItem, value: number)=>void,
    onAmountChange: (item: SaleItem, value: number)=>void
}

type ItemDetailState = {    
}

type ItemDetailThemedProps = ItemDetailProps & {
    classes: any,
    theme: any
}

class ItemDetailThemed extends React.Component<ItemDetailThemedProps,ItemDetailState>{

    amountInputAdapter = decimalAdapterFactory(4);

    constructor(props: ItemDetailThemedProps){
        super(props);
        this.state = {
            
        };
    }

    handleDeleteItem = (event: any) => {
        this.props.onDelete(this.props.saleItem);
    }

    handleMoneyChange = (e:any, value: number)=>{
        this.props.onDiscountChange(this.props.saleItem, value);
    }

    handleAmountChange = (e:any, b:any)  => {
        this.props.onAmountChange(this.props.saleItem, parseFloat(e.target.rawValue));
    }

    render(){
        let { classes, saleItem, onDiscountChange, onDelete, theme, onAmountChange, ...rest } = this.props;
        let { adjustedSellValue, amount, discount } = saleItem;
        let { name, sellValue, sku, unit } = this.props.saleItem.product;

        return (
            <div {...rest} className={classes.root}>
                <Slide in appear>
                    <Grid direction="row" container spacing={24}>
                        <Grid item xs={12} md={8}>
                            
                            <div className={classes.info}>
                                <Typography variant="h5" component="h5">{name}</Typography>
                            </div>

                            <div className={classes.info}>
                                <Typography variant="caption" component="p">Código</Typography>
                                <Typography variant="body1" component="p">{sku}</Typography>
                            </div>
                            
                            <div className={classes.info}>
                                <Typography variant="caption" component="p">Preço Unitário</Typography>
                                <Typography variant="body1" component="p">{numeral(adjustedSellValue).format('$0,0.00')} / {unit}</Typography>
                            </div>
                            <div style={{height:6}}></div>
                            <div className={classes.info}>
                                <TextField
                                    label="Quantidade"
                                    InputLabelProps={{shrink:true}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" >{unit}</InputAdornment>,
                                        inputComponent: this.amountInputAdapter.component,
                                    }}
                                    value={amount}
                                    fullWidth={true} variant={'outlined'}
                                    onChange={this.handleAmountChange} />
                            </div>
                            <DiscountTextField2
                                disabled={adjustedSellValue*amount<=0}
                                value={discount}
                                moneyTextFieldProps={{ variant:'outlined', margin:'normal', label:'Desconto (R$)' }}
                                percentTextFieldProps={{ variant:'outlined', margin:'normal', label:'Desconto (%)' }}
                                separatorSpanProps={{ style:{ position:'relative', top:'-1.6em' } }}
                                onMoneyChange={this.handleMoneyChange}
                                refValue={adjustedSellValue*amount} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{textAlign:'right'}}>
                            <IconButton 
                                className={classes.buttonDelete}
                                onClick={this.handleDeleteItem}>
                                <Delete />
                            </IconButton>                    
                        </Grid>
                    </Grid>
                </Slide>
            </div>
        );
    }
}

let ItemDetail: React.ComponentType<ItemDetailProps> = (withStyles(styles, { withTheme: true })(ItemDetailThemed): any);

export { ItemDetail };