//@flow
//interfaces
import { BaseContainer } from './../base-container';
import type { IAuthenticationService } from './../../services/authentication-service/types';
import type { IBlockerService } from './../../services/blocker-service/types';
import type { IDataStoreService } from './../../services/data-store-service/types';
import type { IPreferencesService } from './../../services/preferences-service/types';
import type { ISynchronizationService } from './../../services/synchronization-service/types';
import type { ISaleService } from './../../services/sale-service/types';

//concrete types
import { DefaultAuthenticationService, LocalStorageAuthenticationDataStore } from './../../services/authentication-service/default-authentication-service';
import { DefaultBlockerService } from './../../services/blocker-service';
import { DefaultSynchronizationService } from './../../services/synchronization-service';
import { DefaultDataStoreService } from './../../services/data-store-service';
import { DefaultSaleService } from './../../services/sale-service/default-sale-service';
import { PrintQueueService, DefaultPrintRequestDataSource, InvoicePrintHandler, ReceiptPrintHandler } from './../../services/print-queue-service';
import { SkyworkRestClient } from './../../util/skywork-rest-client';
import { LocalDatabase } from './../../util/local-database';
import { DefaultPreferencesService, PreferencesLocalStorage } from './../../services/preferences-service/default-preferences-service';

//adapters types
import { AuthenticationTokenProviderAdapter } from './authentication-token-provider-adapter';
import { preferencesLoggedUserKeyProviderFactory } from './preferences-logged-user-key-provider';



export class DefaultContainer extends BaseContainer {
    
    _localDatabase: LocalDatabase;
    _authenticationService: IAuthenticationService;
    _dataStoreService: IDataStoreService;
    _printQueueService: PrintQueueService;
    _syncronizationService: DefaultSynchronizationService;
    _blockerService: IBlockerService;
    _preferencesService: IPreferencesService;
    _saleService: ISaleService;
    

    constructor(){
        super();
        this._errorLoggerService.bind();
        let restClient = this._getRestClient();
        this._preferencesService = new DefaultPreferencesService(
            new PreferencesLocalStorage(preferencesLoggedUserKeyProviderFactory(()=>this._authenticationService)),
            this._appEventEmitter
        );
        this._localDatabase = new LocalDatabase(async (key)=>{
            this._syncronizationService.resetDidFullSync();
        });
        this._dataStoreService = new DefaultDataStoreService(this._localDatabase, restClient);
        this._authenticationService = new DefaultAuthenticationService(restClient, new LocalStorageAuthenticationDataStore());
        this._syncronizationService = new DefaultSynchronizationService(this._localDatabase, restClient, this._preferencesService, this._authenticationService);
        this._blockerService = new DefaultBlockerService(this._syncronizationService, this._preferencesService);
        this._saleService = new DefaultSaleService(this._appEventEmitter, restClient, this._localDatabase);
        this._printQueueService = new PrintQueueService(this._appEventEmitter, new DefaultPrintRequestDataSource(this._localDatabase), {
            'receipt': new ReceiptPrintHandler(this._localDatabase, this._preferencesService, this._saleService),
            'invoice': new InvoicePrintHandler(this._localDatabase, this._preferencesService, this._saleService),
        });
    }

    _getRestClient(){
        let restClientUrl = 'https://sistema.skywork.com.br/api/v1/'; //url produção?
        return new SkyworkRestClient(restClientUrl, new AuthenticationTokenProviderAdapter(()=>this._authenticationService));
    }

    getAuthenticationService(){
        return this._authenticationService;
    }

    getBlockerService(){
        return this._blockerService;
    }

    getDataStoreService(){
        return this._dataStoreService;
    }

    getPrintQueueService(){
        return this._printQueueService;
    }

    getPreferencesService(){
        return this._preferencesService;
    }

    getSynchronizationService(){
        return this._syncronizationService;
    }

    getSaleService(){
        return this._saleService;
    }
}