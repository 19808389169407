//@flow

import * as React from 'react';
import type { City, Province } from './../../../types';
import { Debounce } from './../../../util/debounce';
import { Button, TableCell } from '@material-ui/core';
import { AdvancedSelect } from './../../../components/AdvancedSelect';

type DefaultCitySelectorProps = {
    // currentCity: [City,?Province],
    cityProvider: (filter: string)=>Promise<Array<[City,?Province]>>,
    onSettingSet: (value: ?[City, ?Province], close: bool)=>void,
    value: ?[City, ?Province],
};

type DefaultCitySelectorState = {
    cities: Array<[City,?Province]>,
    filter: string,
    selectedCity: ?[City,?Province],
    selectedCityChanged: bool
}

export class DefaultCitySelector extends React.Component<DefaultCitySelectorProps,DefaultCitySelectorState> {

    filterDebounce: Debounce = new Debounce();

    constructor(props: DefaultCitySelectorProps){
        super(props);
        this.state = {
            cities: [],
            filter: '',
            selectedCity: null,
            selectedCityChanged: false
        }
    }
    
    handleFilterChange = (filter: string) => {
        this.setState({filter});
        this.filterDebounce.run(this.handleFilterChangeDebounce);
    }

    handleFilterChangeDebounce = () => {
        this.props.cityProvider(this.state.filter).then((cities)=>{
            this.setState({cities});
        });
    }

    handleSelectItemClick = (filter: string, index: number)=>{
        let selectedCity = this.state.cities[index];
        this.setState({selectedCity});
        this.props.onSettingSet(selectedCity, true);
        return false;
    }

    render(){
        const selectedCity = this.state.selectedCityChanged ? this.state.selectedCity : this.props.value;
        return (
            <div style={{padding:10}}>
                
                <AdvancedSelect
                    autoFocus
                    filter={this.state.filter}
                    selectedItem={selectedCity}
                    adapter={(city)=>({ label: city[0].name, value: city[0].cityId })}
                    cellRenderers={[(city)=> (<TableCell>{city[1]?city[1].name:'-'}</TableCell>)]}
                    items={ this.state.cities }
                    label={"Cidades"}
                    showSearchIcon={true}
                    onFilterChange={this.handleFilterChange}
                    onSelectItemClick={this.handleSelectItemClick}
                    textFieldWrapper="none"
                    showSelectionIcon={true}
                    onSelectItemDeselect={()=>{
                        this.props.onSettingSet(null, false);
                        this.setState({selectedCity: null, selectedCityChanged: true}); return true;
                    }}
                />
            </div>
        );
    }
}