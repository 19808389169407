//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SwipeableTabs } from './../../../components/SwipeableTabs';

const styles = theme => ({
   tabContentItem:{
       padding: 16,
       height:'100%',
       position:'relative'
   }
});

type TabsProps = {
    addItemsComponent: any,
    customerComponent: any,
    paymentComponent: any,
    onTabIndexChange?: (index:any, ref:any)=>void
}

type TabsState = {
    tabIndex: number
}

type TabsThemedProps = TabsProps & {
    classes: any,
    theme: any
}

class TabsThemed extends React.Component<TabsThemedProps,TabsState>{
    
    swipeableTabsRef: any;
    paymentRef: any;

    constructor(props: TabsThemedProps){
        super(props);
        this.state = {
            tabIndex: 0
        }
    }

    handleKeyDown = (e: any) => {
        if(e.ctrlKey){
            if(e.keyCode==40) this.goNext();
            else if(e.keyCode==38) this.goPrev();
        }
    }

    goNext(){
        if(this.swipeableTabsRef!=null)
            this.swipeableTabsRef.goNext();
    }

    goToStart(){
        if(this.swipeableTabsRef!=null)
            this.swipeableTabsRef.goToStart();
    }
    
    goPrev(){
        if(this.swipeableTabsRef!=null)
            this.swipeableTabsRef.goPrev();
    }

    componentDidMount(){
        window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount(){
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleTabIndexChange = (index: number, ref: any)=>{
        if(this.props.onTabIndexChange){
            this.props.onTabIndexChange(index, ref);
        }
        if(index===2 && this.paymentRef!=null){
            let focusElement = this.paymentRef.querySelector('[data-tab-focus]');
            setTimeout(()=>{
                if(focusElement!=null)
                    focusElement.focus();
            },400);
        }
    }

    handleTabContentRef = (ref: any, index: number) => {
        if(index===2){
            this.paymentRef = ref;
        }
    }

    render(){

        let { classes, ...rest } = this.props;

        return <SwipeableTabs
            { ...rest }
            innerRef={(ref)=>this.swipeableTabsRef = ref}
            onTabIndexChange={this.handleTabIndexChange}
            tabContentRef={this.handleTabContentRef}
            contentItemClass={ classes.tabContentItem }
            tabs={[
                {label:'Adicionar Itens', content: this.props.addItemsComponent},
                {label:'Cliente', content: this.props.customerComponent},
                {label:'Pagamento', content: this.props.paymentComponent}
            ]}
        />
    }
}

let Tabs: React.ComponentType<TabsProps> = (withStyles(styles, { withTheme: true })(TabsThemed): any);

export { Tabs };