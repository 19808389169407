//@flow
import * as React from 'react';
import { Button, Paper, TextField, CircularProgress  } from '@material-ui/core';
import { Validation } from '../../util/validation';
import { ValidationBox } from '../../components/ValidationBox';
import { TouchController } from '../../components/TouchController';
import { withStyles } from '@material-ui/core/styles';
import { withContainer, IContainer } from './../../container'
import * as Events from './../../events';

const styles = theme => ({
    root:{
        height:'100%',
        width:'100%',
        position:'absolute',
        background: theme.palette.primary.dark,
        display:'flex',
        alignItems: 'center', 
        justifyContent: 'center'
    },
    paper:{
        width:400,
        maxWith:'100%',
        padding: theme.spacing.unit*3
    },
    formItem:{
        marginBottom: theme.spacing.unit*3
    },
    buttonGroup:{
        textAlign: 'right'
    },
    button: {
        margin: theme.spacing.unit,
    },
});

type LoginProps = {
}

type LoginState = {    
    username: string,
    password: string,
    busy: bool,
    error: ?string,
    toucheds: {[key: string]: bool}
}

type LoginExtendedProps = LoginProps & {
    classes: any,
    theme: any,
    container: IContainer
}

class LoginExtended extends React.Component<LoginExtendedProps,LoginState>{

    formRef: any;

    constructor(props: LoginExtendedProps){
        super(props);
        this.state = {
            username:this.getLastLoginUsername(),
            password:'',
            busy: false,
            error: null,
            toucheds: {}
        };
    }

    appEventsHandler = (event: Events.AppEvent)=>{
        if(event.name===Events.AuthenticationServiceEvents.OnSignInStarted){
            this.setState({busy: true});
        }
        else if(event.name===Events.AuthenticationServiceEvents.OnSignedIn){
            if(event.error!=null){
                let errorMessage = 'Não foi possível acessar o servidor.';
                if(event.error.hasTag('serverValidation'))
                    errorMessage = 'O usuário e a senha não conferem.';
                
                this.setState({
                    busy: false,
                    error: errorMessage
                });
            }
        }
    }

    componentDidMount(){
        Events.emitter.subscribe(this.appEventsHandler);
    }

    componentWillUnmount(){
        Events.emitter.unsubscribe(this.appEventsHandler);
    }

    handleSubmit = (e?:any) => {
        if(e) e.preventDefault();
        this.setLastLoginUsername(this.state.username);
        this.tryAuthenticate();
        return false;
    }

    tryAuthenticate = () => {
        if(this.validate().valid()){
            let {username, password} = this.state;
            this.props.container.getAuthenticationService()
                .authenticate({username, secret:password});
        }
    }

    setLastLoginUsername(username: string){
        window.localStorage.setItem('last_login_username', username);
    }

    getLastLoginUsername(){
        return window.localStorage.getItem('last_login_username')||'';
    }

    handleForgotPasswordClick(){
        //exibir em modal.
        let url = 'http://sistema.skywork.com.br/';
        if(window.confirm(`Para recuperar a sua senha, você precisa acessar o endereço: "${url}". Continuar?`)){
            window.location=url;
        }
    }

    handleFocus(){
        if(this.state.error!=null){
            this.setState({error: null});
        }
    }

    handleTouchedsChange = (toucheds)=>{
        this.setState({toucheds});
    }

    validate(){
        let validation = new Validation();
        validation.validateRequiredString('username', this.state.username);
        validation.validateRequiredString('password', this.state.password);
        validation.setToucheds(this.state.toucheds);
        return validation;
    }

    render(){
        let { classes, theme } = this.props;

        let validation = this.validate();
        
        return <div className={classes.root} onFocus={this.handleFocus.bind(this)}>
            <TouchController onTouchedsChange={this.handleTouchedsChange} toucheds={this.state.toucheds}>
                <Paper className={classes.paper}>
                    <form
                        ref={(ref)=>this.formRef = ref}
                        component="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}

                    >
                    <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />
                    <h2>Login</h2>
                    <div className={classes.formItem}>
                        <TextField
                            value={this.state.username}
                            onChange={(e)=>this.setState({username:e.target.value})}
                            autoComplete="off"
                            label="Usuário"
                            error={validation.touchedFieldHasErrors('username')}
                            inputProps={{ id: 'username' }}
                            fullWidth={true} />
                        <ValidationBox errors={validation.getErrorsIfTouched('username')} />
                    </div>
                    <div className={classes.formItem}>
                        <TextField 
                            value={this.state.password} 
                            onChange={(e)=>this.setState({password:e.target.value})}
                            label="Senha" 
                            error={validation.touchedFieldHasErrors('password')}
                            inputProps={{id: 'password', autoComplete:'new-password'}}
                            fullWidth={true}
                            type="password" />
                        <ValidationBox errors={validation.getErrorsIfTouched('password')}
                        />
                    </div>
                    <div className={classes.buttonGroup}>
                        <Button
                            className={classes.button}
                            onClick={this.handleForgotPasswordClick.bind(this)}
                        >
                            Esqueci minha senha
                        </Button>
                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={validation.hasErrors() || this.state.busy}
                            onClick={this.tryAuthenticate}
                            >
                            <span style={{opacity: this.state.busy?0:undefined}}>Logar</span>
                            { this.state.busy? ( <CircularProgress size={theme.spacing.unit*3.5} style={{position: 'absolute'}} /> ) : ( null ) }
                        </Button>
                        <input type="submit" value="" style={{width:0, visibility:'hidden', padding:0 }} />
                    </div>
                    { this.state.error!=null? (
                        <ValidationBox style={{textAlign:'center'}} errors={[this.state.error]} />
                    ):( null )}
                    </form>
                </Paper>
            </TouchController>
        </div>;
    }
}

let Login: React.ComponentType<LoginProps> = withContainer(
    withStyles(styles, { withTheme: true })(LoginExtended)
);

export { Login };