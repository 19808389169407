//@flow

type NFeIdentificador = {
    serie: string,
    numero: string,
    dataEmissao: string
}

type NFeItem = {
    numero: string,
    descricao: string,
    quantidade: string,
    unidade: string,
    valor: string,
    valorTotal: string,
    desconto: ?string
}

type NFeProtocolo = {
    chave: string,
    numero: string,
    dataRecebimento: string
}

type NFeEmitente = {
    cnpj: string,
    nome: string,
    nomeFantasia: ?string,
    endereco: {
        logradouro: string,
        numero: string,
        bairro: string,
        municipio: string,
        uf: string,
        cep: string,
        pais: string,
        fone: ?string
    },
    ie: ?string,
    cnae: ?string,
}

type NFeDestinatario = {
    cnpj: ?string,
    cpf: ?string,
    nome: string,
    endereco: ?{
        logradouro: ?string,
        numero: ?string,
        bairro: ?string,
        municipio: ?string,
        uf: ?string,
        cep: ?string,
        pais: ?string,
        fone: ?string
    },
    ie: ?string,
    cnae: ?string,
}

export type NFe = {
    identificador: NFeIdentificador,
    emitente: NFeEmitente,
    destinatario: ?NFeDestinatario,
    qrCode: string,
    itens: Array<NFeItem>,
    informacaoComplementar: string,
    valorTotal: string,
    descontoTotal: string,
    valorTotalPagar: string,
    protocolo: NFeProtocolo
}

function getMaybeText(ele: HTMLElement|Document, query: string): ?string{
    let el = ele.querySelector(query);
    if(el!=null) return el.textContent;
    return null;
}

function getText(ele: HTMLElement|Document, query: string): string {
    let el = ele.querySelector(query);
    if(el!=null) return el.textContent;
    throw new Error(`Tag não encontrada ao solicitar a query '${query}'.`);
}

export function parseInvoice(xmlString: string): NFe{   

    let parser = new DOMParser();
    let nfeDoc = parser.parseFromString(xmlString,"text/xml");
    let infNFe = nfeDoc.querySelector('infNFe');
    if(infNFe==null) throw new Error('Tag <infNFe> não encontrada.');
    
    let ide = infNFe.querySelector('ide');
    if(ide==null) throw new Error('Tag <ide> não encontrada.');
    let identificador: NFeIdentificador = {
        serie: getText(ide, 'serie'),
        numero: getText(ide,'nNF'),
        dataEmissao: getText(ide,'dhEmi'),
    }

    let emit = infNFe.querySelector('emit');
    if(emit==null) throw new Error('Tag <emit> não encontrada.');
    let enderEmit = emit.querySelector('enderEmit');
    if(enderEmit==null) throw new Error('Tag <enderEmit> não encontrada.');
    let emitente: NFeEmitente = {
        cnpj: getText(emit, 'CNPJ'),
        cnae: getMaybeText(emit, 'CNAE'),
        ie: getMaybeText(emit, 'IE'),
        nome: getText(emit, 'xNome'),
        nomeFantasia: getMaybeText(emit, 'xFant'),
        endereco: {
            logradouro: getText(enderEmit, 'xLgr'),
            numero:  getText(enderEmit, 'nro'),
            bairro:  getText(enderEmit, 'xBairro'),
            municipio:  getText(enderEmit, 'xMun'),
            uf: getText(enderEmit, 'UF'),
            cep: getText(enderEmit, 'CEP'),
            pais: getText(enderEmit, 'xPais'),
            fone: getMaybeText(enderEmit, 'fone')
        }
    }

    let dest = infNFe.querySelector('dest');
    let destinatario: ?NFeDestinatario = null;
    if(dest!=null){
        let enderDest = dest.querySelector('enderDest');
        destinatario = {
            cnpj: getMaybeText(dest, 'CNPJ'),
            cpf: getMaybeText(dest, 'CPF'),
            cnae: getMaybeText(dest, 'CNAE'),
            ie: getMaybeText(dest, 'IE'),
            nome: getText(dest, 'xNome'),
            nomeFantasia: getMaybeText(dest, 'xFant'),
            endereco: enderDest!=null?{
                logradouro: getMaybeText(enderDest, 'xLgr'),
                numero:  getMaybeText(enderDest, 'nro'),
                bairro:  getMaybeText(enderDest, 'xBairro'),
                municipio:  getMaybeText(enderDest, 'xMun'),
                uf: getMaybeText(enderDest, 'UF'),
                cep: getMaybeText(enderDest, 'CEP'),
                pais: getMaybeText(enderDest, 'xPais'),
                fone: getMaybeText(enderDest, 'fone')
            }: null
        }
    }

    let itens: Array<NFeItem> = [];
    let dets = infNFe.querySelectorAll('det');
    for(let i = 0; i < dets.length; i++){
        let det = dets[i];
        let item: NFeItem = {
            numero: getText(det, 'prod > nItemPed'),
            descricao: getText(det, 'prod > xProd'),
            quantidade: getText(det, 'prod > qCom'),
            unidade: getText(det, 'prod > uCom'),
            valor: getText(det, 'prod > vProd'),
            desconto: getMaybeText(det, 'prod > vDesc'),
            valorTotal: getText(det, 'prod > vProd'),
        }
        itens.push(item);
    }

    let infProt = nfeDoc.querySelector('protNFe > infProt');
    if(infProt==null) throw new Error('Tag <infProt> não encontrada.');
    let protocolo: NFeProtocolo = {
        chave: getText(infProt, 'chNFe'),
        numero: getText(infProt, 'nProt'),
        dataRecebimento: getText(infProt, 'dhRecbto'),
    }

    let informacaoComplementar = getText(infNFe, 'infAdic > infCpl');
    
    let qrCode = getText(nfeDoc, 'infNFeSupl > qrCode');
    let valorTotal = getText(nfeDoc, 'total > ICMSTot > vProd');
    let valorTotalPagar = getText(nfeDoc, 'total > ICMSTot > vNF');
    let descontoTotal = getText(nfeDoc, 'total > ICMSTot > vDesc');
    
    
    return {
        identificador,
        emitente,
        destinatario,
        itens,
        qrCode,
        informacaoComplementar,
        valorTotal,
        descontoTotal,
        valorTotalPagar,
        protocolo
    }
}