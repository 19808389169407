//@flow
import type { IEvent } from './../types';
import { EventEmitter } from './../util/event-emitter';

const buildEventArray = (obj: {[key: string]: string})=>{
    let array: Array<string> = [];
    for(var key in obj){
        array.push(obj[key]);
    }
    return array;
}

/* AUTHENTICATION */
export const AuthenticationServiceEvents = {
    OnSignInStarted:'OnSignInStarted',
    OnSignedIn:'OnSignedIn',
    OnSignedOut:'OnSignedOut'
}
export const AuthenticationServiceEventsArray = buildEventArray(AuthenticationServiceEvents);

/* BLOCKER */
export const BlockerServiceEvents = {
    OnBlockChanged:'OnBlockChanged'
}
export const BlockerServiceEventsArray = buildEventArray(BlockerServiceEvents);


export const PreferencesServiceEvents = {
    OnPreferenceValueChanged: 'OnPreferenceValueChanged'
}

/* PRINT_QUEUE */
export const PrintQueueServiceEvents = {
    OnPrintQueueChanged:'OnPrintQueueChanged'
}
export const PrintQueueServiceEventsArray = buildEventArray(PrintQueueServiceEvents);

/* DATA_STORE */
export const DataStoreServiceEvents = {
    OnSaleDataInserted:'OnSaleDataInserted',
    OnCustomerDataUpdated: 'OnCustomerDataUpdated'
}
export const DataStoreServiceEventsArray = buildEventArray(DataStoreServiceEvents);

/* DATA_STORE */
export const SaleServiceEvents = {
    OnSaleInvoiceCreated:'OnSaleInvoiceCreated',
    OnSaleInvoiceRequested: 'OnSaleInvoiceRequested'
}
export const SaleServiceEventsArray = buildEventArray(SaleServiceEvents);

/* SINCHRONIZATION */
export const SynchronizationServiceEvents = {
    OnSynchronizationStarted: 'OnSynchronizationStarted',
    OnEntitySynchronizationStarted: 'OnEntitySynchronizationStarted',
    OnEntityItemSynchronizationEnded: 'OnEntityItemSynchronizationEnded',
    OnEntitySynchronizationEnded: 'OnEntitySynchronizationEnded',
    OnSynchronizationEnded: 'OnSynchronizationEnded'
}
export const SynchronizationServiceEventsArray = buildEventArray(SynchronizationServiceEvents);

export const UIEvents = {
    OnSalePrintRequested: 'OnSalePrintRequested',
    OnSceneOpened: 'OnSceneOpened',
}

export type EventNames =
    $Keys<typeof(AuthenticationServiceEvents)> |
    $Keys<typeof(BlockerServiceEvents)> |
    $Keys<typeof(DataStoreServiceEvents)> |
    $Keys<typeof(AuthenticationServiceEvents)> |
    $Keys<typeof(SaleServiceEvents)> |
    $Keys<typeof(SynchronizationServiceEvents)> |
    $Keys<typeof(PrintQueueServiceEvents)> | 
    $Keys<typeof(PreferencesServiceEvents)> |
    $Keys<typeof(UIEvents)>;

export type AppEvent = IEvent<EventNames>;
export type AppEventEmitter = EventEmitter<AppEvent>;  
export const emitter : AppEventEmitter = new EventEmitter(); //remover isso mais tarde, a instância do container deve ser usada ao invés