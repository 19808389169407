//@flow
import * as React from 'react';
import { TextField } from '@material-ui/core';
import { TextFieldMasked, TextMaskComponentFactory } from './../../../../components/TextFieldMasked';
import { cpfMask, cnpjMask } from './../../../../util/masks';

type TextFieldMaskedDemoProps = {

}

type TextFieldMaskedDemoState = {
    generic: string,
    cpf: string,
    cnpj: string
}

class TextFieldMaskedDemo extends React.Component<TextFieldMaskedDemoProps,TextFieldMaskedDemoState>{

    cpfMaskComponent: Function;
    cnpjMaskComponent: Function;

    constructor(props: TextFieldMaskedDemoProps){
        super(props);
        this.state = {
            generic: '',
            cpf: '',
            cnpj: ''
        };
        this.cpfMaskComponent = TextMaskComponentFactory(cpfMask);
        this.cnpjMaskComponent = TextMaskComponentFactory(cnpjMask);
    }

    getHandleChange(field: string){
        return (e: any)=>{
            this.setState({[field]: e.target.value});
        }
    }

    render(){
        let style = {marginBottom: 16};

        return (<React.Fragment>
            
            { /*MANEIRA 1*/ }
            <div style={style}>
                <TextFieldMasked
                    id={'test1'}
                    label={'Test'}
                    mask={['(', /[1-9]/, /\d/, /\d/, ')']}
                    value={this.state.generic}
                    onChange={this.getHandleChange('generic')}
                />
            </div>

            { /*MANEIRA 2*/ }
            <div style={style}>
                <TextField
                    InputProps={{inputComponent: this.cpfMaskComponent }}
                    label={'CPF'}
                    value={this.state.cpf}
                    onChange={this.getHandleChange('cpf')}
                />
            </div>

            <div style={style}>
                <TextField
                    InputProps={{inputComponent: this.cnpjMaskComponent }}
                    label={'CNPJ'}
                    value={this.state.cnpj}
                    onChange={this.getHandleChange('cnpj')}
                />
            </div>
        </React.Fragment>);
    }
}

export default { key: 'text-field-masked', demoComponent: TextFieldMaskedDemo };