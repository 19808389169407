//@flow

import * as React from 'react';
import { DefaultPaymentMethodSelector, PreferenceListItem } from './../components';
import type { RenderDisplayItemContext, RenderSelectorContext, PreferenceHandler } from './../Preferences';
import type { IPreferencesService } from './../../../services/preferences-service'
import type { IDataStoreService } from './../../../services/data-store-service'

export class DefaultPaymentMethodPreferenceHandler implements PreferenceHandler{
    
    preferencesService: IPreferencesService;
    dataStoreService: IDataStoreService;
    
    constructor(preferencesService: IPreferencesService, dataStoreService: IDataStoreService){
        this.preferencesService = preferencesService;
        this.dataStoreService = dataStoreService;
    }
    
    getKey(){
        return 'defaultPaymentMethodId';
    }

    renderSelector(context: RenderSelectorContext){
        return (<DefaultPaymentMethodSelector
            paymentMethods={context.state.paymentMethods||[]}
            onSettingSet={(value)=>{
                this.preferencesService.setDefaultPaymentMethodId(value);
                let stateUpdate = {}
                this.loadDataIntoState(stateUpdate)
                    .then(()=> context.updateState(stateUpdate));
            }}
            value={this.preferencesService.getDefaultPaymentMethodId()}
        />);
    }

    async loadDataIntoState(state: any){
        let defaultPaymentMethodId: ?string = this.preferencesService.getDefaultPaymentMethodId();
        
        if(state.paymentMethods==null)
            state.paymentMethods = await this.dataStoreService.listPaymentMethods();

        if(defaultPaymentMethodId==null)
            state.defaultPaymentMethod = null;
        else
            state.defaultPaymentMethod = state.paymentMethods.find(x => x.paymentMethodId==defaultPaymentMethodId);        
    }

    resetSetting(state: any): void{
        state.defaultPaymentMethod = null;
        this.preferencesService.clearDefaultPaymentMethodId();
    }

    renderDisplayItem(context: RenderDisplayItemContext){
        let { loaded, state, forceUpdate, openSelector } = context;
        let valueLabel = !loaded? '...' : 'Não Configurado';
        let { defaultPaymentMethod } = state;
        if(defaultPaymentMethod!=null){
            valueLabel = defaultPaymentMethod.description;
        }
        return (<PreferenceListItem settingKey={this.getKey()}
            label="Forma de Pagamento Padrão"
            valueLabel={ valueLabel }
            clear={()=>{
                this.preferencesService.clearDefaultPaymentMethodId();
                state.defaultPaymentMethod = null;
                forceUpdate();
            }}
            onClick={()=> openSelector(this.getKey()) }
        />);
    }
}