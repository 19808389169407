//@flow
export function rowCentered(props: { content: string, isHeader?:bool }){
    return `<div style="text-align: center; text-transform: ${props.isHeader||false?'uppercase':'inherit'}">
        ${props.content||'&nbsp;'}
    </div>`;
}

export function rowRightAligned(props: { content: string }){
    return `<div style="text-align: right;}">
        ${props.content||'&nbsp;'}
    </div>`;
}

export function rowItemDescription(props: { item: string, description: string, isHeader?: bool }){
    return `<div style="display:flex; text-transform: ${props.isHeader||false?'uppercase':'inherit'}">
        <div style="width:20%; margin-right:2%">${props.item}</div>
        <div style="width:78%">${props.description}</div>
    </div>`;
}

export function rowItemValues(props: { amount: string, unity: string, unityValue: string, totalValue: string, isHeader?: bool }){
    return `<div style="display:flex; text-transform: ${props.isHeader||false?'uppercase':'inherit'}">
        <div style="width: 20%; margin-right: 2%; text-align:right">${props.amount}</div>
        <div style="width: 20%; margin-right: 2%; text-align:left">${props.unity}</div>
        <div style="width: 27%; margin-right: 2%; text-align:right">${props.unityValue}</div>
        <div style="width: 27%; text-align:right">${props.totalValue}</div>
    </div>`;
}

export function rowLabelValue(props: { label: string, value: string }){
    return `<div style="display:flex;">
        <div style="margin-right:1%; width:49%; text-align: left">${props.label}:</div>
        <div style="margin-left:1%; width:49%; text-align: right">${props.value}</div>
    </div>`;
}

export function horizontalLine(props?: {}){
    return `<div style="height:0; width:100%; border-bottom: solid 1px black; transform: scaleY(0.33);"></div>`;
}

export function lightHorizontalLine(props?: {}){
    return `<div style="height:0; width:'100%'; border-bottom:dotted 1px black; transform: scaleY(0.33)"></div>`;
}

export function rowQRAndContent(props: { qr: string, content: string }){
    return `<div style="display:flex;">
        <div style="min-width:2.5cm; width:3cm; margin-right:1em; margin-top:0.2em; margin-bottom:0.2em;">${props.qr}</div><div>${props.content}</div>
    </div>`;
}
export function row(props: { content: string }){
    return `<div>${props.content}</div>`;
}