//@flow
import * as React from 'react';
import * as Events from './../../events';
import { ProductSearch } from './components/ProductSearch';
import { CustomerAdvancedSelect } from './../../components/CustomerAdvancedSelect';
import { SmartCustomerDetail } from './../../components/CustomerDetail';
import { ProductsShortcuts } from './components/ProductsShortcutsSmart';
import { PaymentAccordionForm as PaymentForm } from './components/PaymentAccordionForm';
import { Tabs } from './components/Tabs';
import { SaleItems } from './components/SaleItems';
import { TotalBar } from './components/TotalBar';
import { withContainerFineGrained } from './../../container';
import type { BusinessUnit, Customer, Product, SaleItem, Sale as SaleType, PaymentMethod } from './../../types';
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton, Paper, Typography, Zoom, Tooltip } from '@material-ui/core/';
import { Check as CheckIcon, ArrowForward as ArrowForwardIcon, AttachMoney as AttachMoneyIcon } from '@material-ui/icons/';
import { Debounce } from './../../util/debounce';
import { ExtendedError } from './../../util/error';
import { EditCustomerDialog } from './../EditCustomerDialog/EditCustomerDialog';
import { SetGlobalDiscountButton } from './components/SetGlobalDiscountButton'
import { ClearSaleButton } from './components/ClearSaleButton'

import type { SaleProps, SaleExtendedProps, SaleState } from './types';
import { styles } from './styles';

class SaleExtended extends React.Component<SaleExtendedProps,SaleState>{

    businessUnit: BusinessUnit;
    unhighlightDebounce: Debounce;
    productFilter: string;
    customerFilter: string;
    productSearchRef: any;   
    customerAdvancedSelectRef: any;
    tabsRef: any;
    increment: number = 0; //workaround para limpar um componente que não é controlável
    multiplierRegExp = /^ *([0-9]+[*])?(.*)$/;
    paymentFormRef: any = null;

    constructor(props: SaleExtendedProps){
        super(props);
        this.unhighlightDebounce = new Debounce(300);
        
        const bu = props.preferencesService.getBusinessUnit();
        if(bu==null) throw new Error('É necessário uma unidade de negócio para vender.');
        this.businessUnit = bu;

        this.state = this.getInitialState();
    }


    getInitialState(): SaleState {

        let printOption = (this.props.preferencesService.getDefaultPrintOption()||'');
        if(printOption==='invoice'&&!this.businessUnit.invoiceEnabled){
            printOption = '';
        }
        

        return {
            productsSearchResult: [],
            productsShortcuts: [],
            highlightedItem: null,
            paymentMethods: [],
            paymentConditions: [],
            customersSearchResult: [],
            selectedCustomer: null,
            openEditCustomerDialog: false,
            items: [],
            payment:{
                paymentMethodId: (this.props.preferencesService.getDefaultPaymentMethodId()||''),
                paymentConditionId: (this.props.preferencesService.getDefaultPaymentConditionId()||''),
                paymentValue: 0,
                discount: 0,
                printOption,
            },
            isLastTab: false
        };
    }

    resetState = () => {
        this.increment+=1;
        let stateUpdate = this.getInitialState();
        delete stateUpdate.paymentMethods;
        delete stateUpdate.paymentConditions;
        this.setState(stateUpdate);
        if(this.tabsRef) this.tabsRef.goToStart();
    }

    applyGlobalDiscount = (money: number) => {
        this.props.saleService.applyGlobalDiscount({items: this.state.items}, money);
        this.suggestPaymentValue();
    }

    refreshProductsShortcuts = () => {
        this.setState({productsShortcuts: this.props.preferencesService.getProductsShortcuts()||[]});
    }

    handleAppEvent = (event: Events.AppEvent) => {
        //data store
        if(event.name===Events.DataStoreServiceEvents.OnCustomerDataUpdated){
            let { customer }: { customer: Customer } = event.data;
            let { selectedCustomer } = this.state;
            if(selectedCustomer && selectedCustomer.customerId === customer.customerId){
                this.setState({selectedCustomer: customer});
            }
        }

        //preferences
        if(event.name===Events.PreferencesServiceEvents.OnPreferenceValueChanged && event.data.key==='productsShortcuts'){
            this.refreshProductsShortcuts();
        }

        //synchronization
        let customer = this.state.selectedCustomer;
        if(customer != null && event.name===Events.SynchronizationServiceEvents.OnEntitySynchronizationEnded){
            let ids: ?Array<any> = event.data.ids;
            if(event.data.entityKey==='customer' && ids!=null){
                if(ids.indexOf(customer.customerId) !== -1||ids.indexOf(customer.tempCustomerId) !== -1){
                    //caso especial: tratando cliente atual alterado durante sincronização
                    if(this.state.openEditCustomerDialog)
                        this.setState({openEditCustomerDialog:false}); //cancelar alterações em andamento
                    this.props.dataStoreService.getCustomerFromOutdated(customer)
                        .then(selectedCustomer=>this.setState({selectedCustomer})); //atualizar usuário selecionado
                }
            }
        }
    }

    async componentDidMount(){
        Events.emitter.subscribe(this.handleAppEvent.bind(this));
        Events.emitter.emit({ name: Events.UIEvents.OnSceneOpened, data: 'Sale', error:null });
        
        let tempSaleState = this.props.saleService.restoreCurrentSaleState();
        let update = {};
        
        let promises = [];
        promises.push(this.props.dataStoreService.listPaymentConditions().then(x => update.paymentConditions = x));
        promises.push(this.props.dataStoreService.listPaymentMethods().then(x => update.paymentMethods = x));
        if(tempSaleState!=null){
            update.items = tempSaleState.items;
            if(tempSaleState.customerId!=null){
                promises.push(this.props.dataStoreService.getCustomer(tempSaleState.customerId).then(x => update.selectedCustomer = x));
            }
            update.payment = {
                ...this.state.payment,
                paymentMethodId: tempSaleState.paymentMethodId,
                paymentConditionId: tempSaleState.paymentConditionId,
                printOption: tempSaleState.printOption,
                paymentValue: tempSaleState.paymentValue
            }
        }
        await Promise.all(promises);
        this.setState(update);
        this.refreshProductsShortcuts();
    }

    componentWillUnmount(){
        Events.emitter.unsubscribe(this.handleAppEvent);
        this.props.saleService.storeTempSaleState({
            items: this.state.items,
            customerId: this.state.selectedCustomer?this.state.selectedCustomer.customerId:null,
            paymentMethodId: this.state.payment.paymentMethodId,
            paymentConditionId: this.state.payment.paymentConditionId,
            printOption: this.state.payment.printOption,
            paymentValue: this.state.payment.paymentValue
        });
    }  

    onProductShortcutClicked = (product: Product) => {
        this.addProduct(product,1);
    }

    onProductSearchItemClicked = (product: Product) => {
        let amount = parseInt(this.productFilter.replace(this.multiplierRegExp,'$1')|| '1');
        this.addProduct(product,amount);
        this.productSearchRef.clear();
    }

    addProduct(product: Product, amount: number){
        let items = this.state.items.slice(0);
        let sellValue: number = product.sellValue;

        let insert = () => {
            let item: ?SaleItem = items.find(x => (
                x.product.productId===product.productId
                && x.adjustedSellValue===sellValue
            ));
            if(item!=null){
                item.amount = item.amount + amount;
            }
            else{
                item = { product, amount, discount:0, adjustedSellValue: sellValue };
                items.push(item);
            }
            this.setState({highlightedItem: item, items});
            this.unhighlightDebounce.run(()=>{
                this.setState({highlightedItem: null})
            });
            this.suggestPaymentValue();
        }

        if(sellValue===0.0){
            this.props.calculatorService.openCalculator({}, {
                callbackLabel: `Adicionar ${product.name}`,
                callback: (result)=>{
                    sellValue = parseFloat(result);
                    insert();
                    //para forçar o campo a abrir os itens
                    this.productSearchRef.focus();
                },
            });
        }
        else{
            insert();
        }
    }

    onProductShortcutToggle = (product: Product) => {
        //esse código pode ir pro search!!
        let shortcuts = this.props.preferencesService.getProductsShortcuts()||[];
        let existentIndex = shortcuts.findIndex(x => x.productId == product.productId);
        if(existentIndex!=-1)
            shortcuts.splice(existentIndex,1);
        else
            shortcuts.push({productId: product.productId, color:'default'});
        this.props.preferencesService.setProductsShortcuts(shortcuts);
    }

    onCustomerAdvancedSelectItemClicked(customer: Customer){
        this.setState({selectedCustomer: customer });
        return true;
    }

    handleCustomerFilterChange(filter){
        this.props.dataStoreService.listCustomers(filter, null).then((customers)=>{
            this.customerFilter = filter;
            this.setState({customersSearchResult: customers});
        })
    }

    handleSaleItemDelete = (item: SaleItem)=>{
        let items = this.state.items.slice(0)
            .filter(x => !(x.product.productId===item.product.productId && x.adjustedSellValue === item.adjustedSellValue));
        this.setState({items});
        this.suggestPaymentValue();
    }

    handleSaleItemChange = (item: any)=>{
        this.suggestPaymentValue();
    }

    handleSaleItemDiscountChange = (item: SaleItem, discount: number) => {
        item.discount = discount;
        this.suggestPaymentValue();
    }

    handleSaleItemAmountChange = (item: SaleItem, amount: number) => {
        item.amount = amount;
        this.suggestPaymentValue();
    }

    saleIsValid = () => {
        let { payment } = this.state;
        return this.state.items.length > 0
            && payment.paymentMethodId
            && payment.paymentConditionId
            && payment.paymentValue
            && payment.printOption;
    }

    handleSubmitSale = async (e) => {
        
        if(!this.state.isLastTab){
            this.tabsRef.goNext();
            return;
        }
        if(!this.saleIsValid())
            return;

        let state = this.state;
        //TODO: full validation
        let businessUnit = this.props.preferencesService.getBusinessUnit();
        if(businessUnit==null)
            throw new ExtendedError('Uma unidade de negócio é necessária para efetuar uma venda.', null, 'summary');
        
        let sale: SaleType = {
            paymentMethodId: state.payment.paymentMethodId,
            paymentConditionId: state.payment.paymentConditionId,
            customerId: state.selectedCustomer?state.selectedCustomer.customerId:null,
            autoFetchInvoice: this.state.payment.printOption==='invoice',
            items: state.items,
            date: (new Date()).toJSON(),
            businessUnitId: businessUnit.businessUnitId,
            paidValue: state.payment.paymentValue
        };

        let response = await this.props.dataStoreService.insertSale(sale);
        
        if(this.state.payment.printOption!=='skip'){
            this.props.eventEmitter.emit({
                name: Events.UIEvents.OnSalePrintRequested,
                error: null,
                data: { sale }
            });
        }
        
        this.resetState();
    }

    handleProductFilterChange = (filter: string)=>{
        this.productFilter = filter;
        let clearFilter = filter.replace(this.multiplierRegExp,'$2');
        this.props.dataStoreService.listProducts(clearFilter, null)
        .then((products)=>{
            this.setState({productsSearchResult: products})
        });        
    }

    handleProductSearchRef = (productSearchRef)=>{
        this.productSearchRef = productSearchRef;
    }

    handleGoToAddItemsClick = ()=>{
        if(this.tabsRef!=null) this.tabsRef.goToStart();
    }

    handleTabIndexChange = (index: number) =>{
        let isLastTab = index == 2;
        this.setState({ isLastTab });
        if(isLastTab && this.paymentFormRef!=null){
            setTimeout(()=>{ this.paymentFormRef.onVisible(); },300);
        }
    }

    // PAYMENT RELATED METHODS

    handlePaymentFormRef = (ref: any) => {
        this.paymentFormRef = ref;
    }

    suggestPaymentValue = () => {
        console.log('Payment value suggested.');
        setTimeout(()=>{
            this.setState({ payment: {
                ...this.state.payment,
                paymentValue: this.props.saleService.calculateTotals({items: this.state.items}).total
            }});
        },100);
    }

    handlePaymentModelChange = (attribute: string)=>{
        if(attribute==='paymentMethodId'){
            let paymentMethod: ?PaymentMethod = this.state.paymentMethods.find(x => x.paymentMethodId===this.state.payment.paymentMethodId);
            if(paymentMethod!=null && paymentMethod.type!=='money'){
                this.suggestPaymentValue();
            }
        }
        else{
            this.forceUpdate();
        }
    }

    render(){
        let { classes } = this.props;
        let { items, isLastTab } = this.state;
        let { total, totalRaw } = this.props.saleService.calculateTotals({items: this.state.items});

        return (
        <React.Fragment>
            <div className={ classes.root }>
                <div className={ classes.leftColumn }>
                    <SaleItems
                        style={{flex:'1'}}
                        items={ items }
                        highlightedItem={this.state.highlightedItem}
                        onItemDelete={ this.handleSaleItemDelete }
                        onItemChange={ this.handleSaleItemChange }
                        onItemDiscountChange={ this.handleSaleItemDiscountChange }
                        onItemAmountChange={ this.handleSaleItemAmountChange }
                    />
                    {/* <Button onClick={()=>{this.productSearchRef.focus()}}>Focus</Button> */}
                    <TotalBar style={{flex:'0'}} items={ items }>
                        <Tooltip title="Remover Todos Itens" disableTouchListener disableFocusListener>
                            <ClearSaleButton onConfirmClick={this.resetState} className={classes.totalBarIcon} />
                        </Tooltip>
                        <Tooltip title="Aplicar Desconto Global" disableTouchListener disableFocusListener>
                            <SetGlobalDiscountButton maxValue={totalRaw} className={classes.totalBarIcon} onConfirmClick={this.applyGlobalDiscount} />
                        </Tooltip>
                    </TotalBar>
                </div>
                <div className={ classes.dividerColumn }></div>
                <div className={ classes.rightColumn }>
                    <Tabs
                        innerRef={(ref)=>{this.tabsRef=ref}}
                        onTabIndexChange={this.handleTabIndexChange}
                        addItemsComponent={(
                            <React.Fragment>
                                <ProductSearch
                                    onProductClick={this.onProductSearchItemClicked}
                                    onProductShortcutToggle={this.onProductShortcutToggle}
                                    products={ this.state.productsSearchResult }
                                    onProductFilterChange={this.handleProductFilterChange}
                                    productsShortcuts={this.state.productsShortcuts}
                                    advancedSelectRef={this.handleProductSearchRef}
                                />
                                <div className={classes.verticalSpacer}></div>
                                <ProductsShortcuts
                                    onProductShortcutClicked={this.onProductShortcutClicked}
                                />
                            </React.Fragment>
                        )}
                        customerComponent={(
                            <React.Fragment>
                                <CustomerAdvancedSelect
                                    key={`customer${this.increment}`}
                                    innerRef={(ref)=>this.customerAdvancedSelectRef=ref}
                                    customers={this.state.customersSearchResult}
                                    selectedCustomer={this.state.selectedCustomer}
                                    onCustomerClick={ this.onCustomerAdvancedSelectItemClicked.bind(this) }
                                    onCustomerDeselect={()=>{ this.setState({selectedCustomer: null}); return true; }}
                                    onCustomerFilterChange={this.handleCustomerFilterChange.bind(this)}
                                    onCreateCustomerClick={()=>{ this.setState({openEditCustomerDialog: true}) }}
                                />
                                <div className={classes.verticalSpacer}></div>
                                { this.state.selectedCustomer? (
                                <Paper style={{padding: '16px'}}>
                                    <SmartCustomerDetail customer={this.state.selectedCustomer} />
                                    <div className={classes.verticalSpacer}></div>
                                    <Button onClick={()=>{this.setState({openEditCustomerDialog:true})}}>Alterar Informações</Button>
                                </Paper>) : (null) }
                            </React.Fragment>
                        )}
                        paymentComponent={(
                            items.length>0 ? (
                                <React.Fragment>
                                    <PaymentForm
                                        paymentMethods={this.state.paymentMethods}
                                        paymentConditions={this.state.paymentConditions}
                                        model={this.state.payment}
                                        total={total}
                                        innerRef={this.handlePaymentFormRef}
                                        onModelChange={this.handlePaymentModelChange}
                                        invoiceEnabled={this.businessUnit.invoiceEnabled}
                                    />
                                </React.Fragment>
                            ) : (<div style={{textAlign: 'center', padding:'3em 0'}}>
                                <p>O pedido está vazio.</p>
                                <p><Button color="primary" onClick={this.handleGoToAddItemsClick}>Adicionar Itens</Button></p>
                            </div>)
                        )}
                    />
                    <Button onClick={this.handleSubmitSale} className={classes.actionButton} disabled={isLastTab && !this.saleIsValid()}
                        variant="fab" color="primary">
                        <Zoom in={isLastTab}><CheckIcon className={classes.actionButtonIcon} style={{position:'absolute'}}/></Zoom>
                        <Zoom in={!isLastTab}><ArrowForwardIcon className={classes.actionButtonIcon} /></Zoom>
                    </Button>
                </div>
            </div>
            <EditCustomerDialog
                key={this.state.selectedCustomer?this.state.selectedCustomer.customerId:''}
                newCustomerName={this.customerFilter}
                customer={this.state.selectedCustomer}
                onClose={()=>{ this.setState({openEditCustomerDialog: false}) }}
                onSubmit={(customer: Customer)=>{
                    this.setState({openEditCustomerDialog: false, selectedCustomer: customer})
                }}
                open={this.state.openEditCustomerDialog}
            />
        </React.Fragment>);
    }
}

let Sale: React.ComponentType<SaleProps> = withContainerFineGrained(
    withStyles(styles, { withTheme: true })(SaleExtended),
    (container)=>{
        return {
            dataStoreService: container.getDataStoreService(),
            synchronizationService: container.getSynchronizationService(),
            preferencesService: container.getPreferencesService(),
            eventEmitter: container.getEventEmitter(),
            saleService: container.getSaleService(),
            calculatorService: container.getCalculatorService(),
        }
    }
);

export { Sale };