//@flow
import * as React from 'react';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';

const styles = theme => ({
});

type TextFieldMaskedProps = {
    id: string,
    label: string,
    mask: any,
    value: string,
    onChange: (e: any)=>void
}

type TextFieldMaskedState = {    
}

type TextFieldMaskedThemedProps = TextFieldMaskedProps & {
    classes: any,
    theme: any
}

export const TextMaskComponentFactory = (mask: Array<any>) => {
        return (props: any) => {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
            {...other}
            ref={inputRef}
            mask={mask}
            placeholderChar={'\u2000'}
            showMask={false}
            />
        );
    }
}

class TextFieldMaskedThemed extends React.Component<TextFieldMaskedThemedProps,TextFieldMaskedState>{

    textMask: any;

    constructor(props: TextFieldMaskedThemedProps){
        super(props);
        this.textMask = TextMaskComponentFactory(props.mask); //TODO: a máscara é fixa após a construção - talvez seja interessante fazer ela atualizável
        this.state = {
            
        };
    }

    handleChange(e: any){
        this.props.onChange(e);
    }

    render(){
        let { id, label } = this.props;
        return (<FormControl className={this.props.classes.formControl}>
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <Input
            value={this.props.value}
            onChange={this.handleChange.bind(this)}
            id={id}
            inputComponent={this.textMask}
          />
        </FormControl>);
    }
}

let TextFieldMasked: React.ComponentType<TextFieldMaskedProps> = (withStyles(styles, { withTheme: true })(TextFieldMaskedThemed): any);

export { TextFieldMasked };