//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Sync as IconSync } from '@material-ui/icons';
import { CircularProgress, Fade } from '@material-ui/core';


const styles = theme => ({
    root: {
        padding: theme.spacing.unit*3,
        textAlign: 'center'
    },
    syncWidget: {
        position: 'relative'
    },
    syncWidgetIcon: {
        fontSize: theme.spacing.unit*4,
        opacity: .2,
        display: 'block',
        margin: `${theme.spacing.unit*2}px auto`
    },
    syncWidgetProgress: {
        position:'absolute',
        left:'50%',
        marginLeft: -(4.0*theme.spacing.unit),
        marginTop: -(2.0*theme.spacing.unit),
        zIndex: 1
    },
    title:{
        marginTop: theme.spacing.unit*3,
        ...theme.typography.title
    },
    primaryText: {
        marginTop: theme.spacing.unit*1,
        ...theme.typography.subheading
    },
    secondaryText: {
        marginTop: theme.spacing.unit*1,
        ...theme.typography.caption
    }
});

type SynchronizationWidgetProps = {
    title: string,
    primaryText: string,
    secondaryText: string,
    spin: bool
}

type SynchronizationWidgetState = {    
}

type SynchronizationWidgetThemedProps = SynchronizationWidgetProps & {
    classes: any,
    theme: any
}

class SynchronizationWidgetThemed extends React.Component<SynchronizationWidgetThemedProps,SynchronizationWidgetState>{

    constructor(props: SynchronizationWidgetThemedProps){
        super(props);
        this.state = {
            
        };
    }

    render(){
        let { classes, primaryText, secondaryText, spin, theme, title } = this.props;

        return <div className={classes.root} >
            <div className={classes.syncWidget}>
                <Fade in={spin} appear={true}>
                    <CircularProgress thickness={2} className={classes.syncWidgetProgress} size={theme.spacing.unit*8} />
                </Fade>
                <IconSync className={classes.syncWidgetIcon} />
            </div>
            { title? (<div className={classes.title}>{title}</div>) : ( null ) }
            { primaryText? (<div className={classes.primaryText}>{primaryText}</div>) : ( null ) }
            { secondaryText? (<div className={classes.secondaryText}>{secondaryText}</div>) : ( null ) }
        </div>;
    }
}

let SynchronizationWidget: React.ComponentType<SynchronizationWidgetProps> = (withStyles(styles, { withTheme: true })(SynchronizationWidgetThemed): any);

export { SynchronizationWidget };