//@flow
import watermark from './watermark.svg';

export const styles = (theme: any) => {
    return {
        root:{
            height:'100%',
            position:'relative',
            display:'flex'
        },
        actionButton:{
            width:'5.5em',
            height:'5.5em',
            position:'absolute',
            bottom:'16px',
            right:'16px',
        },
        actionButtonIcon:{
            fontSize:'2em',
        },
        leftColumn: {
            flex:'50vw 0 0',
            position:'relative',
            display:'flex',
            flexDirection: 'column',
            height:'100%',
            background: `url(${watermark}) center no-repeat`
        },
        dividerColumn:{
            flex:'2px 0 0',
            height:'100%',
            background: theme.palette.primary.dark
        },
        verticalSpacer:{
            height: theme.spacing.unit*2
        },
        rightColumn: {
            flex:1,
            maxHeight:'100%',
            minWidth:'400px',
            overflowY:'scroll'
        },
        tabContentItem:{
            padding: '16px'
        },
        totalBarIcon:{
            marginLeft:8
        }
    }
};