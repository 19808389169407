//@flow

import type { IPrintRequestDataSource } from './print-queue-service';
import type { PrintRequest } from './../../types';
import type { LocalDatabase } from './../../util/local-database';

export class DefaultPrintRequestDataSource implements IPrintRequestDataSource {
    
    _localDatabase: LocalDatabase;

    constructor(localDatabase: LocalDatabase){
        this._localDatabase = localDatabase;
    }
    listPrintRequests(highlightedOnly: bool): Promise<Array<PrintRequest>>{
        return this._localDatabase.listPrintRequests(highlightedOnly);
    }

    putPrintRequest(printRequest: PrintRequest): Promise<void>{
        return this._localDatabase.insertPrintRequest(printRequest);
    }

    getPrintRequestByLocator(locator: string): Promise<?PrintRequest>{
        return this._localDatabase.getPrintRequestByLocator(locator);
    }

    updatePrintRequest(printRequest: PrintRequest): Promise<void>{
        return this._localDatabase.insertOrReplacePrintRequest(printRequest);
    }

    removePrintRequest(printRequest: PrintRequest): Promise<void>{
        return this._localDatabase.removePrintRequest(printRequest);
    }
}