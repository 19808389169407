//@flow

import { Logger } from './../util/logger';
import * as Events from './../events';

export class ErrorLoggerService {

    _logger = new Logger({ name: 'global-errors', keep: 100 });
    _eventEmitter: Events.AppEventEmitter;

    _getNowPrefix(){
        const now = new Date();
        return `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}_${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} > `;
    }

    _handleError = (event: {error: any}) => {  
        const { error } = event;
        this._logger.log(`${this._getNowPrefix()}${error.stack||error.toString()}`);
        return false;
    }

    _handlePromiseError = (event: any) => {
        this._logger.log(`${this._getNowPrefix()}Unhandled rejection (promise: (${event.promise}), reason: (${event.reason})).`);
    }

    constructor(eventEmitter: Events.AppEventEmitter){
        this._eventEmitter = eventEmitter;
        this._eventEmitter.subscribe(this.handleAppEvent);
    }

    handleAppEvent = (event: any)=>{
        if(event.error){
            this._logger.log(`${this._getNowPrefix()}App event error (error: (${event.error.stack||event.error.stack.toString()}).`);
        }
    }

    bind(){
        window.addEventListener('error', this._handleError);
        window.addEventListener('unhandledrejection', this._handlePromiseError);
    }

    async getLastErrors() : Promise<Array<string>>{
        return this._logger.list();
    }
}