//@flow
import * as React from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Sync as IconSync } from '@material-ui/icons';
import { CircularProgress, Fade } from '@material-ui/core';

const styles = theme => {
    return {
        root: {
            position:'relative',
            minWidth: '0',
            marginRight: 8
        },
        icon: {
            color: theme.palette.primary.contrastText,
        },
        iconError: {
            color: theme.palette.error.main,
        },
        progress: {
            position:'absolute',
            color: theme.palette.primary.contrastText,
            zIndex: 1
        }
    }
};

type SyncButtonProps = {
    inSync: bool,
    onSyncClick: ()=>void,
    variant?: 'error'
}

type SyncButtonState = {    
}

type SyncButtonThemedProps = SyncButtonProps & {
    classes: any,
    theme: any
}

class SyncButtonThemed extends React.Component<SyncButtonThemedProps,SyncButtonState>{

    constructor(props: SyncButtonThemedProps){
        super(props);
        this.state = {
            
        };
    }

    handleClick(){
        this.props.onSyncClick();
    }

    render(){

        let { classes, theme, inSync, variant } = this.props;

        return (
            <IconButton onClick={this.handleClick.bind(this)} className={classes.root}>
                <Fade in={inSync}>
                { inSync ? (
                    <CircularProgress
                        size={theme.spacing.unit*4}
                        className={ classes.progress }
                        thickness={2} />
                ) : ( <span></span> ) }
                </Fade>
                <IconSync className={classNames(classes.icon,{[classes.iconError]: variant==='error'})} />
            </IconButton>
        );
    }
}

let SyncButton: React.ComponentType<SyncButtonProps> = (withStyles(styles, { withTheme: true })(SyncButtonThemed): any);

export { SyncButton };