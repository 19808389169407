//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Input, TextField } from '@material-ui/core';
import { decimalAdapterFactory } from './InputAdapters';
import numeral from "numeral";
import 'numeral/locales/pt-br';
numeral.locale('pt-br');

const styles = theme => ({
});

type DiscountTextFieldProps = {
    value: number,
    onMoneyChange: (e: any, money: number)=>void,
    refValue: number,
    percentInputProps?: Object,
    percentTextFieldProps?: Object,
    moneyInputProps?: Object,
    moneyTextFieldProps?: Object,
    buttonProps?: Object
}

type DiscountTextFieldState = {
    percentMode: bool,
    percentValue: number
}

type DiscountTextFieldThemedProps = DiscountTextFieldProps & {
    classes: any,
    theme: any
}

class DiscountTextFieldThemed extends React.Component<DiscountTextFieldThemedProps,DiscountTextFieldState>{

    inputElement: any;
    percentNumberFormatAdapter = decimalAdapterFactory(2);
    moneyNumberFormatAdapter = decimalAdapterFactory(2);

    constructor(props: DiscountTextFieldThemedProps){
        super(props);
        this.state = {
            percentMode: false,
            percentValue: 0
        };
    }

    focusPercentElement = ()=>{
        this.inputElement.focus();
        this.inputElement.select();
    }
    
    handlePercentClick = (e: any) => {
        let percent = this.props.value / this.props.refValue * 100.0;
        this.setState({percentValue: percent, percentMode: true});
        setTimeout(this.focusPercentElement,1);
    }

    handleInputRef = (ref: any) => {
        this.inputElement = ref;
    }

    handlePercentChange = (e: any) =>{
        this.setState({percentValue: e.target.rawValue});
        let refValue = this.props.refValue;
        let money = Math.min(refValue, refValue * parseFloat(e.target.rawValue||'0')/100.0);
        this.props.onMoneyChange(e, money);
    }

    handleMoneyChange = (e: any) =>{
        let money: number = parseFloat(e.target.rawValue);
        let refValue = this.props.refValue;
        let rawValue = parseFloat(e.target.rawValue);
        if(rawValue>refValue){
            money = refValue;
        }
        this.props.onMoneyChange(e, money);
    }

    handlePercentBlur = (e: any) =>{
        this.setState({percentMode:false});
    }

    getCurrentPercent(){
        let value = (this.props.value||0)/this.props.refValue*100.0;
        return numeral(value).format('0,0.00');
    }

    render(){
        let { percentInputProps, percentTextFieldProps, moneyInputProps, moneyTextFieldProps, buttonProps } = this.props;
        let { percentMode } = this.state;

        return (<div style={{display: 'flex'}}>
        
            <TextField
                style={{ display: percentMode?'none':undefined }}
                fullWidth
                value={ this.props.value }
                InputProps={{
                    inputComponent: this.moneyNumberFormatAdapter.component,
                    ...moneyInputProps
                }}
                onChange={ this.handleMoneyChange }
                { ...moneyTextFieldProps }
            />

            <TextField
                style={{ display: !percentMode?'none':undefined }}
                fullWidth
                value={ this.state.percentValue }
                InputProps={{
                    inputComponent: this.percentNumberFormatAdapter.component,
                    inputRef: this.handleInputRef,
                    ...percentInputProps
                }}
                onChange={ this.handlePercentChange }
                onBlur={ this.handlePercentBlur }
                { ...percentTextFieldProps }
            />

            <Button style={{minWidth:'30px'}}
                tabIndex={percentMode?'-1':undefined}
                onClick={this.handlePercentClick}
                {...buttonProps}
            >
                {this.getCurrentPercent()}%
            </Button>        
            
        </div>);
    }
}

let DiscountTextField: React.ComponentType<DiscountTextFieldProps> = (withStyles(styles, { withTheme: true })(DiscountTextFieldThemed): any);

export { DiscountTextField };