//@flow

import * as React from 'react';
import { EnableDeveloperUISelector, PreferenceListItem } from './../components';
import type { RenderDisplayItemContext, RenderSelectorContext, PreferenceHandler } from './../Preferences';
import type { IPreferencesService } from './../../../services/preferences-service'
import { resources } from './../../../util/resources';

export class EnableDeveloperUIPreferenceHandler implements PreferenceHandler{
    preferencesService: IPreferencesService;
    
    constructor(preferencesService: IPreferencesService){
        this.preferencesService = preferencesService;
    }
    
    getKey(){
        return 'enableDeveloperUI';
    }

    renderSelector(context: RenderSelectorContext){
        return (<EnableDeveloperUISelector
            onSettingSet={(value)=>{
                this.preferencesService.setEnableDeveloperUI(value==='true');
                let stateUpdate = {}
                this.loadDataIntoState(stateUpdate)
                    .then(()=> context.updateState(stateUpdate));
            }}
            value={this.preferencesService.getEnableDeveloperUI()}
        />);
    }

    async loadDataIntoState(state: any){
        let enableDeveloperUI: ?bool = this.preferencesService.getEnableDeveloperUI();
        state.enableDeveloperUI = enableDeveloperUI;
    }

    resetSetting(state: any): void{
        state.enableDeveloperUI = null;
        this.preferencesService.clearEnableDeveloperUI();
    }

    renderDisplayItem(context: RenderDisplayItemContext){
        let { loaded, state, forceUpdate, openSelector } = context;
        let valueLabel = !loaded? '...' : 'Não Configurado';
        let { enableDeveloperUI } = state;
        if(enableDeveloperUI!=null){
            valueLabel = enableDeveloperUI?'Habilitado':'Não Habilitado';
        }
        return (<PreferenceListItem settingKey={this.getKey()}
            label="Modo Desenvolvedor"
            valueLabel={ valueLabel }
            clear={()=>{
                this.preferencesService.clearEnableDeveloperUI();
                state.enableDeveloperUI = null;
                forceUpdate();
            }}
            onClick={()=> openSelector(this.getKey()) }
        />);
    }
}