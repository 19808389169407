//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { LocalDatabase } from './../util/local-database';

const DB_NAME: string = "skywork-database";

const styles = theme => ({
});

type DatabaseInspectorDialogProps = {
    open: bool,
    onCloseClick: ()=>void
}

type DatabaseInspectorDialogState = {
    allTables: Array<string>,
    table: string,
    property: string,
    matchOperation: string,
    value: string,
    result: string
}

type DatabaseInspectorDialogThemedProps = DatabaseInspectorDialogProps & {
    classes: any,
    theme: any
}

const MATCH_OPERATIONS = [
    'equals',
    'startsWith',
    'startsWithIgnoreCase',
    'all'
];

const FormItem = (props: any)=>{
    return (<div style={{marginBottom:'24px'}}>{props.children}</div>);
}

class DatabaseInspectorDialogThemed extends React.Component<DatabaseInspectorDialogThemedProps,DatabaseInspectorDialogState>{

    localDatabase = new LocalDatabase((key)=>{return Promise.resolve()});

    constructor(props: DatabaseInspectorDialogThemedProps){
        super(props);
        this.state = {
            allTables: [],
            table: 'customers',
            property: 'name',
            matchOperation: MATCH_OPERATIONS[2],
            value: '',
            result: 'Os resultados serão exibidos aqui...'
        };
    }

    handleKeyUp = (e: any)=>{
        if(e.keyCode===13){
            this.handleRunClick(null);
        }
    }

    handleRunClick = (e: any)=>{
        if(e!=null)
            e.preventDefault();
        
        let {table, property, matchOperation, value} = this.state;

        let run = async ()=>{

            this.setState({result:'Executando...'});

            await this.localDatabase.initialize();
                
            try{
                
                let dbTable = this.localDatabase._db.table(table);
                //$FlowFixMe
                let collection = matchOperation==='all'? dbTable.limit(100) : dbTable.where(property)[matchOperation](value).limit(100);

                collection.toArray().then((result)=>{
                    this.setState({result: JSON.stringify(result, null, '  ')});
                });
            }
            catch(e){
                this.setState({result: e.stack});
            }
        }
        run();
    }

    getHandleChange(key: string){
        return (e: any)=>{
            this.setState({[key]: e.target.value});
        }
    }

    render(){
        let { onCloseClick, ...rest } = this.props;
        return <Dialog
                fullScreen={true}
                onBackdropClick={()=>onCloseClick()}
                onEscapeKeyDown={()=>onCloseClick()}

                {...rest}
            >
            <DialogContent style={{display:'flex', height:'100%'}}>
                <div onKeyUp={this.handleKeyUp} style={{flex:'260px 0 0', padding:'16px'}}>
                    <FormItem><TextField fullWidth label="Tabela" value={this.state.table} onChange={this.getHandleChange('table')} /></FormItem>
                    <FormItem><TextField fullWidth label="Propriedade" value={this.state.property} onChange={this.getHandleChange('property')} /></FormItem>
                    <FormItem><Select fullWidth value={this.state.matchOperation} onChange={this.getHandleChange('matchOperation')}>
                        { MATCH_OPERATIONS.map((op)=>(<MenuItem key={op} value={op}>{op}</MenuItem>)) }
                    </Select></FormItem>
                    <FormItem><TextField fullWidth label="Valor" value={this.state.value} onChange={this.getHandleChange('value')} /></FormItem>
                    <Button style={{marginRight:'8px'}} onClick={this.handleRunClick} color="primary" variant="contained">Executar</Button>
                    <Button onClick={onCloseClick}>Ocultar</Button>
                </div>
                <div style={{flex:'24px 0 0'}}></div>
                <Paper style={{ flex:'1', overflow: 'auto', padding:'16px' }}>
                    <pre style={{widh:'100%'}}>{this.state.result}</pre>
                </Paper>
            </DialogContent>
        </Dialog>;
    }
}

let DatabaseInspectorDialog: React.ComponentType<DatabaseInspectorDialogProps> = (withStyles(styles, { withTheme: true })(DatabaseInspectorDialogThemed): any);

export { DatabaseInspectorDialog };