//@flow
import * as React from 'react';
import numeral from "numeral";
import 'numeral/locales/pt-br';
numeral.locale('pt-br');

export const inputAdapterFactory = (config: {
    /* must convert a valid javascript number to the formated value
       ex: "0.0" => R$0,0 */
    format: (input:string)=>any, 
    /* must correct the input to the formated value
       ex: R$0,0, => R$0,0 */
    enforceValue: (input:string)=>any, 
    /* adjust the selection on a formated value when the input gain focus or when shouldSelectAll is matched after a change
       ex: R$0,0 => 0.0 */
    adjustSelection?: (input:HTMLInputElement, event: 'change'|'focus')=>void,
    /* called after any change
       ex: R$0,0 => 0.0 */
    shouldSelectAll?: (input:HTMLInputElement)=>bool,
    /* must convert a formated value to a valid javascript number string
       ex: R$0,0 => 0.0 */
    unformat: (input:string)=>any,
    /* turn an unformatted value to a formatted as as nice as possible */
    prettify: (input:string)=>any
}) =>{

    class Input extends React.Component<any,any>{
        
        displayValue: any;

        constructor(props: any){
            super(props);            
        }

        render(){
            const { inputRef, onChange, onFocus, onBlur, value, ...rest } = this.props;

            if(this.displayValue==null){
                this.displayValue = config.prettify(this.props.value);
            }
            else if(config.unformat(this.displayValue)!=this.props.value){
                this.displayValue = config.prettify(this.props.value);
            }

            return (
                <input
                    {...rest}
                    ref={inputRef}
                    value={this.displayValue}
                    onFocus={(e)=>{
                        let enforcedValue = config.enforceValue(e.target.value);
                        e.target.value = enforcedValue;
                        this.displayValue = enforcedValue;
                        if(config.adjustSelection!=null){
                            let adjustSelection = config.adjustSelection;
                            e.persist();
                            setTimeout(()=>{ adjustSelection(e.target, 'focus'); },0)
                        }
                        if(onFocus!=null)
                            onFocus(e);
                    }}
                    onBlur={(e)=>{
                        if(config.prettify!=null){
                            //$FlowFixMe
                            let prettyValue = config.prettify(config.unformat(e.target.value)); 
                            this.displayValue = prettyValue;
                            e.target.value = prettyValue;
                        }
                        if(onBlur!=null)
                            onBlur(e);
                    }}
                    onChange={(e)=>{
                        let enforcedValue = config.enforceValue(e.target.value);
                        e.target.value = enforcedValue;
                        this.displayValue = enforcedValue;
                        let unformattedValue = config.unformat(e.target.value);
                        e.target.rawValue = unformattedValue;
                        if(config.adjustSelection!=null){
                            let adjustSelection = config.adjustSelection;
                            e.persist();
                            setTimeout(()=>{ adjustSelection(e.target, 'change'); },0);
                        }
                        onChange(e);
                    }}
                />
            );
        }
    }

    return {
        component: Input,
        functions: config
    }
}

function trimExtraDecimals(input: any, precision: number){
    let parts = input.replace('.',',').toString().split(',');
    if(parts.length>1){
        if(parts[1].length>precision)
            parts[1] = parts[1].substr(0,precision);
        return parts[0]+','+parts[1];
    }
    else{
        return parts[0];
    }
}

function selectZeroValue(htmlInput: HTMLInputElement, zeroReg: RegExp, prefix:string){
    let input = htmlInput.value;
    if(zeroReg.test(input)){
        htmlInput.selectionStart = prefix.length;
        htmlInput.selectionEnd = input.length;
        return true;
    }
    return false;
}

function selectEndingZeros(htmlInput: HTMLInputElement){
    let input = htmlInput.value;
    let res = /0+$/.exec(input);
    if(res){
        htmlInput.selectionStart = res.index;
        htmlInput.selectionEnd = input.length;
    }
}

// export const moneyAdapterFactory = ()=> {
//     const prefix = 'R$';
//     const zeroReg = /^(R[$])?0*,?0*$/;
//     return inputAdapterFactory({
//         format: (input: string)=>{
//             return prefix + trimExtraDecimals(input, 2);
//         },
//         enforceValue: (input: string)=>{
//             input = input.replace(/[^0-9,]/gi,'');
//             return prefix + trimExtraDecimals(input, 2);
//         },
//         adjustSelection: (input, event)=>{
//             if(event==='change'){
//                 selectEndingZeros(input);
//             }
//             else if(event==='focus'){
//                 if(!selectZeroValue(input,zeroReg, prefix))
//                     selectEndingZeros(input);
//             }
//         },
//         unformat: (input: string)=>{
//             return parseFloat(input.replace(',','.').replace(prefix,'')||'0');
//         },
//         prettify: (input: string)=>{
//             return numeral(parseFloat(input||0)).format('$0.00');
//         }
//     });
// };

// export const percentAdapterFactory = ()=> {
//     const prefix = '%';
//     const zeroReg = /^[%]?0*,?0*$/;
//     return inputAdapterFactory({
//         format: (input: string)=>{
//             return prefix + trimExtraDecimals(input, 2);
//         },
//         enforceValue: (input: string)=>{
//             input = input.replace(/[^0-9,]/gi,'');
//             return prefix + trimExtraDecimals(input, 2);
//         },
//         adjustSelection: (input, event)=>{
//             if(event==='change'){
//                 selectZeroValue(input,zeroReg, prefix);
//             }
//             else if(event==='focus'){
//                 if(!selectZeroValue(input,zeroReg, prefix))
//                     selectEndingZeros(input);
//             }
//         },
//         unformat: (input: string)=>{
//             return parseFloat(input.replace(',','.').replace(prefix,'')||'0');
//         },
//         prettify: (input: string)=>{
//             return '%'+numeral(parseFloat(input||0)).format('0.00');
//         }
//     });
// };

export const decimalAdapterFactory = (decimalPlaces: number)=> {
    const zeroReg = /^[%]?0*,?0*$/;
    let format = `0.${[...Array(decimalPlaces).keys()].map(()=>'0').join('')}`;
    return inputAdapterFactory({
        format: (input: string)=>{
            return trimExtraDecimals(input, decimalPlaces);
        },
        enforceValue: (input: string)=>{
            input = input.replace(/[^0-9,]/gi,'');
            input = input.replace(/^,/i,'0,');
            return trimExtraDecimals(input, decimalPlaces);
        },
        adjustSelection: (input, event)=>{
            if(event==='focus'){
                input.select();
            }
        },
        unformat: (input: string)=>{
            return parseFloat(input.replace(',','.')||'0');
        },
        prettify: (input: string)=>{
            return numeral(parseFloat(input||0)).format(format);
        }
    });
};