//@flow

export class EventEmitter<T> {
    
    _subscriptions: Array<{
        handler: (T)=>any,
        onceCondition: ?(T)=>bool,
        key?: string
    }>;
    
    constructor(){
        this._subscriptions = [];
    }

    subscribe(handler: (T)=>any, key?: string){
        this.unsubscribe(key||handler);
        this._subscriptions.push({handler, onceCondition: null, key});
    }

    unsubscribe(handler: ((T)=>any)|string){
        let index = -1;
        if(typeof(handler)==='string')
            index = this._subscriptions.findIndex(x => x.key===handler);
        else
            index = this._subscriptions.findIndex(x => x.handler===handler);
        if(index!==-1) 
            this._subscriptions.splice(index, 1);
    }
    

    once(condition: (T)=>bool, handler: (T)=>any, key?: string){
        this._subscriptions.push({handler, onceCondition: condition, key});
    }

    emit(data: T){
        let expiredSubscriptions = [];
        for(let i = 0; i < this._subscriptions.length; i++){
            let subscription = this._subscriptions[i];
            if(subscription.onceCondition && subscription.onceCondition(data)){
                expiredSubscriptions.push(subscription);
                setTimeout(()=>subscription.handler(data),0);
            }
            else{
                setTimeout(()=>subscription.handler(data),0);
            }
        }
        for(let i = 0; i < expiredSubscriptions.length; i++){
            let index = this._subscriptions.indexOf(expiredSubscriptions);
            if(index!==-1) 
                this._subscriptions.splice(index, 1);
        }
    }
}