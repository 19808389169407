//@flow

const ERROR_TAGS = {
    serverValidation: 'Occorreu um erro de validação ao tentar se comunicar com o servidor remoto.',
    serverCommunication: 'Ocorreu um erro de comunicação com o servidor remoto. Não foi possível alcança-lo.',
    summary: 'Ocorreu um erro (em um nível inferior) que interrompeu o processo.',
}

type ValidTags = $Keys<typeof(ERROR_TAGS)>;

export class ExtendedError extends Error{
    
    isExtended: true;
    innerError: ?Error;
    tags: ValidTags;
    data: any;

    constructor(message: string, innerError: ?Error, tag: ValidTags|Array<ValidTags>|null){
        super(message);
        this.isExtended = true;
        if(Array.isArray(tag)){
            this.tags = (tag: any);
        }
        else if(tag!=null){
            this.tags = (tag: any);
        }
        this.innerError = innerError;
    }

    hasTag = (tag: ValidTags) => {
        return this.getErrorWithTag(tag)!=null;
    }

    getErrorWithTag = (tag: ValidTags) => {
        let current: any = this;
        do {
            if(current.tags && current.tags.indexOf(tag)!=-1){
                return current;
            }
            current = current.innerError;
        } while(current!=null);
        return null;
    }
}