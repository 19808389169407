//@flow

import type { IAuthenticationService } from './types';
import * as Events from './../../events';

export class MockAuthenticationService implements IAuthenticationService {

    _setIsAuthenticated(value:bool){
        localStorage.setItem('mockAuth_isAuthenticated', JSON.stringify(value));
    }

    _getIsAuthenticated(){
        return JSON.parse(localStorage.getItem('mockAuth_isAuthenticated')||JSON.stringify(true));
    }

    authenticate({username, secret}: {username: string, secret: string}): void{
        Events.emitter.emit({name: Events.AuthenticationServiceEvents.OnSignInStarted, data:{username}, error: null});
        setTimeout(() => {
            this._setIsAuthenticated(true);
            Events.emitter.emit({name: Events.AuthenticationServiceEvents.OnSignedIn, data:{username}, error: null});
        }, 2000);        
    }

    authenticateWithToken({token}: {token: string}): void{
        Events.emitter.emit({name: Events.AuthenticationServiceEvents.OnSignInStarted, data:{token}, error: null});
        setTimeout(() => {
            this._setIsAuthenticated(true);
            Events.emitter.emit({name: Events.AuthenticationServiceEvents.OnSignedIn, data:{token}, error: null});
        }, 2000);
    }

    isAuthenticated(): bool{
        return this._getIsAuthenticated();
    }

    signOut(){
        setTimeout(() => {
            let username = this.getAuthenticatedUser();
            this._setIsAuthenticated(false);
            Events.emitter.emit({name: Events.AuthenticationServiceEvents.OnSignedOut, data:{username}, error: null});
        }, 50);
    }

    getAuthenticatedUser(){
        if(this._getIsAuthenticated()){
            return {
                email:'development@skywork.com.br',
                username: 'evelopment',
                token: 'FAKE'
            };
        }
        return null;
    }
}