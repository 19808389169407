//@flow
import * as Events from './../../events';
import { LocalDatabase } from './../../util/local-database';
import { SkyworkRestClient } from './../../util/skywork-rest-client';
import type { GetUnidadeNegocioResponse } from './../../util/skywork-rest-client';
import { unidadeDeNegocioToBusinessUnit } from './../../util/skywork-rest-client';
import type {
    BusinessUnit,
    City,
    Country,
    Customer,
    PaymentCondition,
    PaymentMethod,
    Province,
    Product,
    Sale,
    IEvent,
    SyncState
} from './../../types';

import type { 
    IDataStoreService    
} from './types';

export class DefaultDataStoreService implements IDataStoreService
{   
    _localDatabase: LocalDatabase;
    _restClient: SkyworkRestClient;

    constructor(localDatabase: LocalDatabase, restClient: SkyworkRestClient){
        this._localDatabase = localDatabase;
        this._restClient = restClient;
    }

    //READ METHODS
    async listBusinessUnits(): Promise<Array<BusinessUnit>>{
        let response: GetUnidadeNegocioResponse = await this._restClient.getUnidadeNegocio();
        return response.map(unidadeDeNegocioToBusinessUnit);
    }

    listCountries(filter:string, limit: ?number): Promise<Array<Country>>{
        return this._localDatabase.listCountries(filter, limit);
    }

    listCustomers(filter:string, limit: ?number): Promise<Array<Customer>>{
        return this._localDatabase.listCustomers(filter, limit);
    }

    listCities(filter:string, limit: ?number): Promise<Array<[City, ?Province]>>{
        return this._localDatabase.listCities(filter, limit);
    }

    listPaymentMethods(): Promise<Array<PaymentMethod>>{
        return this._localDatabase.listPaymentMethods();
    }

    listPaymentConditions(): Promise<Array<PaymentCondition>>{
        return this._localDatabase.listPaymentConditions();
    }

    listProducts(filter:string, limit: ?number): Promise<Array<Product>>{
        return this._localDatabase.listProducts(filter, limit);
    }

    listProductsIn(ids: Array<string>): Promise<Array<Product>>{
        return this._localDatabase.listProductsIn(ids);
    }

    listSales(): Promise<Array<{sale:Sale, customer:?Customer}>>{
        return this._localDatabase.listSales();
    }

    async listSyncState(table: string): Promise<Array<SyncState>>{
        return this._localDatabase.listSyncState(table);
    }    

    getCountry(id: string) : Promise<?Country>{
        return this._localDatabase.getCountry(id);
    }

    getCity(id: string) : Promise<?[City, ?Province]>{
        return this._localDatabase.getCity(id);
    }

    getCustomerFromOutdated(customer: Customer): Promise<?Customer>{
        return this._localDatabase.getCustomerFromOutdated(customer);
    }

    getCustomer(id: string): Promise<?Customer>{
        return this._localDatabase.getCustomer(id);
    }

    //WRITE METHODS
    insertOrReplaceCustomer(customer: Customer): Promise<void>{
        return this._localDatabase.insertOrReplaceCustomer(customer).then(()=>{
            Events.emitter.emit({name: Events.DataStoreServiceEvents.OnCustomerDataUpdated, data:{ customer, operation:'insert' }, error: null});
        });
    }

    insertSale(sale: Sale): Promise<void>{
        return this._localDatabase.insertSale(sale).then(()=>{
            Events.emitter.emit({name: Events.DataStoreServiceEvents.OnSaleDataInserted, data:{ sale, operation:'update' }, error: null});
        });
    }

    insertOrReplaceSyncState(syncState: SyncState): Promise<void>{
        return this._localDatabase.insertOrReplaceSyncState(((syncState.table):any), syncState.id, syncState.state);
    }

    getLocalCountry(){
        return { countryId:'30', name:'Brasil' };
    }

    getForeignCity(){
        return [{ cityId:'5566', name:'Exterior', provinceId:'28' }, { provinceId:'28', name:'Exterior', shortName:'EXT' }];
    }
}