//@flow
import * as React from 'react';
import { SoftNumber } from './../../../../components/SoftNumber';

type SoftNumberDemoProps = {

}

type SoftNumberDemoState = {
    valor: number,
    valor2: number
}

class SoftNumberDemo extends React.Component<SoftNumberDemoProps,SoftNumberDemoState>{

    constructor(props: SoftNumberDemoProps){
        super(props);
        this.state = {
            valor: 1,
            valor2: 1
        };
    }

    render(){

        return (
        <div>
            <p>Exemplo 1:</p>
            <div>
                <SoftNumber
                    valor={this.state.valor}
                    onChange={(event, valor)=>{ this.setState({valor}); }} />
            </div>
            <p>Exemplo 2 (com onDestroy):</p>
            <div>
                <SoftNumber
                    valor={this.state.valor2}
                    onChange={(event, valor2)=>{ this.setState({valor2}); }}
                    onDestroy={()=>alert('destroy')}
                    />
            </div>
        </div>
        );
    }
}

export default { key: 'soft-number', demoComponent: SoftNumberDemo };
