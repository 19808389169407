//@flow

import Button from "./Button";
import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core';
import { buttonPanelStyle } from './styles';

class ButtonPanel extends React.Component<any,any> {

  handleClick = (buttonName: string) => {
    this.props.clickHandler(buttonName);
  };

  getTip(buttonName: string){
    if(this.props.tips && this.props.tips[buttonName]){
      return this.props.tips[buttonName];
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.componentButtonPanel}>
        <div className={classes.componentButtonPanelChild}>
          <Button name="AC" tip={this.getTip('AC')} clickHandler={this.handleClick} />
          <Button name="+/-" tip={this.getTip('+/-')} clickHandler={this.handleClick} />
          <Button name="%" tip={this.getTip('%')} clickHandler={this.handleClick} />
          <Button name="÷" tip={this.getTip('÷')} clickHandler={this.handleClick} operationStyle />
        </div>
        <div className={classes.componentButtonPanelChild}>
          <Button name="7" clickHandler={this.handleClick} />
          <Button name="8" clickHandler={this.handleClick} />
          <Button name="9" clickHandler={this.handleClick} />
          <Button name="x" clickHandler={this.handleClick} operationStyle />
        </div>
        <div className={classes.componentButtonPanelChild}>
          <Button name="4" clickHandler={this.handleClick} />
          <Button name="5" clickHandler={this.handleClick} />
          <Button name="6" clickHandler={this.handleClick} />
          <Button name="-" clickHandler={this.handleClick} operationStyle />
        </div>
        <div className={classes.componentButtonPanelChild}>
          <Button name="1" clickHandler={this.handleClick} />
          <Button name="2" clickHandler={this.handleClick} />
          <Button name="3" clickHandler={this.handleClick} />
          <Button name="+" tip={this.getTip('+')} clickHandler={this.handleClick} operationStyle />
        </div>
        <div className={classes.componentButtonPanelChild}>
          <Button name="0" clickHandler={this.handleClick} wide />
          <Button name="." tip={this.getTip('.')} clickHandler={this.handleClick} />
          <Button name="=" tip={this.getTip('=')} clickHandler={this.handleClick} operationStyle />
        </div>
      </div>
    );
  }
}

ButtonPanel.propTypes = {
  clickHandler: PropTypes.func,
};

let ButtonPanelStyled: React.ComponentType<any> = (withStyles(buttonPanelStyle, { withTheme: true })(ButtonPanel): any);

export default ButtonPanelStyled;
