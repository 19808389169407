//@flow

import { IContainer } from './types';
import { MockContainer } from './mock-container';
import { DefaultContainer } from './default-container';

let resolvedContainer: ?IContainer = null;

export function containerFactory(): IContainer{
    if(resolvedContainer==null){
        if(process.env.REACT_APP_CONTAINER){
            if(process.env.REACT_APP_CONTAINER==='HOT'){
                resolvedContainer = new DefaultContainer();
            }
            else if(process.env.REACT_APP_CONTAINER==='MOCK'){
                resolvedContainer = new MockContainer();
            }
        }
        if(resolvedContainer==null){
            resolvedContainer = new MockContainer();
        }
        window.skyContainer = resolvedContainer;
    }
    return resolvedContainer;
}