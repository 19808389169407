//@flow

import * as React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

const preventDefault = (event: any) =>{
    event.preventDefault();
}

export class AdvancedSelectItem extends React.PureComponent<{
    invalidateIndex?: any,
    index: number,
    label: string,
    extraCells: any,
    tableRowClass: string,
    itemHeight: any,
    onItemClick: (e:any)=>void
}> {
    render(){
        let { label, index, extraCells, tableRowClass, itemHeight, onItemClick } = this.props;

        return (
            <TableRow
                tabIndex={-1}
                onClick={onItemClick}
                data-index={index}
                onMouseDown={preventDefault}
                hover={true}
                className={tableRowClass}
                style={{height: itemHeight}}
            >
                <TableCell>{label}</TableCell>
                { extraCells? (<React.Fragment>{extraCells}</React.Fragment>) : (null) }
            </TableRow>
        )
    }
}