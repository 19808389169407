//@flow

import type { IPrintHandler } from './../print-queue-service';
import { RawHTMLPrintHandler } from './raw-html-print-handler';
import { IPreferencesService } from './../../../services/preferences-service/'
import { ISaleService } from './../../../services/sale-service/types'
import * as HTML from './../../../util/html-string-gen';
import { LocalDatabase } from './../../../util/local-database';
import type { BusinessUnit, Customer, PaymentMethod, Sale } from './../../../types';
import * as POS from './pos-print-util';
import numeral from "numeral";
import 'numeral/locales/pt-br';
import { toBrazilianNumber, formatCEP, formatCPF, formatCNPJ } from './../../../util/formatters';
numeral.locale('pt-br');

const fontSize = 0.27;
const lineHeight = 1.4;
const formatMoney = (input:string|number) => {
    return numeral(parseFloat(input)).format('0.00');
}
const formatQuantity = (input:string|number) => {
    return numeral(parseFloat(input)).format('0.0000');
}

const zeroPad = (n: number) => {
    return n<10?`0${n}`:n;
}

const formatDateTime = (input: ?string) => {
    if(input==null||input.length===0){ return ""; }
    let date = new Date(input);
    return `${zeroPad(date.getDate())}/${zeroPad(date.getMonth()+1)}/${date.getFullYear()} ${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`;
}

export class ReceiptPrintHTMLRenderer{

    render(
        sale: Sale,
        totals: {total: number, totalDiscount: number, totalRaw: number},
        customer: ?Customer,
        seller: BusinessUnit,
        paymentMethod: PaymentMethod
    ){
        let customerName = customer!=null ? customer.name : '';
        let sAddress = seller.address;
        let sellerFullAddress = `${sAddress.streetName}, 
N${sAddress.number}
${(sAddress.extraInfo?` (${sAddress.extraInfo})`:'')}
, Bairro ${sAddress.neighborhood} - ${sAddress.cityName}
 / ${sAddress.provinceCode} - Brasil. CEP:&nbsp;${formatCEP(sAddress.postalCode)}`;

        let html = HTML.doc({
            title: 'Recibo',
            cssFragments: [
                HTML.cssRobotoMono400FontFace(),
                HTML.cssPrintReset(),
                HTML.cssForSelector('body', {
                    'font-size':fontSize+'cm',
                    'font-family':`'Roboto Monospace', monospace`,
                    'line-height': lineHeight.toString()
                }),
                HTML.cssHideNotPrintMedia()
            ],
            body:`
            ${POS.rowCentered({content: seller.name, isHeader:true})}
            ${POS.rowCentered({content: `${sellerFullAddress}`})}
            ${POS.rowCentered({content: `CNPJ:&nbsp;${formatCNPJ(seller.document)}`})}
            ${POS.rowCentered({content: ""})}
            ${POS.rowCentered({content: "Documento Não Fiscal"})}
            ${POS.rowCentered({content: `${formatDateTime(sale.date)}`})}
            ${POS.rowCentered({content: ""})}
            ${POS.horizontalLine()}
            ${POS.rowItemDescription({isHeader:true, item:"Item", description:"Descrição"})}
            ${POS.rowItemValues({isHeader:true, amount:"Quant.", unity:"Unidade", unityValue:"Val.Un.", totalValue:"Valor T."})}
            ${POS.horizontalLine()}
            ${sale.items.map((item,i) =>(`
                ${ i!==0? POS.lightHorizontalLine() : '' }
                ${POS.rowItemDescription({item:`${i}`, description:item.product.name})}
                ${POS.rowItemValues({
                    amount:formatQuantity(item.amount),
                    unity:item.product.unit,
                    unityValue:formatMoney(item.adjustedSellValue),
                    totalValue: formatMoney(parseFloat(item.adjustedSellValue) * parseFloat(item.amount))
                })}
            `)).join('')}
            ${POS.horizontalLine()}
            ${POS.rowLabelValue({label:'Forma de Pagamento', value:paymentMethod.description})}
            ${POS.rowLabelValue({label:'Valor Total', value:formatMoney(totals.totalRaw)})}
            ${POS.rowLabelValue({label:'Desconto', value:formatMoney(totals.totalDiscount)})}
            ${POS.rowLabelValue({'label':'Valor a Pagar', value:formatMoney(totals.total)})}
            ${POS.rowLabelValue({'label':'Valor Pago', value:formatMoney(sale.paidValue)})}
            ${POS.rowLabelValue({'label':'Troco', value:formatMoney(parseFloat(sale.paidValue - totals.total))})}
            ${POS.horizontalLine()}
            ${customer!=null ? `
                ${POS.rowCentered({content: ""})}
                ${POS.rowCentered({content: 'Cliente:'})}
                ${POS.rowCentered({content: customer.name})}
                ${POS.rowCentered({content: (customer.type==='PERSON'?`CPF:&nbsp;${formatCPF(customer.cpf_cnpj)}`:`CNPJ:&nbsp;${formatCNPJ(customer.cpf_cnpj)}`)})}
            `: ''}
            ${POS.rowCentered({content: ""})}
            ${POS.horizontalLine()}
            ${POS.rowCentered({content:'Gerado por Skywork: www.skywork.com.br'})}`
        });
        return html;
    }
};

export class ReceiptPrintHandler implements IPrintHandler{
    
    htmlPrintHandler: IPrintHandler = new RawHTMLPrintHandler();
    localDatabase: LocalDatabase;
    preferencesService: IPreferencesService;
    saleService: ISaleService;

    constructor(localDatabase: LocalDatabase, preferencesService: IPreferencesService, saleService: ISaleService ){
        this.localDatabase = localDatabase;
        this.preferencesService = preferencesService;
        this.saleService = saleService;
    }

    async print(data: {saleId: string}){
        let sale = await this.localDatabase.getSale(data.saleId);        
        if(sale==null){
            throw new Error('Venda não encontrada.');
        }
        let totals = this.saleService.calculateTotals(sale);
        
        let customer =  sale.customerId!=null ? ( await this.localDatabase.getCustomer(sale.customerId) ) : null;
        let seller = this.preferencesService.getBusinessUnit();
        if(seller==null)
            throw new Error('A operação requer uma Unidade de Negócio.');
        
        let paymentMethod = (await this.localDatabase.listPaymentMethods()).find(x => x.paymentMethodId === sale.paymentMethodId);
            if(paymentMethod==null) throw new Error('Forma de Pagamento não encontrada.');

        return this.htmlPrintHandler.print(
            {
                title:'Recibo',
                html:new ReceiptPrintHTMLRenderer().render(sale, totals, customer, seller, paymentMethod)
            }
        );
    }

    async getLabel(data: {saleId: string}){
        let customerName = 'Cliente Não Identificado';
        let sale = await this.localDatabase.getSale(data.saleId);
        
        if(sale==null) throw new Error('Venda não localizada.');

        if(sale.customerId!=null){
            let customer = await this.localDatabase.getCustomer(sale.customerId);
            if(customer) customerName = customer.name;
        }

        let totals = this.saleService.calculateTotals(sale);

        return `Comprovante: ${customerName}, R$${formatMoney(totals.total)}`;
    }
}