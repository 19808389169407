//@flow
import * as colors from '@material-ui/core/colors';

export type ColorSetup = { main: string, text: ?string, name: string };
let BUTTON_GROUP_COLORS: { [name: string]: ColorSetup }  = {};

for(var key in colors){
    // if(key==='common') continue;
    let color: any = colors[key];
    let main: string = color.main || color.A400;
    BUTTON_GROUP_COLORS[key] = {
        main,
        text: main!=null ? ( color.constrastText || '#ffffff' ) : null,
        name: key
    };
}

let BUTTON_GROUP_COLORS_LIST: Array<ColorSetup> = [];
for(var key in BUTTON_GROUP_COLORS){
    BUTTON_GROUP_COLORS_LIST.push(BUTTON_GROUP_COLORS[key]);
}

let BUTTON_GROUP_COLORS_VALUES: Array<string> = BUTTON_GROUP_COLORS_LIST.map(x => x.main);
let BUTTON_GROUP_COLORS_KEYS: Array<string> = Object.keys(BUTTON_GROUP_COLORS);

export default {
    MAP: BUTTON_GROUP_COLORS,
    LIST: BUTTON_GROUP_COLORS_LIST,
    VALUES: BUTTON_GROUP_COLORS_VALUES,
    KEYS: BUTTON_GROUP_COLORS_KEYS,
}