//@flow
import * as React from 'react';
import { Divider, IconButton, ListItem, ListItemText, ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Print as PrintIcon, Clear as ClearIcon } from '@material-ui/icons';


const styles = theme => ({
    root: {
        position:'relative',
        minWidth: '0',
        marginRight: 8,
        color: theme.palette.primary.contrastText
    },
    menuList:{
        backgroundColor: theme.palette.primary.dark
    },
    topNumber:{
        position: 'absolute', fontSize:12, left:6, top:6, color: theme.palette.primary.contrastText,
    },
    bottomNumber:{
        position: 'absolute', fontSize:12, right:6, bottom:6, opacity:.5, color: theme.palette.primary.contrastText,
    }
});

type PrintItem = {label: string, ready:bool};

type PrintButtonProps = {
    items: Array<PrintItem>,
    onItemClick: (e: any, index: number)=>void,
    onRemoveItemClick: (e: any, index: number)=>void
}

type PrintButtonState = {    
    menuOpen: bool
}

type PrintButtonThemedProps = PrintButtonProps & {
    classes: any,
    theme: any
}

class PrintButtonThemed extends React.Component<PrintButtonThemedProps,PrintButtonState>{

    iconElement: any = React.createRef;

    constructor(props: PrintButtonThemedProps){
        super(props);
        this.state = {
            menuOpen: false
        };
    }

    handleClick = (e)=>{
        this.iconElement = e.currentTarget;
        this.setState({menuOpen:true});
    }

    handleItemClick = (i) => (e) => {
        if(e.currentTarget.dataset.clear){
            this.props.onRemoveItemClick(e, i);
        }
        else{
            this.props.onItemClick(e, i);
        }
        this.handleClose();
    }

    handleClose = ()=>{
        this.setState({menuOpen:false});
    }

    itemToHash(x: {label: string, ready: bool}){
        return x.label+':'+ (x.ready?'1;':'0;');
    }

    shouldComponentUpdate(nextProps: PrintButtonThemedProps, nextState: PrintButtonState){
        return this.props.items.map(this.itemToHash).join('.')!=nextProps.items.map(this.itemToHash).join('.')
        || this.state.menuOpen != nextState.menuOpen;
    }

    handleSeeAllClick = (e)=>{

    }

    notReadyFilter(item: PrintItem){
        return !item.ready;
    }

    readyFilter(item: PrintItem){
        return item.ready;
    }

    render(){

        let { classes, theme } = this.props;
        let readyCount = this.props.items.filter(this.readyFilter).length;
        let notReadyCount = this.props.items.filter(this.notReadyFilter).length;
        return (<React.Fragment>
            <IconButton disabled={this.props.items.length===0} onClick={this.handleClick} className={classes.root}>
                { readyCount? (<span className={classes.topNumber}>{readyCount}</span>) : (null) }
                { notReadyCount? (<span className={classes.bottomNumber}>{notReadyCount}</span>) : (null) }
                <PrintIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                MenuListProps={{className:classes.menuList}}
                anchorEl={this.iconElement}
                open={this.state.menuOpen}
                onClose={this.handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {this.props.items.map((x,i)=>{
                    let handler = this.handleItemClick(i);
                    return (<MenuItem disabled={!x.ready} key={i} onClick={handler}>
                        <ListItemText
                            style={{ paddingRight:48 }}
                            primary={x.label}
                            secondary={x.ready?'Imprimir':'Aguardando retorno'}
                        />
                        <ListItemSecondaryAction>
                            <IconButton data-clear={true} onClick={handler}>
                                <ClearIcon fontSize={'small'} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </MenuItem>);
                })}
                {/* <Divider /> */}
                {/* <MenuItem onClick={this.handleSeeAllClick}>Ver Todos</MenuItem> */}
            </Menu>
        </React.Fragment>);
    }
}

let PrintButton: React.ComponentType<PrintButtonProps> = (withStyles(styles, { withTheme: true })(PrintButtonThemed): any);

export { PrintButton };