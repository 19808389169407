//@flow

import * as React from 'react';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { containerFactory } from './../../container';
import { ErrorDetailDialog } from './ErrorDetailDialog';
import { Button } from '@material-ui/core';
import * as Events from './../../events';


type ErrorWithMessage = {
    obj:any, message:string
};

const container = containerFactory();

const BASE_SNACK_SETUP = {
    anchorOrigin: { vertical:'bottom', horizontal:'left' }
}

export class SnackStackInner extends React.Component<{
    onErrorDetailRequested: (error: ErrorWithMessage)=>void,
    enqueueSnackbar: Function
},any> {

    shouldComponentUpdate(nextProps: any, nextState: any){
        return false;
    }

    appEventHandler = (event: Events.AppEvent) => {
        let error = event.error;
        if(error!=null){
            if(error.isExtended){
                let summaryError = error.getErrorWithTag('summary');
                if(summaryError!=null){
                    let serverCommunicationError = summaryError.getErrorWithTag('serverCommunication');
                    this.enqueueError(`${summaryError.message}${ serverCommunicationError!=null? ' Erro de rede.':'' }`, error);
                    return;
                }
            }
            this.enqueueError('Ocorreu um erro.', error);
        }
        else{
            if(event.name===Events.DataStoreServiceEvents.OnSaleDataInserted){
                if(event.data.sale.autoFetchInvoice){
                    this.enqueueSuccess('Venda efetuada. A impressão da nota estará disponível em breve.');
                }
                else{
                    this.enqueueSuccess('Venda efetuada.');
                }
            }
            if(event.name===Events.SaleServiceEvents.OnSaleInvoiceCreated && event.data.isFirstRequest===true){
                this.enqueueSuccess('Uma nova nota fiscal está disponível para impressão.');
            }
        }
    }

    enqueueError = (message: string, error: any)=>{
        this.props.enqueueSnackbar(message, {
            ...BASE_SNACK_SETUP,
            action: [
                (<Button
                key="info" color="secondary" size="small"
                    onClick={()=>{ this.props.onErrorDetailRequested({message, obj:error}) }}>
                    Info
                </Button>)
            ],
        });
    }

    enqueueSuccess = (message: string)=>{
        this.props.enqueueSnackbar(message, { ...BASE_SNACK_SETUP });
    }

    componentDidMount(){
        container.getEventEmitter().subscribe(this.appEventHandler);
        //this.enqueueSuccess('Notificações prontas.');
    }

    componentWillUnmount(){
        container.getEventEmitter().unsubscribe(this.appEventHandler);
        // container.getNotificationsService().setDisplayErrorHandler(null);
    }

    render(){
        return (
            null
        );
    }
}

const SnackStackInnerSnackbar = withSnackbar(SnackStackInner);

export class SnackStackBinded extends React.Component<any,{detailedError: ?ErrorWithMessage}> {

    constructor(props: any){
        super(props);
        this.state = { detailedError: null };
    }

    handleErrorDetailRequested = (error: ErrorWithMessage)=>{
        this.setState({
            detailedError: error
        });
    }

    handleErrorDetailDialogClose = ()=>{
        this.setState({
            detailedError: null
        });
    }

    render(){
        return (
            <SnackbarProvider maxSnack={3}>
                <React.Fragment>
                    <SnackStackInnerSnackbar onErrorDetailRequested={this.handleErrorDetailRequested} />
                    <ErrorDetailDialog
                        onClose={this.handleErrorDetailDialogClose}
                        error={this.state.detailedError}
                    />
                </React.Fragment>
            </SnackbarProvider>
        );
    }
}