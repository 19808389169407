//@flow
import * as React from 'react';
import { AdvancedSelect } from './../../../../components/AdvancedSelect';

type AdvancedSelectDemoProps = {
    
}


type AdvancedSelectDemoState = {
    items: Array<any>,
    wokingSampleFilter: string,
    workingSampleSelectedItem: ?any
}

const allItems: Array<{label:string, value:string}> = [
    { value:'abacate', label: 'Abacate' },
    { value:'banana', label: 'Banana' },
    { value:'caqui', label: 'Caqui' },
    { value:'laranja', label: 'Laranja' },
    { value:'limao', label: 'Limão' },
    { value:'melancia', label: 'Melancia' },
    { value:'morango', label: 'Morango' },
    { value:'melao', label: 'Melão' },
    { value:'tomate', label: 'Tomate' }
]

class AdvancedSelectDemo extends React.Component<AdvancedSelectDemoProps,AdvancedSelectDemoState>{

    constructor(props: AdvancedSelectDemoProps){
        super(props);
        this.state = {
            items: allItems,
            wokingSampleFilter: '',
            workingSampleSelectedItem: null
        };
    }

    render(){
        let { items, wokingSampleFilter, workingSampleSelectedItem } = this.state;

        const handleCreateClick = () => { alert(`Create item with filter "${wokingSampleFilter}".`) };
        const handleSelectItemClick = () => { alert(`Clicked item.`); return false; };
        const handleSelectFilterChange = () => {};
        const handleSelectItemDeselect = () => {
            if(workingSampleSelectedItem!=null){
                alert(`Tried to deselect item '${JSON.stringify(workingSampleSelectedItem.label)}'.`);
            }
            return false;
        };
        
        return (
            <React.Fragment>
                <p>All items fixed in list, autoFocus enabled</p>
                <AdvancedSelect
                    adapter={(x)=>x}
                    autoFocus={true}
                    selectedItem={ null }
                    items={ items }
                    onFilterChange={handleSelectFilterChange}
                    onSelectItemClick={handleSelectItemClick}
                    onCreateClick={handleCreateClick}
                    onSelectItemDeselect={handleSelectItemDeselect}
                />

                <p>All items fixed in list, first selected and locked</p>
                <AdvancedSelect
                    adapter={(x)=>x}
                    selectedItem={ items[0] }
                    items={ items }
                    onFilterChange={handleSelectFilterChange}
                    onSelectItemClick={handleSelectItemClick}
                    onCreateClick={handleCreateClick}
                    onSelectItemDeselect={handleSelectItemDeselect}
                />

                <p>3 items fixed in list</p>
                <AdvancedSelect
                    adapter={(x)=>x}
                    selectedItem={ null }
                    items={ items.filter((x,i) => i < 3) }
                    onFilterChange={handleSelectFilterChange}
                    onSelectItemClick={handleSelectItemClick}
                    onCreateClick={handleCreateClick}
                    onSelectItemDeselect={handleSelectItemDeselect}
                />

                <p>0 items fixed in list</p>
                <AdvancedSelect
                    adapter={(x)=>x}
                    selectedItem={ null }
                    items={ [] }
                    onFilterChange={handleSelectFilterChange}
                    onSelectItemClick={handleSelectItemClick}
                    onCreateClick={handleCreateClick}
                    onSelectItemDeselect={handleSelectItemDeselect}
                />

                <p>Fully working sample (except for item creation)</p>
                <AdvancedSelect
                    adapter={(x)=>x}
                    selectedItem={ this.state.workingSampleSelectedItem }
                    items={ items.filter(x => {
                        let testStr: String;
                        if(typeof(x.label)!=='string'){
                            testStr = (x.label: any).join(' - ');
                        }
                        else{
                            testStr = (x.label: any);
                        }
                        return testStr.toLowerCase().indexOf(this.state.wokingSampleFilter.toLowerCase()) > -1;
                    })}
                    onFilterChange={(filter)=>{ this.setState({wokingSampleFilter: filter}) }}
                    onSelectItemClick={(item)=>{ this.setState({workingSampleSelectedItem: item}); return true; }}
                    onCreateClick={handleCreateClick}
                    onSelectItemDeselect={(customer)=>{this.setState({workingSampleSelectedItem: null}); return true; }}
                />

                <div style={{marginTop: 400}}></div>
            </React.Fragment>
        );
    }
}

export default { key: 'advanced-select', demoComponent: AdvancedSelectDemo };