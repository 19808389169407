//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import { DefaultPadding } from './../../components/DefaultPadding';
import { NotFullySyncedBlockDetail } from './components/NotFullySyncedBlockDetail';
import { NoBusinessUnitSelectedDetail } from './components/NoBusinessUnitSelectedDetail';
import { containerFactory } from './../../container';
import { BLOCK_KEYS } from './../../services/blocker-service';
const container = containerFactory();
const blockerService = container.getBlockerService();

const styles = theme => ({
});

type BlockProps = {
}

type BlockState = {    
}

type BlockThemedProps = BlockProps & {
    classes: any,
    theme: any
}

class BlockThemed extends React.Component<BlockThemedProps,BlockState>{

    constructor(props: BlockThemedProps){
        super(props);
        this.state = {
            
        };
    }

    render(){
        let blockedReasons = blockerService.getBlockedReasons();

        let showNotFullySyncedBlock = blockedReasons.indexOf(BLOCK_KEYS.NotFullySynced)!=-1;
        let showNoBusinessUnitSelected = blockedReasons.indexOf(BLOCK_KEYS.NoBusinessUnitSelected)!=-1;

        let Item = (props: any)=>{
            return (<Card style={{flex: '1 0 300px', margin:'0 8px 16px 8px'}}>
                <CardContent>
                    <Typography color="textSecondary">{props.title}</Typography>
                    {props.children}
                </CardContent>
            </Card>);
        }

        return (<React.Fragment>
            <div style={{padding:'24px 24px 0 24px'}}>
                <Card>
                    <CardContent>
                        <p>Sua sessão está temporariamente bloqueada devido a algumas ações que devem ser tomadas para preparar o seu ambiente.</p>
                        <Typography variant="body1" color="textPrimary" style={{fontWeight:500}}>Resolva as pendências abaixo:</Typography>
                    </CardContent>
                </Card>
            </div>
            <div style={{flexWrap: 'wrap', display:'flex', padding:16}} >
                {showNoBusinessUnitSelected?(<NoBusinessUnitSelectedDetail />):(null)}               
                {showNotFullySyncedBlock?(<NotFullySyncedBlockDetail />):(null)}                
            </div>
        </React.Fragment>);
    }
}

let Block: React.ComponentType<BlockProps> = (withStyles(styles, { withTheme: true })(BlockThemed): any);

export { Block };