//@flow

import * as React from 'react';
import { DefaultPaymentConditionSelector, PreferenceListItem } from './../components';
import type { RenderDisplayItemContext, RenderSelectorContext, PreferenceHandler } from './../Preferences';
import type { IPreferencesService } from './../../../services/preferences-service'
import type { IDataStoreService } from './../../../services/data-store-service'

export class DefaultPaymentConditionPreferenceHandler implements PreferenceHandler{
    
    preferencesService: IPreferencesService;
    dataStoreService: IDataStoreService;
    
    constructor(preferencesService: IPreferencesService, dataStoreService: IDataStoreService){
        this.preferencesService = preferencesService;
        this.dataStoreService = dataStoreService;
    }
    
    getKey(){
        return 'defaultPaymentConditionId';
    }

    renderSelector(context: RenderSelectorContext){
        return (<DefaultPaymentConditionSelector
            paymentConditions={context.state.paymentConditions||[]}
            onSettingSet={(value)=>{
                this.preferencesService.setDefaultPaymentConditionId(value);
                let stateUpdate = {}
                this.loadDataIntoState(stateUpdate)
                    .then(()=> context.updateState(stateUpdate));
            }}
            value={this.preferencesService.getDefaultPaymentConditionId()}
        />);
    }

    async loadDataIntoState(state: any){
        let defaultPaymentConditionId: ?string = this.preferencesService.getDefaultPaymentConditionId();
        
        if(state.paymentConditions==null)
            state.paymentConditions = await this.dataStoreService.listPaymentConditions();

        if(defaultPaymentConditionId==null)
            state.defaultPaymentCondition = null;
        else
            state.defaultPaymentCondition = state.paymentConditions.find(x => x.paymentConditionId==defaultPaymentConditionId);        
    }

    resetSetting(state: any): void{
        state.defaultPaymentCondition = null;
        this.preferencesService.clearDefaultPaymentConditionId();
    }

    renderDisplayItem(context: RenderDisplayItemContext){
        let { loaded, state, forceUpdate, openSelector } = context;
        let valueLabel = !loaded? '...' : 'Não Configurado';
        let { defaultPaymentCondition } = state;
        if(defaultPaymentCondition!=null){
            valueLabel = defaultPaymentCondition.description;
        }
        return (<PreferenceListItem settingKey={this.getKey()}
            label="Condição de Pagamento Padrão"
            valueLabel={ valueLabel }
            clear={()=>{
                this.preferencesService.clearDefaultPaymentConditionId();
                state.defaultPaymentCondition = null;
                forceUpdate();
            }}
            onClick={()=> openSelector(this.getKey()) }
        />);
    }
}