//@flow
import * as React from 'react';
import { Wizard } from './../../../../components/Wizard';
import type { WizardStep } from './../../../../components/Wizard';
import { Checkbox, FormControlLabel, Paper } from '@material-ui/core';


type WizardDemoProps = {

}

type WizardDemoState = {
    steps: Array<WizardStep>,
    stepsBools:Array<bool>,
    stepsOptionalOnly: Array<WizardStep>,
    stepsOptionalOnlyBools:Array<bool>,
    stepsMandatoryOnly: Array<WizardStep>,
    stepsMandatoryOnlyBools:Array<bool>,
}

class WizardDemo extends React.Component<WizardDemoProps,WizardDemoState>{

    constructor(props: WizardDemoProps){
        super(props);
        this.state = {
            steps: [
                { label:'First', completed: false, optional: true, key:'step1' },
                { label:'Second', completed: false, optional: false, key:'step2' },
                { label:'Third', completed: false, optional: true, key:'step3' }
            ],
            stepsBools:[false,false,false],
            stepsOptionalOnly: [
                { label:'First', completed: false, optional: true, key:'step1' },
                { label:'Second', completed: false, optional: true, key:'step2' },
                { label:'Third', completed: false, optional: true, key:'step3' }
            ],
            stepsOptionalOnlyBools:[false,false,false],
            stepsMandatoryOnly: [
                { label:'First', completed: false, optional: false, key:'step1' },
                { label:'Second', completed: false, optional: false, key:'step2' },
                { label:'Third', completed: false, optional: false, key:'step3' }
            ],
            stepsMandatoryOnlyBools:[false,false,false],
        };
    }

    render(){
        return (<div>
            <Paper style={{padding:24, margin:'0 0 24px 0'}}>
                <h3>Steps (Misc)</h3>
                <Wizard
                    steps={this.state.steps}
                    stepContentRenderer={(step: WizardStep, index: number)=>{
                        return (<FormControlLabel label={step.optional?'You may check this.':'You MUST check this.'} control={
                            <Checkbox checked={this.state.stepsBools[index]} onChange={(e: any)=>{
                                let steps = this.state.steps.slice();
                                steps[index].completed = e.target.checked;
                                let stepsBools = this.state.stepsBools.concat([]);
                                stepsBools[index]=e.target.checked;
                                this.setState({steps, stepsBools});
                            }} />
                        } />)
                    }}
                    onComplete={()=>alert('Completed!')}
                />
            </Paper>
            <Paper style={{padding:24, margin:'0 0 24px 0'}}>
                <h3>Steps (All Optional)</h3>
                <Wizard
                steps={this.state.stepsOptionalOnly}
                stepContentRenderer={(step: WizardStep, index: number)=>{
                    return (<FormControlLabel label={step.optional?'You may check this.':'You MUST check this.'} control={
                        <Checkbox checked={this.state.stepsOptionalOnlyBools[index]} onChange={(e: any)=>{
                            let stepsOptionalOnly = this.state.stepsOptionalOnly.slice();
                            stepsOptionalOnly[index].completed = e.target.checked;
                            let stepsOptionalOnlyBools = this.state.stepsOptionalOnlyBools.concat([]);
                            stepsOptionalOnlyBools[index]=e.target.checked;
                            this.setState({stepsOptionalOnly, stepsOptionalOnlyBools});
                        }} />
                    } />)
                }}
                onComplete={()=>alert('Completed!')}
                />
            </Paper>
            <Paper style={{padding:24, margin:'0 0 24px 0'}}>
                <h3>Steps (All Mandatory)</h3>
                <Wizard
                    steps={this.state.stepsMandatoryOnly}
                    stepContentRenderer={(step: WizardStep, index: number)=>{
                        return (<FormControlLabel label={step.optional?'You may check this.':'You MUST check this.'} control={
                            <Checkbox checked={this.state.stepsMandatoryOnlyBools[index]} onChange={(e: any)=>{
                                let stepsMandatoryOnly = this.state.stepsMandatoryOnly.slice();
                                stepsMandatoryOnly[index].completed = e.target.checked;
                                let stepsMandatoryOnlyBools = this.state.stepsMandatoryOnlyBools.concat([]);
                                stepsMandatoryOnlyBools[index]=e.target.checked;
                                this.setState({stepsMandatoryOnly, stepsMandatoryOnlyBools});
                            }} />
                        } />)
                    }}
                    onComplete={()=>alert('Completed!')}
                />
            </Paper>
        </div>);
    }
}

export default { key: 'wizard', demoComponent: WizardDemo };