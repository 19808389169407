//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { SaleItem } from '../../../types';
import numeral from "numeral";
import 'numeral/locales/pt-br';
import classNames from 'classnames';
import { withContainerFineGrained } from './../../../container';
import type { ISaleService } from './../../../services/sale-service/types';

const styles = theme => {
    let height = theme.spacing.unit * 7 +'px';
    return {
        root:{
            height,
            lineHeight: height,
            textAlign: 'right',
            borderTop: 'solid 1px #e0e0e0'
        },
        childrenWrapper:{
            float:'left'
        },
        discountLabel:{
            color: theme.palette.secondary.main
        },
        totalValue: {
            fontWeight: '400',
            padding:`0 ${theme.spacing.unit * 2+'px'}`,
            display: 'inline-block',
            backgroundColor: theme.palette.primary.dark,
            height,
            color:'white',
            fontSize:'1.6em'

        }
    }
};

type TotalBarProps = {
    items: Array<SaleItem>,
    className? : string,
    children?: any
}

type TotalBarState = {    
}

type TotalBarDumbProps = TotalBarProps & {
    classes: any,
    theme: any,
    saleService: ISaleService
}

class TotalBarDumb extends React.Component<TotalBarDumbProps,TotalBarState>{

    constructor(props: TotalBarDumbProps){
        super(props);
        this.state = {
            
        };
    }

    render(){
        let { items, children, classes, className } = this.props;
        let { totalRaw, totalDiscount, total } = this.props.saleService.calculateTotals({items});
        return <div className={ classNames( classes.root ) }>
            <div className={classes.childrenWrapper}>{children}</div>
            { totalDiscount? (<React.Fragment>
                <span>{ numeral(totalRaw).format('$0,0.00') } &nbsp; </span>
                <span className={classes.discountLabel}> - &nbsp; {numeral(totalDiscount).format('$0,0.00')}</span>
                <span className={classes.discountLabel}> ({numeral(totalDiscount/totalRaw).format('0.00%')})</span>
                <span> &nbsp; = &nbsp; </span>
            </React.Fragment>): (null) }
            
            <span className={ classes.totalValue }> {numeral(total).format('$0,0.00')}</span>
        </div>;
    }
}

let TotalBar: React.ComponentType<TotalBarProps> = withContainerFineGrained(
    (withStyles(styles, { withTheme: true })(TotalBarDumb): any),
    (container,onDestroySlot) => {
        return {
            saleService: container.getSaleService()
        };
    }
);

export { TotalBar };
