//@flow
import * as React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, Step, Stepper, StepButton, StepLabel, Typography } from '@material-ui/core';
import { Debounce } from './../util/debounce'

const styles = theme => ({
    root:{
        display:'flex',
        flexDirection: 'column',
        width:'100%',
    },
    rootFading:{
        pointerEvents: 'none'
    },
    stepper: {
        flex:'0 0 auto',
        background: 'transparent'
    },
    contentWrapper:{
        flex:'1 1 auto',
        position: 'relative',
        overflowX:'hidden',
        overflowY:'auto',
        padding: '0 24px',
        transition: 'opacity linear 0.150s'
    },
    buttonsWrapper:{
        flex:'0 0 auto',
        margin: 12,
        transition: 'opacity linear 0.150s'
    },
    buttonDivider:{
        width: theme.spacing.unit,
        display: 'inline-block'
    }
});

export type WizardStep = {
    label: string,
    optional: bool,
    completed: bool,
    key: string
};

type WizardProps = {
    className?: string,
    steps: Array<WizardStep>,
    stepContentRenderer: (step: WizardStep, index: number)=>React.Element<any>,
    onComplete?: ()=>void,
    style?: any,
    onFadeChanging?: (fadeIn: bool)=>void,
    extraButtons?: Array<React.Element<typeof(Button)>>
}

type WizardState = {
    activeStep: number,
    visible: bool
}

type WizardThemedProps = WizardProps & {
    classes: any,
    theme: any
}

class WizardThemed extends React.Component<WizardThemedProps,WizardState>{

    visibleDebounce: Debounce;

    constructor(props: WizardThemedProps){
        super(props);
        this.state = {
            activeStep: 0,
            visible: true
        };
        this.visibleDebounce = new Debounce(150);
    }

    changeStep(targetStep: number){
        if(this.state.activeStep!==targetStep){
            this.setState({visible: false});
            if(this.props.onFadeChanging){ this.props.onFadeChanging(false) }
            this.visibleDebounce.run(()=>{
                this.setState({activeStep:targetStep, visible: true});
                if(this.props.onFadeChanging){ this.props.onFadeChanging(true) }
            });
        }
    }

    goToNextStep(){
        this.changeStep(this.state.activeStep+1);
    }

    goToPreviousStep(){
        this.changeStep(this.state.activeStep-1);
    }

    handleCompleteClick(){
        this.changeStep(this.props.steps.length-1);
        if(this.props.onComplete)
            this.props.onComplete();
    }

    getNavigation(currentIsOptional: bool, currentIsCompleted:bool,  hasPrevious:bool, hasNext: bool, hasNextMandatory: bool){
        
        let buttons = [];
        if(hasPrevious){
            buttons.push(<Button onClick={this.goToPreviousStep.bind(this)}>Voltar</Button>);
        }
        else{
            buttons.push(<Button disabled={true}>Voltar</Button>);
        }
        if(hasNext){
            if(currentIsCompleted){
                buttons.push(<Button variant="contained" onClick={this.goToNextStep.bind(this)}>Avançar</Button>)
            }
            else{
                if(currentIsOptional){
                    buttons.push(<Button variant="contained" onClick={this.goToNextStep.bind(this)}>Pular</Button>);
                }
                else{
                    buttons.push(<Button variant="contained" disabled={true}>Avançar</Button>)
                }
            }
        }
        else{
            //complete only
            if(currentIsOptional || currentIsCompleted){
                buttons.push(<Button variant="contained" color="primary" onClick={this.handleCompleteClick.bind(this)}>Concluir</Button>);
            }
            else{
                buttons.push(<Button variant="contained" color="primary" disabled={true}>Concluir</Button>);
            }
        }

        if(hasNext && !hasNextMandatory){
            if(currentIsOptional || currentIsCompleted){
                buttons.push(<Button variant="contained" color="primary" onClick={this.handleCompleteClick.bind(this)}>Concluir</Button>)
            }
            else{
                buttons.push(<Button variant="contained" color="primary" disabled={true}>Concluir</Button>)
            }
        }

        if(this.props.extraButtons){
            buttons.push(<span style={{float: 'right'}}>{this.props.extraButtons}</span>);
        }

        return (<div>
            { buttons.map((button, i)=> (<React.Fragment key={`button-${i}`}>{i>0?(<span className={this.props.classes.buttonDivider}></span>):(null)}{button}</React.Fragment> )) }
        </div>);
    }

    render(){
        let { classes, steps, className } = this.props;
        let { activeStep, visible } = this.state;
        let currentStep = steps[activeStep];
        let pathIsClear = true;

        let stepContent = React.cloneElement(this.props.stepContentRenderer(currentStep, activeStep), { key:currentStep.key });

        let stepper = (
            <Stepper nonLinear className={classes.stepper} activeStep={activeStep}>
                { steps.map((step, index) => {

                    let props = {};
                    let optionalInfo = null;
                    let stepButtonProps = {};

                    if (step.optional) {
                        optionalInfo = <Typography variant="caption">Opcional</Typography>;
                    }
                    if (activeStep>index) {
                        props.completed = step.completed;
                    }
                    if(pathIsClear){
                        stepButtonProps.onClick = ()=>{
                            this.changeStep(index);
                        }
                        pathIsClear = step.optional || step.completed;
                    }
                    else{
                        stepButtonProps.disabled=true;
                    }
                    
                    return (<Step key={`step-${step.key||step.label}`} {...props} >
                        <StepButton {...stepButtonProps} >
                            <StepLabel>{step.label} {optionalInfo}</StepLabel>
                        </StepButton>
                    </Step>);
                }) }
            </Stepper>
        );

        let hasNextMandatory: bool = false;
        for(let i = activeStep + 1; i < steps.length; i++){
            if(!steps[i].optional){
                hasNextMandatory = true;
                break;
            }
        }
        let nav = this.getNavigation(currentStep.optional, currentStep.completed, activeStep>0, activeStep<steps.length-1, hasNextMandatory);        

        return (
            <div className={classNames(classes.root, className, { [classes.rootFading]: !visible })} style={this.props.style}>
                {stepper}
                
                <div style={{opacity: visible?1:0}} className={classes.contentWrapper}>
                    { stepContent }
                </div>
                
                <div style={{opacity: visible?1:0}} className={classes.buttonsWrapper}>
                    {nav}
                </div>
            </div>
        )

    }
}

let Wizard: React.ComponentType<WizardProps> = (withStyles(styles, { withTheme: true })(WizardThemed): any);

export { Wizard };