//@flow
import * as React from 'react';
import { Wizard, ValidationBox, TouchController } from'./../../../components';
import type { WizardStep } from'./../../../components/Wizard';
import { AdvancedSelect } from'./../../../components/AdvancedSelect';
import { Validation } from'./../../../util/validation';
import type { Customer, City, Province, Country } from'./../../../types';
import { CUSTOMER_TYPES } from'./../../../types';
import * as validations from'./../../../util/validations';
import { Checkbox, FormControlLabel, TextField, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextMaskComponentFactory } from './../../../components/TextFieldMasked';
import { cpfMask, cnpjMask } from './../../../util/masks';
import { Debounce } from './../../../util/debounce';
import { CustomerDetail } from './../../../components/CustomerDetail';
import { CityAdvancedSelect } from './CityAdvancedSelect';
import { CountryAdvancedSelect } from './CountryAdvancedSelect';
import { PersonTypeOptions } from './PersonTypeOptions'

const styles = theme => ({
    //useless?
});

const MODEL_DEFAULT_VALUES: Customer = {
    customerId: '',
    name:'',
    type: CUSTOMER_TYPES.PERSON,
    cpf_cnpj: '',
    ie: '',
    ICMSTaxpayerType: null,
    email: '',
    phoneNumber: '',
    address: {
        streetName: '',
        number: '',
        postalCode: '',
        neighborhood: '',
        extraInfo: '',
        cityId: null,
        provinceId: null,
        countryId: null
    },
    observation: ''
}

type CustomerFormDumbState = {    
    steps: Array<WizardStep>,
    customer: Customer,
    cityFilter: string,
    cities: Array<[City, ?Province]>,
    selectedCity: ?[City, ?Province],
    countryFilter: string,
    countries: Array<Country>,
    selectedCountry: ?Country,
    toucheds: {},
}

export type CustomerFormDumbProps = {
    className? : string,
    customerName?: string,
    customer: ?Customer,
    onSubmit: (customer: Customer) => void,
    localCountryId: any,
    foreignCity: [City, Province],
    citiesDataSource: (search: string)=>Promise<Array<[City, ?Province]>>,
    countriesDataSource: (search: string)=>Promise<Array<Country>>,
    cityGetter: (id: string)=>Promise<?[City, ?Province]>,
    countryGetter: (id: string)=>Promise<?Country>,
    classes: any,
    theme: any,
}

const FormItemWrapper = ({children}: {children: any})=>{
    return <div style={{margin:'20px 0'}}>{children}</div>
}

export class CustomerFormDumb extends React.Component<CustomerFormDumbProps,CustomerFormDumbState>{

    cpfMaskComponent: Function;
    cnpjMaskComponent: Function;
    preventFormChanges: bool;
    handleCitiesFilterChangeDebounce: Debounce = new Debounce();
    handleCountriesFilterChangeDebounce: Debounce = new Debounce();

    constructor(props: CustomerFormDumbProps){
        super(props);

        let customer: Customer;
        if(props.customer){
            customer = Object.assign({}, MODEL_DEFAULT_VALUES, props.customer);
        }
        else{
            customer = Object.assign({}, MODEL_DEFAULT_VALUES, {name: props.customerName||''});
        }

        this.state = {
            steps: [
                { label:'Cadastro', completed: false, optional: false, key:'basic' },
                { label:'Complemento', completed: false, optional: true, key:'additional' },
                { label:'Confirmação', completed: false, optional: true, key:'review' }
            ],
            customer,
            cityFilter: '',
            cities: [],
            countryFilter: '',
            countries: [],
            selectedCountry: null,
            toucheds: {},
            selectedCity: null
        };

        this.cpfMaskComponent = TextMaskComponentFactory(cpfMask);
        this.cnpjMaskComponent = TextMaskComponentFactory(cnpjMask);
    }

    componentDidMount(){
        let {customer} = this.state;
        
        if(customer.address.cityId!=null){
            this.props.cityGetter(customer.address.cityId)
            .then(selectedCity=> this.setState({selectedCity}));
        }

        if(customer.address.countryId!=null){
            this.props.countryGetter(customer.address.countryId)
            .then(selectedCountry=> this.setState({selectedCountry}));
        }
    }

    handleTouchedsChange = (toucheds: any)=>{
        this.setState({toucheds});
    }

    validateBasicForm(values: Customer) : Validation {
        let validation = new Validation();
        validation.validateRequiredString('name', values.name);
        validation.validateRequiredString('type', values.type);
        if(values.type===CUSTOMER_TYPES.PERSON){
            if(validation.validateRequiredString('cpf', values.cpf_cnpj)){
                validation.validate('cpf', validations.isValidCPF, values.cpf_cnpj);
            }
        }
        else if(values.type===CUSTOMER_TYPES.COMPANY){
            if(validation.validateRequiredString('cnpj', values.cpf_cnpj)){
                validation.validate('cnpj', validations.isValidCNPJ, values.cpf_cnpj);
            }
        }
        else{
            throw new Error('Unexpected customer type.');
        }
        return validation;
    }

    updateObjectByKey(obj: any, key: any, value: any){
        let container = obj;
        let keyParts = key.split('.');
        for(var i = 0; i < keyParts.length-1; i++) container = container[keyParts[i]];
        container[keyParts[keyParts.length-1]] = value;
    }
    
    renderBasicForm(step: WizardStep){
        let values = this.state.customer;
        var validation = this.validateBasicForm(values);
        validation.setToucheds(this.state.toucheds);

        let customerTypeUniqueFields: React.Node;

        if(values.type===CUSTOMER_TYPES.PERSON){
            customerTypeUniqueFields = (
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        key='document' fullWidth={true} label={'CPF'}
                        value={values.cpf_cnpj} onChange={this.getHandleChange('cpf_cnpj')}
                        InputProps={{ inputComponent:this.cpfMaskComponent, id:'cpf' }}
                        error={validation.touchedFieldHasErrors('cpf')}
                    />
                    <ValidationBox errors={validation.getErrorsIfTouched('cpf')} />
                </FormItemWrapper>
            );
        }
        else if(values.type===CUSTOMER_TYPES.COMPANY){
            customerTypeUniqueFields = (
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        key='document' fullWidth={true} label={'CNPJ'}
                        value={values.cpf_cnpj} onChange={this.getHandleChange('cpf_cnpj')}
                        InputProps={{inputComponent: this.cnpjMaskComponent, id:'cnpj' }}
                        error={validation.touchedFieldHasErrors('cnpj')} />
                    <ValidationBox errors={validation.getErrorsIfTouched('cnpj')} />
                </FormItemWrapper>
            );
        }
        else{
            throw new Error('Unexpected customer type.');
        }

        step.completed = validation.valid();
        
        return (<TouchController toucheds={this.state.toucheds} onTouchedsChange={this.handleTouchedsChange}>
            <FormItemWrapper>
                <TextField
                    InputLabelProps={{shrink:true}} autoFocus={true}
                    fullWidth={true} label={'Nome'} value={values.name}
                    inputProps={{id: 'name'}} onChange={this.getHandleChange('name')}
                    error={validation.touchedFieldHasErrors('name')}
                    />
                <ValidationBox errors={validation.getErrorsIfTouched('name')} />
                 
            </FormItemWrapper>
            <FormItemWrapper>
                <PersonTypeOptions
                    onPersonTypeOptionChange={this.handlePersonTypeChange}
                    personTypeOption={values.type}
                />
            </FormItemWrapper>
            { customerTypeUniqueFields }
        </TouchController>);
    }

    validateAdditionalForm(values: Customer) : Validation {
        let validation = new Validation();
        return validation;
    }

    handleCountrySelectFilterChange(countryFilter: any){
        this.setState({countryFilter});
        this.handleCountriesFilterChangeDebounce.run(()=>{
            this.props.countriesDataSource(countryFilter).then((countries)=>{
                this.setState({countries});
            });
        });
    }

    handleCountrySelectItemClick(country: Country){
        Object.assign(this.state.customer.address, MODEL_DEFAULT_VALUES.address, { countryId: country.countryId });
        if(country.countryId===this.props.localCountryId){
            Object.assign(this.state, {selectedCountry: country, selectedCity: null, cityFilter:''});
        }
        else{
            let selectedCity = [this.props.foreignCity[0], (this.props.foreignCity[1]: any)];
            Object.assign(this.state, {selectedCountry: country, selectedCity, cityFilter:''});
        }
        this.forceUpdate();
    }

    handleCountrySelectDeselect(){
        Object.assign(this.state.customer.address, MODEL_DEFAULT_VALUES.address);
        Object.assign(this.state, {selectedCountry: null, selectedCity: null});
        this.forceUpdate();
        return true;
    }

    handleCitySelectFilterChange(cityFilter: any){
        this.setState({cityFilter});
        this.handleCitiesFilterChangeDebounce.run(()=>{
            this.props.citiesDataSource(cityFilter).then((cities)=>{
                this.setState({cities: cities});
            });
        });
    }   

    handleCitySelectItemClick(city: [City, ?Province]){
        let cityId: string = city[0].cityId;
        let provinceId: ?string = city[0].provinceId;
        Object.assign(this.state.customer.address, { cityId, provinceId });
        Object.assign(this.state, {selectedCity: city});
        this.forceUpdate();
    }

    handleCitySelectDeselect(){
        this.setState({selectedCity: null});
        return true;
    }

    handlePersonTypeChange = (e: any) =>{
        let customer = this.state.customer;
        this.setState({customer: { ...customer, type:e.target.value }});
    }

    getHandleChange(key: string){
        return (e: any)=>{
            if(this.preventFormChanges) return;
            let value = typeof(e)==='string'?e:e.target.value;
            this.updateObjectByKey(this.state.customer, key, value);
            this.forceUpdate();
        }
    }

    renderAdditionalForm(step: WizardStep){
        let values = this.state.customer;
        var validation = this.validateAdditionalForm(values);

        let renderHeader = (label: string)=>{
            return <p style={{marginBottom: 0, marginTop:24}}>{label.toUpperCase()}</p>
        }

        let isCompany = this.state.customer.type===CUSTOMER_TYPES.COMPANY;
        step.completed = validation.valid();
        return (<TouchController toucheds={this.state.toucheds} onTouchedsChange={this.handleTouchedsChange}>
            { isCompany ? ( <React.Fragment>
                <FormItemWrapper>
                    {renderHeader('Empresa')}
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        autoFocus={true} fullWidth={true} label={'Inscrição Estadual'}
                        value={values.ie} onChange={this.getHandleChange('ie')} />
                    <ValidationBox errors={validation.getErrors('ie')} />
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        fullWidth={true} label={'Contribuição de ICMS'}
                        value={values.ICMSTaxpayerType||''}
                        onChange={this.getHandleChange('ICMSTaxpayerType')} />
                    <ValidationBox errors={validation.getErrors('ICMSTaxpayerType')} />
                </FormItemWrapper>
            </React.Fragment>
            ) : ( null ) }

            <FormItemWrapper>{renderHeader('Contato')}</FormItemWrapper>
            <FormItemWrapper>
                <TextField
                    InputLabelProps={{shrink:true}}
                    autoFocus={!isCompany} fullWidth={true} label={'Email'}
                    value={values.email||''} onChange={this.getHandleChange('email')} />
                <ValidationBox errors={validation.getErrors('email')} />
            </FormItemWrapper>
            <FormItemWrapper>
                <TextField
                    InputLabelProps={{shrink:true}}
                    fullWidth={true} label={'Telefone'} value={values.phoneNumber||''}
                    onChange={this.getHandleChange('phoneNumber')} />
                <ValidationBox errors={validation.getErrors('phoneNumber')} />
            </FormItemWrapper>

            <FormItemWrapper>{renderHeader('Endereço')}</FormItemWrapper>
            <FormItemWrapper>
                <CountryAdvancedSelect
                    InputLabelProps={{shrink:true}}
                    label="País" countries={this.state.countries} selectedCountry={this.state.selectedCountry}
                    filter={this.state.countryFilter} onFilterChange={this.handleCountrySelectFilterChange.bind(this)}
                    onDeselect={this.handleCountrySelectDeselect.bind(this)}
                    onItemClick={this.handleCountrySelectItemClick.bind(this)} />
            </FormItemWrapper>
            { values.address.countryId!=null ? (<React.Fragment>
                
                <FormItemWrapper>
                    <CityAdvancedSelect
                        InputLabelProps={{shrink:true}}
                        label="Cidade" cities={this.state.cities||[]} filter={this.state.cityFilter}
                        selectedCity={this.state.selectedCity} 
                        onFilterChange={this.handleCitySelectFilterChange.bind(this)}
                        onDeselect={this.handleCitySelectDeselect.bind(this)}
                        disabled={values.address.countryId!=this.props.localCountryId}
                        onItemClick={this.handleCitySelectItemClick.bind(this)} />
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}} fullWidth={true} label={'UF'}
                        value={this.state.selectedCity&&this.state.selectedCity[1]?this.state.selectedCity[1].name:''} readOnly />
                    <ValidationBox errors={validation.getErrors('streetName')} />
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        fullWidth={true} label={'Endereço'} value={values.address.streetName||''}
                        onChange={this.getHandleChange('address.streetName')} />
                    <ValidationBox errors={validation.getErrors('streetName')} />
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        fullWidth={true} label={'Número'} value={values.address.number||''}
                        onChange={this.getHandleChange('address.number')} />
                    <ValidationBox errors={validation.getErrors('number')} />
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        fullWidth={true} label={'CEP'} value={values.address.postalCode||''}
                        onChange={this.getHandleChange('address.postalCode')} />
                    <ValidationBox errors={validation.getErrors('postalCode')} />
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        fullWidth={true} label={'Bairro'} value={values.address.neighborhood||''}
                        onChange={this.getHandleChange('address.neighborhood')} />
                    <ValidationBox errors={validation.getErrors('neighborhood')} />
                </FormItemWrapper>
                <FormItemWrapper>
                    <TextField
                        InputLabelProps={{shrink:true}}
                        fullWidth={true} label={'Complemento'} value={values.address.extraInfo||''}
                        onChange={this.getHandleChange('address.extraInfo')} />
                    <ValidationBox errors={validation.getErrors('extraInfo')} />
                </FormItemWrapper>
            </React.Fragment>) : ( null ) }
            
            <FormItemWrapper>{renderHeader('Observações')}</FormItemWrapper>
            <FormItemWrapper>
                <TextField
                    InputLabelProps={{shrink:true}}
                    fullWidth={true} label={'Observações Gerais'} multiline={true}
                    value={values.observation||''} onChange={this.getHandleChange('observation')} />
                <ValidationBox errors={validation.getErrors('observation')} />
            </FormItemWrapper>
        </TouchController>);
    }

    renderReviewRow(label: string, value: string){
        return <p><span style={{fontWeight:'500'}}>{label}:</span> {value}</p>
    }

    renderReview(step: WizardStep){
        return (<CustomerDetail
            customer={this.state.customer}
            customerCityName={this.state.selectedCity?this.state.selectedCity[0].name:null}
            customerProvinceName={this.state.selectedCity&&this.state.selectedCity[1]?this.state.selectedCity[1].name:null}
            customerCountryName={this.state.selectedCountry?this.state.selectedCountry.name: null}
            />);
    }

    handleWizardComplete(){
        this.props.onSubmit(this.state.customer);
    }

    render(){

        let { classes } = this.props;

        return (
        <Wizard
            className={this.props.className}
            steps={this.state.steps}
            onFadeChanging={(fadeIn)=>{ this.preventFormChanges = !fadeIn; }}
            stepContentRenderer={(step: WizardStep, index: number)=>{
                if(step.key==='basic'){
                    return this.renderBasicForm(step);
                }
                else if(step.key==='additional'){
                    return this.renderAdditionalForm(step);
                }
                else if(step.key==='review'){
                    return this.renderReview(step);
                }
                else{
                    return <p>Not implemented</p>;
                }
            }}
            onComplete={this.handleWizardComplete.bind(this)}
        />
        );
    }
}

type CustomerFormDumbStyledProps = $Diff<CustomerFormDumbProps, {theme:any, classes:any}>;
let CustomerFormDumbStyled: React.ComponentType<CustomerFormDumbStyledProps> = (withStyles(styles, { withTheme: true })(CustomerFormDumb): any);
export { CustomerFormDumbStyled };