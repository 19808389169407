//@flow
import * as React from 'react';
import { Calculator } from './../../components/Calculator';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Slide } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { containerFactory } from './../../container/container-factory';
import Draggable from 'react-draggable';

const container = containerFactory();

const styles = theme => ({
    calculatorOverlay: {
        position:'absolute',
        width:'100%',
        height:'100%',
        display:'flex',
        alignItems: 'center',
        justifyContent:'center',
        "&:focus":{
            outline: 'none'
        },
        flexDirection:'column'
    },
    calculatorWrapper: {
        width: '28em',
    },
    calculator: {
      height: '34em'  
    }
});

type CalculatorDialogProps = {

};

type CalculatorDialogState = {
    open: bool,
    actionLabel: ?string,
    action: ?(result: string)=>void,
    abortAction: ?()=>void,
    initialState?: any
};

type _CalculatorDialogProps = CalculatorDialogProps & { theme: any, classes: any };

class _CalculatorDialog extends React.Component<_CalculatorDialogProps,CalculatorDialogState> {

    constructor(props: _CalculatorDialogProps){
        super(props);
        this.state = {open: false, actionLabel: null, action:null, abortAction: null};
    }

    calculatorRef: any

    openHandler = (openContext) => {
        this.setState({
            open:true,
            actionLabel: openContext.callbackLabel||'Executar',
            action:openContext.callback,
            abortAction:openContext.cancelCallback,
            initialState: openContext.initialState
        });
    }

    componentDidMount(){
        container.getCalculatorService().setOpenHandler(this.openHandler);
    }

    handleCalculatorRef = (ref)=>{
        this.calculatorRef = ref;
    }

    handleKeyDown = (e: any)=>{
        let keyCode = e.which;
        let buttonName = '';
        if (keyCode == 8){
            this.calculatorRef.deleteLastChar();
        }
        if (keyCode == 27){
            this.setState({open: false});
        }
        else if (keyCode >= 96 && keyCode <= 105) {
            buttonName = (keyCode - 96).toString();
        }
        else{
            switch (keyCode) {
                case 13: buttonName = "="; break;
                case 12: buttonName = "="; break;
                case 46: buttonName = "AC"; break;
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                    buttonName = (keyCode-48).toString(); break;
                case 106: buttonName = "x"; break;
                case 107: buttonName = "+"; break;
                case 108: buttonName = "."; break;
                case 109: buttonName = "-"; break;
                case 110: buttonName = "."; break;
                case 111: buttonName = "÷"; break;
                case 194: buttonName = "."; break;
                default: break;
            }
        }
        if(buttonName.length && this.calculatorRef){
            e.preventDefault();
            if(buttonName==='=' && this.calculatorRef.isResolved())
                this.handleActionClick();
            else
                this.calculatorRef.triggerButtonByName(buttonName);
        }
    }

    handleClose = ()=>{
        this.setState({open: false});
        if(this.state.abortAction!=null){ this.state.abortAction(); }
    }

    handleActionClick = () => {
        let result = this.calculatorRef.calculate();
        if(this.state.action!=null){
            this.state.action(result.total||result.next||'0.00');
            this.setState({open: false});
        }
    }

    render(){

        let minDimension = Math.min(window.innerWidth, window.innerHeight);
        let width = parseInt(0.8 * minDimension,10);
        let height = parseInt(0.9 * minDimension,10);
        if(window.innerHeight-height<240){
            height = window.innerHeight-240;
        }

        return (this.state.open && <Dialog
            open={this.state.open}
            onKeyDown={this.handleKeyDown}
            // disableAutoFocus={true}
            // TransitionComponent={Draggable}
            // TransitionProps={{ handle: '.calculator-display' }}
            onClose={this.handleClose}
        >
            <DialogContent style={{padding: 0 }}>
                <Calculator
                    buttonTips={{'AC':'DEL'}}
                    ref={(ref)=>{this.calculatorRef = ref; }}
                    onClick={(e)=>e.stopPropagation()}
                    className={this.props.classes.calculator}
                    style={{ height, width }}
                    initialState={this.state.initialState}
                />
                { this.state.action? (
                    <Button style={{ padding: '2.5em' }} variant="contained"
                        color="secondary" size="large" fullWidth
                        onClick={this.handleActionClick}
                    >{ this.state.actionLabel }</Button>
                ) : (null) }
            </DialogContent>
        </Dialog>);
    }
}

export const CalculatorDialog: React.ComponentType<CalculatorDialogProps> = (withStyles(styles, { withTheme: true })(_CalculatorDialog): any);