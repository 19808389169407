//@flow
import type { AuthenticatedUser } from './../types';

const authUserKey = 'defaultAuth_authenticatedUser';

export class LocalStorageAuthenticationDataStore {
    storeAuthenticatedUser(data: AuthenticatedUser): void
    {
        window.localStorage.setItem(authUserKey, JSON.stringify(data));
    }

    clearAuthenticatedUser(): void
    {
        window.localStorage.removeItem(authUserKey);
    }

    getAuthenticatedUser(): ?AuthenticatedUser{
        return JSON.parse(window.localStorage.getItem(authUserKey));
    }

    isAuthenticatedUserStored(): bool{
        return !!window.localStorage.getItem(authUserKey);
    }
}