//@flow

import type { IAuthenticationService, AuthenticatedUser } from './../types';
import { SkyworkRestClient } from './../../../util/skywork-rest-client';
import type { GetLoginPDVResponse } from './../../../util/skywork-rest-client/types';
import * as Events from './../../../events';
import type { IAuthenticationDataStore } from './types';


export class DefaultAuthenticationService implements IAuthenticationService {
    
    _restClient: SkyworkRestClient;
    _dataStore: IAuthenticationDataStore;
    
    constructor(restClient: SkyworkRestClient , dataStore: IAuthenticationDataStore){
        this._restClient = restClient;
        this._dataStore = dataStore;
    }

    handleLoginPromise(promise: Promise<GetLoginPDVResponse>){
        promise.then(
            response=>{                
                this._dataStore.storeAuthenticatedUser({
                    email: response.email,
                    token: response.token,
                    username: response.username
                });
                Events.emitter.emit({
                    name: Events.AuthenticationServiceEvents.OnSignedIn,
                    data:{username: response.username},
                    error: null
                })
            },
            error=>{
                Events.emitter.emit({
                    name: Events.AuthenticationServiceEvents.OnSignedIn,
                    data: null, error
                })
            }
        );
    }
    
    authenticate(credentials: {username: string, secret: string}): void
    {
        Events.emitter.emit({ name: Events.AuthenticationServiceEvents.OnSignInStarted, data: null, error: null });

        this.handleLoginPromise(this._restClient.getLoginPDV({
            username:credentials.username,
            senha:credentials.secret,
            fields: ['username', 'token', 'email']
        }));
    }

    authenticateWithToken(credentials: {token: string}): void
    {
        Events.emitter.emit({ name: Events.AuthenticationServiceEvents.OnSignInStarted, data: null, error: null });

        this.handleLoginPromise(this._restClient.getLoginPDV({
            token: credentials.token,
            fields: ['username', 'token', 'email']
        }));
    }

    isAuthenticated(): bool
    {
        return this._dataStore.isAuthenticatedUserStored();
    }

    getAuthenticatedUser(): ?AuthenticatedUser
    {
        let user = this._dataStore.getAuthenticatedUser();
        if(user!=null){
            return user;
        }
        return null;
    }

    signOut(): void
    {
        this._dataStore.clearAuthenticatedUser();
        Events.emitter.emit({
            name: Events.AuthenticationServiceEvents.OnSignedOut,
            data: null,
            error: null
        })
    }   

}