//@flow
import * as React from 'react';
import type { Customer } from '../types';
import { withStyles } from '@material-ui/core/styles';
import * as Events from './../events';
import { Typography, Divider } from '@material-ui/core';
import numeral from "numeral";
import 'numeral/locales/pt-br';
import { containerFactory } from './../container';

numeral.locale('pt-br');

const styles = theme => ({
    bold: {
        fontWeight: '500',
    },
    title: {
        fontWeight: '500',
        textTransform: 'uppercase',
        marginBottom: '8px'
    },
    typography:{
        marginBottom: '5px',
        fontSize: '14px',
    },
    detailContainer: {
        marginTop: '-15px',
    },
    containerDetailItem: {
        minWidth: '80%',
    },
    buttonContainer: {
        width: '100%',
        float: 'right'
    },
    buttonCreate: {
        float: 'right',
    },
});

type CustomerDetailProps = {
    customer: ?Customer,
    customerCityName: ?string,
    customerProvinceName: ?string,
    customerCountryName: ?string,
}

type CustomerDetailThemedProps =CustomerDetailProps & {
    classes: any,
    theme: any
}

type CustomerDetailState = {};

class CustomerDetailThemed extends React.Component<CustomerDetailThemedProps,CustomerDetailState>{

    constructor(props: CustomerDetailThemedProps){
        super(props);
        this.state = {
            
        };
    }

    renderDetailRow(label: string, value: any){
        let { classes } = this.props;

        return (
            <Typography key={label} component="p" variant="caption" className={classes.typography}>
                <span className={classes.bold}>{label}</span> {value}
            </Typography>
        );
    }

    render(){

        let { classes, customer } = this.props;
        
        let rCustomer: Customer; 
        if(customer!=null){
            rCustomer = customer;
        }
        else{
            rCustomer = {
                customerId: '',
                name: '',
                type: '',
                cpf_cnpj: '',
                ie: '',
                ICMSTaxpayerType: null,
                email: '',
                phoneNumber: '',
                address: {
                    streetName: '',
                    number: '',
                    postalCode: '',
                    neighborhood: '',
                    extraInfo: '',
                    cityId: null,
                    provinceId: null,
                    countryId: null,
                },
                observation: ''
            };
        }

        return (<div>
            <div style={{display:'flex'}}>
            <div style={{flex:1}}>
                <Typography component="p" variant="caption" className={classes.title}>Dados Pessoais</Typography>
                <Divider />
                <div style={{paddingTop:'10px'}}>
                    {/* {this.renderDetailRow('Id:', rCustomer.customerId)} */}
                    {this.renderDetailRow('Nome:', rCustomer.name)}
                    {this.renderDetailRow('Email:', rCustomer.email)}
                    {this.renderDetailRow('Telefone:', rCustomer.phoneNumber)}
                    {
                        rCustomer.type==='PERSON' ? [
                            this.renderDetailRow('Tipo:', 'Pessoa Física'),
                            this.renderDetailRow('CPF:', rCustomer.cpf_cnpj)
                        ] : [
                            this.renderDetailRow('Tipo:', 'Pessoa Jurídica'),
                            this.renderDetailRow('CNPJ:', rCustomer.cpf_cnpj),
                            this.renderDetailRow('IE:', rCustomer.ie),
                            this.renderDetailRow('Contribuição ICMS:', rCustomer.ICMSTaxpayerType||'')
                        ]
                    }
                </div>
            </div>
            <div style={{flex:'16px 0 0'}}></div>
            <div style={{flex:1}}>
                <Typography component="p" variant="caption" className={classes.title}>Endereço</Typography>
                <Divider />
                <div style={{paddingTop:'10px'}}>
                    {this.renderDetailRow('Logradouro:', rCustomer.address.streetName)}
                    {this.renderDetailRow('Número:', rCustomer.address.number)}
                    {this.renderDetailRow('CEP:', rCustomer.address.postalCode)}
                    {this.renderDetailRow('Bairro:', rCustomer.address.neighborhood)}
                    {this.renderDetailRow('Complemento:', rCustomer.address.extraInfo)}
                    {this.renderDetailRow('Cidade:', this.props.customerCityName)}
                    {this.renderDetailRow('UF:', this.props.customerProvinceName)}
                    {this.renderDetailRow('País:', this.props.customerCountryName)}
                </div>
            </div>
            </div>
            <br />
            <div style={{paddingTop:10}}> 
                <Typography component="p" variant="caption" className={classes.title}>Observações</Typography>
                <Divider />
                <div style={{paddingTop:'10px'}}>
                    {this.renderDetailRow('', rCustomer.observation)}
                 </div>
            </div>
        </div>);
    }
}

let CustomerDetail: React.ComponentType<CustomerDetailProps> = (withStyles(styles, { withTheme: true })(CustomerDetailThemed): any);

export { CustomerDetail };


type SmartCustomerDetailProps = {
    customer: ?Customer
}

type SmartCustomerDetailState = {
    customerCity: string,
    customerProvince: string,
    customerCountry: string
}

export class SmartCustomerDetail extends React.Component<SmartCustomerDetailProps,SmartCustomerDetailState>{

    constructor(props: SmartCustomerDetailProps){
        super(props);
        this.state = {
            customerCity: ' - ',
            customerProvince: ' - ',
            customerCountry: ' - '
        }
    }

    handleAppEvent = (event: Events.AppEvent) => {
        if(event.name===Events.DataStoreServiceEvents.OnCustomerDataUpdated){
            this.buildLabels();
        }
    }

    buildLabels = async ()=>{
        let customerCity = '';
        let customerProvince = '';
        let customerCountry = '';
        
        let customer = this.props.customer;
        if(customer!=null){
            let dataStore = containerFactory().getDataStoreService();
            let address = customer.address;
            if(address.cityId!=null){
                await dataStore.getCity(address.cityId).then(city=>{
                    if(city!=null){
                        customerCity = city[0].name;
                        if(city[1]!=null){
                            customerProvince = city[1].name;
                        }
                    }
                })
            }
            if(address.countryId!=null){
                await dataStore.getCountry(address.countryId).then(country=>{
                    if(country!=null){
                        customerCountry = country.name;
                    }
                });
            }
        }
        this.setState({customerCountry, customerProvince, customerCity});
    }

    componentDidMount(){
        this.buildLabels();
        containerFactory().getEventEmitter().subscribe(this.handleAppEvent);
    }

    componentWillUnmount(){
        containerFactory().getEventEmitter().unsubscribe(this.handleAppEvent);
    }

    render(){
        let { customer, ...rest } = this.props;
        return (<CustomerDetail
            customer={customer}
            customerCityName={this.state.customerCity}
            customerProvinceName={this.state.customerProvince}
            customerCountryName={this.state.customerCountry}
            {...rest}
        />);
    }

}