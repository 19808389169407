//@flow
import TabsDemo from './TabsDemo';
import SaleItemsDemo from './SaleItemsDemo';
import TotalBarDemo from './TotalBarDemo';

type DemoItem = { key: string, demoComponent: any };

let items: Array<DemoItem> = [
    SaleItemsDemo,
    TabsDemo,
    TotalBarDemo
].map((item: DemoItem)=>{
    return { key: 'sales/'+item.key, demoComponent:item.demoComponent };
});

export default items;