//@flow
/* eslint-disable */
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Divider, Drawer, List } from '@material-ui/core';
import { containerFactory } from './../../container'
import type { City, Province, PaymentMethod, PaymentCondition } from './../../types';
import * as Handlers from './preference-handlers';
const container = containerFactory();
const dataStoreService = container.getDataStoreService();
const preferencesService = container.getPreferencesService();

const styles = theme => ({
    resetUIContainer:{
        position: 'absolute',
        bottom: '16px',
        left: '16px'
    }
});

type PreferencesProps = {
}

type PreferencesState = {
    open: bool,
    currentSetting: string,
    loaded: bool
} & {[name:string]: any};

type PreferencesThemedProps = PreferencesProps & {
    classes: any,
    theme: any
}

export interface PreferenceHandler{
    getKey(): string;
    renderDisplayItem(context: RenderDisplayItemContext): any;
    renderSelector(context: RenderSelectorContext) : any;
    loadDataIntoState(state: any): Promise<void>;
    resetSetting(state: any): void;
}

export type RenderSelectorContext = {
    state: any,
    updateState: (state: any)=>void
}

export type RenderDisplayItemContext = {
    loaded: bool,
    state: any,
    forceUpdate: ()=>void,
    openSelector: (key:string)=>void
}

class PreferencesThemed extends React.Component<PreferencesThemedProps,PreferencesState>{

    handlers: Array<PreferenceHandler> = [];

    constructor(props: PreferencesThemedProps){
        super(props);
        
        let dataStoreService = container.getDataStoreService();
        let preferencesService = container.getPreferencesService();

        this.handlers.push(new Handlers.DefaultCityPreferenceHandler(preferencesService, dataStoreService));
        this.handlers.push(new Handlers.DefaultPrintOptionPreferenceHandler(preferencesService));
        this.handlers.push(new Handlers.DefaultPaymentMethodPreferenceHandler(preferencesService, dataStoreService));
        this.handlers.push(new Handlers.DefaultPaymentConditionPreferenceHandler(preferencesService, dataStoreService));
        this.handlers.push(new Handlers.CurrentBusinessUnitPreferenceHandler(preferencesService));
        this.handlers.push(new Handlers.OptimizeUIForTouchPreferenceHandler(preferencesService));
        this.handlers.push(new Handlers.EnableDeveloperUIPreferenceHandler(preferencesService));

        this.state = {
            currentSetting: '',
            open: false,
            loaded: false
        };
    }


    componentDidMount(){
        let load = async ()=>{
            let stateUpdate = {};
            await Promise.all(this.handlers.map(x => x.loadDataIntoState(this.state)));
            stateUpdate.loaded = true;            
            this.setState(stateUpdate);
        }
        load();
    }

    handleListItemClick = (e: any)=>{
        this.setState({
            currentSetting: e.currentTarget.dataset.value,
            open: true
        });
    }

    renderCurrentSettingSelector = () => {
        let inner: any = (null);

        let handler = this.handlers.find(x => x.getKey()===this.state.currentSetting);
        if(handler==null) return (null);

        let context = {
            updateState: (state:any)=>{
                this.setState({ open:false, ...state });
            },
            state: this.state
        };
        return (<div style={{minWidth:'33vw', width:400, maxWidth:'90vw'}}>{handler.renderSelector(context)}</div>);
    }

    closeCurrentSettingUI = ()=>{
        this.setState({open: false});
    }

    handleResetCurrentSettingClick = (e: any) => {
        let handler = this.handlers.find(x => x.getKey()===this.state.currentSetting);
        if(handler==null) return;
        handler.resetSetting(this.state);
        this.setState({open: false});
    }

    render(){

        let { classes } = this.props;
        let { loaded, defaultCity, defaultPaymentMethod, defaultPaymentCondition, defaultPrintOption } = this.state;

        let displayItemContext = {
            loaded: this.state.loaded,
            openSelector: (key: string)=>{ this.setState({currentSetting: key, open: true}) },
            forceUpdate: ()=> { this.forceUpdate(); },
            state: this.state
        };

        return (<React.Fragment>
            <List component="nav">
                <Divider />
                { this.handlers.map(x => (
                <React.Fragment key={x.getKey()}>
                    {x.renderDisplayItem(displayItemContext)}
                    <Divider />
                </React.Fragment>)) }
            </List>
            <Drawer
                anchor="right"
                open={this.state.open}
                onClose={this.closeCurrentSettingUI}
                >
                { this.renderCurrentSettingSelector() }
                <div className={classes.resetUIContainer}>
                    <Button variant="contained" color="secondary" onClick={this.handleResetCurrentSettingClick}>Resetar Configuração</Button>
                </div>
            </Drawer>
        </React.Fragment>);
    }
}

let Preferences: React.ComponentType<PreferencesProps> = (withStyles(styles, { withTheme: true })(PreferencesThemed): any);

export { Preferences };