//@flow
/* eslint-disable */

import * as Events from './../../../../events';
import type { ISynchronizer } from './../default-synchronization-service';
import type { SynchronizationEntityData, SynchronizationEntitiesKeys } from './../../types';
import type { Sale } from './../../../../types';
import { SynchronizationEntities } from './../../types';
import { SkyworkRestClient } from './../../../../util/skywork-rest-client';
import type { SetPedidosVendaRequest, SetPedidosVendaItemRequest, SetPedidosVendaItemItemRequest } from './../../../../util/skywork-rest-client';
import { EventEmitter } from './../../../../util/event-emitter';
import { ExtendedError } from './../../../../util/error';
import { LocalDatabase } from './../../../../util/local-database';

export class SalesUpSynchronizer implements ISynchronizer {
    
    _localDatabase: LocalDatabase;
    _restClient: SkyworkRestClient;
    _eventEmitter: EventEmitter<Events.AppEvent>;

    constructor(
        restClient: SkyworkRestClient,
        localDatabase: LocalDatabase,
        eventEmitter: EventEmitter<Events.AppEvent>
    ){
        this._eventEmitter = eventEmitter;
        this._restClient = restClient;
        this._localDatabase = localDatabase;
    }

    dateTimeToFormat(time: number){
        let date = new Date(time);
        return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    }

    async synchronize(): Promise<void>{
        
        let entityKey = 'sale';
        let syncedIds = [];
        let download = false;
        this._eventEmitter.emit({
            name: Events.SynchronizationServiceEvents.OnEntitySynchronizationStarted,
            data:{ entityKey, download},
            error:null
        });

        let error: ?Error = null;
        let touchedIds = [];

        try{
            let ids = await this._localDatabase.getSyncStates('sales','pending');

            for(let i = 0; i < ids.length; i++){

                let id = ids[i];
                let sale: ?Sale = await this._localDatabase.getSale(id);
                let deleteSyncState = true;
                if(sale!=null){
                    
                    let items: Array<SetPedidosVendaItemItemRequest> = sale.items.map(x=>{
                        return {
                            part_number: x.product.sku,
                            qtd: x.amount.toString(),
                            valor_unitario: (x.adjustedSellValue || x.product.sellValue).toString(),
                            valor_desconto: (x.discount).toString(),
                            descricao: x.product.name
                        };
                    });

                    let pedido: SetPedidosVendaItemRequest = {
                        id_sistema_externo: sale.saleId||'',
                        cad_unidade_negocio_id: sale.businessUnitId,
                        com_pedido_situacao_id: '2', //1 = orçamento 2 = aprovado
                        cad_cliente_id: sale.customerId||undefined,
                        dt_pedido: sale.date.split('T')[0],
                        itens: items,
                        con_forma_pgto_id: sale.paymentMethodId,
                        com_condicao_pgto_id: sale.paymentConditionId,
                        NFC: '0',
                        gerar_fatura_pdv: '1', //verificar se podemos permitir que esse parâmetro seja configurável
                        modalidade_frete: '9',
                        origem_pdv: '1',
                    };

                    let setPedidosVendaRequest = {
                        pedidos: [pedido],
                    };

                    try{

                        let salesResponse = await this._restClient.setPedidosVenda(setPedidosVendaRequest);
                        let saleResponse = salesResponse.pedidos[pedido.id_sistema_externo] || salesResponse.pedidos[''];

                        if(saleResponse.erro){
                            // erro não previsto. venda possivelmente não inserida.
                            // vamos bloquear a venda para que não prejudique as próximas sincronizações
                            await this._localDatabase.insertOrReplaceSyncState('sales', id, 'blocked');
                            this._eventEmitter.emit({ name: Events.SynchronizationServiceEvents.OnEntityItemSynchronizationEnded, data:{ entityKey, download, sale }, error: new Error(saleResponse.erro) });
                        }
                        else{

                            //a venda foi sincronizada, então podemos limpar a sincronização
                            await this._localDatabase.deleteSyncState('sales', id);

                            if(sale.autoFetchInvoice){
                                // tentando emitir nota da venda
                                let invoiceResponse = await this._restClient.emiteNotaPedido({
                                    id_sistema_externo: sale.saleId||'',
                                    cad_unidade_negocio_id: sale.businessUnitId}
                                );
                                
                                if(invoiceResponse.erro || invoiceResponse.nfce_status_id!='6' || !invoiceResponse.arquivo_xml){
                                    // erro não previsto ou venda não emitida. venda possivelmente não inserida
                                    // mas a nota foi criada com sucesso no skywork!
                                    // precisamos avisar esse resultado parcial para que os outros componentes possam se virar.
                                    sale.autoFetchInvoice = false;
                                    await this._localDatabase.insertOrReplaceSale(sale);

                                    const error = new Error(`Não foi possível emitir a nota. Status: ${invoiceResponse.status_nome||'?'} ${invoiceResponse.erro?`(${invoiceResponse.erro})`:''}`);
                                    this._eventEmitter.emit({name:Events.SaleServiceEvents.OnSaleInvoiceCreated, data: {sale}, error});
                                }
                                else{
                                    sale.invoiceData = invoiceResponse.arquivo_xml;
                                    await this._localDatabase.insertOrReplaceSale(sale);
                                    this._eventEmitter.emit({name:Events.SaleServiceEvents.OnSaleInvoiceCreated, data: {sale, isFirstRequest: true}, error:null});
                                }
                            }
                        }
                    }
                    catch(e){ //capturando erros de comunicação
                        this._eventEmitter.emit({ name: Events.SynchronizationServiceEvents.OnEntityItemSynchronizationEnded, data:{ entityKey, download, sale }, error: e });
                    }
                    
                    touchedIds.push(sale.saleId);
                }
            }
        }
        catch(e){
            error = new ExtendedError('Ocorreu um erro ao enviar as vendas.', e, 'summary');
        }

        this._eventEmitter.emit({
            name: Events.SynchronizationServiceEvents.OnEntitySynchronizationEnded,
            data:{ entityKey, download, ids:touchedIds },
            error
        });

        if(error) throw error;
    }
    
}