//@flow

import type { Sale, SaleItem } from './../../types';
import type { ISaleService, SaleTempState } from './types';

export class BaseSaleService implements ISaleService {

    storedSale: ?string = null;

    validateSale(sale: Sale): bool{
        return false;
    }

    insertSale(sale: Sale): Promise<void>{
        throw new Error('Not implemented.');
    }

    requestInvoice(sale: Sale): Promise<void>{
        throw new Error('Not implemented.');
    }

    roundDecimal2(value: number): number{
        return +(value.toFixed(2));
    }

    applyGlobalDiscount(sale: {items: Array<SaleItem>}, value: number): void{
        let totals = this.calculateTotals(sale);
        let ratio =  value / totals.totalRaw;
        sale.items.forEach(x => x.discount = this.roundDecimal2(x.adjustedSellValue * x.amount * ratio));
    }

    storeTempSaleState(state: SaleTempState): void{
        this.storedSale = JSON.stringify(state);
    }

    restoreCurrentSaleState() : ?SaleTempState{
        if(this.storedSale==null){ return null };
        let storedSale = this.storedSale;
        this.storedSale = null;
        return JSON.parse(storedSale);
    }

    calculateTotals(sale: {items: Array<SaleItem>}): { totalRaw: number, total:number, totalDiscount: number }{
        let totalRaw = 0.0;
        let totalDiscount = 0.0;
        sale.items.forEach(item=>{
            let itemTotal = item.amount * item.adjustedSellValue;
            totalRaw += itemTotal;
            totalDiscount += item.discount;
        })
        let total = totalRaw - totalDiscount;
        return { totalRaw, total, totalDiscount };
    }

    getPendingInvoiceRequests(): Array<string>{
        return [];
    }
}