//@flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Switch, Zoom } from '@material-ui/core';
import type { PaymentMethod } from './../../../types';
import { EditableButtonGroup, EditableButtonGroupItem } from './../../../components/EditableButtonGroup';
import { withContainerFineGrained } from './../../../container';
import { ButtonPrefEditCheckbox as ButtonPrefEditCheckbox } from './../../../components/ButtonPrefEditCheckbox';

const styles = theme => ({
    
});

type PaymentMethodsShortcutsProps = {
    onPaymentMethodShortcutClick: (PaymentMethod)=>void,
    paymentMethods: Array<PaymentMethod>,
}

type PaymentMethodsShortcutsState = { 
    isEditEnabled: bool,
    paymentMethods: Array<{paymentMethod: PaymentMethod, color: string, index: number}>,
}

type PaymentMethodsShortcutsDumbProps = {
    classes: any,
    theme: any,
    setPaymentMethodsShortcus: (Array<{paymentMethodId: string, color: string}>) => void,
    getPaymentMethodsShortcus: () => ?Array<{paymentMethodId: string, color: string}>,
    onPaymentMethodShortcutClick: (PaymentMethod)=>void,
    paymentMethods: Array<PaymentMethod>,
};

class PaymentMethodsShortcutsDumb extends React.Component<PaymentMethodsShortcutsDumbProps,PaymentMethodsShortcutsState>{
    constructor(props: PaymentMethodsShortcutsDumbProps){
        super(props);
        this.state = {
            isEditEnabled: false,
            paymentMethods: []
        };
    }

    refreshShortcuts = async () =>{
        let shortcuts = this.props.getPaymentMethodsShortcus()||[];
        let paymentMethods = this.props.paymentMethods;

        let shortcutsExtended = (paymentMethods||[]).map(paymentMethod => {
            let index = shortcuts.findIndex(x => x.paymentMethodId == paymentMethod.paymentMethodId);
            let shortcut = shortcuts[index];
            if(shortcut==null){
                return { paymentMethod, color:'default', index:10000 };
            }
            else{
                return { paymentMethod, color:shortcut.color, index };
            }
        });
        shortcutsExtended.sort((a,b)=>{
            return a.index > b.index?1:-1;
        });
        this.setState({paymentMethods: shortcutsExtended});
    }

    componentDidMount = () => {
        this.refreshShortcuts();
    }

    handleEditableButtonItemClicked = (index: number) => {
        this.props.onPaymentMethodShortcutClick(this.state.paymentMethods[index].paymentMethod);
    }

    handleSetItemColorClick = (index: number, color: string) =>{
        let paymentMethods = this.state.paymentMethods.slice(0);
        let shortcut = paymentMethods[index];
        shortcut.color = color;
        this.setState({paymentMethods}, ()=> this.persistShortcuts());
    }

    persistShortcuts(){
        this.props.setPaymentMethodsShortcus(this.state.paymentMethods.map(x =>({
            paymentMethodId: x.paymentMethod.paymentMethodId,
            color: x.color
        })));
    }

    handleEditEnabledToggle = ()=>{
        this.setState({isEditEnabled:false});
    }

    handleToggleChange(event: any){
        this.setState({isEditEnabled: event.target.checked});
    };

    handleSwapItemsClick = (from: number, to: number)=>{
        let paymentMethods = this.state.paymentMethods.slice(0);
        let temp = paymentMethods[from];
        paymentMethods[from] = paymentMethods[to];
        paymentMethods[to] = temp;
        this.setState({paymentMethods}, ()=> this.persistShortcuts());
    }

    render(){
        let { classes, theme } = this.props;
        let { isEditEnabled, paymentMethods } = this.state;
                
        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        }

        return <div style={{width: '100%'}}>
            <EditableButtonGroup
                autoFocusIndex={0}
                editEnabled={isEditEnabled}
                onItemClick={this.handleEditableButtonItemClicked}
                onEditEnabledToggle={this.handleEditEnabledToggle}
                onSetItemColorClick={this.handleSetItemColorClick}
                onSwapItemsClick={this.handleSwapItemsClick}
                items={paymentMethods.map(x => ({name:x.paymentMethod.description, color:x.color}))}
            />
            { paymentMethods.length > 0 ? (
            <Zoom 
                in={ paymentMethods.length > 0 } timeout={transitionDuration}
                style={{ transitionDelay: this.state.isEditEnabled === true ? transitionDuration.exit : 0 }}
                unmountOnExit={true}
            >
                <ButtonPrefEditCheckbox
                    checked={this.state.isEditEnabled}
                    onChange={this.handleToggleChange.bind(this)}
                />
            </Zoom>
        ) : ( null ) }
        </div>
    }
}

let PaymentMethodsShortcuts: React.ComponentType<PaymentMethodsShortcutsProps> = withContainerFineGrained(
    withStyles(styles, { withTheme: true })(PaymentMethodsShortcutsDumb),
    (container, onDestroy)=>{

        return {
            getPaymentMethodsShortcus: ()=> container.getPreferencesService().getPaymentMethodsShortcuts(),
            setPaymentMethodsShortcus: (items)=> container.getPreferencesService().setPaymentMethodsShortcuts(items),
        };
    }
);

export { PaymentMethodsShortcuts };
