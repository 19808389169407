export function cssRobotoMono400FontFace(){
    return `@font-face { 
        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 400;
        src: local('Roboto Mono'), local('Roboto-Mono'),
            url('/static/media/roboto-mono-v5-latin-regular.woff2') format('woff2'),
            url('/static/media/roboto-mono-v5-latin-regular.woff') format('woff'); 
    }`
}

export function cssForSelector(
    selector: string,
    props: { [prop:string]: string }
){
    return `${selector} { ${Object.keys(props).map(key=>`${key}: ${props[key]}; `).join('')} }`;
}

export function cssPrintReset(){
    return `body { margin:0; padding: 2rem 0; }`;
}

export function cssHideNotPrintMedia(
    selector: string,
    props: { [prop:string]: string }
){
    return `@media screen { 
        body * { display:none!important; }
        body {
            background: #eeeeee url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxnPiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+IDwvZz4gPGc+ICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+ICA8cGF0aCBmaWxsPSIjMDAwMDAwIiBmaWxsLW9wYWNpdHk9IjAuMyIgaWQ9InN2Z18xIiBkPSJtMTksOGwtMTQsMGMtMS42NiwwIC0zLDEuMzQgLTMsM2wwLDZsNCwwbDAsNGwxMiwwbDAsLTRsNCwwbDAsLTZjMCwtMS42NiAtMS4zNCwtMyAtMywtM3ptLTMsMTFsLTgsMGwwLC01bDgsMGwwLDV6bTMsLTdjLTAuNTUsMCAtMSwtMC40NSAtMSwtMXMwLjQ1LC0xIDEsLTFzMSwwLjQ1IDEsMXMtMC40NSwxIC0xLDF6bS0xLC05bC0xMiwwbDAsNGwxMiwwbDAsLTR6Ii8+ICA8cGF0aCBpZD0ic3ZnXzIiIGZpbGw9Im5vbmUiIGQ9Im0wLC0wLjA2MjVsMjQsMGwwLDI0bC0yNCwwbDAsLTI0eiIvPiA8L2c+PC9zdmc+) no-repeat center center;
            background-size: 25%;
        }
    }`
}

export function docRooted(data: { cssFragments?: Array<string>, rootId?: string, title?: string }){
    return `<!DOCTYPE html>
<html>
    <head>
        <title>${data.title||''}</title>
        <style type="text/css">
    ${(data.cssFragments||[]).map(x => `       ${x}
    `).join('')}
        </style>
    </head>
    <body>
        <div id="${data.rootId||'container'}"></div>
    </body>
</html>`;
}

export function doc(data: {cssFragments?: Array<string>, body: string, rootId?: string, title?: string}){
    return `<!DOCTYPE html>
<html>
    <head>
        <title>${data.title||''}</title>
        <style type="text/css">
${(data.cssFragments||[]).map(x => `       ${x}
`).join('')}
        </style>
    </head>
    <body><div id="${data.rootId||'container'}">${data.body}</div></body>
</html>`;
}